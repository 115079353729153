import { JsonFormData, LoggerInterface } from '@cheaseed/node-utils';
import { Observable, Subject } from 'rxjs';
import { UserRecord } from './user.models';
import { ExcelUtils } from './excel-utils';

export enum Provider {
  DOCUPANDA = 'docupanda',
  OPENAI = 'openai',
  LLAMAPARSE = 'llamaparse',
}

export enum AgentType {
    'assistant' = 'assistant',
    'completions' = 'completions',
}

export interface FlowDomain {
  docId: string;
  isDefault?: boolean;
  domainName: string;
  domainDescription: string;
  classes: FlowSchemaType[];
  schemas: any[];
}

export interface FlowSchemaType {
  docId: string;                // the firestore doc id
  className: string;
  classificationInstructions: string;
  classId: string;             // the docupanda class id
  description: string;
  schemaName: string;
  schemaId: string;            // the docupanda schema id
  schemaInstructions: string;
  jsonSchema: any;  
  instructions: string;
  useSchemaOfClass: string;
  lastUpdatedAt: Date;
  lastRegeneratedAt: Date;      // when greater than lastUpdatedAt, the schema is considered stale and must be regenerated in docupanda
} 

export interface FlowFile {
    jobId?: string;
    partnerId?: string;
    customerId?: string;
    callbackURL?: string;
    docId: string;
    //userId: string;
    createdAt: Date;
    updatedAt: Date;
    userDocId: string;
    isFolder: boolean;
    containedInFolder: string;
    state: 'uploaded' | 'idle' | 'archived' | 'preparing' | 'running' | 'error' | 'deleting'; // | 'analyzing' | 'analyzed' | 'transforming' | 'transformed' | 'approved' | 'archived' | 'error';
    stateDescription?: string;
    pipelineName: string;
    fileName: string;
    fileType: string;  
    storageName: string;
    downloadURL: string;
    size: number;
    numPages?: number;
    opened?: boolean;
    output?: any;
    outputURL?: string;
    outputStorageName?: string;
    outputType?: string;
    rating?: number;
    externalDocumentId?: string;
    domainName?: string;
    schemas?: any[];
  }

export interface PipelineLoggerInterface  extends LoggerInterface{
    constructPrefix(context: PipelineContext): void
  }
  export interface PipelineContext {
    pipeline: string,
    tool?: string
    userId: string,
    //logger: PipelineLoggerInterface
  }
  export interface FlowToolMetadata {
    name: string;
    description: string;
    className?: string;
    instructions?: string;
    updatedAt?: Date;
  }
  
  export interface FlowTool extends FlowToolMetadata {
    type: string;
    apiType?: AgentType;
    outputType?: string;
    parameters?: JsonFormData;
    assistantId?: string;
    precedents?: FlowTool[];
    checkExecute?: (file: FlowFile, user: UserRecord) => Promise<void>;
    execute: (file: FlowFile, last: FlowStep | null, params?: any) => Promise<any>;
    getContentDisposition: (fileName: string) => string;
    generateOutputPreview?: (output: any) => any;
    startTime?: number;
  }
export abstract class BaseFlowTool implements FlowTool {
    public name: string;
    public type: string;
    public description: string;
    public apiType?: AgentType;
    public outputType?: string;
    public assistantId?: string;
    public parameters?: JsonFormData;
    public instructions?: string;
    public updatedAt?: Date;
    public precedents: FlowTool[] = [];
    public startTime?: number;
    protected flowService!: FileflowServiceInterface;
   
    constructor(flowService?: FileflowServiceInterface) {
        if (flowService)
          this.initialize(flowService)
    }

    protected initialize(flowService: FileflowServiceInterface) {
        this.flowService = flowService;
        return this;
    }
  
    async checkExecute(file: FlowFile, user: UserRecord): Promise<void> {
        await this.flowService.defaultCheckExecute(file, user, 1);
    }
  
    abstract execute(file: FlowFile, last: FlowStep | null, params?: any): Promise<any>;
  
    getContentDisposition(fileName: string): string {
        return 'inline;';
    }
  
    protected handleError(error: Error): never {
        // Add logging or error transformation logic here
        throw error;
    }
  }

export abstract class BaseXlsTransformerTool extends BaseFlowTool {
  protected readonly excelUtils: ExcelUtils;
  type = 'xls-transformer';

  constructor(flowService: FileflowServiceInterface) {
    super(flowService)
    this.excelUtils = new ExcelUtils(flowService!);
  }

  protected override initialize(flowService: FileflowServiceInterface): this {
    super.initialize(flowService);
    return this;
  }
}
  
  export interface FlowPrompt {
    docId: string;
    prompt: string;
    response: string;
    createdAt: Date;
  }
  
  export interface FlowPipeline {
    name: string;
    description: string;
    tools: FlowTool[];
    isDefault?: boolean;
    isInternal?: boolean;
  }
  export interface FlowStep {
    name: string;
    description?: string;
    state: 'pending' | 'complete' | 'error';
    type: string; // 'ocr' | 'transformer';
    error?: string;
    instructions?: string;
    parameters?: { name: string; value: any }[];
    storageName: string;
    outputURL: string;
    output?: any;
    elapsedMsec: number;
    lastUpdatedAt: Date;
    opened?: boolean;
    specialProcessing?: any; // TODO remove
    warning?: {
      reason: string,
      appliedProcessing: string
      user: string
      inputFileName: string,
      page: number
    }
  }
export interface FileflowServiceInterface extends PipelineLoggerInterface {
    getToolParameters(tool: FlowTool): JsonFormData
    getToolInstructions(tool: FlowTool): Promise<string>
    getUserId(): string
    getUser(userId: string): Promise<UserRecord>
    sendMeterEvent(numPages: number): Promise<void>
    defaultCheckExecute(file: FlowFile, user: UserRecord, pages: number): Promise<void>
    executeStep(context: PipelineContext | null, tool: FlowTool, file: FlowFile, params?: any): Promise<void>
    getFileSteps(file: FlowFile): Promise<any>
    getFileStepPrompts(file: FlowFile, stepName: string): Observable<FlowPrompt[]> | Promise<FlowPrompt[]>
    getLastCompletedStep(file: FlowFile, priorToolName: string): Observable<any> | Promise<any>
    getCompletedStepForTool(file: FlowFile, toolName: string): Observable<any> | Promise<any>
    updateStep(file: FlowFile, s: Partial<FlowStep>): Promise<any>
    updateStepPrompt(file: FlowFile, step: string, input: any, output: string): Promise<any>
    updateFile(file: FlowFile, data: Partial<FlowFile>): Promise<void>
    deleteStepPrompts(file: FlowFile, stepName: string): Promise<void>
    deleteStep(file: FlowFile, step: FlowStep): Promise<void>
    deleteFile(file: FlowFile, stepsOnly?: boolean): Promise<void>
    getFileContents(storagePath: string, asJson?: boolean): Promise<any>
    getFileContentsAsBlob(storagePath: string): Promise<Blob>
    uploadBlob(tool: FlowTool, file: FlowFile, blob: Blob, suffix: string): Promise<void>
    uploadAnalysis(tool: FlowTool, file: FlowFile, analysis: any): Promise<any>
    renderMarkdown(text: string): string | any
    getFileContentsBase64(storagePath: string): Promise<string>
    setContextTool(toolName: string): void
    clearContextTool(): void
    consumePages(numPages: number): void
    defaultDomainName(): string
    getClassMapForDomain(domain: string): Map<string, FlowSchemaType> | undefined
    getEnv(): string
    logPipelineStep(data: PipelineStepLog): Promise<void>
    postDocument(payload: any): Promise<any>
    pollJob(payload: any): Promise<any>
    sendProcessingWarningEmail(payload: any): Promise<any>
    getPageBalance(user: UserRecord): Promise<number>
    updateJob(file: FlowFile, status: 'error' | 'processing' | 'complete', statusDescription: string): Promise<void> //only for API pipeline related flo
    ignoreIndenterErrors: boolean
    //publish(payload: MessagePayload): void
}

export interface PipelineStepLog {
  jobId?: string
  user: string
  groupDocId?: string
  loggedAt: Date
  fileDocId: string
  fileName: string
  fileSize?: number
  numPages?: number
  stepName: string  // identifies provider
  promptDocId?: string
  credits?: number
  cost?: number
  input_tokens?: number
  output_tokens?: number
  azureModelUsed?: string
  azureTokensUsed?: number
  azureCost?: number
  elapsedMsec: number
}
