import { Injectable, inject } from '@angular/core';
import { BEHAVIOR_READ_ONLY_REPORT_TEMPLATE, Entry, TRACKER_REPORTS_SPECS, USER_LAST_CHAT_ID, splitTrim } from '@cheaseed/node-utils';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { HandlebarsService } from './handlebars.service';
import { OpenAIDriverService } from './openai-driver.service';
import { SharedUserService } from './shared-user.service';
import { ChatStateService } from './chat-state.service';
import { debounceTime, filter, map, switchMap, withLatestFrom } from 'rxjs';
import { Events, SharedEventService } from './shared-event.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  private contentService = inject(ContentService)
  private userService = inject(SharedUserService)
  private entryService = inject(EntryService)
  private eventService = inject(SharedEventService)
  private handlebars = inject(HandlebarsService)
  private chatStateService = inject(ChatStateService)
  private openai = inject(OpenAIDriverService)

  reportTypes$ = this.contentService.isLoaded$
    .pipe(
      map(() => {
        const reports = this.contentService.getGlobal(TRACKER_REPORTS_SPECS)
        return splitTrim(reports)
          .map(name => this.contentService.getEntrySpec(name))
          .filter(spec => spec?.behaviors?.includes(BEHAVIOR_READ_ONLY_REPORT_TEMPLATE))
          .map(spec => spec?.name)
      })
    )

  reports$ = this.contentService.isLoaded$
    .pipe(
      switchMap(() => this.entryService.recentHistory$),
      filter(entries => !!entries),
      debounceTime(200),
      withLatestFrom(
        this.reportTypes$,
        this.entryService.entryCounts$),
      map(([ entries, reportTypes, counts ]) => {
        const reportCounts = Object.fromEntries(Object.entries(counts).filter(([key, val]) => reportTypes.find(t => t === key)))
        const reports = entries?.filter(item => reportTypes.includes(item.indexType() as string))
        const total = Object.values(reportCounts).reduce((acc:number, curr:number) => acc + curr, 0)
        return {
          reports,
          reportCounts,
          reportTypes,
          instructionsKey: total === 0 ? 'instructions.all.reports.message' : null
        }}),
        // tap(data => console.log('data', data))
    )  

    reportsUnviewed$ = this.reports$
      .pipe(
        map(data => {
          const numUnviewed = data.reports?.filter(report => !report.attributes.viewed && (report.approved !== false))
          return numUnviewed?.length
        })
      )

  async generateReadOnlyEntry(type: string, needsApproval = false, needsReview = false) {
    const spec = this.contentService.getEntrySpec(type)
    if (spec.hasBehavior(BEHAVIOR_READ_ONLY_REPORT_TEMPLATE)) {
      // create entry
      const entry = this.entryService.createInMemoryEntry(type, null)
      // fill reportTemplate
      const term = this.contentService.getSingularGlobal(type)
      const name = `${term} ${new Date().toLocaleDateString()}`
      const report = spec.reportTemplate 
        ? this.handlebars.formatWithUserKeys(spec.reportTemplate as string, true)
        : this.openai.lastResponse
      const keys = splitTrim(this.contentService.getGlobal(`approval.${type}.keys`))
      const keySnapshot = keys.map(k => this.userService.getUserKeyDoc(k)).filter(k => !!k)
      const chatId = this.userService.getUserKey(USER_LAST_CHAT_ID) || this.chatStateService.lastChatId
      const attrs: any = {
        name, 
        report,
        keySnapshot,
        threadId: this.openai.thread,  // TODO: remove threadId, since the last threadId is irrelevant
        chatId,
        userId: this.userService.getCurrentUserId()
      }
      entry.attributes = attrs
      if (needsApproval) {
        entry.approved = false
        // todo add approval date later on in process
        // In admin: retrieve prompts from threadId
      }
      else {
        entry.needsReview = needsReview
      }
      // Prevent creation of duplicate report based on existing entry with attribute chatId same as this one #3572
      const existing = await this.entryService.findReportEntryByChatId(type, chatId)
      if (existing)
        console.log(`Report already exists for chatId ${chatId}, skipping generation`)
      else {
        await this.entryService.updateEntryLite(entry)
        this.eventService.record(Events.ReportGenerated, { entryType: type, report, chatId })
      }
    }
  }

  markAsViewed(entry: Entry) {    
    entry.attributes.viewed = true
    this.entryService.updateEntryLite(entry)
  }
}
