import { computed, inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { debounceTime, Observable } from 'rxjs';
import { FirebaseService } from './firebase.service';
const CONTENT_CONFIG = 'content/config'

export interface RuntimeConfig {
    approved: boolean
    buildTag: string
    latestReleaseTag: string
    maintenance_mode: boolean
    patchLevel: number
}

@Injectable({
    providedIn: 'root'
})
export class RuntimeConfigService {
    private firebase = inject(FirebaseService)

    config$ = this.firebase.doc$(CONTENT_CONFIG).pipe(debounceTime(300)) as Observable<RuntimeConfig>
    runtimeConfig = toSignal(this.config$)
    isMaintenanceMode = computed(() => {
        const result = this.runtimeConfig()?.maintenance_mode
        // console.log('checking maintenance mode', result)
        return result
    })

    async toggleMaintenanceMode() {
        await this.firebase.updateAt(CONTENT_CONFIG, { maintenance_mode: !this.isMaintenanceMode() })
    }
}