import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultRecord, DefaultsService, JsonFormComponent } from '@fidoc/util';
import { JsonFormData } from '@cheaseed/node-utils';
import { BehaviorSubject } from 'rxjs';
import { UtilityService } from '@fidoc/util';
import { IonContent, IonModal } from '@ionic/angular/standalone';

@Component({
  selector: 'lib-defaults',
  standalone: true,
  imports: [
    CommonModule,
    IonContent,
    IonModal,
    JsonFormComponent
  ],
  templateUrl: './defaults.component.html',
  styleUrl: './defaults.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultsComponent {

    defaultsService = inject(DefaultsService)
    utilityService = inject(UtilityService)

    editDefault$ = new BehaviorSubject<DefaultRecord | null>(null)

    renderValue(doc: DefaultRecord) {
        return doc.value === 'object' ? JSON.stringify(doc.value, null, 2) : doc.value
    }

    editDefault(doc: DefaultRecord) {
        this.editDefault$.next(doc)
      }
    
      addDefault() {
        this.editDefault$.next({ key: '', value: '', updatedAt: new Date() })
      }
    
      async deleteDefault(doc: DefaultRecord) {
        await this.utilityService.confirm({
          header: `Delete ${doc.key}`,
          message: `Are you sure you want to delete default for ${doc.key} ?`,
          confirm: () => {
            this.defaultsService.deleteDefault(doc)
          },
        });
      }
    
      submitDefault(controls: any, doc: DefaultRecord) {
        if (controls.key && controls.value) {
          const doc: DefaultRecord = {
            key: controls.key,
            value: controls.value,
            updatedAt: new Date()
          }
          this.defaultsService.updateDefault(doc, { key: controls.key, value: controls.value})
        }
      }
    
      prepareDefaultForm(doc: DefaultRecord) {
        const cssClass = 'white text-base text-black'
        const formData: JsonFormData = {
          submitAlwaysEnabled: true,
          controls: [ 
            { 
              name: 'key', 
              label: 'Key',
              type: 'textarea',
              cssClass,
              value: doc.key
            },
            { 
              name: 'value', 
              label: 'Value',
              type: 'textarea',
              cssClass,
              value: doc.value
            }
          ]
        }
        return formData
      }
    
}
