import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { MatMenuModule } from '@angular/material/menu';
import { JsonFormData } from '@cheaseed/node-utils';
import { JsonFormComponent, UtilityService } from '@fidoc/util';
import { GroupService } from '@fidoc/groups';
import { FirestoreCollectionTypes, Group, GroupMember, PipelineStepLog, UserRecord } from '@fidoc/shared';
import { FirebaseService, InspectorService, UserRecordConverter, UserService } from '@fidoc/util';
import { IonContent, IonModal } from "@ionic/angular/standalone";
import { BehaviorSubject, combineLatestWith, debounceTime, map, tap } from 'rxjs';

@Component({
    selector: 'lib-customer-view',
    standalone: true,
    imports: [
        IonModal, 
        IonContent,
        MatMenuModule,
        JsonFormComponent,
        CommonModule
    ],
    templateUrl: './customer-view.component.html',
    styleUrl: './customer-view.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerViewComponent {
    userService = inject(UserService)
    utilityService = inject(UtilityService)
    inspectorService = inject(InspectorService)
    firebase = inject(FirebaseService)
    groupService = inject(GroupService)
    partner = input.required<UserRecord>()
    customer = input.required<Group>()
    clients = signal<any[]>([]);
    provisionClient$ = new BehaviorSubject(false)
    provisionClientFormData: JsonFormData = {
        controls: [ 
            {
                name: 'user',
                type: 'text',
                validators: {
                    required: true,
                    minLength: 3
                },
                label: 'Client Name'
            },
            {
                name: 'email',
                type: 'email',
                label: 'Email',
                errorText: "Must be a valid email address",
                validators: {
                  required: true,
                  email: true
                },
              }  
        ]
    }

    constructor() {
        effect(() => {
            const customerGroupId = this.customer()?.docId;
            if (customerGroupId) {
                this.firebase.collection$(
                    FirestoreCollectionTypes.USERS,
                    where('groupDocId', '==', customerGroupId),
                    orderBy('createdAt', 'desc'))
                .pipe(
                    debounceTime(300),         
                    map(clients => UserRecordConverter.fromArray(clients)),
                    tap(clients => console.log('retrieved partner clients', clients, customerGroupId)),
                    combineLatestWith(this.groupService.getGroupCosts(customerGroupId)))
                .subscribe(([clients, costs]) => {
                    const result = clients.map(cli => {
                        const memberCosts = costs.filter((cost: PipelineStepLog) => cost.user === cli.docId)
                        const numFiles = [ ...new Set(memberCosts.map((cost: PipelineStepLog) => cost.fileName)) ].length
                        const pageTotal = memberCosts.reduce((acc, cost:PipelineStepLog) => acc + (cost?.numPages || 0), 0)
                        return {
                            ...cli,
                            numFiles,
                            pageTotal
                        }
                    })
                    console.log('setting clients', result)
                    this.clients.set(result);
                })
            }
        })
    }
    
    createClient() {
        this.provisionClient$.next(true)
    }

    deleteClient(cli: any) {
        console.log('deleteClient')
    }

    async confirmClientCreation(data: { user: string, email: string }) {
        if (await this.userService.confirmPartnerClientCreation({ ...data, groupDocId: this.customer().docId as string })) {
            await this.groupService.putGroupMember(this.customer(), { userId: data.email, status: 'active' } as GroupMember)
        }
    }

    viewParameters(cli: UserRecord) {
        const params = {
            partnerId: this.partner().docId,
            customerId: this.customer().docId,
            clientId: cli.docId
        }
        this.inspectorService.inspect("API Parameters", params)
    }
}
