import { Workbook } from 'exceljs';
import { BaseXlsTransformerTool, FileflowServiceInterface, FlowFile, FlowStep } from '../fileflow.interface';
import { getBlobFromBuffer } from '../utils';
import { DocuPandaStandardizationTool } from './DocuPandaStandardizationTool';

export class DocuPandaStandardizationXlsTool extends BaseXlsTransformerTool {
  name = 'standardization-xls-transformer'
  description = 'Transform Standardization to Spreadsheet'
  type = 'xls-transformer'
  startTime = 0
  
  protected override initialize(flowService: FileflowServiceInterface) {
    super.initialize(flowService)
    this.precedents.push(new DocuPandaStandardizationTool(flowService))
    return this
  }

  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any): Promise<boolean> {
      this.startTime = Date.now()
      
      // Get the output from the last step
      const schemas = last?.output
      if (!schemas)
        throw new Error(`No standardizations found in last step ${last?.name}`)
  
      const wb = new Workbook()
      const schemaNames = Object.keys(schemas)
      for (const name of schemaNames) {
        const ws = wb.addWorksheet(name)
        const schema = schemas[name].data
        this.excelUtils.streamObjectToWorksheet(schema, ws)
        this.excelUtils.autoFitColumns(ws)
      }
      // Upload the workbook to storage
      const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
      await this.flowService.uploadBlob(this, file, blob, '.xlsx');
      return true
  } 
}