import { Route } from '@angular/router'
import { inject } from '@angular/core'
import { AuthService } from '@fidoc/util'

export const appRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadComponent: () =>
      import('@fidoc/fileflow').then((m) => m.FileflowComponent),
  },
  {
    path: 'admin',
    loadComponent: () =>
      import('@fidoc/admin').then((m) => m.AdminComponent)
  },
  {
    path: 'pricing',
    loadComponent: () =>
      import('@fidoc/admin').then((m) => m.PricingComponent)
  },
  {
    path: 'profile',
    loadComponent: () =>
      import('@fidoc/profile').then((m) => m.ProfileComponent),
    canActivate: [() => inject(AuthService).isLoggedIn()]
  },
  {
    path: 'review/:docId',
    loadComponent: () =>
      import('@fidoc/fileflow').then((m) => m.FileReviewerComponent),
    canActivate: [() => inject(AuthService).isLoggedIn()]
  },
  {
    path: 'share/:sharedId',
    loadComponent: () =>
      import('@fidoc/fileflow').then((m) => m.FileflowComponent),
    canActivate: [() => inject(AuthService).isLoggedIn()]
  },
  {
    path: '**',
    redirectTo: 'home'
  }
]

// function checkAdminRole(){
//   const router = inject(Router)
//   const auth = inject(AuthService)
//   const userService = inject(UserService)
//   return userService.user$
//     .pipe(
//       tap(user => {
//         if (!user)
//           router.navigate(['home'])
//       }),
//       map(user => !!user?.isAdmin),
//       tap(isAdmin => {
//         console.log('isAdmin', isAdmin)
//         if (!isAdmin) {
//           console.log('non-admin user, redirecting to home')
//           router.navigate(['home'])
//         }
//       })
//     )
// }