import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileflowService, FileSummaryComponent, ToolsService } from '@fidoc/fileflow';
import { marked } from 'marked'
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { BehaviorSubject, combineLatestWith, debounceTime, filter, map, shareReplay } from 'rxjs';
import { DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { IonSearchbar } from "@ionic/angular/standalone";
import { UtilityService } from '@fidoc/util';
import { JsonFormData  } from '@cheaseed/node-utils';
import { FlowFile, flowFileConverter, FlowTool, UserRecord, FirestoreCollectionTypes } from '@fidoc/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomainComponent } from '../domain/domain.component';
import { orderBy } from '@angular/fire/firestore';
import { GroupsComponent, GroupService } from '@fidoc/groups';
import { UserTableComponent } from '../user-table/user-table.component';
import { CostTableComponent } from '../cost-table/cost-table.component';
import { ActionsComponent } from './actions/actions.component';
import { DefaultsComponent } from './defaults/defaults.component';
import { PartnersComponent } from './partners/partners.component';

@Component({
  selector: 'lib-admin',
  standalone: true,
  imports: [
    IonSearchbar,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    FileSummaryComponent,
    DomainComponent,
    GroupsComponent,
    UserTableComponent,
    CostTableComponent,
    DefaultsComponent,
    PartnersComponent,
    ActionsComponent
],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent {
  toolsService = inject(ToolsService)
  flowService = inject(FileflowService)
  userService = inject(UserService)
  defaultsService = inject(DefaultsService)
  utilityService = inject(UtilityService)
  firebase = inject(FirebaseService)
  groupService = inject(GroupService)
  
  private route = inject(ActivatedRoute)
  private router = inject(Router)

  tab$ = new BehaviorSubject('')
  tabIndex$ = new BehaviorSubject(0)

  provisionUser$ = new BehaviorSubject(false)

  searchFilter$ = new BehaviorSubject<string|null|undefined>('') 
  userSearchFilter$ = new BehaviorSubject<string|null|undefined>('') 

  recentUsers$ = this.userService.recentUsers$
    .pipe(
      debounceTime(300),
      combineLatestWith(this.userSearchFilter$),
      map(([users, searchTerm]) => users.filter(user => (!searchTerm || ('' + user.name + user.docId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))),
      shareReplay(1),
    )

  recentFiles$ = this.firebase.collectionGroupWithConverter$<FlowFile>(
    FirestoreCollectionTypes.FILEFLOW_COLLECTION, 
    flowFileConverter, 
    orderBy('createdAt', 'desc'))
    .pipe(
      debounceTime(300),
      shareReplay(1),
      combineLatestWith(this.searchFilter$),
      map(([files, searchTerm]) => files.filter(file => (!searchTerm || ('' + file.fileName + file.userDocId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))),
      // sort all files by createdAt descending
      // map(files => files.toSorted((a, b) => a.createdAt > b.createdAt ? -1 : 1)),
      // tap(files => console.log('files', files.map(f => ({ docId: f.docId, createdAt: f.createdAt }))))
    )
  
  billOverageFormData: JsonFormData = {
    controls: [
      {
        name: 'overageStartDate',
        type: 'date',
        validators: {
          required: true,
        },
        label: 'From'
      },
      {
        name: 'overageEndDate',
        type: 'date',
        validators: {
          required: true,
        },
        label: 'Till'
      }
    ]
  } 
  constructor(
    @Inject('environment') private environment: any
  ) {
    
    marked.setOptions({
      pedantic: false,
      gfm: true,
      breaks: false
    })

    // Ensure user has admin role
    this.userService.user$
      .pipe(
        takeUntilDestroyed())
      .subscribe(user => {
        const isAdmin = !!user?.isAdmin
        if (!isAdmin) {
          console.log('non-admin user, redirecting to home')
          this.router.navigate(['home'])
        }
      })      

    // this.route.queryParamMap
    //   .pipe(
    //     takeUntilDestroyed())
    //   .subscribe(params => {
    //     const userId = params.get('approve')
    //     if (userId) {
    //       console.log('approve', userId)
    //       this.tabIndex$.next(0)
    //     }
    //   })

    this.route.params
        .pipe(
            map(params => params['groupId'] as string),
            filter(groupId => !!groupId),
            combineLatestWith(this.route.queryParamMap),
            takeUntilDestroyed())
        .subscribe(([groupId, params]) => {
            this.groupService.selectGroup(groupId as string)
            // const tab = params.get('tab') as string
            // Set the tabIndex$ to the index of the tab with value "groups"
            this.tabIndex$.next(6)
        })
  }

  tabChanged(ev: any) {
    // console.log('tab changed', ev)
    this.tabIndex$.next(ev.index)
    this.tab$.next(ev.tab.textLabel.toLowerCase())
  }

}
