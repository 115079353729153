import Stripe from "stripe"
import { getStripeSecretKey } from "./stripe-constants"
import { UserRecord } from "./user.models"
import { LoggerInterface  } from "@cheaseed/node-utils"

export async function getMeterEventSummaries(env: string, user: UserRecord, start_time: Date, end_time: Date, logger?: LoggerInterface) {
  const subscriptionInfo = user.subscriptionInfo
  if(!subscriptionInfo) {
    logger?.warn('getMeterEventSummaries', `No subscription information found for user ${user}`)
    return
  }
  if(subscriptionInfo.status !== 'active') {
    logger?.warn('getMeterEventSummaries', `Subscription not active for user ${user}`)
    return
  }
  if(!subscriptionInfo.meterId) {
    logger?.warn('getMeterEventSummaries', `MeterId not found for user ${user}`)
    return
  }
  const stripe = getStripe(env)
  //const event_name = getMeterEventName(stripe)
  const identifier = subscriptionInfo.meterId
  logger?.log(`Start time: ${start_time}`)
  logger?.log(`identifier: ${identifier}, customer: ${subscriptionInfo.customerId}`)
  //logger?.log('typeof start_time', typeof start_time)

  
  const summary = await stripe.billing.meters.listEventSummaries(identifier, {
    customer: subscriptionInfo.customerId, 
    start_time: Math.floor(start_time.getTime()/1000), //getTimestamp(start_time), 
    end_time: Math.floor(Date.now()/1000)
  })
  return summary.data
}

function getTimestamp(date: Date) {
  //const d = new Date(date)
  return Math.floor(date.getTime()/1000)
}

export async function getMeterIdForProduct(productId: string, stripe: Stripe) {
  const tieredPrices = await getTieredPrices(stripe)
  const price = tieredPrices.find(p => p.product === productId)
  return price.recurring.meter
}
async function getTieredPrices(stripe: Stripe) {
  const prices = await stripe.prices.list({ expand: [ 'data.tiers' ] })
  return prices.data.filter(p => p.active && p.billing_scheme === 'tiered')
}
export async function sendMeterEvent(env: string, user: UserRecord, pages: number, logger?: LoggerInterface) {
  const subscriptionInfo = user.subscriptionInfo
  if(!subscriptionInfo) {
    logger?.warn('sendMeterEvent', `No subscription information found for user ${user.docId}`)
    return
  }
  if(subscriptionInfo.status !== 'active') {
    logger?.warn('sendMeterEvent', `Subscription not active for user ${user.docId}`)
    return
  }
  if(!subscriptionInfo.meterId) {
    logger?.warn('sendMeterEvent', `meterId not defined for user ${user.docId}`)
    return
  }
  const stripe = getStripe(env)
  //const meterId = await getMeterIdForProduct(stripe, subscriptionInfo.productId)
  const meter = await stripe.billing.meters.retrieve(subscriptionInfo.meterId)
  const event_name = meter.event_name //getMeterEventName(subscriptionInfo.interval as string)
  //const identifier = getMeterEventId(event_name)
  
  const eventResponse = await stripe.billing.meterEvents.create({
    //identifier,
    event_name,
    payload: {
      pages: pages.toString(),
      stripe_customer_id: subscriptionInfo.customerId
    },
    timestamp: Math.floor(Date.now()/1000)
  })
  logger?.log('Response to event sent to stripe meter ', JSON.stringify(eventResponse))
  //const summary = await getMeterEventSummaries(env, user, subscriptionInfo.subscriptionStartDate, subscriptionInfo.subscriptionEndDate, logger)
  //logger?.log('Event Summary: ', JSON.stringify(summary))
}

/*
function getMeterEventId(event_name: string) {
  if(event_name === MONTHLY_METER_EVENT_NAME)
    return MONTHLY_METER_ID
  if(event_name === YEARLY_METER_EVENT_NAME) 
    return YEARLY_METER_ID
  throw new Error(`getMeterEventId: Unknown event ${event_name}`)
}
*/
/** 
export function getMeterEventName(interval: string) {
  if(!interval || (interval !== 'month' && interval !== 'year')) 
    throw new Error(`Invalid interval in subscription ${interval}`)
  
  return interval === 'month' ? MONTHLY_METER_EVENT_NAME : YEARLY_METER_EVENT_NAME
}
  */
export function getStripe(env: string) {
  return new Stripe(getStripeSecretKey(env))
}