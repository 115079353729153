import { classify, split, standardize, getDocupandaSplitInstructions, getDocupandaOutput, DOCUPANDA_CREDIT_OVERAGE_COST, DOCUPANDA_CREDIT_COST } from '../docupanda-utils';
import { BaseFlowTool, FileflowServiceInterface, FlowFile, FlowStep, FlowTool } from '../fileflow.interface';
import { UserRecord } from '../user.models';
import { DocuPandaAsImageTool } from './DocuPandaAsImageTool';
import { DocuPandaRemoveWatermarkTool } from './DocuPandaRemoveWatermarkTool';
import { DocuPandaTool } from './DocuPandaTool';

export class DocuPandaStandardizationTool extends BaseFlowTool {
  name = 'standardization'
  description = 'Standardization (dp)'
  precedents: FlowTool[] = []
  type = 'standardization'
  startTime = 0
  
  constructor(flowService?: FileflowServiceInterface) {
    super()
    if(flowService)
      this.initialize(flowService)
  }
  
  protected override initialize(flowService: FileflowServiceInterface) {
    super.initialize(flowService)
    this.precedents.push(
      new DocuPandaTool(flowService), 
      new DocuPandaAsImageTool(flowService),
      new DocuPandaRemoveWatermarkTool(flowService)
    )
    return this
  }

  async checkExecute(file: FlowFile, user: UserRecord) {
    await this.flowService.defaultCheckExecute(file, user, file.numPages)
  }

  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any) {
      this.startTime = Date.now()
      const schemas:any = {}
      const domain = file.domainName || this.flowService.defaultDomainName()
      let documentId = file.externalDocumentId
      let credits = 0

      // If unknown documentId, get it from last step
      if (!documentId) {
        const output = await getDocupandaOutput(file, this.flowService)
        if (output)
          documentId = output.data.documentId
      }

      // Classify and take first result
      let result = (await this.classify( [ documentId ], domain ))[0]
      credits += 1.0
      // If multi-class and multi-page, split
      let subdocIds: string[]
      if (file.numPages > 1 && result.assignedClassIds.length > 1) {
        const instructions = this.getSplitInstructions(file, result.assignedClassIds)
        subdocIds = await split( documentId, instructions )
        credits += (file.numPages * 0.20)
        if (subdocIds) {
            const classifyResult = await this.classify( subdocIds, domain )
            credits += subdocIds.length * 1.0
            for (const cls of classifyResult) {
            // Get classId for each subdocId
            result = await this.standardize(cls.documentId, domain)
            for (const res of result)
                schemas[res.className] = res.standardization
            }
        }
      }
      if (!subdocIds) {
        result = await this.standardize(documentId, domain)
        for (const res of result)
          schemas[res.className] = res.standardization
      }
      credits += 2.0 * file.numPages // TODO: this may be inaccurate, get actual from dp response

      await this.flowService.uploadAnalysis(this, file, schemas)
      // Attach standardizations to file
      await this.flowService.updateFile(file, { externalDocumentId: documentId, schemas })
      await this.flowService.updateStep(file, {
        name: this.name,
        description: this.description,
        elapsedMsec: Date.now() - (this.startTime || 0),
        state: 'complete',
        error: undefined,
        output: schemas // TODO: this may be too big
    })
    const userId = this.flowService.getUserId()
    const user = await this.flowService.getUser(userId)
    const balance = await this.flowService.getPageBalance(user)
    await this.flowService.logPipelineStep({
        user: userId,
        groupDocId: user?.groupDocId, 
        fileDocId: file.docId,
        fileName: file.fileName,
        loggedAt: new Date(),
        stepName: this.name,          
        credits,
        cost: credits * (balance < 0 
        ? DOCUPANDA_CREDIT_OVERAGE_COST 
        : DOCUPANDA_CREDIT_COST),
        elapsedMsec: Date.now() - this.startTime
    })
  }

  async classify(documentIds: string[], domain: string) {
    const classNameMap = this.flowService.getClassMapForDomain(domain)
    return await classify(documentIds, classNameMap)
  }

  async standardize(documentId: string, domain: string) {
    // Get classes for domain
    const classNameMap = this.flowService.getClassMapForDomain(domain)
    return await standardize(documentId, classNameMap)
  }

  getSplitInstructions(file: FlowFile, classIds: string[]) {
    const classMap = this.flowService.getClassMapForDomain(file.domainName as string)
    return getDocupandaSplitInstructions(classMap!, classIds)
  }
}