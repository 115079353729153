<div class="w-full flex flex-col">
    <button class="self-end mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="addDefault()">
        Add Default
    </button>
    <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
        <thead>
            <tr class="px-2 text-center">
                <th>Key</th>
                <th>Value</th>
                <th class="w-40">Last Updated</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
        @for (doc of defaultsService.allDefaults$ | async; track doc.docId) {
            <tr class="even:bg-slate-100 odd:white text-center">
                <td class="p-2 text-left">{{doc.key}}</td>
                <td>
                    {{ renderValue(doc) }}
                </td>
                <td>{{doc.updatedAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                <td class="w-28">
                    <button mat-icon-button (click)="editDefault(doc)">
                        <span class="material-symbols-outlined">
                            edit
                        </span>                            
                    </button>
                    <button mat-icon-button (click)="deleteDefault(doc)">
                        <span class="material-symbols-outlined">
                            delete
                        </span>                            
                    </button>
                </td>
            </tr>
        }
        </tbody>
    </table>
</div>

<ion-modal #defaultsEditModal
  *ngIf="editDefault$ | async as doc"
  [isOpen]="!!doc" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="editDefault$.next(null)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="defaultsEditModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">{{ doc.key || 'Add Default' }}</h1>
        <cheaseed-json-form
          [formData]="prepareDefaultForm(doc)" 
          (formSubmit)="submitDefault($event, doc); defaultsEditModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>
