<div class="flex justify-end p-2">
    <button class="bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="createClient()">
        Create Client
    </button>
</div>
@if (clients().length > 0) {
    <div class="text-sm w-full my-4 p-4">
        <table class="w-full">
            <thead>
                <tr class="px-2">
                    <th class="w-44">Client</th>
                    <th class="w-44">Email</th>
                    <th class="w-44">Documents</th>
                    <th class="w-44">Pages</th>
                    <th class="w-44">Created</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            @for (cli of clients(); track cli.docId) {
                <tr class="even:bg-slate-100 odd:white text-center h-12">
                    <td>{{ cli.name }}</td>
                    <td>{{ cli.docId }}</td>
                    <td>{{ cli.numFiles }}</td>
                    <td>{{ cli.pageTotal }}</td>
                    <td>{{ cli.createdAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                    <td class="w-28">
                        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="Open menu">
                            <span class="material-symbols-outlined">
                                more_horiz
                            </span>
                        </button>
                        <mat-menu #moreMenu="matMenu" xPosition="after">
                            <ng-template matMenuContent>
                                <button mat-menu-item (click)="viewParameters(cli)">
                                    View API Parameters
                                </button>
                            </ng-template>
                        </mat-menu>
                </tr>
            }
            </tbody>
        </table>
    </div>
}

<ion-modal #provisionClientModal
  [isOpen]="provisionClient$ | async" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="provisionClient$.next(false)"
  style="--height: 50vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 50vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="provisionClientModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">Create Client</h1>
        <cheaseed-json-form
          [formData]="provisionClientFormData" 
          (formRejectSubmit)="utilityService.onFormRejectSubmit($event)"
          (formSubmit)="confirmClientCreation($event); provisionClientModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>