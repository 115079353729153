import { Workbook, Worksheet } from 'exceljs';
import { FileflowServiceInterface, FlowFile, FlowStep, FlowTool } from '../fileflow.interface';
import { getBlobFromBuffer } from '../utils';
import { DocuPandaStandardizationTool } from './DocuPandaStandardizationTool';
import { ExcelUtils } from '../excel-utils';

export class DocuPandaStandardizationXlsTool implements FlowTool {
  name = 'standardization-xls-transformer'
  description = 'Transform Standardization to Spreadsheet'
  precedents: FlowTool[] = []
  type = 'xls-transformer'
  startTime = 0
  excelUtils: ExcelUtils
  
  flowService: FileflowServiceInterface

  constructor(flowService?: FileflowServiceInterface) {
    if (flowService)
      this.initialize(flowService)
  }
  shouldPublish(): boolean {
      return false
  }
  initialize(flowService: FileflowServiceInterface) {
    this.flowService = flowService
    this.excelUtils = new ExcelUtils(flowService)
    this.precedents.push(
      new DocuPandaStandardizationTool(this.flowService), 
    )
    return this
  }

  getContentDisposition(fileName: string) {
    return  'inline;'
  }

  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any) {
      this.startTime = Date.now()
      
      // Get the output from the last step
      const schemas = last?.data
      if (!schemas)
        throw new Error(`No standardizations found in last step ${last?.name}`)
  
      const wb = new Workbook()
      const schemaNames = Object.keys(schemas)
      for (const name of schemaNames) {
        const ws = wb.addWorksheet(name)
        const schema = schemas[name].data
        this.excelUtils.streamObjectToWorksheet(schema, ws)
        this.excelUtils.autoFitColumns(ws)
      }
      // Upload the workbook to storage
      const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
      await this.flowService.uploadBlob(this, file, blob, '.xlsx');
      return true
  } 
}