import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, computed, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { marked } from 'marked'
import { DefaultsService } from '../defaults.service';

@Component({
  selector: 'lib-default-message',
  standalone: true,
  imports: [ CommonModule ],
  template: `<div [innerHtml]="answer()"></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultMessageComponent {

  @Input() key: string|null = null
  @Input() default?: string
  @Input() args?: (string | undefined)[]
  @Input() sanitize? = true
  @Input() markdown? = false

  defaultsService = inject(DefaultsService)
  sanitizer = inject(DomSanitizer)

  answer = computed(() => {
    const defaults = this.defaultsService.defaults()
    const result = defaults.get(this.key as string)?.value || this.default // || this.key
    if (result) {
        const replaced = this.defaultsService.substituteArgs(result, this.args as string[])
        const processed = this.markdown ? marked(replaced) as string : replaced
        return this.sanitize ? this.sanitizer.bypassSecurityTrustHtml(processed) : processed
    }
    else {
        if (defaults.size > 0) // only warn if there are defaults
          console.warn(`DefaultMessageComponent: key ${this.key} not found`)
        return ''
    }
  })
}