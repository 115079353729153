import { Injectable, inject } from '@angular/core';
import {
  Firestore,
  FirestoreDataConverter,
  addDoc,
  collection,
  collectionData,
  collectionGroup,
  deleteDoc,
  doc,
  docData,
  getDocs,
  query,
  setDoc,
} from '@angular/fire/firestore';
import { Observable, from, map } from 'rxjs';
import { AuthService } from './auth.service';
import { getFunctions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {

  private firestore = inject(Firestore)
  private auth = inject(AuthService)

  takeUntilAuth<T>() {
    return this.auth.takeUntilAuth<T>();
  }

  doc$(path: string, converter?: FirestoreDataConverter<any, any>) {
    // console.log('docWithConverter$', path)
    return docData(
      (converter 
        ? doc(this.firestore, path).withConverter(converter)
        : doc(this.firestore, path)), 
      { "idField": 'docId' })
        .pipe(this.takeUntilAuth())
  }

  // Note: An orderBy() clause also filters for existence of the given fields. The result set will not include documents that do not contain the given fields.
  collection$(path: string, ...constraints): Observable<any> {
    // console.log('collection$', path, ...constraints)
    return collectionData(this.queryCollection(path, null, ...constraints), {
      idField: 'docId',
    }).pipe(this.takeUntilAuth());
  }

  queryCollection(
    path: string,
    converter: FirestoreDataConverter<any, any> | null,
    ...constraints
  ) {
    return query(
      collection(this.firestore, path).withConverter(
        converter as FirestoreDataConverter<any>,
      ),
      ...constraints,
    ).withConverter(converter as FirestoreDataConverter<any, any>);
  }

  collectionWithConverter$(
    path: string,
    converter: FirestoreDataConverter<any, any> | null,
    ...constraints) {
    return this.collectionWithConverterNoAuth$(path, converter as FirestoreDataConverter<any, any>, ...constraints)
      .pipe(this.takeUntilAuth())
  }

  collectionWithConverterNoAuth$(
    path: string,
    converter: FirestoreDataConverter<any, any> | null,
    ...constraints) {
    return collectionData(
      query(
        converter 
          ? collection(this.firestore, path).withConverter(converter as FirestoreDataConverter<any, any>)
          : collection(this.firestore, path),
        ...constraints,
      ),
      { idField: 'docId' },
    )
  }

  collectionGroupQuery$(path: string, ...constraints) {
    return query(collectionGroup(this.firestore, path), ...constraints);
  }

  collectionGroupQueryWithConverter$(
    path: string,
    converter: FirestoreDataConverter<any, any>,
    ...constraints
  ) {
    return query(
      collectionGroup(this.firestore, path).withConverter(converter),
      ...constraints,
    ).withConverter(converter);
  }

  collectionGroup$(path: string, ...constraints): Observable<any> {
    return collectionData(this.collectionGroupQuery$(path, ...constraints), {
      idField: 'docId',
    }).pipe(this.takeUntilAuth());
  }

  collectionGroupWithConverter$<T>(
    path: string,
    converter: FirestoreDataConverter<any, any>,
    ...constraints
  ): Observable<T[]> {
    return collectionData(
      this.collectionGroupQueryWithConverter$(path, converter, ...constraints),
      { idField: 'docId' },
    ).pipe(this.takeUntilAuth());
  }

  collectionGroupDocs$(path: string, ...constraints) {
    return from(getDocs(this.collectionGroupQuery$(path, ...constraints)));
  }

  updateAt(
    path: string,
    data: any,
    merge = true,
    converter?: FirestoreDataConverter<any, any> | undefined,
  ): Promise<any> {
    const segments = path.split('/').filter((v) => v);
    if (segments.length % 2) {
      // Odd is always a collection
      console.log(`Adding document ${path}`);
      const coll = converter
        ? collection(this.firestore, path).withConverter(converter)
        : collection(this.firestore, path);
      return addDoc(coll, data);
    } else {
      // Even is always a document
      console.log(`Updating document ${path}`);
      const docRef = converter
        ? doc(this.firestore, path).withConverter(converter)
        : doc(this.firestore, path);
      return setDoc(docRef, data, { merge });
    }
  }

  delete(path:string) {
    console.log(`Deleting document ${path}`)
    return deleteDoc(doc(this.firestore, path))
  }

  async awaitCloudFunction(funcname: string, args: any, timeout?: number) {
    console.log(`Calling cloud function ${funcname}`, timeout, args)
    const callable = timeout ?
        httpsCallable(getFunctions(), funcname, { timeout }) :
        httpsCallable(getFunctions(), funcname)
    return callable(args)
  }

  callCloudFunction(funcname: string, args: any, timeout?: number): Observable<any> {
      return from(this.awaitCloudFunction(funcname, args, timeout))
  }

}
