import { Injectable, inject } from '@angular/core';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { map, firstValueFrom, shareReplay, tap } from 'rxjs';
import { DOMAINS_COLLECTION, FlowDomain, FlowSchemaType, flowSchemaTypeConverter } from '@fidoc/shared';

export const TYPES_COLLECTION = 'types'

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  auth = inject(AuthService)
  firebase = inject(FirebaseService)

  constructor() {}

  private getDomainsPath(id:string|null = null) {
    return `${DOMAINS_COLLECTION}/${id}`
  }

  getDomains() {
    return this.firebase.collection$(DOMAINS_COLLECTION)
      .pipe(
        map((result: FlowDomain[]) => result.toSorted((a, b) => a.domainName < b.domainName ? -1 : 1)),
        this.auth.takeUntilAuth(),
        // tap(result => console.log("getDomains", result)),
        shareReplay(1)
      )
  }

  getDomainTypes(domain: FlowDomain) {
    return this.firebase.collectionWithConverter$(`${this.getDomainsPath(domain.docId)}/${TYPES_COLLECTION}`, flowSchemaTypeConverter)
      .pipe(
        map((result: FlowSchemaType[]) => result.toSorted((a, b) => a.className < b.className ? -1 : 1)),
        this.auth.takeUntilAuth(),
        tap(result => console.log("getDomainTypes", result)),
        shareReplay(1)
      )
  }

  async updateType(domain: FlowDomain, type: Partial<FlowSchemaType>) {
    const path = `${this.getDomainsPath(domain.docId)}/${TYPES_COLLECTION}${type.docId ? '/' + type.docId : ''}`
    const user = await firstValueFrom(this.auth.user$)
    await this.firebase.updateAt(path, { ...type, updatedAt: new Date(), lastUpdateBy: user?.email }) 
  }

  async deleteType(domain: FlowDomain, type: Partial<FlowSchemaType>) {
    const path = `${this.getDomainsPath(domain.docId)}/${TYPES_COLLECTION}/${type.docId}`
    await this.firebase.delete(path)
  }
}
