@if (recentUsers$ | async; as users) {
    @if (groupsMap$ | async; as groups) {
        <div class="p-4">
            <ion-searchbar
            [debounce]="500"
            show-clear-button="always"
            [value]="userSearchFilter$ | async"
            (ionInput)="userSearchFilter$.next($event.target.value)">
            </ion-searchbar>
        </div>
        <table class="my-4 w-full border-separate border-0 border-slate-400 text-sm">
            <thead>
            <tr class="px-2 text-center">
                <th>Name</th>
                <th>Email</th>
                <th>Last Login</th>
                <th>Status</th>
                <th>Admin</th>
                <th>Execution</th>
                <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            @for (user of users; track user.docId) {
                <tr class="even:bg-slate-100 odd:white text-center">
                    <td>{{user.name}}</td>
                    <td>{{user.docId}}</td>
                    <td class="text-sm">{{user.lastLogin | date: 'yyyy-MM-dd hh:mma' }}</td>
                    <td>
                        @if (user.groupDocId) {
                            Group User ({{ groups.get(user.groupDocId)?.name }})
                        }
                        @else if (user.enterpriseTrial) { 
                            Enterprise Trial User 
                        }
                        @else if (!user.subscriptionInfo) {
                            Beta User
                        }
                        @else if (user.subscriptionInfo.cancelled && user.subscriptionInfo.status !== 'canceled'){                                        
                            {{user.subscriptionInfo.status}} (Canceled)
                        }
                        @else {
                            {{user.subscriptionInfo.status}}
                        }
                        
                    </td>
                    <td>
                        @if (user.isAdmin) {
                            Yes            
                        }
                        @else {
                            No
                        }
                    </td>
                    <td>{{ user.pipelineEnv }}</td>
                    <td>
                        <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="Open menu">
                            <span class="material-symbols-outlined">
                                more_horiz
                            </span>                            
                        </button>
                        <mat-menu #moreMenu="matMenu" xPosition="after">
                            <ng-template matMenuContent>
                                <button mat-menu-item (click)="showInspector(user)">
                                    Inspect
                                </button>    
                                <button mat-menu-item (click)="addPageBalance(user)">
                                    Add Page Balance
                                </button>
                                <button *ngIf="user.enterpriseTrial" mat-menu-item (click)="editEvalEndDate(user)">
                                    Edit Eval End Date
                                </button>
                                <button mat-menu-item (click)="toggleAdmin(user)">
                                    Toggle Admin
                                </button>
                                <button mat-menu-item (click)="toggleExecutionEnv(user)">
                                    Toggle Execution Mode
                                </button>
                                <button mat-menu-item (click)="confirmZipUser(user)">
                                    Download User Files
                                </button>
                                @if (inTrialPeriod(user)) {
                                    <button mat-menu-item (click)="endTrialPeriod(user)">
                                        End Trial Period
                                    </button>
                                }
                                @if (hasSubscription(user)) {
                                    <button mat-menu-item (click)="cancelSubscription(user)">
                                        Cancel Subscription
                                    </button>
                                    @if (user.pageBalance < 0) {
                                        <button mat-menu-item (click)=" billOverage(user)">
                                            Bill Overage
                                        </button>
                                    }
                                }
                                @if (!user.isAdmin) {                                            
                                    <button mat-menu-item (click)="confirmObliterate(user)">
                                        Obliterate User
                                    </button>
                                }
                            </ng-template>
                        </mat-menu>    
                    </td>
                </tr>
            }
            </tbody>
        </table>
    }
}
@else {
    <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
}
