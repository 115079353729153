@if (selectedCustomer(); as customer) {
    <lib-breadcrumb-strip [title]="customer.name" [message]="customer.docId" (action)="selectedCustomer.set(null)"/>
    <lib-customer-view [partner]="partner()" [customer]="customer"/>
}
@else {
    <div class="flex justify-end p-2">
        <button class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="createCustomer()">
            Create Customer
        </button>
    </div>
    @if (customers().length > 0) {
        <div class="text-sm w-full my-4 p-4">
            <table class="w-full">
                <thead>
                    <tr class="px-2">
                        <th class="w-44">Customer</th>
                        <th>API Key</th>
                        <th>Balance</th>
                        <th class="w-44">Created</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                @for (cust of customers(); track cust.docId) {
                    <tr class="even:bg-slate-100 odd:white text-center h-12">
                        <td>
                            <a (click)="selectedCustomer.set(cust)" class="text-blue-600 underline cursor-pointer">{{ cust.name }}</a>
                        </td>
                        <td>{{ cust.apiKey }}</td>
                        <td>{{ cust.pageBalance }}</td>
                        <td>{{ cust.createdAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                        <td class="w-28">
                            <button mat-icon-button (click)="deleteCustomer(cust)">
                                <span class="material-symbols-outlined">
                                    delete
                                </span>
                            </button>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    }
}

<ion-modal #provisionCustomerModal
  [isOpen]="provisionCustomer$ | async" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="provisionCustomer$.next(false)"
  style="--height: 50vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 50vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="provisionCustomerModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">Create Customer</h1>
        <cheaseed-json-form
          [formData]="provisionCustomerFormData" 
          (formRejectSubmit)="utilityService.onFormRejectSubmit($event)"
          (formSubmit)="confirmCustomerCreation($event); provisionCustomerModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>