import { Injectable, inject } from '@angular/core';
import { debounceTime, firstValueFrom, map, switchMap } from 'rxjs';
import {
  BatchMessagePayload,
  CLOUD_FUNCTION_TIMEOUT,
  FirestoreCollectionTypes,
  FlowFile,
  FlowPipeline,
  FlowStep,
  FlowTool,
  getFlowToolsRegistry,
  PIPELINE_ENV_KEY,
  PipelineContext,
  PipelineEnvironment,
  UserRecord,
} from '@fidoc/shared';
import { FileflowService } from './fileflow.service';
import { DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import { delay } from '@fidoc/shared';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root',
})
export class ToolsService {

  flowService = inject(FileflowService)
  userService = inject(UserService)
  firebase = inject(FirebaseService)
  defaults = inject(DefaultsService)

  private FlowToolsRegistry: FlowTool[] = getFlowToolsRegistry(this.flowService)
  private FlowPipelinesRegistry: FlowPipeline[] = []
  
  private tools$ = this.firebase.doc$(`${FirestoreCollectionTypes.CONTENT_COLLECTION}/tools`)
    .pipe(
      debounceTime(500),
      map(doc => doc.tools as FlowTool[]))

  private pipelines$ = this.userService.userDocId$
    .pipe(
      switchMap(() => this.firebase.doc$(`${FirestoreCollectionTypes.CONTENT_COLLECTION}/pipelines`)),
      debounceTime(500),
      map(doc => doc.pipelines as FlowPipeline[]))

  constructor() {

    this.tools$
      .pipe(takeUntilDestroyed())
      .subscribe(tools => {
        this.flowService.setCurrentTools(tools)
    })
    
    this.pipelines$
      .pipe(takeUntilDestroyed())
      .subscribe((pipelines:FlowPipeline[]) => {
        const pipes = pipelines.filter(p => !p.isInternal).map((p:any) => {
          return {
            name: p.name,
            description: p.description,
            tools: p.tools.map((t:string) => this.getTool(t)),
            isDefault: !!p.isDefault
          }
        })
        this.FlowPipelinesRegistry = pipes as FlowPipeline[]
        // console.log('pipelines updated', this.FlowPipelinesRegistry.length)
      })
    }

  getTools() {
    return this.FlowToolsRegistry
  }

  getPipelines() {
    return this.FlowPipelinesRegistry
  }

  getPipeline(name: string) {
    return this.getPipelines().find(p => p.name === name)
  }

  getDefaultPipeline() {
    return this.getPipelines().find(p => p.isDefault)
  }

  getTool(name: string) {
    return this.getTools().find(t => t.name === name)
  }

  async executePipeline(pipeline: FlowPipeline | null, file: FlowFile) {
    const user = this.userService.user() as UserRecord;

    const exec = user.pipelineEnv
      || this.defaults.getDefault(PIPELINE_ENV_KEY) 
      || PipelineEnvironment.PIPELINE_EXEC_ENV_PUBSUB;
    if ( exec === PipelineEnvironment.PIPELINE_EXEC_ENV_PUBSUB) {
      this.flowService.markFileOpened(file)
      this.firebase.awaitCloudFunction('publishPipelineMessages', {
        pipelineName: file.pipelineName, 
        userId: file.userDocId, 
        flowFileDocIds: [file.docId]
      } as BatchMessagePayload)
    }
    else {
      if (exec === PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD) {
        this.flowService.markFileOpened(file)
      // no await here to queue up execution in parallel
        this.firebase.awaitCloudFunction('executePipeline', {
          userId: user.docId,
          pipeline: pipeline?.name || 'none',
          file: JSON.stringify(file)
        },
        CLOUD_FUNCTION_TIMEOUT)
      }
      else
        this.executeLocalPipeline(pipeline, file)
    }
  }

  async executeLocalPipeline(pipeline: FlowPipeline | null, file: FlowFile) {
    let lastStep: FlowStep | null = null
    for (const tool of (pipeline?.tools || [])) {
      const context: PipelineContext = {
        pipeline: pipeline!.name,
        userId: (this.userService.user() as UserRecord).docId
      }
      if (tool) {
        try {
          context.tool = tool.name
          await this.flowService.executeStep(context, tool, file)
        }
        catch (e:any) {
          // exit the pipeline if any tool fails
          return
        }
        // TODO: Is this necessary?
        // To handle delay in finalizing upload (10 secs)
        let cnt = 0
        do {          
          lastStep = await firstValueFrom(this.flowService.getCompletedStepForTool(file, tool.name)) as FlowStep
          if (!lastStep) 
            await delay(1000)
          cnt++
        } while (!lastStep && cnt <= 10)
        if (cnt > 10) 
          console.error(`tool ${tool.name} not completed`)
      }
      else {
        console.error(`tool not found`)
      }
    }
  }
}
