import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-breadcrumb-strip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './breadcrumb-strip.component.html',
  styleUrl: './breadcrumb-strip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbStripComponent {
    title = input.required<string>();
    message = input<string>();
    action = output();
    hideBackArrow = input<boolean>(false);
}