import { QueryDocumentSnapshot } from '@google-cloud/firestore'

export interface AffiliateTerms {
  name: string;
  payout: number;
  discount: number;
  default?: boolean;
}

export const AFFILIATE_TERMS: AffiliateTerms[] = [
  { name: '10% discount', payout: 0.0, discount: 0.1, default: true },
  { name: '10% payout', payout: 0.1, discount: 0.0 },
  // { name: '5% payout + 5% discount', payout: 0.5, discount: 0.5 },
]

export interface PrepaidTerms {
  name: string;
  description?: string;
  devUrl: string;
  notifyOnBalance?: number;
  order: number; // UI attribute for sorting
  price: number;
  pricePerCoach?: number;
  title?: string
}

// /groups/{groupId}/members/{userId}
export interface GroupMember {
  userId: string;
  status: 'pending' | 'active' | 'pre-approved' | 'invited';
  approvedAt: Date;
  updatedAt: Date;
}

export const GroupMemberConverter = {    
  toFirestore(sub: GroupMember) { 
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): GroupMember {
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): GroupMember {
    if (data) {
      data.approvedAt = data.approvedAt?.toDate()
      data.updatedAt = data.updatedAt.toDate()
      return data as GroupMember
    }
    else
      return {} as GroupMember
  },
  fromArray(data: any[]): GroupMember[] {
    //console.log('GroupConverter', 'fromArray', data)
    return data.map(d => this.fromFirestoreData(d))
  }
}

export interface LedgerEntry {
  docId?: string
  createdAt: Date
  userId: string
  type: 'credit' | 'debit'
  source: 'gift' | 'purchase' | 'refund' | 'prepaid'
  origin?: string
  amount: number
  credits?: any
  offer?: any
  description?: string;
  discountAmount?: number
}

export interface GroupLedgerEntry extends LedgerEntry{
  balance?: number
  payoutAmount?: number
  
}

// /group/{docId}
export interface Group {
  docId?: string;
  ownerUserId: string;
  name: string;
  description: string;
  pricingPlan: 'affiliate' | 'prepaid';
  affiliateTerms: string;
  prepaidTerms: PrepaidTerms;
  pricePerCoach: number;
  invitationLink?: string;
  balance: number;
  secondaryOwners: string[];
  approvedAt?: Date; // approved by default whenever pricingPlan is set, can be revoked in admin to disable group purchases
  createdAt: Date;
  updatedAt: Date;
  hasUnlimitedBalance?: boolean;
  [key: string]: string | string[] | number | PrepaidTerms | Date | undefined | boolean;
}

export const GroupConverter = {    
  toFirestore(sub: Group) { 
    console.log('GroupConverter', 'toFirestore', sub)
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Group {
    console.log('GroupConverter', 'fromFirestore', snapshot)
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): Group {
    if (data) {
      data.createdAt = data.createdAt.toDate()
      data.updatedAt = data.updatedAt.toDate()
      data.approvedAt = data.approvedAt?.toDate()
      if(!data.balance)
        data.balance = 0
      return data as Group
    }
    else
      return {} as Group
  },
  fromArray(data: any[]): Group[] {
    //console.log('GroupConverter', 'fromArray', data)
    return data.map(d => this.fromFirestoreData(d))
  }
}
