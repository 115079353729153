<div class="w-full flex flex-col">
    <button class="self-end mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="exportCosts()">
      Export Costs
    </button>
  
    <!-- Display current start and end record numbers -->
    <!-- Pagination Controls -->
    <div class="flex justify-center items-center gap-4 my-4">
        <button class="bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-1 px-3 rounded"
            (click)="previousPage()" [disabled]="currentPage() === 1">
            Previous
        </button>
        <span class="text-xs text-center">Page {{ currentPage() }}<br/>{{ startRecord() }} - {{ endRecord() }}</span>
        <button class="bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-1 px-3 rounded"
            (click)="nextPage()" [disabled]="numCosts() < itemsPerPage">
            Next
        </button>
    </div>
    
    <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
      <thead>
        <tr class="px-2 text-center">
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'loggedAt', label: 'Time' }"></ng-container> 
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'user', label: 'User' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'groupDocId', label: 'Group' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'fileName', label: 'File' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'numPages', label: 'Pages' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'stepName', label: 'Step' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'credits', label: 'Credits' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'cost', label: 'Cost' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'azureModelUsed', label: 'Azure' }"></ng-container>
            <ng-container *ngTemplateOutlet="renderHeaderColumn; context: { attribute: 'elapsedMsec', label: 'Elapsed' }"></ng-container>
      </thead>
      <tbody>
        @if (numCosts() > 0) {
          @for (cost of costs(); track cost.docId) {
            <tr class="even:bg-slate-100 odd:white text-center text-xs">
              <td class="p-1 w-40">{{cost.loggedAt | date: 'yyyy-MM-dd hh:mm:ssa' }}</td>
              <td class="p-1">{{cost.user}}</td>
              <td class="p-1">{{cost.groupDocId}}</td>
              <td class="p-1 w-60">{{cost.fileName}}</td>
              <td class="p-1">{{cost.numPages}}</td>
              <td class="p-1">{{cost.stepName}}</td>
              <td class="p-1">{{cost.credits}}</td>
              <td class="p-1">{{cost.cost ? cost.cost?.toFixed(4) : cost.azureCost?.toFixed(4)}}</td>
              <td class="p-1">{{cost.azureModelUsed ? `${cost.azureModelUsed} (${cost.azureTokensUsed ? cost.azureTokensUsed : cost.input_tokens + '/' + cost.output_tokens})` : ''}}</td>
              <td class="p-1">{{cost.elapsedMsec}}</td>
            </tr>
          }
        } @else {
          <tr>
            <td class="p-1" colspan="10">
              <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
            </td>
          </tr>
        }
      </tbody>
    </table>  
</div>

<ng-template #renderHeaderColumn let-attribute="attribute" let-label="label">
    <th (click)="onSortChange(attribute)">{{ label }} {{ sortBy.column === attribute ? (sortBy.direction === 'asc' ? '↑' : '↓') : '' }}</th>
</ng-template>