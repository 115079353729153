import { QueryDocumentSnapshot } from '@google-cloud/firestore'
import { Subscription } from 'rxjs';
import { SubscriptionInfo } from './stripe-constants';

// /groups/{groupId}/members/{userId}
export interface GroupMember {
  userId: string;
  status: 'pending' | 'active' | 'pre-approved' | 'invited';
  approvedAt: Date;
  updatedAt: Date;
}

export const GroupMemberConverter = {    
  toFirestore(sub: GroupMember) { 
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): GroupMember {
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): GroupMember {
    if (data) {
      data.approvedAt = data.approvedAt?.toDate()
      data.updatedAt = data.updatedAt.toDate()
      return data as GroupMember
    }
    else
      return {} as GroupMember
  },
  fromArray(data: any[]): GroupMember[] {
    //console.log('GroupConverter', 'fromArray', data)
    return data.map(d => this.fromFirestoreData(d))
  }
}

export interface LedgerEntry {
  docId?: string
  createdAt: Date
  userId: string
  pages: number
  description?: string;
}

export interface GroupLedgerEntry extends LedgerEntry{
  pageBalance?: number
  
}

// /group/{docId}
export interface Group {
  docId?: string;
  invoiceId?: string
  ownerUserId: string;
  name: string;
  description: string;
  invitationLink?: string;
  pageBalance: number;
  //secondaryOwners: string[];
  approvedAt?: Date; 
  createdAt: Date;
  updatedAt: Date;
  isGroup?: boolean
  subscriptionInfo?: Partial<SubscriptionInfo>;
  lastOverageInvoiceStartDate?: Date;
  lastOverageInvoiceEndDate?: Date;
  lastOverageInvoiceId?: string;
  [key: string]: string | string[] | number | Date | undefined | boolean| Partial<SubscriptionInfo>;
}

export const GroupConverter = {    
  toFirestore(sub: Group) { 
    console.log('GroupConverter', 'toFirestore', sub)
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): Group {
    console.log('GroupConverter', 'fromFirestore', snapshot)
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): Group {
    if (data) {
      data.createdAt = data.createdAt.toDate()
      data.updatedAt = data.updatedAt.toDate()
      data.approvedAt = data.approvedAt?.toDate()
      if(!data.pageBalance)
        data.pageBalance = 0
      return data as Group
    }
    else
      return {} as Group
  },
  fromArray(data: any[]): Group[] {
    //console.log('GroupConverter', 'fromArray', data)
    return data.map(d => this.fromFirestoreData(d))
  }
}
