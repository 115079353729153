<div class="bg-white py-4">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-4xl text-center">
        <!-- <h2 class="text-base font-semibold leading-7 text-indigo-600">Pricing</h2> -->
        <p class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
          <lib-default-message key="pricing.title"/>
        </p>
      </div>
      <p class="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600 text-center">
        <lib-default-message key="pricing.subtitle"/>
      </p>
      <div class="mt-10 flow-root">
        @let numProducts=productsForDisplay().length;
        <div [ngClass]="{
            'grid-cols-1 lg:grid-cols-4': numProducts >= 4,
            'grid-cols-1 lg:grid-cols-2': numProducts < 4
          }"
          class="isolate grid max-w-sm gap-x-4 gap-y-16 divide-y divide-gray-100 lg:divide-x lg:divide-y-0 mx-auto lg:mt-0 lg:max-w-none">
            @for (product of productsForDisplay(); track product.id) {
                <div class="p-6 bg-gray-100 rounded-lg shadow-sm">
                    <h3 id="tier-basic" class="text-lg font-semibold leading-7 text-gray-900">
                        {{ product.name }}
                    </h3>
                    <p class="mt-6 flex items-baseline gap-x-1">
                      <span class="text-3xl font-bold tracking-tight text-gray-900">
                        ${{ getProductPriceWithDiscount(product.amount/100) }}
                      </span>
                      <span class="text-sm font-semibold text-gray-600">/ {{ product.interval }}</span>
                    </p>
                    <p class="mt-3 text-sm h-16">
                        {{ product.description }}
                        @if (this.stripeService.globalDiscount() > 0.0) {
                            <span class="text-indigo-600">Normally ${{ product.amount/100 }} / {{ product.interval }}.</span>
                        }
                    </p>
                    <a  href="{{ stripeService.getPaymentLinkUrl(userDocId, product.paymentLink) }}"
                        target="_blank" 
                        aria-describedby="tier-basic" 
                        class="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <lib-default-message key="pricing.buybutton.message"/>
                    </a>
                    <!-- <p *ngIf="product.marketing_features?.length" class="mt-10 text-sm font-semibold leading-6 text-gray-900">Everything necessary to get started.</p> -->
                    <ul role="list" class="mt-6 space-y-3 text-sm leading-6 text-gray-600 ml-0">
                      @for (feature of product.marketing_features; track feature.name) {
                        <ng-container *ngTemplateOutlet="benefit; context: { description: feature.name }"></ng-container>
                      }
                    </ul>
                </div>
            }
        </div>
      </div>
    </div>
  </div>
  
  <ng-template #benefit let-description="description">
    <li class="flex gap-x-3">
        <svg class="h-6 w-5 flex-none text-indigo-600" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
          <path fill-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm3.857-9.809a.75.75 0 0 0-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 1 0-1.06 1.061l2.5 2.5a.75.75 0 0 0 1.137-.089l4-5.5Z" clip-rule="evenodd" />
        </svg>
        {{ description }}
      </li>
  </ng-template>