import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileflowService, FileSummaryComponent, ToolsService } from '@fidoc/fileflow';
import { marked } from 'marked'
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { BehaviorSubject, combineLatestWith, debounceTime, firstValueFrom, map, shareReplay } from 'rxjs';
import { DefaultRecord, DefaultsService, FirebaseService, LabelledSpinnerComponent, UserService } from '@fidoc/util';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { IonModal, IonContent, IonSearchbar } from "@ionic/angular/standalone";
import { PortalUtilityService } from '@cheaseed/portal/util';
import { JsonFormComponent } from '@cheaseed/shared/ui';
import { JsonFormData  } from '@cheaseed/node-utils';
import { FlowFile, flowFileConverter, FlowTool, getUserFilesPath, PIPELINE_EXEC_ENV_CLOUD, PIPELINE_EXEC_ENV_LOCAL, UserRecord } from '@fidoc/shared';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { orderBy } from 'firebase/firestore';
import { DomainComponent } from '../domain/domain.component';
import Stripe from 'stripe'

@Component({
  selector: 'lib-admin',
  standalone: true,
  imports: [
    IonSearchbar, 
    IonContent,
    IonModal, 
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    JsonFormComponent,
    FileSummaryComponent,
    LabelledSpinnerComponent,
    DomainComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminComponent {
  toolsService = inject(ToolsService)
  flowService = inject(FileflowService)
  userService = inject(UserService)
  defaultsService = inject(DefaultsService)
  utilityService = inject(PortalUtilityService)
  firebase = inject(FirebaseService)
  
  private route = inject(ActivatedRoute)
  private router = inject(Router)

  loadingMessage = signal('')
  
  tab$ = new BehaviorSubject('')
  tabIndex$ = new BehaviorSubject(0)

  editDefault$ = new BehaviorSubject<DefaultRecord | null>(null)

  tools$ = this.toolsService.tools$
    .pipe(
      map(toolDocs => {
        return this.toolsService.getTools()
          .map(tool => {
            const toolDoc = toolDocs.find(doc => doc.name === tool.name)
            const instructions = toolDoc?.instructions || tool.instructions
            const markdownInstructions = instructions ? this.flowService.renderMarkdown(instructions) : undefined
            return {
              ...tool,
              instructions,
              markdownInstructions
            }
          })
      })
    )

  searchFilter$ = new BehaviorSubject<string|null|undefined>('') 

  recentFiles$ = this.firebase.collectionGroupWithConverter$<FlowFile>(
    'flowfiles', 
    flowFileConverter, 
    orderBy('createdAt', 'desc'))
    .pipe(
      debounceTime(300),
      shareReplay(1),
      combineLatestWith(this.searchFilter$),
      map(([files, searchTerm]) => files.filter(file => (!searchTerm || ('' + file.fileName + file.userDocId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))))
  
  constructor() {
    marked.setOptions({
      pedantic: false,
      gfm: true,
      breaks: false
    })

    // Ensure user has admin role
    this.userService.user$
      .pipe(
        takeUntilDestroyed())
      .subscribe(user => {
        const isAdmin = !!user?.isAdmin
        if (!isAdmin) {
          console.log('non-admin user, redirecting to home')
          this.router.navigate(['home'])
        }
      })      

    this.route.queryParamMap
      .pipe(
        takeUntilDestroyed())
      .subscribe(params => {
        const userId = params.get('approve')
        if (userId) {
          console.log('approve', userId)
          this.tabIndex$.next(0)
        }
      })
  }

  getUserFiles(userId: string) {
    return this.firebase.collectionWithConverter$(getUserFilesPath(userId), flowFileConverter)
      .pipe(
        debounceTime(300),
        map(files => files.toSorted((a, b) => a.createdAt > b.createdAt ? -1 : 1) as FlowFile[]),
        shareReplay(1))
  }

  async editInstructions(tool: FlowTool) {
    const params = this.flowService.getToolParameters(tool)
    params.controls = params.controls.filter(param => param.name === 'instructions' )
    console.log('params', params)
    this.flowService.showToolParameters$.next({ tool, file: null, params })

    // await this.utilityService.prompt({
    //   header: `Edit Instructions for ${tool.name}`,
    //   inputType: 'textarea',
    //   inputValue: tool.instructions,
    //   inputCssClass: 'instructions-prompt',
    //   confirm: (result: any) => {
    //     const instructions = result.value 
    //     tool.instructions = instructions // record current instructions on tool
    //     this.flowService.updateTool(tool, { instructions })
    //   }
    // })
  }

  tabChanged(ev: any) {
    console.log('tab changed', ev)
    this.tabIndex$.next(ev.index)
    this.tab$.next(ev.tab.textLabel.toLowerCase())
  }

  async toggleApproval(user: UserRecord) {
    await this.userService.updateUser(user, { isApproved: !user.isApproved })
    if (!user.isApproved)
      this.userService.notifyUserApproved(user)
  }

  async zipUser(user: UserRecord) {
    const files = await firstValueFrom(this.getUserFiles(user.docId))
    const fileNames: string[] = []
    files.forEach(f => {
      if (f.storageName)
        fileNames.push(f.storageName)
      if (f.outputStorageName)
        fileNames.push(f.outputStorageName as string)
    })
    const zipfileName = `files-${user.docId}.zip`
    await this.flowService.zipFiles(zipfileName, fileNames, user.docId)
  }

  toggleExecutionEnv(user: UserRecord) {
    const pipelineEnv = (!user.pipelineEnv || user.pipelineEnv === PIPELINE_EXEC_ENV_LOCAL) 
      ? PIPELINE_EXEC_ENV_CLOUD
      : PIPELINE_EXEC_ENV_LOCAL
    this.userService.updateUser(user, { pipelineEnv })
  }

  toggleAdmin(user: UserRecord) {
    this.userService.updateUser(user, { isAdmin: !user.isAdmin, currentRole: user.isAdmin ? 'user' : 'admin' })
  }

  editDefault(doc: DefaultRecord) {
    this.editDefault$.next(doc)
  }

  addDefault() {
    this.editDefault$.next({ key: '', value: '', updatedAt: new Date() })
  }

  async deleteDefault(doc: DefaultRecord) {
    await this.utilityService.confirm({
      header: `Delete ${doc.key}`,
      message: `Are you sure you want to delete default for ${doc.key} ?`,
      confirm: () => {
        this.defaultsService.deleteDefault(doc)
      },
    });
  }

  submitDefault(controls: any, doc: DefaultRecord) {
    if (controls.key && controls.value) {
      const doc: DefaultRecord = {
        key: controls.key,
        value: controls.value,
        updatedAt: new Date()
      }
      this.defaultsService.updateDefault(doc, { key: controls.key, value: controls.value})
    }
  }

  prepareDefaultForm(doc: DefaultRecord) {
    const cssClass = 'white text-base text-black'
    const formData: JsonFormData = {
      submitAlwaysEnabled: true,
      controls: [ 
        { 
          name: 'key', 
          label: 'Key',
          type: 'textarea',
          cssClass,
          value: doc.key
        },
        { 
          name: 'value', 
          label: 'Value',
          type: 'textarea',
          cssClass,
          value: doc.value
        }
      ]
    }
    return formData
  }

  async confirmObliterate(user: UserRecord) {
    await this.utilityService.confirm({
      header: "Confirm Obliterate",
      message: `Are you absolutely sure you want to obliterate <b>${user.docId}</b>? This is not recoverable.`,
      confirm: () => this.obliterateUser(user) 
      })
  }

  async obliterateUser(user: UserRecord) {
    this.loadingMessage.set('Obliterating user')
    const files = await this.flowService.getFlowFilesForUser(user.docId)
    for (const file of files) {
      this.loadingMessage.set(`Deleting file ${file.fileName}`)
      await this.flowService.deleteFile(file)
    }
    this.loadingMessage.set(`Deleting user ${user.docId}`)
    await this.userService.deleteUser(user)
    this.loadingMessage.set('')
  }


  inTrialPeriod(user: UserRecord) {
    return user.subscriptionInfo && user.subscriptionInfo.status === 'trialing' //Stripe.Subscription.Status
  }

  hasSubscription(user: UserRecord) {
     if(!user.subscriptionInfo)
        return false
    const status = user.subscriptionInfo.status as Stripe.Subscription.Status
    return status === 'active' || status === 'trialing'
  }

  async endTrialPeriod(user: UserRecord) {
    if(!user.subscriptionInfo)
       return
   return await this.userService.endTrialPeriod(user)
  }

  async cancelSubscription(user: UserRecord) {
    if(!user.subscriptionInfo)
       return
   return await this.userService.cancelSubscription(user)
  }

  showInspector(obj: any) {
    this.flowService.showInspector$.next({ 
      title: `Inspect`,
      message: JSON.stringify(obj, null, 2)
    })
  }
  
  addPageBalance(user: UserRecord) {
    this.utilityService.prompt({
      header: 'Add Page Balance',
      inputType: 'text',
      placeholder: '100',
      confirm: this.confirmPageBalance.bind(this, user)
    })
  }
  async confirmPageBalance(user: UserRecord, data: { value: string; }) {
    //console.log('User', user)
    console.log('adding pages to', user.email, data)
    const pages = Number.parseInt(data.value)
    if(Number.isNaN(pages) || pages <= 0 || pages > 1000 ) {
      await this.utilityService.presentToast('Please enter a number between 1 and 1000')
      return false
    }
    // no await since we want the dialog to be dismissed quickly
    this.userService.updatePageBalance(user, pages)
    return true
  }
}
