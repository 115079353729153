@if (file(); as file) {
  <div class="flex flex-col border-2 bg-slate-300 p-2">
    <div class="flex items-center justify-normal gap-2">
      <span class="font-bold overflow-clip">{{ file.name }}</span>
      <span class="bg-red-100 font-normal text-xs">{{ file.type }}</span>
    </div>
    <div class="font-normal">{{ file.size }}</div>
    <div class="font-normal">{{ file.lastModified }}</div>
  </div>
}
