import { Component, inject, input, model, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { IonModal, IonContent, IonRadio, IonRadioGroup, IonItem } from '@ionic/angular/standalone';
import { ToolsService } from '../fileflow/tools.service';

@Component({
  selector: 'lib-pipeline-selector',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    IonModal,
    IonContent,
    IonRadio,
    IonRadioGroup, 
    IonItem
  ],
  templateUrl: './pipeline-selector.component.html',
  styleUrl: './pipeline-selector.component.scss',
})
export class PipelineSelectorComponent {

  toolsService = inject(ToolsService)

  selection = model<string>()
  message1 = input<string>()
  message2 = input<string>()
  dismiss = output<boolean>()
  submit = output<string|undefined>()
}
