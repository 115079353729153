import { Component, Inject, OnDestroy, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmationResult } from 'firebase/auth';
import { AuthService, DefaultsService, LabelledSpinnerComponent } from '@fidoc/util';
import { FirebaseService } from '@fidoc/util';
import { ADMIN_CERTIFIED, AUTH_FUNCTIONS, FIREBASE_AUTH_CLOUD_FUNCTION, MailProviders, SENDGRID_magic_link_template_id } from '@fidoc/shared';

@Component({
  selector: 'lib-login',
  standalone: true,
  imports: [ 
    CommonModule, 
    MatButtonModule, 
    LabelledSpinnerComponent
  ],
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnDestroy {

  auth = inject(AuthService)
  route = inject(ActivatedRoute)
  utilityService = inject(PortalUtilityService)
  firebaseService = inject(FirebaseService)
  defaultsService = inject(DefaultsService)
  errorMessage = ''
  loading = signal(false)

  constructor(
    @Inject('environment') public environment: any) {}

  ngOnDestroy(): void {
    // Temporary hack to fix backdrop scrolling issue
    // If ion_modal.didDismiss worked after login, we could do this there
    console.log('login component destroyed')
    document.body.style.overflowY = ''      
  }

  checkDevPassword(func: Function) {
    if(this.environment.production || window.localStorage.getItem(ADMIN_CERTIFIED))
      return func.bind(this)()
    this.utilityService.prompt({
      message: 'Enter fidocs dev password', 
      confirm: (prompt) => {
        console.log('password prompt value', prompt)
        if(!prompt || prompt.value !== this.environment.devPwd) {
          this.utilityService.notify({ message: 'Invalid Password' })
          return false // abort dismiss
        }
        window.localStorage.setItem(ADMIN_CERTIFIED, "true")
        func.bind(this)()
        // console.log("dev password accepted, navigating to", url)
        //this.ngZone.run(() => { this.router.navigateByUrl(url) })
        return true
      },
      cancel() {
        return false
      }
    })
  }

  googleLogin() {
    this.loading.set(true)
    this.auth.signInWithGoogle(this.environment)
      .subscribe({
        next: () => null,
        error: (e) => { 
          console.error(e)
          this.loading.set(false)
          this.errorMessage = e.message 
        },
        complete: () => console.info('googleLogin complete') 
      })
    
  }

  microsoftLogin() {
    this.loading.set(true)
    this.auth.signInWithMicrosoft(this.environment)
      .subscribe({
        next: () => null,
        error: (e) => { 
          console.error(e)
          this.loading.set(false)
          this.errorMessage = e.message 
        },
        complete: () => console.info('microsoftLogin complete') 
      })
  }
  appleLogin() {
    this.loading.set(true)
    this.auth.signInWithApple(this.environment)
      .subscribe({
        next: () => null,
        error: (e) => { 
          console.error(e)
          this.loading.set(false)
          this.errorMessage = e.message 
        },
        complete: () => console.info('appleLogin complete') 
      })
  }

  async promptForEmail() {
    console.log('promptForEmail')
    await this.utilityService.prompt({
      message: 'We will send a single-use sign-in link to the email address you specify below. When you receive the email, click the link to sign in.',
      placeholder: 'Enter email address',
      inputType: 'text',
      confirm: this.confirmPromptForEmail.bind(this)
    })
  }

  private confirmPromptForEmail(data: any)  {
    const email = data.value.toLowerCase().trim()
    console.log('EMAIL', email)

    if (email === '' || !this.utilityService.validateEmail(email)) {
      this.utilityService.presentToast('Please enter a valid email address')
      return false
    }

    this.firebaseService.awaitCloudFunction(FIREBASE_AUTH_CLOUD_FUNCTION, {
      function: AUTH_FUNCTIONS.GEN_PASSWORD_LINK,
      email, 
      sendEmail: false
    }).then (res => {
        console.log('res', res)
        const magic_link = res.data
        const provider = this.defaultsService.getDefault('mailProvider')
        if (provider === MailProviders.MAILGUN) {
          const subject = this.defaultsService.getDefault('magicLink.email.subject')
          const text = this.defaultsService.getDefault('magicLink.email.message', [ email, magic_link, this.environment.adminEmail, this.environment.adminEmail ])
          this.firebaseService.awaitCloudFunction("sendEmailAttachment", {
              to: email,
              provider: MailProviders.MAILGUN,
              subject,
              text,
              html: text
          })
        }
        else if (provider === MailProviders.SENDGRID)
        this.firebaseService.awaitCloudFunction("sendEmailAttachment", {
          to: email,
          provider: MailProviders.SENDGRID,
          templateId: SENDGRID_magic_link_template_id,
          dynamicTemplateData: {
              first_name: email,
              email,
              magic_link
          }
      })
    })

    localStorage.setItem('emailForSignIn', email)
    this.utilityService.notify({ message: `A magic link has been sent to ${email}. Click the link to sign in. You may close this window.` })
    return true
  }
  async promptForPhone() {
    await this.utilityService.prompt({
      message: 'Enter your phone number below and we will send a verification code to your phone.',
      placeholder: 'Enter phone number',
      inputType: 'tel',
      inputId: 'phoneInput',
      confirm: async (phone: any) => {
        let confirmationResult:ConfirmationResult
        try {
          confirmationResult = await this.auth.sendVerificationCode(phone.value, 'phoneInput')
        }
        catch (e: any) {
          this.utilityService.notify({ message: e.code === 'auth/invalid-phone-number'
            ? 'Invalid phone number'
            : e.message
          })
          return
        }
        this.utilityService.prompt({
          message: 'Enter the verification code sent to your phone',
          placeholder: 'Enter verification code',
          confirm: async (code: any) => {
            try {
              await this.auth.signInWithPhone(code.value, confirmationResult)
            }
            catch (e: any) {
              this.utilityService.notify({ message: e.code === 'auth/invalid-verification-code' 
                ? 'Invalid verification code' 
                : e.message })
            }
          }
        })
      }
    })

  }


}
