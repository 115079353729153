<div class="h-[85vh]">
    <mat-tab-group 
        class="h-full"
        mat-stretch-tabs="false" 
        mat-align-tabs="start" 
        animationDuration="0ms" 
        [selectedIndex]="tabIndex$ | async" 
        (selectedTabChange)="tabChanged($event)">
        @for (schemaName of schemaNames(); track schemaName) {
            @let schema = schemas()[schemaName];
            @let classDescription = classes().get(schemaName)?.description;
            @let displayMode = schemaModes().get(schemaName);
            <mat-tab [label]="schemaName">
                <ng-template matTabContent>
                    <div class="flex justify-between items-center p-2 py-3">
                        <div class="font-bold">{{ classDescription }}</div>
                        <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-xl sm:text-2xl" [matMenuTriggerFor]="moreMenu">
                            more_horiz
                          </span>
                          <mat-menu #moreMenu="matMenu" xPosition="after" class="dialog-menu">
                              <button mat-menu-item (click)="toggleModeChanged(displayMode, schemaName)">
                                {{ displayMode ? 'View as Table' : 'View as JSON' }}
                              </button>
                              <button mat-menu-item (click)="downloadFile()">
                                Download as XLSX
                              </button>
                          </mat-menu>                  
                        <!-- <mat-checkbox [checked]="displayJson" (change)="displayModeChanged($event, schemaName)">JSON</mat-checkbox> -->
                    </div>
                    <div class="overflow-y-scroll">
                        @if (displayMode) {
                            <pre class="text-xs">{{ schema.data | json }}</pre>
                        }
                        @else {
                            @let stream = schemaStreams().get(schemaName);
                            @for (t of stream; track t.id) {
                                <table [id]="t.id" class="table-auto text-xs border w-full">
                                    @if (t.headers) {
                                        <thead>
                                            <tr>
                                                @for (h of t.headers; let index = $index; track index) {
                                                    <th class="text-left px-2">{{ h }}</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            @for (r of t.rows; let index = $index; track index) {
                                                <tr>
                                                    @for (c of r; let cindex = $index; track cindex) {
                                                        <td class="px-2">{{ c }}</td>
                                                    }
                                                </tr>
                                            }
                                        </tbody>
                                    }
                                    @else {
                                        <tbody>
                                            @for (r of t.rows; let index = $index; track index) {
                                                <tr>
                                                    @for (c of r; let cindex = $index; track cindex) {
                                                        <td class="px-2 last:text-right">{{ c }}</td>
                                                    }
                                                </tr>
                                            }
                                        </tbody>
                                    }
                                </table>
                            }
                            <!-- <pre class="text-xs">{{ stream | json }}</pre> -->
                        }
                    </div>    
            </ng-template>
            </mat-tab>
        }
    </mat-tab-group>
</div>
    