import { Inject, Injectable, computed, inject, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { filter, map, tap, take, combineLatestWith, switchMap, timeout, catchError, of, debounceTime, shareReplay, firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { toObservable } from '@angular/core/rxjs-interop';
import { UserRecord, UserCollectionTypes, sendMeterEvent, USERS_COLLECTION, STRIPE_enterprise_welcome_template_id } from '@fidoc/shared';
import { QueryDocumentSnapshot, runTransaction, getFirestore, doc } from '@angular/fire/firestore';
import { DefaultsService } from './defaults.service';
import { PortalUtilityService } from '@cheaseed/portal/util';

export const UserRecordConverter = {    
  toFirestore(sub: UserRecord) { 
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): UserRecord {
    // console.log("fromFirestore")
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): UserRecord {
    if (data) {
      // Handle createdAt strings (old) for now
      if (data.createdAt) {
        data.createdAt = (typeof data.createdAt === 'string') 
          ? new Date(data.createdAt) 
          : data.createdAt.toDate()
      }
      data.lastLogin = data.lastLogin.toDate()
      data.updatedAt = data.updatedAt?.toDate()
      if (data.subscriptionInfo) {
        data.subscriptionInfo.trialStartDate = data.subscriptionInfo.trialStartDate?.toDate()
        data.subscriptionInfo.trialEndDate = data.subscriptionInfo.trialEndDate?.toDate()
        data.subscriptionInfo.subscriptionStartDate = data.subscriptionInfo.subscriptionStartDate?.toDate()
        data.subscriptionInfo.subscriptionEndDate = data.subscriptionInfo.subscriptionEndDate?.toDate()
      }
      return data as UserRecord
    }
    else
      return {} as UserRecord
  },
  fromArray(data: any[]): UserRecord[] {
    console.log("fromFirestoreArray")
    return data.map(d => this.fromFirestoreData(d))
  }
}
@Injectable({
  providedIn: 'root'
})
export class UserService {

  auth = inject(AuthService)
  firebase = inject(FirebaseService)
  defaultsService = inject(DefaultsService)
  http = inject(HttpClient)
  utilityService = inject(PortalUtilityService)

  user = signal<UserRecord | null | undefined>(undefined)
  userPath = computed(() => this.getUserPath(this.user()?.docId))
  // role = signal<ROLE_TYPE | undefined>(undefined)
  // role$ = new BehaviorSubject<ROLE_TYPE>(ROLE_TYPE.user) // can be toggled to admin if hasAdminClaim
  userDocId = computed(() => this.user()?.docId)
  userDocId$ = toObservable(this.userDocId)
  user$ = toObservable(this.user)
  adminRole = computed(() => this.user()?.currentRole === 'admin')

  recentUsers$ = this.firebase.collectionWithConverter$(UserCollectionTypes.USERS, UserRecordConverter)
    .pipe(
      debounceTime(800),
      // tap(users => console.log("recentUsers", users)),
      map((users:UserRecord[]) => users.toSorted((a, b) => a.lastLogin! > b.lastLogin! ? -1 : 1)),
      shareReplay(1)
    )
  
  
  private getUser(docId: string) {
    return this.firebase.doc$(this.getUserPath(docId), UserRecordConverter)
  }

  private getUserPath(id:string|null = null) {
    return `${UserCollectionTypes.USERS}/${id}`
  }

  private getIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json")
      .pipe(
        timeout(2000),
        catchError(err => { // if ipify fails after 1 sec, just continue
          console.error("ipify failed with error", err)
          return of(null)
        }))
  }

  signOut() {
    this.user.set(undefined)
    this.auth.logout()
  }

  guessTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  constructor(
    @Inject('environment') private environment: any
  ) { 

    this.auth.user$
      .pipe(
        tap(u => { if (!u) this.user.set(null) }),
        filter(u => !!u),
        switchMap(u => this.getUser(u?.email)
          .pipe(
            take(1),
            combineLatestWith(this.getIpAddress()), 
            
            map(([ user, resp ]) => ({ user, address: resp, firebaseUser: u })),
          )),
        map((data: any) => {
          const { user, address, firebaseUser } = data
          const displayName = this.auth.clearDisplayName()
          const u = firebaseUser
          const docId = u.email
          const path = this.getUserPath(docId)
          const update: any = {
            lastIpAddress: address?.ip,
            lastLogin: new Date(),
            buildTag: this.environment.buildTag,
            releaseTag: this.environment.releaseTag,
            patchLevel: this.environment.patchLevel > 0 ? this.environment.patchLevel : undefined,
            lastReferrer: document.referrer,
            name: displayName || u.displayName || user?.name,
            photoURL: u.photoURL,
            provider: u.providerId,
            isAnonymousUser: u.isAnonymousUser,
            lastUserAgent: navigator.userAgent
          }
          
          let newUser:UserRecord
          if (!user) {
            newUser = { 
              ...update,
              initialBuildTag: this.environment.buildTag,
              initialReleaseTag: this.environment.releaseTag,
              timezone: this.guessTimezone(),
              createdAt: new Date(),
              pageBalance: 0,
              isApproved: false,
              currentRole: 'user',
              initialPatchLevel: this.environment.patchLevel > 0 ? this.environment.patchLevel : undefined,
              initialReferrer: document.referrer ? document.referrer : undefined,
            }
            // console.log("creating new user with", newUser)
            // Double check user exists in firestore
            this.firebase.updateAt(path, newUser)
          }
          else {
            newUser = { ...user, ...update }
            //For enterprise trial users, we have already created a template but dont know
            // the timezone till the user logs in for the first time. The first login
            // will be an update for such users instead of an insert
            if(!newUser.timezone) 
              update.timezone = this.guessTimezone()
            // Update firestore user with latest session data, unless an admin environment
            if (!this.environment.admin)
              this.firebase.updateAt(path, update)
          }
          // Track role locally
          // this.role$.next(newUser.claims?.admin ? ROLE_TYPE.admin : ROLE_TYPE.user)
          // this.role.set(newUser.claims?.admin ? ROLE_TYPE.admin : ROLE_TYPE.user)
          // if (!this.environment.admin) {
          // this.updateClevertapProfile(docId, { ...update, photoURL: null })
          // }
          const result = { 
            ...newUser, 
            docId,
          }
          return result
        }),
        filter(u => !this.environment.admin)
      ) // do not continue with admin app
      .subscribe((user:UserRecord) => {
          console.log("user set to", user.docId)
          this.user.set(user)
          this.watchUserChanges(user.docId)
      })    
  }

  watchUserChanges(docId: string) {
    this.getUser(docId)
      .pipe(
        filter(u => !!u),
        this.auth.takeUntilAuth(),
        debounceTime(1000), 
      )
      .subscribe((user:UserRecord) => {
        console.log("observeUserChanges for", docId)
        this.user.set(user)
      })
  }

  async addEnterpriseTrialUserTemplate(email: string, u: Partial<UserRecord>) {
    await this.firebase.updateAt(USERS_COLLECTION + `/${email}`, u)
    const enterpriseTrialEmailSubject = this.defaultsService.getDefault('admin.enterpriseTrialEmail.subject')
    const res = await this.firebase.awaitCloudFunction("generatePasswordLessLoginLink", {email, sendEmail: false})
    this.firebase.awaitCloudFunction("sendEmailAttachment",
      {
        to: email,
        provider: 'sendgrid',
        templateId: STRIPE_enterprise_welcome_template_id,
        dynamicTemplateData: {
            first_name: u.name || email,
            email,
            email_subject: enterpriseTrialEmailSubject,
            url: res.data,
            trial_pages: u.pageBalance,
            evaluation_end_date: '' // TODO
        }
    })
  }

  /**
   * NOTE: duplicated code - same method exists fileflow-cloud.service.ts
   * We cannot share this code because of different firebase libraries used 
   * in the cloud and locally
   * @param user 
   * @param delta 
   */
  async updatePageBalance(user: UserRecord, delta: number) {
    // Update user document in a Firestore transaction
    const path = this.getUserPath(user.docId)
    const db = getFirestore()
    let overagePages = 0
    try {
        await runTransaction(db, async transaction => {
            const ref = doc(db, path)
            const u = await transaction.get(ref);
            const data = u.data() as UserRecord
            //delta is a negative number
            if(data.pageBalance <= 0) {
              //already in overage state - all pages consumed are metered
              overagePages = -delta
          }
          else {
              //send the difference if consuming delta pages causes
              // the balance to go negative
              if(data.pageBalance + delta < 0)
                overagePages = Math.abs(data.pageBalance + delta)
          }
          const pageBalance = data.pageBalance + delta
          transaction.update(ref, { pageBalance })
          console.log('Transaction succeeded, pageBalance updated', pageBalance)
        })
    }
    catch (e) {
        console.error('Transaction failed, pageBalance did not update', delta)
    }
    if(overagePages > 0) {
      console.log('Sending meter event to Stripe with payload', overagePages)
      await sendMeterEvent(
        this.environment.production ? 'prod' : 'dev', 
        this.user() as UserRecord, 
        overagePages, 
        console
      )
    }
  }

  async updateUser(user: UserRecord, data: any) {
    const path = this.getUserPath(user.docId)
    await this.firebase.updateAt(path, { ...data, updatedAt: new Date() })
  }

  notifyAdminForApproval(user: UserRecord) {
    // Exit if production
    if (this.environment.production) 
      return
    // Send email to admin for approval
    const subject = this.defaultsService.getDefault('admin.approval.email.subject', [ user.docId, this.environment.production ? 'prod' : 'dev' ])
    const body = this.defaultsService.getDefault('admin.approval.email.body', [ this.environment.adminHost, user.docId ])
    this.firebase.awaitCloudFunction("sendEmailAttachment", {                
      to: this.environment.adminEmail,
      subject,
      text: body,
      provider: 'sendgrid'
    })
  }

  notifyUserApproved(user: UserRecord) {
    // Exit if production
    if (this.environment.production) 
      return
    const subject = this.defaultsService.getDefault('user.approved.email.subject')
    const body = this.defaultsService.getDefault('user.approved.email.body', [ this.environment.portalHost ])
    this.firebase.awaitCloudFunction("sendEmailAttachment", {                
      to: user.docId,
      subject,
      text: body,
      provider: 'sendgrid'
    })
  }

  deleteUser(user: UserRecord) { 
    const path = this.getUserPath(user.docId)
    this.firebase.delete(path)
    //TODO: delete user from Firebase Auth
  }

  async endTrialPeriod(user: UserRecord) {
    return await this.firebase.awaitCloudFunction('mutateSubscription', {
      userId: user.docId,
      subscriptionId: user.subscriptionInfo?.subscriptionId,
      action: 'endTrial'
    });
  }
  async cancelSubscription(user: UserRecord) {
    return await this.firebase.awaitCloudFunction('mutateSubscription', {
      userId: user.docId,
      subscriptionId: user.subscriptionInfo?.subscriptionId,
      action: 'cancel'
    });
  }

  manageStripeSubscription(user: UserRecord) {
    window.open(`${this.environment.stripe.customerPortalLink}?prefilled_email=${user.docId}`, '_blank')
  }

  async provisionEnterpriseTrialUser() {
    console.log('Current user', this.user())
    await this.utilityService.multiPrompt({
      header: 'Enter User Details',
      inputs: [
        {
          name: 'email',
          type: 'email',
          label: 'Email',
          placeholder: 'email'
        },
        {
          name: 'user',
          type: 'text',
          label: 'User Name',
          placeholder: 'User Name'
        },
        {
          name: 'pages',
          type: 'number',
          label: 'Pages',
          placeholder: 'pages',
          value: 200,
          min: 10,
          max: 1000
        }
      ],
      confirm: this.confirmEnterpiseTrialUserCreation.bind(this)
    })

  }
  async confirmEnterpiseTrialUserCreation(data: { email: string, user: string, pages: string }) {
    console.log('Data', data)
    if(!await this.enterpriseTrialEmailHandler(data.email)) {
      //await this.utilityService.presentToast('Please enter a valid email address')
      return false
    }
    const pages = Number.parseInt(data.pages) 

    if(Number.isNaN(pages) || pages < 10 || pages > 1000) {
      await this.utilityService.presentToast('Please enter a valid number of pages to allocate (10-1000)')
      return false
    }
    //console.log('this.user()', this.user())
    const u: Partial<UserRecord> = {
      isEnterpriseTrialUser: true,
      enterpriseTrialUserCreatedBy: this.user()?.docId,
      name: data.user || '',
      buildTag: this.environment.buildTag,
      createdAt: new Date(),
      //email: data.email,
      initialBuildTag: this.environment.buildTag,
      releaseTag: this.environment.releaseTag,
      currentRole: 'user',
      hasAcceptedTerms: false,
      isApproved: true,
      pageBalance: pages,
      lastLogin: new Date(),
      initialPatchLevel: this.environment.patchLevel > 0 ? this.environment.patchLevel : undefined,
      initialReferrer: document.referrer ? document.referrer : undefined,
    }
    console.debug(`Creating Enterprise Trial User`, u)
    await this.addEnterpriseTrialUserTemplate(data.email, u)
    return true
  }
  
  async enterpriseTrialEmailHandler(email: string) {
    //console.log('Input to enterpriseTrialEmailHandler', email)
    //const email = input.value
    if(!email || email === '') {
      await this.utilityService.presentToast('Email is required')
      return false
    }
    if(await firstValueFrom(this.getUser(email))) {
      await this.utilityService.presentToast('A user with this email already exists')
      return false
    }
    if(!this.utilityService.validateEmail(email)) {
      await this.utilityService.presentToast('Please enter a valid email address')
      return false
    }
    return true
  }
}
