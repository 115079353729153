import { AnalyzeResultOperationOutput, DocumentLineOutput, DocumentParagraphOutput, DocumentSpanOutput } from '@azure-rest/ai-document-intelligence'
import { DocIntelTool } from './DocIntelTool';

export interface DocIntelJSONAnalysisPageInterface {
    pageNumber: number
    lines: DocumentLineOutput[]
    spans: DocumentSpanOutput[]
    paragraphs: DocumentParagraphOutput[]

}
export interface DocIntelJSONAnalysisInterface {
  pages: DocIntelJSONAnalysisPageInterface[]
}

export class DocIntelJSONTool extends DocIntelTool {

  override name = 'docintel-json'

  override async reformatDocIntelAnalysisOutput(analysis: AnalyzeResultOperationOutput) {
    const obj: any = {}
    
    const pages = analysis.analyzeResult?.pages || []
    const paragraphs = analysis.analyzeResult?.paragraphs
  
    obj.pages = []
    let currentIndex = 0
    for (let i = 0; i < pages.length; i++) {
      const p = pages[i]
      const pObj:any = { pageNumber: p.pageNumber, lines: p.lines, spans: p.spans}
      pObj.paragraphs = paragraphs?.filter(p => p.boundingRegions?.at(0)?.pageNumber === i+ 1)
      this.flowService.log(`paragraphs on page ${i+1}`, pObj.paragraphs)
      this.flowService.log('LINES', p.lines)
      obj.pages.push(pObj as DocIntelJSONAnalysisPageInterface)
      // break up the markdown page by page by comparing the last 2 lines
      //const secondLastLine = lines.length <= 1 ? "": lines[lines.length - 2] 
    }
    
    return obj 
  }
  
}
