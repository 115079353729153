/**
 * Constants used by cloud functions and the fidocs app
 * TODO - migrate the constants.ts file under node-utils here
 */
export const MAX_OPENAI_RETRIES = 4
export const DEFAULT_OPENAI_TIMEOUT = 540000 // 9 minutes to get one response from OpenAI

export enum AUTH_FUNCTIONS  {
    GEN_PASSWORD_LINK = "generatePasswordLink",
    GET_SIGNIN_METHODS = "getSigninMethods",
    CREATE_CUSTOM_TOKEN = "createToken",
    OBLITERATE_USER = 'obliterateUser'
}
export const FIREBASE_AUTH_CLOUD_FUNCTION = "firebaseAuthFunctions"
export const TOOLS_PIPELINE_TOPIC = "fidocs-tools-pipeline"

export enum FirestoreCollectionTypes {
    USERS = "users",
    PAYMENTS = "payments",
    DOMAINS_COLLECTION = 'domains',
    TOOLS_COLLECTION = 'tools',
    PIPELINES_COLLECTION = 'pipelines',
    FILEFLOW_COLLECTION = 'flowfiles',
    COSTS_COLLECTION = 'costs',
    DEFAULTS_COLLECTION = 'defaults'
  }
export const ADMIN_CERTIFIED = 'fidocs-admin-certified'
export interface MessagePayload  {
    userId: string
    flowFileDocId: string
    pipelineName: string
    index?: number // the index of the next tool in the pipeline's tools collection

}
export interface BatchMessagePayload  {
    userId: string
    flowFileDocIds: string[]
    pipelineName: string
    index?: number // the index of the next tool in the pipeline's tools collection
}
