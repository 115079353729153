import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlowDomain, FlowSchemaType } from '@fidoc/shared';
import { DomainService, InspectorService, UtilityService } from '@fidoc/util';

@Component({
  selector: 'lib-domain',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './domain.component.html',
  styleUrl: './domain.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainComponent {

    inspectorService = inject(InspectorService)
    domainService = inject(DomainService)
    utilityService = inject(UtilityService)
    domains = toSignal(this.domainService.allDomains$)
    selectedDomain = signal<FlowDomain | null>(null)

    domainClasses = computed(() => {
        const domain = this.selectedDomain()
        if (!domain) return []
        const classes = domain.classes.sort((a, b) => a.className < b.className ? -1 : 1)
        const map = new Map<string, FlowSchemaType>(classes.map(c => [c.className, c]))
        return classes.map(c => {
          const useClass = map.get(c.useSchemaOfClass)
          return c.useSchemaOfClass
            ? { ...c, schemaName: useClass?.schemaName, schemaId: useClass?.schemaId }
            : c
        })
    })

    constructor() {
        effect(() => {
            const domains = this.domains() || []
            if (domains.length > 0) 
                this.selectedDomain.set(domains[0])
        })
    }

    viewSchema(domain: FlowDomain, doc: FlowSchemaType) {
        const schema = doc.jsonSchema
        if (schema) {
            this.inspectorService.inspect(`${doc.className} Schema`, schema)
        }
    }

    viewSchemaInstructions(domain: FlowDomain, doc: FlowSchemaType) {
        const cls = (doc.useSchemaOfClass 
            ? domain.classes.find(c => c.className === doc.useSchemaOfClass)
            : doc) as FlowSchemaType
        this.inspectorService.inspect(`${doc.className} Schema Instructions`, cls.instructions as string)
    }

  regenerateSchema(domain: FlowDomain, doc: FlowSchemaType) {
    // Dispatch to Github Action to run regenerate script
    this.utilityService.notify({ header: 'Coming Soon' })
  }



}
