import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'cheaseed-file-drop',
  standalone: true,
  imports: [
    CommonModule, 
    NgxFileDropModule,
    MatButtonModule
  ],
  templateUrl: './file-drop.component.html',
  styleUrl: './file-drop.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropComponent {

  // Alternative: https://github.com/hackingharold/ngx-dropzone
  // Alternative: https://www.youtube.com/watch?v=w7vKcUJld-U, https://gitlab.com/ryanlogsdon/angular-drag-and-drop-file-upload

  private files: FileSystemFileEntry[] = []
  @Output() droppedFiles = new EventEmitter<FileSystemFileEntry[]>();

  public fileOver(event: any){
    // console.log(event);
  }

  public fileLeave(event: any){
    // console.log(event);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = []
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        this.files.push(fileEntry)
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log("nonfile", droppedFile.relativePath, fileEntry);
      }
    }
    // console.log('emitting', this.files.length, this.files);
    this.droppedFiles.emit(this.files);
  }
}
