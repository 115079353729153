import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExcelUtils, FlowFile } from '@fidoc/shared';
import { MatTab, MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';
import { DomainService } from '@fidoc/util';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FileflowService } from '../fileflow/fileflow.service';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'lib-file-schema-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatTab,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule
  ],
  templateUrl: './file-schema-viewer.component.html',
  styleUrl: './file-schema-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSchemaViewerComponent {

  domainService = inject(DomainService)
  fileflowService = inject(FileflowService)
  excelUtils = new ExcelUtils(this.fileflowService)

  file = input.required<FlowFile>()
  domain = computed(() => {
    const domainName = this.file().domainName as string
    const domain = this.domainService.getDomainNamed(domainName)
    return domain
  })
  schemas = computed<Record <string, any>>(() => this.file().schemas || {})
  schemaNames = computed(() => Object.keys(this.schemas()).toSorted())
  schemaModes = signal<Map<string, boolean>>(new Map())
  
  classes = computed(() => {
    const types = this.domain()?.classes || []
    return new Map<string, any>(types.map(t => [t.className, t]))
  })

  schemaStreams = computed(() => {
    const streams = new Map<string, any>()
    for (const schemaName of this.schemaNames()) {
      const schema = this.schemas()[schemaName]
      const stream: any[] = []
      this.streamSchemaToTable(schema.data, stream)
      streams.set(schemaName, stream)
    }
    return streams
  })

  tab$ = new BehaviorSubject('')
  tabIndex$ = new BehaviorSubject(0)

  tabChanged(ev: any) {
    console.log('tab changed', ev)
    this.tabIndex$.next(ev.index)
    this.tab$.next(ev.tab.textLabel.toLowerCase())
  }

  displayModeChanged(ev: any, schemaName: string) {
    this.schemaModes.update(modes => modes.set(schemaName, ev.checked))
  }

  toggleModeChanged(displayMode: boolean | undefined, schemaName: string) {
    this.schemaModes.update(modes => modes.set(schemaName, !displayMode))
  }

  async downloadFile() {
    const f = this.file()
    await this.fileflowService.downloadOutputFile(f)
  }

  appendTable(id: string, ws: any[], rows: any[], headers: string[] | null = null) {
    ws.push({ id, headers, rows})
  }

  streamSchemaToTable(obj: any, ws: any[], keyPrefix = '') {
    const keys = Object.keys(obj)
    // Order literals first, then objects, then arrays
    const literals = keys.filter(key => typeof obj[key] !== 'object' && !Array.isArray(obj[key])).sort()
    const objects = keys.filter(key => typeof obj[key] === 'object' && !Array.isArray(obj[key])).sort()
    const arrays = keys.filter(key => Array.isArray(obj[key])).sort()
    let cnt = 0
    // Collect all literals and objects into one set of rows and sort them
    // Write literals table
    const literalRows:any = []
    for (const key of literals) {
      const val = obj[key]
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      literalRows.push([fullKey, `${val}`])
    }
    this.appendTable(`${keyPrefix}.${cnt}`, ws, literalRows)
    cnt++

    // Write object tables
    for (const key of objects) {
      const val = obj[key]
      this.streamSchemaToTable(val, ws, keyPrefix ? `${keyPrefix}.${key}` : key)
    }

    // Write array tables
    for (const key of arrays) {
      const val = obj[key]
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      if (Array.isArray(val)) {
        const colHeaders = this.excelUtils.inferColumnHeaders(val)
        const tableRows = val.map(row => colHeaders.map(key => row[key] ? `${row[key]}` : ''))
        // console.log({ colHeaders, tableRows })
        this.appendTable(`${keyPrefix}.${cnt}`, ws, tableRows, colHeaders)
      }
    }
  }

}
