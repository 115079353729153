import Stripe from 'stripe'
const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51Q2RJaJc8FXHjVD6QadT3QNfKgJclljxGYJgwoED1VwP9VLqe7ve2ibX4NPSVaSS3yUjvQ1K1u86ktg4BLYyW0MP00MCQqI1r3'
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51Q2RJaJc8FXHjVD6ZTVGnDVIVlk7zCu0SmECvEfGkWeFWab5lkcvYdUXxLBLh7SXD14aj4lpk69JcnopoOkdjGHB00eUoipEcQ'
const STRIPE_SECRET_KEY = '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'
const STRIPE_TEST_SECRET_KEY = 'sk_test_51Q2RJaJc8FXHjVD6FgWnSP77nq1oiyKSObPXb7oITlswpEKD2vK5riYExjDiCI5dVNLt7ZSRkmf2FtK7f7lZRHPd00Q1YLdifq'
const STRIPE_TEST_ENDPOINT_SECRET = 'whsec_azR4hQbUwhKVnLWk4BsmaKLkoy04KN1a'
const STRIPE_ENDPOINT_SECRET = 'whsec_i9NZDas968FAENBlM1SmxG9lHo2Zm6i7'
export const TRIAL_PAGE_BALANCE = 25

export const INVOICE_OVERAGE_TYPE = "overage"
export const INVOICE_OVERAGE_MEMO = 'Overage Invoice from ArkData LLC'
export const INVOICE_OVERAGE_ITEM_DESCRIPTION = 'Overage Charges'
export const BILL_OVERAGE_TOPIC_NAME = 'bill-overage'

export const COUPON_CODE = 'FIDOCS50'
export const STRIPE_SUBSCRIPTION_INVALID_STATUSES = ['incomplete', 'incomplete_expired', 'past_due', 'unpaid', 'paused']
export interface SubscriptionInfo {
  subscriptionId: string
  productId: string
  customerId: string
  invoiceId?: string
  meterId?: string
  status: Stripe.Subscription.Status
  interval?: Stripe.Plan.Interval 
  trialStartDate?: Date
  trialEndDate?: Date
  pagesAllocated?: number
  subscriptionStartDate?: Date
  subscriptionEndDate?: Date
  hasPaid: boolean,
  cancelled?: boolean,
  failureData?: Stripe.Charge.Outcome
}

export interface OverageMessage {
  userId: string,
  overage: number
  overage_start: Date 
  overage_end: Date 
}
export function getStripeSecretKey(env: string) {
    // console.log('Env = ', env)
    return env === 'dev'
        ? STRIPE_TEST_SECRET_KEY
        : STRIPE_SECRET_KEY
  }

  export function getStripePublishableKey(env: string) {
    return env === 'dev' ?
        STRIPE_TEST_PUBLISHABLE_KEY
        : STRIPE_PUBLISHABLE_KEY
  }

  export function getStripePaymentLinkEndPointSecret(env: string) {
    return env === "dev" ?
        STRIPE_TEST_ENDPOINT_SECRET
        : STRIPE_ENDPOINT_SECRET
  }
/**
 * 
 * @param productId 
 * @param stripe 
 * @returns the perUnit Stripe.Price object associated with the product. Assumes only one such
 * price exists
 */
  export async function getPerUnitPrice(productId: string, stripe: Stripe) {
    const prices = await stripe.prices.list({ active: true, product: productId, type: 'recurring' })
    const perUnitPrices = prices.data.filter(p => p.active && p.billing_scheme === 'per_unit')
    if(perUnitPrices.length === 0) {
      throw new Error(`Internal Error: No per unit price found for ${productId}`, {cause: 'internal'})
    }
    return perUnitPrices[0]
}