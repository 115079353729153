import { AssistantsClient, AzureKeyCredential, MessageTextContent } from '@azure/openai-assistants';
import {
  DEFAULT_ASSISTANT_MODEL,
  DOCUPANDA_CLASSES_ENDPOINT,
  DOCUPANDA_KEY,
  DOCUPANDA_SCHEMAS_ENDPOINT
} from './fileflow';
import { JsonFormData, LoggerInterface } from '@cheaseed/node-utils';
import { Table } from 'exceljs';
import { AzureOpenAI, RateLimitError } from 'openai';
import { AgentType, FlowTool } from './fileflow.interface';
import { AzureServerConfig, getAzureOpenAIServer } from './azure-constants';
import { BackoffOptions, backOff } from 'exponential-backoff'
import { ChatCompletion } from 'openai/resources';
import { MAX_OPENAI_RETRIES, DEFAULT_OPENAI_TIMEOUT, FirestoreCollectionTypes } from './fidoc-constants';

export const SENDGRID_generic_template_id = 'd-ed90adc8ce534fe39f881eec702f4f01'
export const SENDGRID_magic_link_template_id = 'd-d46903a81add4e7bbca626c196f671d5'
export const SENDGRID_welcome_template_id = 'd-96f729b3cbaa4788895d9d3a80121732'
export const SENDGRID_enterprise_welcome_template_id = 'd-b84a19d9eb0b45cbaad9b9ae4c24666e'
export const SENDGRID_unsubscribe_group_id = 25239

export const STORAGE_FOLDER = 'uploads'
export const FILE_EXT_SEPARATOR = '.'
export const PATH_SEPARATOR = '/'
 //treat non-ascii em-dash as valid input
export const NON_PRINTABLE_REGEX = /(?![—])[^\x20-\x7E]/gu
export const CELL_PADDING = 0;
export const COMPLETIONS_SEED = 1
export interface TableCoords {
  table: Table;
  startRow: number;
  numRows: number;
}

export const defaultTransformerParameters: JsonFormData = {
  submitAlwaysEnabled: true,
  controls: [ 
    { 
      name: 'instructions', 
      label: 'Instructions',
      type: 'textarea',
      cssClass: 'bg-yellow-100 text-xs'
      // value intentionally left blank, set on the fly per use
    },
    {          
      name: 'saveGlobally',
      label: 'Save Globally',
      type: 'checkbox'
    }
  ]
}

export async function delay(ms: number){
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function getUserFilesPath(userId: string) {
  return `${FirestoreCollectionTypes.USERS}/${userId}/${FirestoreCollectionTypes.FILEFLOW_COLLECTION}`
}

export function getUserFilePath(userId: string, docId: string) {
  return `${getUserFilesPath(userId)}/${docId}`
}

export function getUserFileStepPath(userId: string, docId: string, stepId: string) {
  return `${getUserFilePath(userId, docId)}/steps/${stepId}`
}

export function getUserFileStepPromptPath(userId: string, docId: string, stepId: string, promptId: string) {
  return `${getUserFileStepPath(userId, docId, stepId)}/prompts/${promptId}`
}

/**
 * 
 * @param userId 
 * @param fileName 
 * @returns a unique path in the default storage bucket. 
 * NOTE: CANNOT be used for lookups since it generates a unique name
 * Use fileFlowFile.storageName to lookup a file in storage
 */
export function getStorageFilePath(userId: string, fileName: string) {
  return `${STORAGE_FOLDER}/${userId}/${Date.now()}${fileName}/${fileName}`
}

export function getStorageFilePathForSuffix(uploadedPDFPath: string, fileName: string, toolName: string, suffix: string) {
  const lastPathSeparatorIndex = uploadedPDFPath.lastIndexOf(PATH_SEPARATOR)
  const baseDirName = uploadedPDFPath.substring(0, lastPathSeparatorIndex)
  const newBaseDirName = baseDirName + '/' + toolName

  const baseFileName = fileName.substring(0, fileName.lastIndexOf(FILE_EXT_SEPARATOR))
  const newBaseFileName = baseFileName.substring(0, uploadedPDFPath.lastIndexOf(FILE_EXT_SEPARATOR))
  // suffix example ".json" or ".xslx" - note the period at the beginning
  return newBaseDirName + '/' + newBaseFileName + suffix

}
export function getToolPath(name: string) {
  return `${FirestoreCollectionTypes.TOOLS_COLLECTION}/${name}`
}

export function getSafeName(filename: string) {
  const safeName = filename.replace(/([^a-z0-9.]+)/gi, ''); // file name stripped of spaces and special chars
  return `${Date.now()}_${safeName}`;
}

export function getBlobFromJson(analysis: any) {
  const jsonString = JSON.stringify(analysis);
  const textEncoder = new TextEncoder();
  const encodedString = textEncoder.encode(jsonString);
  return new Blob([encodedString], { type: 'application/json' });
}

export function getBlobFromBuffer(buffer: ArrayBuffer) {
  return new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
  });
}

// Convert Date object to Microsoft serial date aka ms date aka OA date
export function dateToSerial(date: Date): number {
  const timezoneOffset = date.getTimezoneOffset() / (60 * 24);
  const msDate = date.getTime() / 86400000 + (25569 - timezoneOffset);
  return msDate;
}

export async function executeAzureTool(
  tool: FlowTool,
  messages: any[],
  instructions: string,
  logger: LoggerInterface,
  isProduction = false
) {
  const server = getAzureOpenAIServer(isProduction ? 'prod' : 'dev');
  return tool.apiType === AgentType.completions ?
    await executeToolCompletions(tool, messages, instructions, server, logger) :
    await executeToolAssistant(tool, messages, instructions, server, logger)
}

 
async function executeToolCompletions(
  tool: FlowTool,
  userMessages: any[],
  instructions: string,
  server: AzureServerConfig,
  logger: LoggerInterface
) {
  const completion_options = { temperature: 0.3, top_p: 0.7 }
  if (tool.outputType === 'json')
    completion_options['response_format'] = { type: "json_object" }
  const apiVersion = "2024-06-01";
  //const client = new AzureOpenAI({ azureADTokenProvider, deployment, apiVersion });
  const client = new AzureOpenAI({
    apiKey: server?.key as string,
    timeout: DEFAULT_OPENAI_TIMEOUT,
    maxRetries: MAX_OPENAI_RETRIES,
    dangerouslyAllowBrowser: true,
    endpoint: server?.endpoint as string,
    apiVersion: apiVersion,
    deployment: server.defaultModel
  })
  const messages: any = [{
    role: 'system',
    content: instructions,
  }, ...userMessages]

  logger.log(`Using completions API`)
  logger.log('Messages:', messages)
  const backOffOptions: BackoffOptions = {
    delayFirstAttempt: false,
    jitter: 'full',
    numOfAttempts: MAX_OPENAI_RETRIES,
    retry(e: any, attemptNumber: number) {
        logger.log(`Retrying after error from completions API. Attempt Number: ${attemptNumber}`, e)
        return e instanceof RateLimitError
    },
    maxDelay: DEFAULT_OPENAI_TIMEOUT
  }
  let response: ChatCompletion
  try {
    response = await backOff( 
      async() => {
        logger.log(`Running completions API with exponential backoff`)
        return await runCompletions(client, messages, completion_options)
      },
      backOffOptions
      )
  }
  catch(e) {
    logger.error(`Error running completions`, e)
    throw e
  }

  if(response.choices[0].finish_reason !== 'stop' )
    throw new Error(`getChatCompletions finished with finishReason ${response.choices[0].finish_reason}`)
  return correctChatGPTResponseText(response.choices[0].message?.content as string)
}

async function runCompletions(client: AzureOpenAI, messages: any[], completion_options) {
  return await client.chat.completions.create(
    {
      model: 'gpt-4o',
      messages,
      seed: COMPLETIONS_SEED,
      ...completion_options
    }
  )
}
async function executeToolAssistant(
tool: FlowTool, messages: any[], instructions: string, server: AzureServerConfig, logger: LoggerInterface, files?: File[]) {
  const client = new AssistantsClient(
    server?.endpoint as string,
    new AzureKeyCredential(server?.key as string),
  );
  logger.log('Running assistant tool')
  let assistant = await client.getAssistant(tool.assistantId as string);
  if (!assistant) {
    assistant = await client.createAssistant({
      model: DEFAULT_ASSISTANT_MODEL,
      name: tool.name,
      description: tool.description,
      instructions: instructions
    });
  }
  const thread = await client.createThread({ messages: messages });
  logger.log('Created thread', thread);
  const run = await client.createRun(thread.id, {
    assistantId: assistant.id,
    instructions, // override run with latest instructions
  });
  logger.log('Created run', run);
  let runResponse;
  do {
    await new Promise((resolve) => setTimeout(resolve, 1200));
    runResponse = await client.getRun(thread.id, run.id);
    logger.log('run status', runResponse.status);
  } while (
    runResponse.status === 'queued' ||
    runResponse.status === 'in_progress'
  );
  const failedStatuses = [
    'requires_action',
    'cancelling',
    'cancelled',
    'failed',
    'expired',
  ];
  if (failedStatuses.includes(runResponse.status)) {
    console.error('Run failed', run.status, run.lastError);
  }
  const response = await client.listMessages(thread.id);
  const result = (response.data[0].content[0] as MessageTextContent).text.value
  logger.log('Assistant result', result)
  return correctChatGPTResponseText(result)
}

/**
 * 
 * @param responseText 
 * @returns remove prefixed and trailing markdown characters so that
 * the JSON returned from chatGPT can be parsed as a valid JSON string
 */
export function correctChatGPTResponseText(responseText: string) {
  return  responseText?.replaceAll("```json", "").replaceAll("```", "").trimStart()

}
export function getTableStartRow(tableList: string[][]) {
  return tableList.findIndex(row => row.find(cell => cell !== ''))
}
export function isRowBlank(row: string[]) {
  return row.find(cell => cell !== '') === undefined
}
export async function getFileContents(url: string, asJson = true) {
  return await fetch(url).then((response) => {
    try {
      return asJson ? response.json() : response.text()
    }
    catch(e) {
      console.error('JSON parse error for', response)
      throw e
    }
  })
}

export async function getBinaryFileContents(url: string) {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    return await blob.arrayBuffer()
  } 
  catch(e) {
    console.error(`Error fetching binary file at ${url}`)
    throw e
  }
}

export function isObjectEmpty(obj: any) {
  return (!obj || Object.keys(obj).length === 0) // && objectName.constructor === Object
}

export function expandPageRange(range: string) {
  const segments:string[] = range.split(',')
  const pageNums = segments.flatMap(segment => {
    const range = segment.split('-')
    if (range.length === 2) {
      const start = parseInt(range[0])
      const end = parseInt(range[1])
      return Array.from({ length: end - start + 1 }, (_, i) => i + start)
    } else {
      return parseInt(segment)
    }
  })
  return pageNums
}

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export function base64ToBlob(base64: string, mimeType: string): Blob {
  // Decode base64 string to binary string
  const binaryString = atob(base64);

  // Convert binary string to Uint8Array
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
  }

  // Create a Blob from Uint8Array
  return new Blob([bytes], { type: mimeType });
}

export function stringifyKeysInOrder(obj: any) {
  const replacer = (key:string, value:any) =>
    value instanceof Object && !(value instanceof Array) 
      ? 
        Object.keys(value)
        .sort()
        .reduce((sorted:any, key) => {
          sorted[key] = value[key]
          return sorted 
        }, {}) 
    : value
  return JSON.stringify(obj, replacer, 2)
}

export function getFetchHeaders() {
  return {
      "accept": 'application/json', 
      "content-type": 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
      "X-API-Key": DOCUPANDA_KEY
  }
}

export async function get(url: string) {
  const   method = 'GET',
          headers = getFetchHeaders(),
          options = { method, headers }
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

export async function del(url: string) {
  const   method = 'DELETE',
          headers = getFetchHeaders(),
          options = { method, headers }
  console.log(`${method} ${url}`)
  const res = await fetch(url, options)
  const json = await res.json()
  return json
}

export async function post(url: string, body: any, debug = false) {
  const   method = 'POST',
          headers = getFetchHeaders(),
          bodystr = JSON.stringify(body),
          options = { method, headers, body: bodystr }
  console.log(`${method} ${url}${debug ? ' - ' + stringifyKeysInOrder(body) : ''}`)
  const res = await fetch(url, options)
  const json = await res.json()
  if (res.status !== 200) 
    throw new Error(`${res.status} : body ${bodystr} : result ${JSON.stringify(json)}`)
  return json
}

export async function poll(url: string, interval: number = 1000) {
  let cnt = 0
  let data:any = { status: 'initializing'}
  while (data.status !== 'completed') {
    if (data.status === 'error' || cnt === 10) {
      throw new Error(`Error detected while polling ${url}: ${JSON.stringify(data)}`)
    }
    else if (!data.status) {
      // Job may not be ready, so just wait and try again (10 times max)
      cnt++
    }
    console.log(`... poll ${data.status} ${cnt} ${url}`)
    await delay(interval)
    try {
      data = await get(url)
    }
    catch(e) {
      console.warn(`... error polling ${url}`)
      cnt++
    }
  }
  return data
}

export async function getDocupandaClasses() {
  return await get(DOCUPANDA_CLASSES_ENDPOINT)
}

export async function getDocupandaSchemas() {
  return await get(DOCUPANDA_SCHEMAS_ENDPOINT)
}

export function substituteArgs(value: string, args?: any[]) {
  let val = value
  if (args) {
    args.forEach((arg, index) => {
      val = val.replace(/\$\w+/, arg)
    })
  }
  return val
}

export function getTimestamp(date: Date) {
  //const d = new Date(date)
  return Math.floor(date.getTime()/1000)
}