@if (userService.user$ | async; as user) {
    @if (selectedPartner(); as partner) {
        <lib-breadcrumb-strip [title]="partner.name" [message]="partner.docId" (action)="selectedPartner.set(null)"/>
        <lib-partner-view [partner]="partner"/>
    }
    @else if (partners$ | async) {
        @let partners = partners$ | async;
        <div class="flex justify-end p-4">
            <button class="mx-4 bg-fidocs-green hover:bg-slate-400 text-sm text-white font-bold py-2 px-4 rounded" (click)="createPartner(user)">
                Create Partner
            </button>
        </div>
        @if (partners!.length > 0) {
            <div class="p-4 text-sm w-full my-4">
                <table class="w-full">
                    <thead>
                        <tr class="px-2 text-center">
                            <th>Partner</th>
                            <th>Email</th>
                            <th class="w-44">Created</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                    @for (partner of partners; track partner.docId) {
                        <tr class="even:bg-slate-100 odd:white h-12 text-center">
                            <td>
                                <a (click)="selectedPartner.set(partner)" class="text-blue-600 underline cursor-pointer">{{ partner.name }}</a>
                            </td>
                            <td>{{ partner.docId }}</td>
                            <td>{{ partner.createdAt | date: 'yyyy-MM-dd hh:mma' }}</td>
                            <td class="w-28">
                                <button mat-icon-button (click)="deletePartner(partner)">
                                    <span class="material-symbols-outlined">
                                        delete
                                    </span>
                                </button>
                        </tr>
                    }
                    </tbody>
                </table>
            </div>
        }
    }
}

<ion-modal #provisionPartnerModal
  [isOpen]="provisionPartner$ | async" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="provisionPartner$.next(false)"
  style="--height: 50vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 50vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="provisionPartnerModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">Create Partner</h1>
        <cheaseed-json-form
          [formData]="provisionPartnerFormData" 
          (formRejectSubmit)="utilityService.onFormRejectSubmit($event)"
          (formSubmit)="userService.confirmPartnerCreation($event); provisionPartnerModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>