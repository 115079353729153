import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, inject, computed, signal, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowFile, FlowPipeline, FlowStep, FlowTool } from '@fidoc/shared';
import { FileStepsComponent } from '../file-steps/file-steps.component';
import { DateFnsModule } from 'ngx-date-fns';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileflowService } from '../fileflow/fileflow.service';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { Observable, of } from 'rxjs';
import { ToolsService } from '../fileflow/tools.service';
import { DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionSheetController, IonModal, IonContent } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { addOutline, closeCircleOutline, shareOutline, trashOutline } from 'ionicons/icons';
import { MatButtonModule } from '@angular/material/button';
import { FolderMenuComponent } from "../folder-menu/folder-menu.component";
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FileSchemaViewerComponent } from "../file-schema-viewer/file-schema-viewer.component";

export interface FlowButton {
  type: string;
  label: string;
  disabled?: boolean;
  tool?: FlowTool;
  pipeline?: FlowPipeline;
}

export interface FlowFileState {
  file?: FlowFile;
  steps?: FlowStep[];
}

@Component({
  selector: 'lib-file-summary',
  standalone: true,
  imports: [
    IonContent,
    IonModal,
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    FileStepsComponent,
    DateFnsModule,
    FolderMenuComponent,
    NgxExtendedPdfViewerModule,
    FileSchemaViewerComponent
],
  templateUrl: './file-summary.component.html',
  styleUrl: './file-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSummaryComponent implements OnInit, OnDestroy {

  firebase = inject(FirebaseService);
  fileFlowService = inject(FileflowService)
  utilityService = inject(PortalUtilityService)
  toolsService = inject(ToolsService)
  userService = inject(UserService)
  defaultsService = inject(DefaultsService)
  actionSheetController = inject(ActionSheetController)

  @Input() file!: FlowFile;
  @Input() readOnly = false;
  @Input() adminView = false;

  openExecuteSelector = signal(false)
  openFeedbackModal = signal(false)
  openFileViewerModal = signal<any>(false)
  
  steps$: Observable<FlowStep[]> = of([] as FlowStep[]);

  opened = computed(() => {
    const filesOpened = this.fileFlowService.openedFiles()
    const result = filesOpened.has(this.file.docId)
    // console.log('checking if file is opened', this.file.docId, result)
    return result
  })

  constructor(
    @Inject('environment') private environment: any
  ) { }

  ngOnInit(): void {
    addIcons({ addOutline, trashOutline, shareOutline, closeCircleOutline });
    this.steps$ = this.fileFlowService.getFileSteps(this.file as FlowFile)
    if (this.readOnly)
      this.fileFlowService.markFileOpened(this.file)
  }

  ngOnDestroy(): void {
    // console.log('destroying file summary component', this.file?.docId)
  }

  pipelineMenuOptions() {
    return this.toolsService.getPipelines()
      .map(pipeline => {
        return {
          type: 'pipeline',
          label: pipeline.description,
          pipeline
        } as FlowButton
    })
  }

  toolMenuOptions(steps: FlowStep[]) {
    const tools = this.toolsService.getTools()
    const available = tools.filter(tool => 
      !steps.find(step => step.name === tool.name) // tool hasn't been executed yet
      && (!tool.precedents || tool.precedents.some(precedent => !!steps.find(step => step.name === precedent.name)))
      && (tool.type !== 'ocr' || !steps.find(step => step.type === 'ocr')) // old steps don't have type!
    )
    return tools.map(tool => {
      return {
        type: 'tool',
        disabled: !available.includes(tool),
        label: tool.description,
        tool
      } as FlowButton
    })
  }

  async selectAction(button: FlowButton, file: FlowFile, steps: FlowStep[] | null) {
    this.fileFlowService.markFileOpened(file)
    if (button.type === 'pipeline') {
      if (steps && steps.length > 0) {
        await this.utilityService.confirm({
          header: 'Confirm',
          message: 'This file has already been processed. Are you sure you want to execute this pipeline and replace the previous steps?',
          confirm: () => {
            this.toolsService.executePipeline(button.pipeline as FlowPipeline, file)
          }
        })
      }
      else 
        this.toolsService.executePipeline(button.pipeline as FlowPipeline, file)
    }
    else
      this.fileFlowService.checkExecuteStep(button.tool as FlowTool, file)
  }

  generateShareUrl(file: FlowFile) {
    const sharedId = window.btoa(JSON.stringify({ user: file.userDocId, docId: file.docId }))
    return `${window.location.origin}/share/${sharedId}`
  }

  async copyShareLink(file: FlowFile) {
    const url = this.generateShareUrl(file)
    navigator.clipboard.writeText(url)
    await this.utilityService.presentToast('Link copied to clipboard')
  }

  async submitFeedback(file: FlowFile, text: string) {
    if (text.trim().length > 0) {
      const url = this.generateShareUrl(file)
      const body = `${file.userDocId} provided the following feedback on ${file.fileName}:\n\n${text}\n\nShare link: ${url}`
      await this.firebase.awaitCloudFunction("sendEmailAttachment", {
        to: this.environment.adminEmail,
        subject: `received fidocs feedback from ${file.userDocId}`,
        text: body
      })
      const template = this.defaultsService.getDefault('user.feedback.email.body', [ file.userDocId, this.environment.adminEmail ])
      const subject = this.defaultsService.getDefault('user.feedback.email.subject', [ file.fileName ])
      await this.firebase.awaitCloudFunction("sendEmailAttachment", {
        to: file.userDocId,
        subject,
        text: template
      })
    }
  }

  async setRating(file: FlowFile, rating: number) {
    // if rating is same as existing value, then set to 0
    if (file.rating === rating)
      rating = 0
    if (!this.adminView)
      await this.fileFlowService.updateFile(file, { rating })
    else
      await this.unavailable()
  }

  executeSelect() {
    if (!this.adminView)
      this.openExecuteSelector.set(true)
    else
      this.unavailable()
  }

  async unavailable() {
    await this.utilityService.presentToast('Not available in Admin View')
  }

  async viewFileInBrowser(file: FlowFile) {
    const url = await this.fileFlowService.getFileContentsAsLocalURL(file.storageName)
    window.open(url, '_blank');
  }

  async downloadSourceFile(file: FlowFile) {
    const url = await this.fileFlowService.getFileContentsAsLocalURL(file.storageName)
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.download = file.fileName;
    link.click();
    setTimeout(() => URL.revokeObjectURL(url), 60) // For Firefox it is necessary to delay revoking the ObjectURL
  }

  async downloadOutputFile(file: FlowFile) {
    if (file.outputStorageName) {
      const url = await this.fileFlowService.getFileContentsAsLocalURL(file.outputStorageName)
      const link = document.createElement('a');
      link.href = url;
      link.download = file.outputStorageName!.split('/').pop()!
      link.click();
      setTimeout(() => URL.revokeObjectURL(url), 60) // For Firefox it is necessary to delay revoking the ObjectURL
    }
    else {
      const url = file.outputURL as string
      window.open(decodeURI(url), '_blank')
    }
  }

  async launchPreview(file: FlowFile) {
    const url = await this.fileFlowService.getFileContentsAsLocalURL(file.storageName)
    // const sanitized = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    // console.log({ url, sanitized })
    this.openFileViewerModal.set(url)
  }

  popoutFileViewer() {
    window.open(`${this.environment.portalHost}/review/${this.file.docId}`, '_blank')
  }
}