import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonSearchbar } from '@ionic/angular/standalone';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { GroupService } from '@fidoc/groups';
import { DefaultsService, FirebaseService, InspectorService, UserService } from '@fidoc/util';
import { BehaviorSubject, debounceTime, combineLatestWith, map, shareReplay, firstValueFrom } from 'rxjs';
import { UtilityService } from '@fidoc/util';
import { AUTH_FUNCTIONS, FIREBASE_AUTH_CLOUD_FUNCTION, PipelineEnvironment, UserRecord } from '@fidoc/shared';
import Stripe from 'stripe'
import { FileflowService } from '@fidoc/fileflow';

@Component({
    selector: 'lib-user-table',
    standalone: true,
    imports: [
    IonSearchbar,
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatTabsModule,
    MatProgressSpinnerModule
],
    templateUrl: './user-table.component.html',
    styleUrl: './user-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserTableComponent {
    flowService = inject(FileflowService)
    inspectorService = inject(InspectorService)
    firebase = inject(FirebaseService)
    userService = inject(UserService)
    defaultsService = inject(DefaultsService)
    utilityService = inject(UtilityService)
    groupService = inject(GroupService)

    searchFilter$ = new BehaviorSubject<string | null | undefined>('')
    userSearchFilter$ = new BehaviorSubject<string | null | undefined>('')

    recentUsers$ = this.userService.recentUsers$
        .pipe(
            debounceTime(300),
            combineLatestWith(this.userSearchFilter$),
            map(([users, searchTerm]) => users.filter(user => (!searchTerm || ('' + user.name + user.docId).toLowerCase().indexOf(searchTerm.toLowerCase()) > -1))),
            shareReplay(1),
        )

    groupsMap$ = this.groupService.ownedGroups$
        .pipe(
            map(groups => new Map(groups.map(g => [g.docId as string, g])))
        )

    async confirmZipUser(user: UserRecord) {
        await this.utilityService.confirm({
            header: `Zip ${user.docId}`,
            message: `Are you sure you want to zip files for ${user.docId}?`,
            confirm: () => this.zipUser(user)
        })
    }

    async zipUser(user: UserRecord) {
        const files = await firstValueFrom(this.flowService.getUserFiles(user.docId))
        const fileNames: string[] = []
        files.forEach(f => {
            if (f.storageName)
                fileNames.push(f.storageName)
            if (f.outputStorageName)
                fileNames.push(f.outputStorageName as string)
        })
        const zipfileName = `files-${user.docId}.zip`
        await this.flowService.zipFiles(zipfileName, fileNames, user.docId)
    }

    toggleExecutionEnv(user: UserRecord) {
        let pipelineEnv = user.pipelineEnv
        switch (pipelineEnv) {
            case undefined:
            case PipelineEnvironment.PIPELINE_EXEC_ENV_LOCAL:
                pipelineEnv = PipelineEnvironment.PIPELINE_EXEC_ENV_PUBSUB
                break
            case PipelineEnvironment.PIPELINE_EXEC_ENV_PUBSUB:
                pipelineEnv = PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD
                break
            case PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD:
                pipelineEnv = PipelineEnvironment.PIPELINE_EXEC_ENV_LOCAL
                break

            default:
                throw new Error(`Undefined pipeline env: ${pipelineEnv}`)
        }

        this.userService.updateUser(user, { pipelineEnv })
    }

    toggleAdmin(user: UserRecord) {
        this.userService.updateUser(user, { isAdmin: !user.isAdmin, currentRole: user.isAdmin ? 'user' : 'admin' })
    }

    async confirmObliterate(user: UserRecord) {
        await this.utilityService.confirm({
            header: "Confirm Obliterate",
            message: `Are you absolutely sure you want to obliterate <b>${user.docId}</b>? This is not recoverable.`,
            confirm: () => this.obliterateUser(user)
        })
    }

    async obliterateUser(user: UserRecord) {
        const loading = await this.utilityService.loading(`Obliterating <b>${user.docId}</b>`)
        try {
            await this.firebase.awaitCloudFunction(
                FIREBASE_AUTH_CLOUD_FUNCTION, {
                function: AUTH_FUNCTIONS.OBLITERATE_USER,
                email: user.docId,
                stripeCustomerId: user.subscriptionInfo?.customerId
            })
        }
        catch (e: any) {
            console.error('obliterate user', e)
            await this.utilityService.notify({ header: 'Obliterate User Failed', message: e.message })
        }
        finally {
            loading.dismiss()
        }
    }


    inTrialPeriod(user: UserRecord) {
        return user.subscriptionInfo && user.subscriptionInfo.status === 'trialing' //Stripe.Subscription.Status
    }

    hasSubscription(user: UserRecord) {
        if (!user.subscriptionInfo)
            return false
        const status = user.subscriptionInfo.status as Stripe.Subscription.Status
        return status === 'active' || status === 'trialing'
    }

    async endTrialPeriod(user: UserRecord) {
        if (!user.subscriptionInfo)
            return
        return await this.userService.endTrialPeriod(user)
    }

    async cancelSubscription(user: UserRecord) {
        if (!user.subscriptionInfo)
            return
        return await this.userService.cancelSubscription(user)
    }

    async billOverage(user: UserRecord) {
        const now = new Date()
        //user.lastOverageInvoiceStartDate = new Date(user.lastOverageInvoiceStartDate?.toString() as string)
        //user.lastOverageInvoiceEndDate = new Date(user.lastOverageInvoiceEndDate?.toString() as string)

        //const inv = await billOverage(this.environment.production ? "prod": "dev", user, console, user.lastOverageInvoiceEndDate, now)
        const start = (user.lastOverageInvoiceEndDate || user.subscriptionInfo?.subscriptionStartDate) as Date
        console.log('start', start)
        console.log('user', user)
        const res = await this.firebase.awaitCloudFunction('billOverage', {
            userId: user.docId,
            overage: user.pageBalance,
            start: start,
            end: now
        })
        const inv = res.data as Stripe.Invoice
        //page balance is not set to 0  in the "real" billing in the cloud function
        await this.userService.updateUser(user, { pageBalance: 0, lastOverageInvoiceStartDate: start, lastOverageInvoiceEndDate: now, lastOverageInvoiceId: inv.id })
    }

    editEvalEndDate(user: UserRecord) {
        this.utilityService.prompt({
            header: 'Edit Evaluation End Date',
            inputType: 'text',
            placeholder: '100',
            inputValue: user.enterpriseTrial?.evalEndDate,
            confirm: (data) => {
                const val = !isNaN(Date.parse(data.value)) ? new Date(data.value) : null
                if (val && user.enterpriseTrial) {
                    const curr: any = user.enterpriseTrial
                    curr.evalEndDate = data.value
                    this.userService.updateUser(user, { enterpriseTrial: curr })
                }
                else
                    this.utilityService.notify({ header: 'Invalid Date', message: 'Please enter a valid date' })
            }
        })
    }

    addPageBalance(user: UserRecord) {
        this.utilityService.prompt({
            header: 'Add Page Balance',
            inputType: 'text',
            placeholder: '100',
            confirm: this.confirmPageBalance.bind(this, user)
        })
    }
    async confirmPageBalance(user: UserRecord, data: { value: string; }) {
        //console.log('User', user)
        console.log('adding pages to', user.email, data)
        const pages = Number.parseInt(data.value)
        if (Number.isNaN(pages) || pages <= 0 || pages > 1000) {
            await this.utilityService.presentToast('Please enter a number between 1 and 1000')
            return false
        }
        // no await since we want the dialog to be dismissed quickly
        this.userService.updatePageBalance(user, pages)
        return true
    }
}
