import { Injectable, inject } from '@angular/core';
import { debounceTime, firstValueFrom, map, switchMap } from 'rxjs';
import {
  CLOUD_FUNCTION_TIMEOUT,
  FlowFile,
  FlowPipeline,
  FlowStep,
  FlowTool,
  getFlowToolsRegistry,
  PIPELINE_ENV_KEY,
  PIPELINE_EXEC_ENV_CLOUD,
  PipelineContext,
  PIPELINES_COLLECTION,
  TOOLS_COLLECTION,
  UserRecord,
} from '@fidoc/shared';
import { FileflowService } from './fileflow.service';
import { DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import { 
  delay,
  getToolPath
} from '@fidoc/shared';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root',
})
export class ToolsService {

  flowService = inject(FileflowService)
  userService = inject(UserService)
  firebase = inject(FirebaseService)
  defaults = inject(DefaultsService)

  private FlowToolsRegistry: FlowTool[] = getFlowToolsRegistry(this.flowService)

  private FlowPipelinesRegistry: FlowPipeline[] = []
  
  tools$ = this.firebase.collection$(`${TOOLS_COLLECTION}`)
    .pipe(
      debounceTime(500),
      map(docs => docs as FlowTool[]))

  pipelines$ = this.userService.userDocId$
    .pipe(
      switchMap(() => this.firebase.collection$(PIPELINES_COLLECTION)),
      debounceTime(500),
      map(docs => docs as FlowPipeline[]))

  constructor() {
    this.tools$
      .pipe(takeUntilDestroyed())
      .subscribe(tools => {
        for (const t of tools) {
          const tool = this.getTool(t.name)
          if (tool) {
            tool.instructions = t.instructions
            tool.updatedAt = t.updatedAt
          }
        }
        // console.log('tools updated', tools)
      })
    this.pipelines$
      .pipe(takeUntilDestroyed())
      .subscribe((pipelines:any) => {
        const pipes = pipelines.map(p => {
          return {
            name: p.name,
            description: p.description,
            tools: p.tools.map((t:string) => this.getTool(t)),
            isDefault: !!p.isDefault
          }
        })
        this.FlowPipelinesRegistry = pipes as FlowPipeline[]
        // console.log('pipelines updated', this.FlowPipelinesRegistry.length)
      })
  }

  async writePipelines() {
    for (const pipeline of this.FlowPipelinesRegistry) {
      await this.firebase.updateAt(`${PIPELINES_COLLECTION}/${pipeline.name}`, {
        name: pipeline.name,
        description: pipeline.description,
        tools: pipeline.tools.map(t => { return t.name }),
        updatedAt: new Date()
      })
    }
    for (const tool of this.FlowToolsRegistry) {
      await this.firebase.updateAt(`${TOOLS_COLLECTION}/${tool.name}`, {
        name: tool.name,
        description: tool.description,
        type: tool.type,
        updatedAt: new Date()
      })
    }
  }

  getTools() {
    return this.FlowToolsRegistry
  }

  getPipelines() {
    return this.FlowPipelinesRegistry
  }

  getPipeline(name: string) {
    return this.getPipelines().find(p => p.name === name)
  }

  getDefaultPipeline() {
    return this.getPipelines().find(p => p.isDefault)
  }

  getTool(name: string) {
    return this.getTools().find(t => t.name === name)
  }

  isExecEnvCloud() {

  }
  async executePipeline(pipeline: FlowPipeline, file: FlowFile) {
    const user = this.userService.user() as UserRecord;
    const exec = user.pipelineEnv
      || this.defaults.getDefault(PIPELINE_ENV_KEY) 
      || PIPELINE_EXEC_ENV_CLOUD;

    if (exec === PIPELINE_EXEC_ENV_CLOUD) {
      this.flowService.markFileOpened(file)
      // no await here to queue up execution in parallel
      this.firebase.awaitCloudFunction('executePipeline', {
        userId: user.docId,
        pipeline: pipeline.name,
        file: JSON.stringify(file)
      },
      CLOUD_FUNCTION_TIMEOUT)
    }
    else
      return await this.executeLocalPipeline(pipeline, file)
  }

  async executeLocalPipeline(pipeline: FlowPipeline, file: FlowFile) {
    let lastStep: FlowStep | null = null
    for (const tool of (pipeline?.tools || [])) {
      const context: PipelineContext = {
        pipeline: pipeline.name,
        userId: (this.userService.user() as UserRecord).docId
      }
      if (tool) {
        try {
          context.tool = tool.name
          await this.flowService.executeStep(context, tool, file)
        }
        catch (e:any) {
          // exit the pipeline if any tool fails
          return
        }
        // To handle delay in finalizing upload (10 secs)
        let cnt = 0
        do {          
          lastStep = await firstValueFrom(this.flowService.getCompletedStepForTool(file, tool.name)) as FlowStep
          if (!lastStep) 
            await delay(1000)
          cnt++
        } while (!lastStep && cnt <= 10)
        if (cnt > 10) 
          console.error(`tool ${tool.name} not completed`)
      }
      else {
        console.error(`tool not found`)
      }
    }
  }
  getToolData(name: string) {
    return firstValueFrom(
      this.firebase.doc$(getToolPath(name))
        .pipe(map(doc => doc as FlowTool))
    )
  }

}
