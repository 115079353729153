import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { stringifyKeysInOrder } from '@fidoc/shared';
import { marked } from 'marked';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})
export class InspectorService {

    sanitizer = inject(DomSanitizer)
    showInspector$ = new BehaviorSubject<{ title: string, message: string } | undefined>(undefined)

    inspect(title: string, obj: any) {
        this.showInspector$.next({
            title,
            message: (typeof obj === 'string') 
                ? obj
                : `<span style="white-space: pre-wrap">${stringifyKeysInOrder(obj)}</span>`
        })
    }

    renderMarkdown(text: string) {
        // this.log('rendering markdown', text)
        const block = (marked(text) as string).trim()
        return block ? this.sanitizer.bypassSecurityTrustHtml(block) : null
    }
}
