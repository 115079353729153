//import { DocumentData, FirestoreDataConverter } from '@google-cloud/firestore';
import { PipelineEnvironment, UserRecord } from './user.models';
import { DocuPandaTool } from './tools/DocuPandaTool';
import { DocIntelGenerateXlsTool } from './tools/DocIntelGenerateXlsTool';
import { DocIntelMarkdownTransformerTool } from './tools/DocIntelMarkdownTransformerTool';
import { DocIntelTool } from './tools/DocIntelTool';
import { DocuPandaAsImageTool } from './tools/DocuPandaAsImageTool';
import { DocuPandaGenerateXlsTool } from './tools/DocuPandaGenerateXlsTool';
import { DocuPandaJsonIndenterTool } from './tools/DocuPandaJsonIndenterTool';
import { DocuPandaRemoveWatermarkTool } from './tools/DocuPandaRemoveWatermarkTool';
import { DocuPandaTextTableToMarkdownTool } from './tools/DocuPandaTextTableToMarkdownTool';
import { FileflowServiceInterface, FlowFile, FlowStep, FlowTool, FlowPrompt, FlowSchemaType } from './fileflow.interface';
import { DocuPandaStandardizationTool } from './tools/DocuPandaStandardizationTool';
import { DocuPandaStandardizationXlsTool } from './tools/DocuPandaStandardizationXlsTool';

export const DEFAULT_ASSISTANT_MODEL = 'gpt-4o';
export const DOCUPANDA_KEY = 'v4dsiydswLT8eJg0BB7GaSJoSE83';
export const DOCUPANDA_DOCUMENT_ENDPOINT = 'https://app.docupanda.io/document';
export const DOCUPANDA_CLASSES_ENDPOINT = 'https://app.docupanda.io/classes';
export const DOCUPANDA_SCHEMAS_ENDPOINT = 'https://app.docupanda.io/schemas';
export const DOCUPANDA_CREDIT_COST = 0.0396;
export const DOCUPANDA_CREDIT_OVERAGE_COST = 0.08;
export const USERS_COLLECTION = 'users';
export const DOMAINS_COLLECTION = 'domains';
export const TOOLS_COLLECTION = 'tools';
export const PIPELINES_COLLECTION = 'pipelines';
export const FILEFLOW_COLLECTION = 'flowfiles';
export const COSTS_COLLECTION = 'costs';
export const INITIAL_PAGE_BALANCE = 500
export const PIPELINE_EXEC_ENV_CLOUD = 'cloud'
export const PIPELINE_EXEC_ENV_LOCAL = 'local'

export const PIPELINE_ENV_KEY = 'pipelines.executionEnvironment'
export const FIDOCS_PIPELINE_TOPIC = 'fidocs-pipeline'
export const NO_PIPELINE = '<None>'
export const NO_TOOL = '<None>'

export const CLOUD_FUNCTION_TIMEOUT = 540 * 1000

export function isExecEnvCloud(user: UserRecord, systemSetting: PipelineEnvironment) {
  if(user.pipelineEnv) {
    return user.pipelineEnv === PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD
  }
  return systemSetting === PipelineEnvironment.PIPELINE_EXEC_ENV_CLOUD
}

export const flowStepConverter = {
  toFirestore(f: FlowStep) {
    return f;
  },
  fromFirestore(snapshot: any): FlowStep {
    const obj: any = snapshot.data();
    return {
      ...obj,
      lastUpdatedAt: obj.lastUpdatedAt?.toDate(),
    } as FlowStep;
  },
};


//let g_toolsRegistry: FlowTool[]
export function getFlowToolsRegistry(flowService: FileflowServiceInterface) {
  //if(!g_toolsRegistry)
  //g_toolsRegistry = 
  return [
    new DocuPandaTool(flowService),                      // 0
    new DocuPandaRemoveWatermarkTool(flowService),       // 1
    new DocuPandaAsImageTool(flowService),               // 2
    new DocIntelTool(flowService),                       // 3
    new DocuPandaJsonIndenterTool(flowService),          // 4
    new DocuPandaGenerateXlsTool(flowService),           // 5
    new DocuPandaTextTableToMarkdownTool(flowService),   // 6
    new DocIntelMarkdownTransformerTool(flowService),    // 7
    new DocIntelGenerateXlsTool(flowService),            // 8
    new DocuPandaStandardizationTool(flowService),       // 9
    new DocuPandaStandardizationXlsTool(flowService),    // 10
    /*inject(DocIntelJSONTool),
    inject(DocIntelJSONTransformerTool),
    inject(DocIntelJSONGenerateXlsTool)
    */
  ]
  //return g_toolsRegistry
}
export const flowFileConverter = {
  toFirestore(f: FlowFile) {
    return f;
  },
  fromFirestore(snapshot: any): FlowFile {
    const obj: any = snapshot.data();
    return {
      ...obj,
      createdAt: obj.createdAt?.toDate(),
    } as FlowFile;
  },
};

export const flowStepPromptConverter = {
  toFirestore(f: FlowPrompt) {
    return f
  },
  fromFirestore(snapshot: any): FlowPrompt {
    const obj: any = snapshot.data()
    return {
      ...obj,
      createdAt: obj.createdAt?.toDate(),
    } as FlowPrompt
  }
};

export const flowSchemaTypeConverter = {
  toFirestore(f: FlowSchemaType) {
    return f
  },
  fromFirestore(snapshot: any): FlowSchemaType {
    const obj: any = snapshot.data()
    return {
      ...obj,
      updatedAt: obj.updatedAt?.toDate(),
    } as FlowSchemaType
  }
};
