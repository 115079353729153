import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPopover,
  IonRange,
  IonTextarea,
  IonToggle,
} from '@ionic/angular/standalone';
import { JsonFormControl, JsonFormData } from '@cheaseed/node-utils';
import { addIcons } from 'ionicons';
import { copyOutline, informationCircleOutline, lockClosed, sunny, sunnyOutline } from 'ionicons/icons';
import { BehaviorSubject, withLatestFrom } from 'rxjs';

@Component({
  selector: 'cheaseed-json-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonItem,
    IonLabel,
    IonInput,
    IonTextarea,
    IonCheckbox,
    IonToggle,
    IonRange,
    IonIcon,
    IonButton,
    IonDatetime,
    IonDatetimeButton,
    IonModal,
    IonPopover,
    IonContent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss'],
})
export class JsonFormComponent implements OnInit {

  // See https://accesto.com/blog/angular-dynamic-forms-using-json/
  // See https://www.youtube.com/watch?v=ByHw_RMjkKM

  private fb = inject(FormBuilder)
  
  @Input() formData!: JsonFormData
  @Output() formChanged = new EventEmitter()
  @Output() formSubmit = new EventEmitter()
  @Output() copyControl = new EventEmitter()
  
  infoText$ = new BehaviorSubject<string | undefined>(undefined)
  
  formDirty$ = new BehaviorSubject<boolean>(false)

  public myForm: FormGroup = this.fb.group({})

  constructor() {
    addIcons({ sunny, sunnyOutline, lockClosed, copyOutline, informationCircleOutline });
    this.myForm.valueChanges
      .pipe(withLatestFrom(this.formDirty$))
      .subscribe(([ , formDirty ]) => {
        if (!formDirty && this.myForm.dirty) {
          this.formDirty$.next(true)
          this.formChanged.emit(true)
        }
    })
  }

  ngOnInit() {
    // console.log('ngOnInit formData', this.formData)
    this.createForm(this.formData.controls)
  }

  createForm(controls: JsonFormControl[]) {
    for (const control of controls) {
      const validatorsToAdd = [];
      for (const [key, value] of Object.entries(control.validators || {})) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value)
              validatorsToAdd.push(Validators.required);
            break;
          case 'requiredTrue':
            if (value)
              validatorsToAdd.push(Validators.requiredTrue);
            break;
          case 'email':
            if (value)
              validatorsToAdd.push(Validators.email);
            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value)
              validatorsToAdd.push(Validators.nullValidator);
            break;
          default:
            break;
        }
      }
      this.myForm.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd),
      );
    }
  }

  copyToClipboard(control: JsonFormControl) {
    navigator.clipboard.writeText(control.value as string)
    this.copyControl.emit(control)
  }
  
  onSubmit() {
    // console.log('Form valid: ', this.myForm.valid);
    // console.log('Form values: ', this.myForm.value);
    if ((this.myForm.dirty || !!this.formData.submitAlwaysEnabled) && this.myForm.valid) {
      this.myForm.markAsPristine()
      this.formDirty$.next(false)
      this.formChanged.emit(false)
      this.formSubmit.emit(this.myForm.value)
    }
  }

}
