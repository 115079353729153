import { Environment } from "@fidoc/util";

export const environment:Environment = {
  production: false,
  appSource: 'fidoc',
  portalHost: 'https://app-dev.fidocs.ai',
  webHost: 'https://www-dev.fidocs.ai',
  // portalHost: 'http://localhost:4204',
  admin: false, // not an admin app
  adminHost: "https://app-dev.fidocs.ai/admin",
  adminEmail: 'support@fidocs.ai',
  devPwd: 'ArkFid!Test2025',
  useEmulators: false,
  useCompletions: true,
  firebase: {
    apiKey: "AIzaSyDzI1p25OW3aGDhUXTHcgtp5M-FkQNeEI8",
    authDomain: "fidocs-dev.firebaseapp.com",
    databaseURL: 'https://fidocs-dev.firebaseio.com',
    projectId: "fidocs-dev",
    storageBucket: "fidocs-dev.appspot.com",
    messagingSenderId: "350105042208",
    appId: "1:350105042208:web:c36926e0a5f9c4f7b3eb45"
  },
  globalErrors: {
    slackURL: 'https://hooks.slack.com/services/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',
    channelName: '#dev-runtime-errors'
  },
  stripe: {
    customerPortalLink: 'https://billing.stripe.com/p/login/test_3csg2mc487UH7YY000'
  }
}