import { ChangeDetectionStrategy, Component, inject, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DateFnsModule } from 'ngx-date-fns';
import { FlowFile, FlowStep } from '@fidoc/shared';
import { differenceInDays, format } from 'date-fns';
import { FirebaseService, UserService } from '@fidoc/util';
import { FileflowService } from '../fileflow/fileflow.service';
import { ToolsService } from '../fileflow/tools.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { firstValueFrom } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'lib-file-steps',
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    DateFnsModule,],
  templateUrl: './file-steps.component.html',
  styleUrl: './file-steps.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileStepsComponent {

  firebase = inject(FirebaseService);
  fileFlowService = inject(FileflowService)
  toolsService = inject(ToolsService)
  userService = inject(UserService)

  file = input.required<FlowFile>();
  steps = input.required<FlowStep[]>();
  readOnly = input<boolean>(false);
  loading = signal(false)
  
  withinLastNDays(date: Date, n: number) {
    return differenceInDays(new Date(), date) <= n;
  }

  async viewOutput(step: FlowStep) {
    const url = await this.fileFlowService.getFileContentsAsLocalURL(step.storageName)
    window.open(url, '_blank');
  }

  async viewTranscript(step: FlowStep) {
    this.loading.set(true)
    const prompts = await firstValueFrom(this.fileFlowService.getFileStepPrompts(this.file(), step.name))
    this.loading.set(false)
    const result = []
    for (const prompt of prompts) {
      const promptDate = format(prompt.createdAt, 'yyyy-MM-dd HH:mm:ss')
      const elem = { promptDate, prompt: prompt.prompt, response: prompt.response }
      // console.log(JSON.stringify(elem, null, 2))
      result.push(elem)
    }
    this.fileFlowService.inspect(`${step.name} transcript`, result)
  }

  rerun(step: FlowStep) {
    const tool = this.toolsService.getTool(step.name)
    if (tool)
      this.fileFlowService.checkExecuteStep(tool, this.file())
  }

  delete(step: FlowStep) {
    this.fileFlowService.deleteStep(this.file(), step)
  }

  inspect(step: FlowStep) {
    // console.log('inspect', JSON.stringify(step, null, 2))
    this.fileFlowService.inspect(`${step.name} inspector`, step)
  }

  showParameters(step: FlowStep) {
    if (step.parameters) {
      this.fileFlowService.inspect(`${step.name} parameters`, step.parameters)
    }    
  }
}
