import { Inject, Injectable, computed, inject, signal } from '@angular/core';
import { FirebaseService } from './firebase.service';
import Stripe from 'stripe';
import { COUPON_CODE, getStripeSecretKey } from '@fidoc/shared';
import { DefaultsService } from './defaults.service';

@Injectable({
  providedIn: 'root'
})
export class StripeService {

  firebase = inject(FirebaseService)
  defaultsService = inject(DefaultsService)
  stripe: Stripe
  stripeState = signal<any>(null)
  productsForDisplay = computed(() => this.stripeState()?.productsForDisplay)
  subscriptions = computed(() => this.stripeState()?.subscriptions)
  globalDiscount = computed(() => {
    const defaults = this.defaultsService.defaults()
    const globalDiscount = defaults.get('globalDiscount')
    return globalDiscount?.value || 0.0
  })

  constructor(@Inject('environment') private environment: any) {

    // TODO: Consider caching stripe state in Firestore to minimize calls on Stripe

    this.stripe = new Stripe(getStripeSecretKey(this.environment.production ? "prod" : "dev"))
    Promise.all([ 
      this.stripe.products.list( { expand: [ 'data.default_price' ], active: true }), 
      this.stripe.prices.list( { expand: [ 'data.tiers' ] }),
      this.stripe.paymentLinks.list( { expand: ['data.line_items'], active: true }) 
    ])
      .then(([ products, prices, links ]) => {
        const productsForDisplay = products.data.map(prod => {          
          const link = links.data.find(l => {
            return l.line_items?.data.find(li => li.price?.product === prod.id)
          })
          const tiers = prices.data.find(price => price.product === prod.id && price.billing_scheme === 'per_unit')?.tiers
          const price = prod.default_price as Stripe.Price
          return {
            id: prod.id,
            name: prod.name,
            description: prod.description,
            marketing_features: prod.marketing_features,
            amount: price.unit_amount,
            currency: price.currency,
            interval: price.recurring?.interval,
            isTest: prod.metadata['test'] === 'true',
            isFree: prod.metadata['free'] === 'true',
            isContact: prod.metadata['contact'] === 'true',            
            tiers,
            paymentLink: link?.url
          }
        }).sort((a, b) => (a.amount || 0) - (b.amount || 0))
        
        const data = { products, prices, links, productsForDisplay }
        this.stripeState.set(data)
        // console.debug('stripe state', data)
      })
  }

  getProduct(id: string) {
    return this.productsForDisplay().find((p:any) => p.id === id)
  }

  getPaymentLinkUrl(userDocId: string, url: string) {
    const params:any = {
      prefilled_email: userDocId,
      prefilled_promo_code: this.globalDiscount() > 0.0 ? COUPON_CODE : ''
    }
    const qparams = Object.entries(params).map(([k, v]) => `${k}=${v}`).join('&')
    return `${url}?${qparams}`
  }

  async getSubscription(sub: string) {
    const subscription = await this.stripe.subscriptions.retrieve(sub)
    // console.log('subscription', subscription)
    return subscription
  }

}
 
