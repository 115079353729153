import { FileflowServiceInterface, FlowFile, FlowStep, FlowTool } from '../fileflow.interface';
import { UserRecord } from '../user.models';

export class DocuPandaClassificationTool implements FlowTool {
  name = 'classification';
  description = 'Classification and Extraction';
  type = 'classification'
  startTime = 0

  flowService: FileflowServiceInterface

  constructor(flowService?: FileflowServiceInterface) {
    if(flowService)
      this.initialize(flowService)
  }
  
  initialize(flowService: FileflowServiceInterface) {
    this.flowService = flowService
    return this
  }

  shouldPublish(): boolean {
      return false
  }
  checkExecute(file: FlowFile, user: UserRecord) {
    this.flowService.defaultCheckExecute(file, user)
  }

  getContentDisposition(fileName: string) {
    return  'inline;'
  }

  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any) {
      this.startTime = Date.now()
      this.flowService.log(`classification`, file, params)

      if (file.numPages > 1) {
        // Split into subdocuments and retrieve docids and classes
      }
      else {
        // retrieve classification of last document id
      }
      return true
  }

}
