<div class="h-[85vh]">
    <mat-tab-group 
        class="h-full"
        mat-stretch-tabs="false" 
        mat-align-tabs="start" 
        animationDuration="0ms" 
        [selectedIndex]="tabIndex$ | async" 
        (selectedTabChange)="tabChanged($event)">
        @for (schemaName of schemaNames(); track schemaName) {
            @let schema = schemas()[schemaName];
            <mat-tab [label]="schemaName">
                <ng-template matTabContent>
                    <div class="overflow-y-scroll">
                        <pre class="text-xs">{{ schema.data | json }}</pre>
                    </div>
                </ng-template>
            </mat-tab>
        }
    </mat-tab-group>
</div>
    