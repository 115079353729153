<!-- Display shared file -->
@if (sharedFile$ | async; as sharedFile) {
  <h1 class="text-2xl px-1 py-3">Shared from {{ sharedFile.userDocId }}</h1>
  <div class="flex flex-col items-center gap-1">
    <div class="flex flex-col items-center justify-between p-2 w-full">
      <lib-file-summary 
        [file]="sharedFile" 
        [readOnly]="true"
        class="w-full"/>
    </div>
  </div>
}
@else {
  <!-- Display current folder view -->
  <div class="text-sm px-5 italic text-gray-400">
    <lib-default-message key="dashboard.tagline" />
  </div> 
  <div class="center p-4">
    <cheaseed-file-drop (droppedFiles)="dropFiles($event)"/>
    <div class="flex items-center justify-between">
      @if (fileFlowService.currentFolder(); as folder) {
        <h1 class="text-2xl px-1 py-3">
          <a (click)="fileFlowService.resetCurrentFolder()" class="text-blue-500 underline cursor-pointer">My Files</a> / {{ folder.fileName }}
        </h1>
        <span class="material-symbols-outlined cursor-pointer hover:bg-slate-300 px-2 my-2" [matMenuTriggerFor]="folderMenu">
          more_horiz
        </span>
        <mat-menu #folderMenu="matMenu" xPosition="after" class="w-64">
          <ng-template matMenuContent>
            <lib-folder-menu [folder]="folder"/>
          </ng-template>
        </mat-menu>
      }
      @else {
        <div class="flex items-center">
          <h1 class="text-2xl px-1 py-3">My Files</h1>
          <span class="material-symbols-outlined cursor-pointer hover:bg-slate-300 px-2 my-2" (click)="addFolder()">
            create_new_folder
          </span>
        </div>
      }      
    </div>
    <div>
      @if (fileFlowService.currentFolderFiles(); as files) {
        @for (item of files; track item.docId;
          let index = $index, first = $first;
          let count = $count
        ) {
          <div class="p-2 w-full even:bg-slate-100 odd:white">
            <lib-file-summary [file]="item" class="w-full"/>
          </div>
        }
      } @else {
        <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
      }
    </div>
  </div>
}

<ion-modal #autoRunPipeline
  [isOpen]="openPipelineSelector()" 
  canDismiss="true"
  (didDismiss)="openPipelineSelector.set(false)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ng-container *ngIf="droppedFiles() as files">
      <ion-content style="height: 80vh">
        <lib-pipeline-selector
        message1="You have selected {{ files.length + ( files.length > 1 ? ' files' : ' file') }} for processing."
        message2="Now select the pipeline to apply, or none to process each file individually."
        [selection]="selectedPipeline"
        (submit)="submitFiles(files, $event)"
        (dismiss)="autoRunPipeline.dismiss()" />
      </ion-content>
    </ng-container>
  </ng-template>
</ion-modal>