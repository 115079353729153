@if (item(); as item) {
    @if (user(); as user) {
        <mat-menu #submenu="matMenu" xPosition="before" [hasBackdrop]="false">
            <ng-template matMenuContent>                
                @for (subitem of item.submenu; track subitem.label) {
                    @if (subitem.submenu) {
                        <lib-option-item-menu [item]="subitem" [user]="user" />
                    }
                    @else {
                        <button mat-menu-item 
                            (click)="action.emit({ item: subitem, user})">
                            {{ subitem.labelFunc ? subitem.labelFunc(user) : subitem.label }}
                        </button>
                    }
                }
            </ng-template>
        </mat-menu>
        @if (item.submenu) {
            <button mat-menu-item [matMenuTriggerFor]="submenu"
                class="font-sans"                
                #submenuTrigger="matMenuTrigger" 
                (mouseover)="submenuTrigger.openMenu()"                
                [disabled]="item.enableIf && !item.enableIf(user)">
                    {{ item.labelFunc ? item.labelFunc(user) : item.label }}
            </button>
        }
        @else {
            <button mat-menu-item
                class="font-sans"
                (click)="action.emit({ item, user})"
                [disabled]="item.enableIf && !item.enableIf(user)">
                    {{ item.labelFunc ? item.labelFunc(user) : item.label }}
            </button>
        }
    }
}