import {
  BaseFlowTool,
  BaseXlsTransformerTool,
  FlowFile,
  FlowStep,
  FlowTool,
} from '../fileflow.interface';
import { FileflowServiceInterface } from '../fileflow.interface';
import { getBlobFromBuffer } from '../utils';
import { ExcelUtils } from '../excel-utils'
import { Workbook } from 'exceljs';
import { DocIntelMarkdownTransformerTool } from './DocIntelMarkdownTransformerTool';


export class DocIntelGenerateXlsTool extends BaseXlsTransformerTool {

    name = 'alternate-xls-transformer'
    type = 'xls-transformer'
    description = 'Alternate Transform to XLS'
    startTime = 0
    
    protected override initialize(flowService: FileflowServiceInterface){
      super.initialize(flowService)
      this.precedents.push(new DocIntelMarkdownTransformerTool(this.flowService))
      return this
    }

    async execute(
        file: FlowFile,
        last: FlowStep | null) 
    {

      this.startTime = Date.now()
      // Get the output from the last step
      const output = last ? await this.flowService.getFileContents(last.storageName) : null;
      if (!output)
        throw new Error(
          `No output found in last step ${last?.name} ${last?.storageName}`,
        );
      
      // Correct the indentation of the tables in the output
      const pages = output.pages;
      this.flowService.log('Pages', pages)
      this.flowService.log(`Pages in the analyzed JSON document: ${pages.length}`);
      const wb = new Workbook();
      let table_id = 1
      for(let i = 0; i < pages.length; i++ ) {
        const page = pages[i]
        this.flowService.log('Page', 'Page ' + (page.pageNumber || i))
        const ws = wb.addWorksheet('Page ' + (page.pageNumber || i))
        const textRowNumbers: number[] = []
        for(const element of page.elements) {
          let text_id = 1
          if(element.type === "text" || element.type === "subheader") {
            textRowNumbers.push(this.excelUtils.addTextElement(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "table") {
            if(element.columnHeaders.length === 0) {
                textRowNumbers.push(this.excelUtils.addTextElement(ws, element.content, text_id))
                text_id++
            }
            else {
              this.excelUtils.addTable(ws, element.rows, element.columnHeaders, table_id)
                table_id++
            }
          }
          else if(element.type === "footer") {
            textRowNumbers.push(this.excelUtils.addFooter(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "figure") {
            textRowNumbers.push(this.excelUtils.addFigureElement(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "page_num") {
            textRowNumbers.push(this.excelUtils.addPageNumber(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "header" || element.type === 'page_header' || element.type === 'page_footer') {
            textRowNumbers.push(this.excelUtils.addTextElement(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "figure") {
            if(element.figureContent) {
              textRowNumbers.push(this.excelUtils.addFigureElement(ws, element.caption || element.figureContent, text_id))
              text_id++
            }
          
          }
          else if(element.type === "page_break") {
            //ignore for now - not sure what to do with it
            this.flowService.warn('Detected docintel element type page_break with value ', element.content)
          }
          else 
            throw new Error(`Unknown element type detected in ${JSON.stringify(element)}`)
        }
        //mergeTextCells(ws, textRowNumbers)
        this.excelUtils.autoFitColumns(ws)
        this.excelUtils.mergeTextCells(ws, textRowNumbers)
      }
      
      // Upload the workbook to storage
      const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
      await this.flowService.uploadBlob(this, file, blob, '.xlsx');
    }
  
    getContentDisposition(fileName: string) {
      return  'attachment; filename*=utf-8\'\'' + fileName
    }
}
