import {
  FlowFile,
  FlowStep,
  FlowTool,
  FileflowServiceInterface
} from '../fileflow.interface';
import { getBlobFromBuffer } from '../utils';
import { ExcelUtils } from '../excel-utils'
import { Workbook } from 'exceljs';
import { DocIntelJSONTransformerTool } from './DocIntelJsonTransformerTool'


export class DocIntelJSONGenerateXlsTool implements FlowTool {

    name = 'json-xls-transformer'
    type = 'transformer'
    description = 'Generate an XLS file from the GPT4-o JSON'
    precedents: FlowTool[] = [] // [ inject(DocIntelJSONTransformerTool) ]

    flowService: FileflowServiceInterface
    excelUtils: ExcelUtils;

    constructor(flowService?: FileflowServiceInterface) {
      if(flowService)
        this.initialize(flowService)
    }

    shouldPublish(): boolean {
        return false
    }
    initialize(flowService: FileflowServiceInterface) {
      this.flowService = flowService
      this.precedents.push(new DocIntelJSONTransformerTool(this.flowService))
      this.excelUtils = new ExcelUtils(flowService)
      return this
    }
    
    async execute(
        file: FlowFile,
        last: FlowStep | null) 
    {
      // Get the output from the last step
      const output = last ? await this.flowService.getFileContents(last.storageName) : null;
      if (!output)
        throw new Error(
          `No output found in last step ${last?.name} ${last?.storageName}`,
        );
      // Correct the indentation of the tables in the output
      const pages = output.pages;
      this.flowService.log('Pages', pages)
      this.flowService.log(`Pages in the analyzed JSON document: ${pages.length}`);
      const wb = new Workbook();
      let table_id = 1
      for(const page of pages) {
        const ws = wb.addWorksheet('Page ' + page.pageNumber)
        this.flowService.log('Page', page)
        const textRowNumbers: number[] = []
        for(const element of page.elements) {
          let text_id = 1
          if(element.type === "text") {
            textRowNumbers.push(this.excelUtils.addTextElement(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "table") {
            if(element.columnHeaders.length === 0) {
                textRowNumbers.push(this.excelUtils.addTextElement(ws, element.content, text_id))
                text_id++
            }
            else {
                this.excelUtils.addTable(ws, element.rows, element.columnHeaders, table_id)
                table_id++
            }
          }
          else if(element.type === "footer") {
            textRowNumbers.push(this.excelUtils.addFooter(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "figure") {
            textRowNumbers.push(this.excelUtils.addFigureElement(ws, element.content, text_id))
            text_id++
          }
          else if(element.type === "page_num") {
            textRowNumbers.push(this.excelUtils.addPageNumber(ws, element.content, text_id))
            text_id++
          }
          else 
            throw new Error(`Unknown element type detected in ${JSON.stringify(element)}`)
        }
        this.excelUtils.mergeTextCells(ws, textRowNumbers)

      }
      // Upload the workbook to storage
      const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
      await this.flowService.uploadBlob(this, file, blob, '.xlsx');
    }

    getContentDisposition(fileName: string) {
      return  'attachment; filename*=utf-8\'\'' + fileName
    }
  
}
