export interface Environment {
    production: boolean
    appSource: string
    admin: boolean
    useEmulators: boolean
    useCompletions: boolean
    releaseTag?: string
    patchLevel?: number
    buildTag?: string
    portalHost: string
    webHost: string,
    adminHost: string
    adminEmail: string
    firebase: {
      apiKey: string
      authDomain: string
      databaseURL?: string
      projectId: string
      storageBucket: string
      messagingSenderId: string
      appId: string
      //measurementId: string
    }
    globalErrors: {
      slackURL: string
      channelName: string
    }
    stripe: {
      customerPortalLink: string
    }
  }