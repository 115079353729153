import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { map, timer } from 'rxjs';

@Component({
  selector: 'lib-spinner',
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: './labelled-spinner.component.html',
})
export class LabelledSpinnerComponent {
  @Input() title:string|undefined = undefined
  @Input() subtitle:string|undefined = undefined
  delay$ = timer(300).pipe(map(() => true))
}
