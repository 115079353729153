import { JsonFormData, LoggerInterface } from '@cheaseed/node-utils';
import { Observable, Subject } from 'rxjs';
import { UserRecord } from './user.models';

export enum Provider {
  DOCUPANDA = 'docupanda',
  OPENAI = 'openai',
  LLAMAPARSE = 'llamaparse',
}

export enum AgentType {
    'assistant' = 'assistant',
    'completions' = 'completions',
}

export interface FlowDomain {
  docId: string;
  isDefault?: boolean;
  domainName: string;
  domainDescription: string;
  classes: FlowSchemaType[];
  schemas: any[];
}

export interface FlowSchemaType {
  docId: string;                // the firestore doc id
  className: string;
  classId: string;             // the docupanda class id
  description: string;
  schemaName: string;
  schemaId: string;            // the docupanda schema id
  schemaInstructions: string;
  jsonSchema: any;  
  instructions: string;
  useSchemaOfClass: string;
} 

export interface FlowFile {
    docId: string;
    userId: string;
    createdAt: Date;
    updatedAt: Date;
    userDocId: string;
    isFolder: boolean;
    containedInFolder: string;
    state: 'uploaded' | 'idle' | 'archived' | 'preparing' | 'running' | 'error' | 'deleting'; // | 'analyzing' | 'analyzed' | 'transforming' | 'transformed' | 'approved' | 'archived' | 'error';
    stateDescription?: string;
    pipelineName: string;
    fileName: string;
    fileType: string;  
    storageName: string;
    downloadURL: string;
    size: number;
    numPages?: number;
    opened?: boolean;
    output?: any;
    outputURL?: string;
    outputStorageName?: string;
    outputType?: string;
    rating?: number;
    externalDocumentId?: string;
    domainName?: string;
    schemas?: any[];
  }

export interface PipelineLoggerInterface  extends LoggerInterface{
    constructPrefix(context: PipelineContext): void
  }
  export interface PipelineContext {
    pipeline: string,
    tool?: string
    userId: string,
    //logger: PipelineLoggerInterface
  }
  export interface FlowTool {
    // Future: Defined as tools metadata in Firestore
    name: string;
    description?: string;
    apiType?: AgentType;
    outputType?: string;
    assistantId?: string;
    parameters?: JsonFormData;
    instructions?: string;
    updatedAt?: Date;
  
    // Defined in sub-classes
    type: string; // 'ocr' | 'transformer';
    precedents?: FlowTool[];
    checkExecute?: (file: FlowFile, user: UserRecord) => void;
    execute: (file: FlowFile, last: FlowStep | null, params?: any) => Promise<any>;
    getContentDisposition: (fileName: string) => string;
    shouldPublish(): boolean
    generateOutputPreview?: (output: any) => any;
    // Transient variable used in execution
    startTime?: number;
  }
  
  export interface FlowPrompt {
    docId: string;
    prompt: string;
    response: string;
    createdAt: Date;
  }
  
  export class BaseFlowTool implements FlowTool {
    flowService: FileflowServiceInterface
    name: string;
    type: string; // 'ocr' | 'transformer';
    async execute(file: FlowFile, last: FlowStep | null, params?: any) { return Promise.resolve() };
    getContentDisposition(fileName: string)  { return 'inline;'};
    //initialize(flowService: FileflowServiceInterface) {this.flowService = flowService}
    shouldPublish(): boolean {
        return false
    }
  }
  export interface FlowPipeline {
    name: string;
    description: string;
    tools: FlowTool[];
    isDefault?: boolean;
  }
  export interface FlowStep {
    name: string;
    description?: string;
    state: 'pending' | 'complete' | 'error';
    type: string; // 'ocr' | 'transformer';
    error?: string;
    instructions?: string;
    parameters?: { name: string; value: any }[];
    storageName: string;
    outputURL: string;
    output?: any;
    elapsedMsec: number;
    lastUpdatedAt: Date;
    opened?: boolean;
  }
export interface FileflowServiceInterface extends PipelineLoggerInterface {
    getToolParameters(tool: FlowTool): JsonFormData
    getUserId(): string
    getUser(userId: string): Promise<UserRecord>
    sendMeterEvent(numPages: number): Promise<void>
    defaultCheckExecute(file: FlowFile, user: UserRecord, pages: number): void
    executeStep(context: PipelineContext | null, tool: FlowTool, file: FlowFile, params?: any): Promise<void>
    getFileSteps(file: FlowFile): Promise<any>
    getFileStepPrompts(file: FlowFile, stepName: string): Observable<FlowPrompt[]> | Promise<FlowPrompt[]>
    getLastCompletedStep(file: FlowFile, priorToolName: string): Observable<any> | Promise<any>
    getCompletedStepForTool(file: FlowFile, toolName: string): Observable<any> | Promise<any>
    updateStep(file: FlowFile, s: Partial<FlowStep>): Promise<any>
    updateStepPrompt(file: FlowFile, step: string, input: any, output: string): Promise<any>
    updateFile(file: FlowFile, data: Partial<FlowFile>): Promise<void>
    deleteStepPrompts(file: FlowFile, stepName: string): Promise<void>
    deleteStep(file: FlowFile, step: FlowStep): Promise<void>
    deleteFile(file: FlowFile, stepsOnly?: boolean): Promise<void>
    getFileContents(storagePath: string, asJson?: boolean): Promise<any>
    getFileContentsAsBlob(storagePath: string): Promise<Blob>
    uploadBlob(tool: FlowTool, file: FlowFile, blob: Blob, suffix: string): Promise<void>
    uploadAnalysis(tool: FlowTool, file: FlowFile, analysis: any): Promise<any>
    updateTool(tool: FlowTool, data: Partial<FlowTool>): Promise<void>
    renderMarkdown(text: string): string | any
    getFileContentsBase64(storagePath: string): Promise<string>
    setContextTool(toolName: string): void
    clearContextTool(): void
    consumedPages$: Subject<number>
    defaultDomainName(): string
    getClassMapForDomain(domain: string): Map<string, FlowSchemaType> | undefined
    getEnv(): string
    logPipelineStep(data: PipelineStepLog): Promise<void>
    postDocument(payload: any): Promise<any>
    pollJob(payload: any): Promise<any>
    getPageBalance(user: UserRecord): Promise<number>
    ignoreIndenterErrors: boolean
    //publish(payload: MessagePayload): void
}

export interface PipelineStepLog {
  user: string
  groupDocId?: string
  loggedAt: Date
  fileDocId: string
  fileName: string
  fileSize?: number
  numPages?: number
  stepName: string  // identifies provider
  promptDocId?: string
  credits?: number
  cost?: number
  azureModelUsed?: string
  azureTokensUsed?: number
  azureCost?: number
  elapsedMsec: number
}
