import { CommonModule, Location } from '@angular/common';
import { Component, Inject, computed, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { JsonFormComponent, UtilityService } from '@fidoc/util';
import { BreadcrumbStripComponent, PartnerViewComponent, PricingComponent } from '@fidoc/admin';
import { FileflowService, ToolsService } from '@fidoc/fileflow';
import { EditGroupComponent, GroupService } from '@fidoc/groups';
import { AcceptTermsComponent, LoginComponent } from '@fidoc/profile';
import { INITIAL_PAGE_BALANCE, UserRecord } from '@fidoc/shared';
import { AuthService, DefaultMessageComponent, Environment, FirebaseService, InspectorService, OptionItem, OptionItemMenuComponent, StripeService, UserService } from '@fidoc/util';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { BehaviorSubject, debounceTime, filter, firstValueFrom, map, tap } from 'rxjs';

const CONTENT_CONFIG = 'content/config'

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule,
    LoginComponent,
    IonContent,
    IonModal,
    JsonFormComponent,
    AcceptTermsComponent,
    DefaultMessageComponent,
    OptionItemMenuComponent,
    PricingComponent,
    EditGroupComponent,
    PartnerViewComponent,
    BreadcrumbStripComponent
],
  selector: 'fidoc-root',
  templateUrl: './app.component.html',
  styles: ``,
})
export class AppComponent {
  utilityService = inject(UtilityService)
  inspectorService = inject(InspectorService)
  auth = inject(AuthService)
  userService = inject(UserService)
  fileFlowService = inject(FileflowService)
  toolsService = inject(ToolsService)
  stripeService = inject(StripeService)
  firebase = inject(FirebaseService)
  router = inject(Router)
  route = inject(ActivatedRoute)
  groupService = inject(GroupService)

  title = 'fidoc';
  appInfo = signal<Environment|undefined>(undefined)
  maintenanceMode$ = new BehaviorSubject(false)
  lastBuildTag$ = new BehaviorSubject('')
  shouldSignOut$ = new BehaviorSubject(false)
  launchedStripe$ = new BehaviorSubject(false)
  selectedPlan$ = this.route.queryParamMap.pipe(map(params => params.get('selectedPlan')))
  isPartnerRoute$ = this.route.url.pipe(map(url => url[0].path === 'partner'))
  isReview = false
  
  launchSelectedPlan$ = this.selectedPlan$
    .pipe(
      tap(async (selectedPlan) => {
        // console.log('selectedPlan', selectedPlan)
        const stripeLaunched = await firstValueFrom(this.launchedStripe$)
        if (!stripeLaunched)
          setTimeout(() => this.launchStripePaymentLink(selectedPlan), 300)
        return selectedPlan
      })      
    )

  menuOptions: OptionItem[] = [
    { label: 'Home', routerLink: '/home' },
    { label: 'Pricing', routerLink: '/pricing', role: 'admin' },
    { label: 'Admin', routerLink: '/admin', role: 'admin' },
  ]

  profileOptions: OptionItem[] = []
  authorizedOptions = computed(() => {
    const user = this.userService.user()
    return this.menuOptions.filter(p => (p.role === 'admin' && user.currentRole === 'admin') || !p.role)
  })

  authorizedProfileOptions = computed(() => {
    const user = this.userService.user()
    return this.profileOptions.filter(p => (p.role === 'admin' && user.isAdmin) || !p.role)
  })

  constructor(
    @Inject('environment') public environment: any,
    private location: Location
  ) { 
      this.isReview = this.location.path().includes('review')
      console.log('ENVIRONMENT', this.environment)
      this.appInfo.set(this.environment)

      this.auth.user$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed())
      .subscribe(user => {
        const url = window.location.href
        // console.log('app auth.user$', user, url)
        // If no user or anonymous user
        if (!user) {
          // Check if email link
          if (this.auth.isSignInWithEmailLink(url))
            try {
              this.auth.processEmailLinkSignIn(url)
            }
            catch(e) {
              console.log('SignIn with email failed', e)
              this.utilityService.presentToast(`Failed to sign in. Please try again`)
            }
        }
      })

      // Initial #367
      // TODO: Ensure latest buildTag is written to content/config doc on each deploy
      //
      // this.firebase.doc$(CONTENT_CONFIG)
      //   .pipe(
      //     filter(data => !!data),
      //     debounceTime(200),
      //     withLatestFrom(this.maintenanceMode$, this.lastBuildTag$))
      //   .subscribe(([doc, maintenanceMode, lastBuildTag]) => {
      //       if (doc.lastBuildTag !== lastBuildTag && !doc.maintenanceMode) {
      //         console.log(`${CONTENT_CONFIG} new buildTag ${doc.lastBuildTag}`)
      //         this.lastBuildTag$.next(doc.lastBuildTag)
      //         window.location.reload() // force reload of app
      //       }
      //       if (doc.maintenanceMode !== maintenanceMode) {
      //         console.log(`${CONTENT_CONFIG} maintenance mode change detected ${doc.maintenanceMode}`)
      //         this.maintenanceMode$.next(doc.maintenanceMode)
      //       }
      //     })

      this.shouldSignOut$
        .pipe(
          filter(signOut => !!signOut),
          takeUntilDestroyed())
        .subscribe(() => {
          this.userService.signOut()
          this.shouldSignOut$.next(false)
        })
      
      this.profileOptions = [
          { label: 'Profile', icon: 'person', routerLink: '/profile' },
          { label: 'Manage Group', icon: 'group', 
              displayIf: (user: UserRecord) => !!user.groupDocId && this.groupService.isOwnerOfCurrentGroup(user.docId),
              action: (user: UserRecord) => this.groupService.selectGroup(user.groupDocId) 
        },
          { label: 'FAQ', icon: 'faq', externalLink: `${this.environment.webHost}/docs/faq`, top: false },
          { label: 'Privacy Notice', icon: 'privacy', externalLink: `${this.environment.webHost}/privacy`, top: false },
          { label: 'Terms of Service', icon: 'privacy', externalLink: `${this.environment.webHost}/tos`, top: false },
          { label: 'Sign Out', icon: 'logout', action: () => this.signOut() },
        ]
  }

  async signOut() {
    if (this.auth.isLoggedIn()) {
      this.shouldSignOut$.next(true)
      await this.router.navigate(['/'])
    }
  }

  clickMenuItem(item: OptionItem, user: UserRecord) {
    // console.log('clickMenuItem', item, user)
    if (item.externalLink)
      window.open(item.externalLink, '_blank')
    else if (item.routerLink)
      this.router.navigateByUrl(item.routerLink)
    else if (item.action)
      item.action(user)
  }

  clickMenuOption(ev: any) {
    console.log('clickMenuOption', ev)
    const { item, user } = ev
    this.clickMenuItem(item, user)
  }

  /**
   * 
   *  deprecated - not used any more
   */
  clickCredits(user: UserRecord) {
    // console.log('clickCredits', user)
    if (user.currentRole === 'admin' && (user.pageBalance || 0) < 10) {
      this.userService.updatePageBalance(user, INITIAL_PAGE_BALANCE)
      this.utilityService.presentToast(`Added ${INITIAL_PAGE_BALANCE} credits to your (admin) account`)
    }
  }

  async acceptTerms(user: UserRecord) {
    console.log('acceptTerms', user)
    await this.userService.updateUser(user, { hasAcceptedTerms: true, acceptedTermsAt: new Date() })
    // an enterprise trial user is preapproved - so dont send email if so
    if(!!user.enterpriseTrial) {
      const selectedPlan = await firstValueFrom(this.selectedPlan$)
      this.launchStripePaymentLink(selectedPlan)
    }
  }

  launchStripePaymentLink(selectedPlan: string) {
    // console.log('launchStripePaymentLink', selectedPlan)
    if (selectedPlan) {
      const prod = this.stripeService.getProduct(selectedPlan)
      if (prod) {
        const url = this.stripeService.getPaymentLinkUrl(this.userService.userDocId(), prod.paymentLink)
        if (url) {
          window.open(url, '_blank')
          this.launchedStripe$.next(true)
        }
      }
      else
        console.warn('Could not find product', selectedPlan)
    }
  }

  isUserActive(user: UserRecord) {
    const url = window.location.href
    // console.log('userShouldPass', url)
    return !!user.enterpriseTrial 
        || !!user.groupDocId 
        || [ 'active', 'trialing', 'canceled' ].includes(user.subscriptionInfo?.status)
        || url.indexOf('group-invite') > -1
        || user.currentRole == 'partner'
  }

  hasFreeTrialExpired(user: UserRecord) {
    return user.enterpriseTrial?.isFreeTrial && 
      ( user.pageBalance <= 0 || 
        new Date(user.enterpriseTrial?.evalEndDate) < new Date()
      )
  }
  
}
