/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, from, takeUntil, filter, MonoTypeOperatorFunction, map, shareReplay, withLatestFrom, combineLatest, catchError, throwError } from 'rxjs';
import {
  Auth,
  signInWithPopup,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInAnonymously,
  signOut,
  TwitterAuthProvider,
  UserCredential,
  authState,
  onAuthStateChanged,
  User,
  sendSignInLinkToEmail,
  ActionCodeSettings,
  isSignInWithEmailLink,
  signInWithEmailLink,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  fetchSignInMethodsForEmail,
  signInWithRedirect,
  signInWithCustomToken} from '@angular/fire/auth';
import { ADMIN_CERTIFIED, AUTH_FUNCTIONS, FIREBASE_AUTH_CLOUD_FUNCTION } from '@fidoc/shared';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { AuthCredential, OAuthCredential, signInWithCredential } from 'firebase/auth';

const supportedPopupSignInMethods = [
    GoogleAuthProvider.PROVIDER_ID,
    'microsoft.com'
  ];
const getProvider = (providerId: string) => {
    switch (providerId) {
      case GoogleAuthProvider.PROVIDER_ID:
        return new GoogleAuthProvider();
      case 'microsoft.com':
        return new OAuthProvider(providerId);
      default:
        throw new Error(`No provider implemented for ${providerId}`);
    }
  };
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private afAuth = inject(Auth)
  public loggedIn$ = new BehaviorSubject<boolean|undefined>(undefined)
  private lastUser$ = new BehaviorSubject<User|undefined>(undefined)

  user$ = authState(this.afAuth)
    .pipe(
      withLatestFrom(this.lastUser$),
      map(([ currUser, lastUser ]) => {
        // console.log(`auth.user$ currUser ${currUser?.email || currUser?.uid}, lastuser ${lastUser?.email || lastUser?.uid}`)
        if (currUser && currUser.uid !== lastUser?.uid)
          this.lastUser$.next(currUser)
        if (currUser) 
          return { 
            ...currUser,
            email: currUser.email || currUser.uid,
            isAnonymousUser: currUser.email ? undefined : true
          }
        else {
          // console.log('auth signing in anonymously')
          // this.signInAnonymously()
          return null
        }
      }),
      shareReplay(1)
    )

  signInKey$ = new BehaviorSubject('portal.signin.message')
  
  // ephemeral property used during apple signin
  displayName: string | null = null;
  
  constructor(
  )
    {
      onAuthStateChanged(this.afAuth, (user) => {
        this.loggedIn$.next(!!user && !user.isAnonymous)
      })        
  }
  
  clearDisplayName() {
    const result = this.displayName
    this.displayName = null
    return result
  }

  takeUntilAuth<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(
      combineLatest([ authState(this.afAuth), this.lastUser$ ])
        .pipe(
          // tap(data => console.log('takeUntilAuth', data)),
          filter(([ u, last ]) => !u || (u?.uid !== last?.uid)), // stop the subscription if no user or user changed
          // tap(data => console.log('takeUntilAuth filtered', data))
          ))
  }

  signInWithEmail(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.afAuth, email, password)
  }

  signUpWithEmail(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.afAuth, email, password)
  }

  signInAnonymously(): Promise<UserCredential> {
    return signInAnonymously(this.afAuth)
  }
 
  logout(): Promise<void> {
    console.log('logging out')
    this.loggedIn$.next(false)
    // window.localStorage.removeItem(ADMIN_CERTIFIED)
    return signOut(this.afAuth)
  }

  isLoggedIn() {
    return this.loggedIn$.getValue()
  }

  getPhotoURL(signInProviderId: string, photoURL: string): string {
    // Default imgs are too small and our app needs a bigger image
    switch (signInProviderId) {
      case 'facebook.com':
        return photoURL + '?height=400';
      case 'password':
        return 'https://s3-us-west-2.amazonaws.com/ionicthemes/otros/avatar-placeholder.png';
      case 'twitter.com':
        return photoURL.replace('_normal', '_400x400');
      case 'google.com':
        return photoURL.split('=')[0];
      default:
        return photoURL;
    }
  }

  getAppleSignInRedirectURI(environment: any) {
    return 'https://' + environment.firebase.authDomain + '/__/auth/handler'
  }

  socialSignIn(providerName: string, environment: any, scopes?: Array<string>): Observable<any> {
    const provider = new OAuthProvider(providerName);
    //console.log('provider', provider)
    if (scopes)
      scopes.forEach((scope) => provider.addScope(scope))
    return from(signInWithPopup(this.afAuth, provider)).pipe(
      catchError(async(err) => {
        console.log('ERROR', JSON.stringify(err))
        if (err.code === "auth/account-exists-with-different-credential") {
          console.log('USER', err.customData.email)
          
          //const providers = await fetchSignInMethodsForEmail(this.afAuth, err.customData.email);
          /* let res = await this.awaitCloudFunction(FIREBASE_AUTH_CLOUD_FUNCTION, { 
            function: AUTH_FUNCTIONS.GET_SIGNIN_METHODS,
            emailConfig: false
          
          })
            */
          //const providers = await fetchSignInMethodsForEmail(this.afAuth, err.customData.email);
          /*res = await this.awaitCloudFunction(FIREBASE_AUTH_CLOUD_FUNCTION, { 
            function: AUTH_FUNCTIONS.GET_SIGNIN_METHODS,
            emailConfig: true
          
          })
            */
          /*await adminAuth().projectConfigManager().updateProjectConfig(
            {
              emailPrivacyConfig: {
                enableImprovedEmailPrivacy: true,
              },
            }
          );*/
          //console.log('PROVIDERS', providers)
          //const firstPopupProviderMethod = providers.find((p) =>
          //      supportedPopupSignInMethods.includes(p)
          //    );

          // Test: Could this happen with email link then trying social provider?
          //if (!firstPopupProviderMethod) {
          //  throwError(() => new Error(`Your account is linked to a provider that isn't supported.`))
          //}
          const res = await this.awaitCloudFunction(FIREBASE_AUTH_CLOUD_FUNCTION, { 
            function: AUTH_FUNCTIONS.CREATE_CUSTOM_TOKEN, 
            email: err.customData.email
          })
          return from(signInWithCustomToken(this.afAuth, res.data as string))
          /*if(linkedProvider instanceof GoogleAuthProvider) {
            creds = GoogleAuthProvider.credential(err.customData._tokenResponse.oauthIdToken, err.customData._tokenResponse.oauthAccessToken)  //credentialFromError(err)
            //creds = OAuthProvider.credentialFromError(err)
            console.log('GOOGLE CREDS', creds)
          }
          else {
            creds = OAuthProvider.credentialFromError(err) as OAuthCredential
            console.log('OAUTH CREDS', creds)

          }

          return from(signInWithCredential(this.afAuth, creds))
          */
            
        }
        else
          throwError(() => new Error(err))

      }),
      map(userCred => userCred) 
    )
        
  }

  signInWithFacebook(environment: any): Observable<any> {
    const provider = new FacebookAuthProvider();
    return this.socialSignIn(provider.providerId, environment);
  }

  signInWithGoogle(environment: any): Observable<any> {
    const provider = new GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    const result = this.socialSignIn(provider.providerId, environment, scopes);
    return result
  }

  signInWithMicrosoft(environment: any): Observable<any> {
    const provider = new OAuthProvider('microsoft.com');
    const scopes = ['profile', 'email'];
    console.log('Provider', provider)
    let result
    result = this.socialSignIn(provider.providerId, environment, scopes);
    
    /*catch(err: any)  {
      console.log('ERROR', err)
      if (err.code === "auth/account-exists-with-different-credential") {
        fetchSignInMethodsForEmail(this.afAuth, err.customData.email).then(
          (providers) => {
            const firstPopupProviderMethod = providers.find((p) =>
              supportedPopupSignInMethods.includes(p)
            );

            // Test: Could this happen with email link then trying social provider?
            if (!firstPopupProviderMethod) {
              throw new Error(
                `Your account is linked to a provider that isn't supported.`
              );
            }

            const linkedProvider = getProvider(firstPopupProviderMethod);
            linkedProvider.setCustomParameters({ login_hint: err.email });

            result = signInWithRedirect(this.afAuth, linkedProvider)
          }
        );
        console.error(err);
      }
      else
        throw err
      
    }*/
    return result as Observable<any>
  }
  signInWithTwitter(environment: any): Observable<any> {
    const provider = new TwitterAuthProvider();
    return this.socialSignIn(provider.providerId, environment);
  }

  signInWithApple(environment: any): Observable<any> {
    const provider = new OAuthProvider('apple.com');
    return this.socialSignIn(provider.providerId, environment);
  }

  async signInWithEmailLink(email: string, environment: any) {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${environment.portalHost}`, // TODO send source as anonymous ID to transfer keys? vs link to anonymous account
      handleCodeInApp: true
    };
    console.log("signInWithEmailLink", email, actionCodeSettings)
    await sendSignInLinkToEmail(this.afAuth, email, actionCodeSettings)
    // Set emailForSignIn in localStorage for later use in another session
    localStorage.setItem('emailForSignIn', email)
  }

  isSignInWithEmailLink(url: string) {
    return isSignInWithEmailLink(this.afAuth, url)
  }

  async processEmailLinkSignIn(url: string) {
    console.log("processEmailLinkSignIn", url)
    // Confirm the link is a sign-in with email link.
    if (this.isSignInWithEmailLink(url)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }
      try {
        const result = await signInWithEmailLink(this.afAuth, email as string, url)
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log("processEmailLinkSignIn", result)
      }
      catch(e) {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      }
    }
  }

  async sendVerificationCode(phoneNumber: string, buttonId: string) {
    // specify the ID of the button that submits your sign-in form
    const verifier = new RecaptchaVerifier(this.afAuth, buttonId, {
      size: 'invisible',
      callback: (response: any) => {
        console.log("recaptchaVerifier callback", response)
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    })
    const confirmationResult = await signInWithPhoneNumber(this.afAuth, phoneNumber, verifier)
    console.log("signInWithPhone confirmationResult", confirmationResult)
    return confirmationResult
  }

  async signInWithPhone(code: string, confirmationResult: any) {
    const result = await confirmationResult.confirm(code)
    console.log("signInWithPhone result", result)

    // Get the AuthCredential object for the account
    // const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, code)
    // signInWithCredential(this.afAuth, credential)
  }

  async register(email: string, password: string) {
    const response = await createUserWithEmailAndPassword(
      this.afAuth,
      email,
      password
    )
    return response
  }

  async login(email: string, password: string) {
    return await signInWithEmailAndPassword(this.afAuth, email, password)
  }
  async awaitCloudFunction(funcname: string, args: any, timeout?: number) {
    console.log(`Calling cloud function ${funcname}`, timeout, args)
    const callable = timeout ?
      httpsCallable(getFunctions(), funcname, { timeout }) :
      httpsCallable(getFunctions(), funcname)
    return callable(args)
  }
}
