<div class="w-full flex flex-row pt-4 gap-2">
    <div class="w-auto flex flex-col gap-2 bg-slate-100">
        @if (domains(); as domains) {
            @for (domain of domains; track domain.docId) {
                <div class="p-2 text-nowrap hover:bg-green-50 cursor-pointer" (click)="selectedDomain.set(domain)">{{ domain.domainName }}</div>    
            }
        }
    </div>
    <div class="p-2 w-full flex flex-col">
        @if (selectedDomain(); as domain) {
            <div class="px-2 text-xl flex flex-row justify-between items-center">
                <div class="text-xl">{{ domain.domainDescription }}</div>
                <!-- <button mat-icon-button>
                    <span class="material-symbols-outlined">
                        more_horiz
                    </span>                            
                </button> -->
            </div>
            <table class="my-8 w-full border-separate border-0 border-slate-400 text-sm">
                <thead>
                    <tr class="text-left">
                        <th class="px-2">Class</th>
                        <th class="px-2">Class Description</th>
                        <th class="px-2">Schema</th>
                        <th class="px-2">Actions</th>
                    </tr>
                </thead>
                <tbody>
                @for (doc of domainClasses(); track doc.className) {
                    <tr class="even:bg-slate-100 odd:white text-left">
                        <td class="px-2 whitespace-nowrap">{{ doc.className }}</td>
                        <td class="px-2">
                            <span class="font-bold">{{ doc.description }}</span>
                            <div *ngIf="doc.classificationInstructions" class="text-xs italic">
                                {{ doc.classificationInstructions }}
                            </div>
                        </td>
                        <!-- <td class="px-2">{{ doc.classificationInstructions }}</td> -->
                        <td class="px-2">{{ doc.schemaName }} {{doc.schemaId ? '(' + doc.schemaId + ')' : '' }}</td>
                        <td class="px-2">
                            <button mat-icon-button [matMenuTriggerFor]="moreMenu" aria-label="Open menu">
                                <span class="material-symbols-outlined">
                                    more_horiz
                                </span>                            
                            </button>
                            <mat-menu #moreMenu="matMenu" xPosition="after">
                                <ng-template matMenuContent>
                                    <button mat-menu-item (click)="viewSchema(domain, doc)">
                                        View Schema
                                    </button>
                                    <button mat-menu-item (click)="viewSchemaInstructions(domain, doc)">
                                        View Schema Instructions
                                    </button>
                                    <button mat-menu-item (click)="regenerateSchema(domain, doc)">
                                        Regenerate Schema
                                    </button>
                                </ng-template>
                            </mat-menu>    
                        </td>
                    </tr>
                }
                </tbody>
            </table>
        }
    </div>
</div>
