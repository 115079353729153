<div class="flex justify-between p-4 m-2 bg-slate-50">
    <div class="flex items-center justify-center cursor-pointer hover:bg-slate-200" (click)="action.emit()">
        @if (!hideBackArrow()) {
            <span class="material-symbols-outlined text-xl">
                keyboard_double_arrow_left
            </span>    
        }
        <div class="px-2 text-base" [innerHtml]="title()"></div>
    </div>
    <div class="px-4 text-xs content-center">
        {{ message() }}
    </div>
</div>