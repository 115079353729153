<div class="flex flex-col items-start p-8 gap-4">
    @if (userService.user(); as user) {
        @for (action of actionOptions(); track action.action!) {
            <button mat-raised-button
                class="mx-4 bg-white border border-gray-400 hover:bg-slate-400 active:bg-white text-sm text-black font-bold py-2 px-4 rounded" 
                (click)="action.action!(user)">
                    {{ action.label || action.labelFunc!(user) }}
            </button>
        }
    }
</div>

<ion-modal #provisionUserModal
  [isOpen]="provisionUser$ | async" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="provisionUser$.next(false)"
  style="--height: 50vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 50vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="provisionUserModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">Provision Enterprise Trial User</h1>
        <cheaseed-json-form
          [formData]="provisionUserFormData" 
          (formRejectSubmit)="utilityService.onFormRejectSubmit($event)"
          (formSubmit)="userService.confirmEnterpiseTrialUserCreation($event); provisionUserModal.dismiss()"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>