import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatest, debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs';
import { FirebaseService, UserService } from '@fidoc/util';
import { FlowFile, flowFileConverter, getUserFilePath } from '@fidoc/shared';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FileSchemaViewerComponent } from '../file-schema-viewer/file-schema-viewer.component';
import { FileflowService } from '../fileflow/fileflow.service';

@Component({
  selector: 'lib-file-reviewer',
  standalone: true,
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    FileSchemaViewerComponent
  ],
  templateUrl: './file-reviewer.component.html',
  styleUrl: './file-reviewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileReviewerComponent {
  
  route = inject(ActivatedRoute)
  firebase = inject(FirebaseService)
  userService = inject(UserService)
  fileFlowService = inject(FileflowService)
  docUrl$ = new BehaviorSubject('')

  fileDocId$ = this.route.paramMap.pipe(map(params => params.get('docId')))
  
  file$ = combineLatest([ this.fileDocId$, this.userService.user$ ])
    .pipe(
      filter(([ fileDocId, user ]) => !!fileDocId && !!user),
      switchMap(([ fileDocId, user ]) => this.firebase.doc$(getUserFilePath(user!.docId, fileDocId as string), flowFileConverter)),
      map(file => file as FlowFile),
      tap(async (file) => {
        if (!this.docUrl$.getValue()) {
          const url = await this.fileFlowService.getFileContentsAsLocalURL(file!.storageName)
          this.docUrl$.next(url)
        }
      })
    )
}
