import { ChangeDetectionStrategy, Component, effect, inject, input, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AUTH_FUNCTIONS, FirestoreCollectionTypes, GroupConverter, UserRecord } from '@fidoc/shared';
import { JsonFormComponent, UtilityService } from '@fidoc/util';
import { FirebaseService, UserService } from '@fidoc/util';
import { orderBy, where } from '@angular/fire/firestore';
import { BehaviorSubject, debounceTime, map, shareReplay, tap } from 'rxjs';
import { IonContent, IonModal } from "@ionic/angular/standalone";
import { JsonFormData } from '@cheaseed/node-utils';
import { GroupService } from '@fidoc/groups';
import { CustomerViewComponent } from '../customer-view/customer-view.component';
import { BreadcrumbStripComponent } from '../nav/breadcrumb-strip.component';

@Component({
  selector: 'lib-partner-view',
  standalone: true,
  imports: [
    IonModal, 
    CommonModule,
    IonContent,
    JsonFormComponent,
    CustomerViewComponent,
    BreadcrumbStripComponent
  ],
  templateUrl: './partner-view.component.html',
  styleUrl: './partner-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerViewComponent {
    userService = inject(UserService)
    utilityService = inject(UtilityService)
    firebase = inject(FirebaseService)
    groupService = inject(GroupService)
    partner = input.required<UserRecord>()
    customers = signal<any[]>([]);
    selectedCustomer = signal<any>(null)
    provisionCustomer$ = new BehaviorSubject(false)
    provisionCustomerFormData: JsonFormData = {
        controls: [ 
            {
                name: 'customer',
                type: 'text',
                validators: {
                    required: true,
                    minLength: 3
                },
                label: 'Customer Name'
            }
        ]
    };

    constructor() {
        effect(() => {
            const partnerId = this.partner()?.docId;
            if (partnerId) {
                this.firebase.collection$(
                    FirestoreCollectionTypes.GROUPS_COLLECTION,
                    where('ownerUserId', '==', partnerId),
                    orderBy('createdAt', 'desc')
                )
                .pipe(           
                    debounceTime(300),         
                    map(groups => GroupConverter.fromArray(groups)),
                    tap(groups => console.log('retrieved partner groups', groups, partnerId)),
                    shareReplay(1)
                )
                .subscribe(result => {
                    this.customers.set(result);
                });
            }
        });
    }

    createCustomer() {
        this.provisionCustomer$.next(true)
    }

    deleteCustomer(cust: any) {
        console.log('deleteCustomer')
    }

    async confirmCustomerCreation(data: { customer: string }) {
        console.log('confirmCustomerCreation', data)
        const loading = await this.utilityService.loading(`Creating API key for customer <b>${data.customer}</b>`)
        try {
            const res = await this.firebase.awaitCloudFunction('firebaseAuthFunctions', { function: AUTH_FUNCTIONS.CREATE_APIKEY, customer: data.customer })
            const keyData: any = res.data
            console.log('API Key created:', keyData)
            await this.groupService.createGroup(data.customer, this.partner(), keyData?.keyString)
        }
        catch (e: any) {
            console.error('confirmCustomerCreation error', e)
            await this.utilityService.notify({ header: 'Customer creation failed', message: e.message })
        }
        finally {
            loading.dismiss()
        }
    }
}
