<div class="p-8 flex flex-col gap-4">
    <h1 class="text-xl w-full">{{ message1() }}</h1>
    <div class="text-xl">{{ message2() }}</div>
    <div class="flex flex-col items-start justify-start w-full gap-0 text-xs">
        <ion-radio-group [value]="selection()" (ionChange)="selection.set($event.detail.value)">
            @for (pipeline of toolsService.getPipelines(); track pipeline) {
                <ion-item>
                    <ion-radio 
                        labelPlacement="end" 
                        justify="start"
                        mode="md"
                        value="{{pipeline.name}}">
                        <span class="text-base whitespace-normal">{{ pipeline.description }}{{ pipeline.isDefault ? ' (default)' : '' }}</span>
                    </ion-radio>
                </ion-item>
            }
            <ion-item>
                <ion-radio 
                    labelPlacement="end" 
                    justify="start"
                    mode="md"
                    value="none">
                    <span class="text-base whitespace-normal">none</span>
                </ion-radio>
            </ion-item>
        </ion-radio-group>                       
    </div>
    <div class="pt-8 flex items-center justify-center gap-8">
    <button mat-raised-button color="secondary" (click)="dismiss.emit(true)">Cancel</button>
    <button mat-raised-button color="primary" (click)="submit.emit(selection()); dismiss.emit(true)">Submit</button>  
    </div>
</div>