/**
 * Constants used by cloud functions and the fidocs app
 * TODO - migrate the constants.ts file under node-utils here
 */
export const MAX_OPENAI_RETRIES = 4
export const DEFAULT_OPENAI_TIMEOUT = 540000 // 9 minutes to get one response from OpenAI

export enum AUTH_FUNCTIONS  {
    GEN_PASSWORD_LINK = "generatePasswordLink",
    GET_SIGNIN_METHODS = "getSigninMethods",
    CREATE_CUSTOM_TOKEN = "createToken",
    OBLITERATE_USER = 'obliterateUser',
    CREATE_APIKEY = 'createAPIKey'
}

export enum GATEWAY_API_FUNCTIONS {
    CREATE = "create",
    UPDATE = "update",
    DELETE = "delete",
    DESCRIBE = "describe",
    GET = "get",
    LIST = "list"
}
export const FIREBASE_AUTH_CLOUD_FUNCTION = "firebaseAuthFunctions"
export const TOOLS_PIPELINE_TOPIC = "fidocs-tools-pipeline"
export const UNDERSCORE = "_"
export enum FirestoreCollectionTypes {
    USERS = "users",
    PAYMENTS = "payments",
    DOMAINS_COLLECTION = "domains",
    FILEFLOW_COLLECTION = "flowfiles",
    COSTS_COLLECTION = "costs",
    DEFAULTS_COLLECTION = "defaults",
    GROUPS_COLLECTION = "groups",
    CONTENT_COLLECTION = "content"
}

export const ADMIN_CERTIFIED = 'fidocs-admin-certified'
export interface MessagePayload  {
    userId: string
    flowFileDocId: string
    pipelineName: string
    index?: number // the index of the next tool in the pipeline's tools collection

}
export interface BatchMessagePayload  {
    userId: string
    flowFileDocIds: string[]
    pipelineName: string
    index?: number // the index of the next tool in the pipeline's tools collection
}

export const API_NAME = 'fidocs-api'
export const API_GATEWAY_NAME = 'fidocs-gateway'
export interface APIJob {
    status: 'processing' | 'error' | 'complete'
    statusDescription: string
    jobId?: string
    inputFileType: string
    fileName: string
    //outputFileName: string
    partnerId: string
    customerId: string
    clientId: string
    //outputType?: string
    callbackURL?: string
    createdAt: Date
    updatedAt: Date
    completedAt?: Date,
    flowFileId?: string
}

//groups
export const MAX_GROUP_NAME_LENGTH = 40
export const MIN_GROUP_NAME_LENGTH = 10
export const GROUP_LEDGER_COLLECTION_NAME = "groupLedger"
export const GROUP_INVOICES_COLLECTION_NAME = "invoices"
export const GROUP_BALANCE_LOW = 200

// enterprise trial users
export const LOGIN_PARAMS_KEY = 'loginParameters'
export const ENTERPRISE_TRIAL_PERIOD = 1 
export const ENTERPRISE_TRIAL_PAGES = 25