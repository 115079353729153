import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultMessageComponent, DefaultsService, FileDropComponent, FirebaseService, UserService } from '@fidoc/util';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileflowService } from './fileflow.service';
import { ToolsService } from './tools.service';
import { FileSummaryComponent } from '../file-summary/file-summary.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, filter, map, switchMap, firstValueFrom } from 'rxjs';
import { getUserFilePath, STRIPE_SUBSCRIPTION_INVALID_STATUSES, UserRecord } from '@fidoc/shared';
import { FlowFile, FlowPipeline, flowFileConverter } from '@fidoc/shared'
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { UtilityService } from '@fidoc/util';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { PipelineSelectorComponent } from '../pipeline-selector/pipeline-selector.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FolderMenuComponent } from '../folder-menu/folder-menu.component';

const SUPPORTED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png']

@Component({
  selector: 'lib-fileflow',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FileDropComponent,
    FileSummaryComponent,
    FormsModule,
    IonModal,
    IonContent,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    PipelineSelectorComponent,
    DefaultMessageComponent,
    FolderMenuComponent
],
  templateUrl: './fileflow.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileflowComponent {
  route = inject(ActivatedRoute);
  router = inject(Router)

  fileFlowService = inject(FileflowService);
  defaultsService = inject(DefaultsService);
  toolsService = inject(ToolsService);
  userService = inject(UserService);
  firebase = inject(FirebaseService);
  droppedFiles = signal<FileSystemFileEntry[]>([]);
  filesToUpload = computed(() => {
    const files:File[] = []
    for (const file of this.droppedFiles())
      file.file(f => { 
        if (SUPPORTED_FILE_TYPES.includes(f.type)) {
          files.push(f)
        }
      })
      return files
    })
  utilityService = inject(UtilityService);
  selectedPipeline = ''
  filesSubmitted = signal(false)
  openPipelineSelector = signal(false)
  endTrialPeriod$ = new BehaviorSubject<any>(null)

  sharedId$ = this.route.paramMap.pipe(map(params => params.get('sharedId')))
  sharedFile$ = this.sharedId$
    .pipe(
      filter(sharedId => !!sharedId),
      map(sharedId => {
        const { user, docId } = JSON.parse(window.atob(sharedId as string))
        return { user, docId }
      }),
      switchMap(({ user, docId }) => {
        return this.firebase.doc$(getUserFilePath(user, docId), flowFileConverter)
          .pipe(
            map(file => file as FlowFile))
      })
    )

  constructor(
    @Inject('environment') private environment: any
  ) {

    effect(() => {
      const queue = this.fileFlowService.uploadedFileReadyQueue();
      const isPubSub = this.userService.isPublishSubscribe()
      // FOr pub/sub execution we will batch the entire queue up later
      if (queue.length > 0) {        
        if (!isPubSub) {
          const file = queue.shift();
          if (file) {
            this.handleUploadedFile(file);
          }
          this.fileFlowService.uploadedFileReadyQueue.set(queue);
        }
        else if (isPubSub && queue.length === this.filesToUpload().length) {
          this.clearDroppedFiles()
          // the publish will clear the queue after it calls the cloud function
          this.fileFlowService.publishMessages(queue, this.selectedPipeline)
        }
      }
    });

    this.endTrialPeriod$
      .pipe(
        filter(state => !!state))
      .subscribe(async (info) => {
        const { user, files } = info
        const userDocId = user.docId
        const loading = await this.utilityService.loading(`Ending trial`)
        await this.userService.endTrialPeriod(user)
        loading.dismiss()
        this.uploadFiles(files, userDocId)
        this.endTrialPeriod$.next(null)
      })
  }

  private async handleUploadedFile(file: FlowFile) {
    const pipeline = this.toolsService.getPipeline(this.selectedPipeline) as FlowPipeline
    console.log('handleUploadedFile', file.fileName, this.selectedPipeline)
    await this.toolsService.executePipeline(pipeline, file)
    this.fileFlowService.updateFile(file, { state: 'idle', stateDescription: 'uploaded' })
    return file
  }

  private clearDroppedFiles() {
    this.droppedFiles.set([])
  }

    dropFiles(files: FileSystemFileEntry[]) {
        //reject if a previous drop is in progress
        if (this.droppedFiles().length != 0) {
            console.log('droppedFiles', this.droppedFiles().length)
            this.utilityService.presentToast('An upload is already in progress. Please try later', { duration: 3000})
        }
        else if (files.length === 0) {
            console.log('No files dropped')
        }
        else {
            // console.log('droppedFiles', files)
            const user = this.userService.user() as UserRecord
            this.droppedFiles.set(files)
            if (user?.currentRole === 'admin') {
                this.filesSubmitted.set(false)
                this.openPipelineSelector.set(true)
            }
            else
                this.submitFiles(files, user?.defaultPipeline)
        }
    }

    pipelineSelectorDismissed() {
        // console.log('pipelineSelectorDismissed')
        this.openPipelineSelector.set(false)
        if (!this.filesSubmitted())
            this.clearDroppedFiles()
    }

  getFileAsPromise(fileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) => {
      fileEntry.file(
        (file) => resolve(file),
        (error) => reject(error)
      );
    });
  }

  async submitFiles(files: FileSystemFileEntry[], selectedPipeline: string | undefined) {
    console.log('submitting files', files, selectedPipeline)
    this.filesSubmitted.set(true)
    const user = this.userService.user() as UserRecord
    const userDocId = user.docId
    if (!selectedPipeline && !user.isAdmin) {
      const defaultPipeline = this.toolsService.getDefaultPipeline()
      console.log('defaultPipeline', defaultPipeline)
      await this.userService.updateUser(user, { defaultPipeline: defaultPipeline?.name })
      selectedPipeline = defaultPipeline?.name
    }
    this.selectedPipeline = selectedPipeline as string
    let message: string, showManageSub = false
    const info = user.groupDocId ? (await firstValueFrom(this.userService.getGroup(user.groupDocId))).subscriptionInfo : user.subscriptionInfo
    const pageCount = await this.computePageCount()
    const currPageBalance = await this.userService.getPageBalance()
    const pageBalanceTooLow = pageCount > currPageBalance
    console.log({ pageCount, currPageBalance, pageBalanceTooLow })

    const enterpriseTrialEvalEnded = user.enterpriseTrial && new Date(user.enterpriseTrial.evalEndDate!) < new Date()
    const isFreeTrialUser = !!user?.enterpriseTrial?.isFreeTrial
    if (user.enterpriseTrial) {
      if (enterpriseTrialEvalEnded) {
        const messageKey = isFreeTrialUser ? 'freeTrialUser.evalPeriodEnded.message' : 'enterpriseTrialUser.evalPeriodEnded.message'
        message = this.defaultsService.getDefault(messageKey, [this.environment.supportEmail, this.environment.supportEmail])
        this.clearDroppedFiles()
        if (!isFreeTrialUser) {
          await this.utilityService.notify({ header: 'Enterprise Trial Period Expired', message })
          return
        }
        else
          await this.showEnterpriseTrialConfirmation('Free Trial Period Expired', message)
          return
      }
      else if (pageBalanceTooLow) {
        const messageKey = isFreeTrialUser ? 'freeTrialUser.noPageBalance.message' : 'enterpriseTrialUser.noPageBalance.message'
        message = this.defaultsService.getDefault(messageKey, [this.environment.supportEmail, this.environment.supportEmail])
        this.clearDroppedFiles()
        if (!isFreeTrialUser) {
          await this.utilityService.notify({ header: 'Insufficient Page Balance', message })
          return
        }
        else {
          await this.showEnterpriseTrialConfirmation('Insufficient Page Balance', message)
          return
        }
      }
    }
    if (pageBalanceTooLow) {
      if (info?.status === 'canceled') {
        message = this.defaultsService.getDefault('paymentCancelled.message')
        showManageSub = true
      }
      else if (!info) {
        if (!user.groupDocId) {
          message = this.defaultsService.getDefault('requireSubscription.message', [files.length, pageCount])
          showManageSub = false
        }
        else {
          message = this.defaultsService.getDefault('requireSubscriptionGroup.message', [files.length, pageCount])
          showManageSub = true
        }
      }
      else if ((STRIPE_SUBSCRIPTION_INVALID_STATUSES.includes(info?.status as string)) || info?.cancelled) {
        message = this.defaultsService.getDefault('paymentFailed.message')
        showManageSub = true
      }
      else if (info?.status === 'trialing')
        message = this.defaultsService.getDefault('exceedTrial.message', [pageCount])
      else
        message = this.defaultsService.getDefault('exceedPageBalance.message', [files.length, pageCount])

      if (showManageSub) {
        if (!user.groupDocId) {
          await this.utilityService.confirm({
            header: 'Subscription Status Invalid',
            message,
            yesLabel: 'Manage Subscription',
            noLabel: 'Cancel Upload',
            confirm: () => {
              this.userService.manageStripeSubscription(user)
              this.clearDroppedFiles()
            },
            cancel: () => {
              this.clearDroppedFiles()
            }
          })
        }
        else {
          // Group subscription
          // if not group owner, show message asking the user to contact the group owner
          // otherwise 
          const group = await firstValueFrom(this.userService.getGroup(user.groupDocId))
          message = this.defaultsService.getDefault('insufficientGroupPageBalance.message',
            [group.ownerUserId === user.docId ? this.environment.supportEmail : group.ownerUserId])

          await this.utilityService.notify({
            header: 'Subscription Status Invalid',
            message,
            cancel: () => {
              this.clearDroppedFiles()
            }
          })
        }
      }
      else {
        await this.utilityService.confirm({
          header: 'Page balance exceeded',
          message,
          yesLabel: 'OK',
          noLabel: 'Cancel',
          confirm: () => {
            // Set signal to end trial and upload files          
            if (!info) {
              this.clearDroppedFiles()
              this.router.navigate(['/pricing'])
            }
            else if (info?.status === 'trialing') {
              this.clearDroppedFiles()
              this.endTrialPeriod$.next({ user, files })
            }
            else if (info?.status === 'canceled') {
              //this really wont happen since we have already checked for cancel
              //above. Leaving it in here for now
              this.userService.manageStripeSubscription(user)
              this.clearDroppedFiles()
            }
            else
              this.uploadFiles(files, userDocId)
          },
          cancel: () => {
            this.clearDroppedFiles()
          }
        })
      }
    }
    else {
      this.uploadFiles(files, userDocId)
    }

  }

  async showEnterpriseTrialConfirmation(header: string, message: string) {
    await this.utilityService.confirm({
      header: header,
      yesLabel: 'OK',
      noLabel: 'Cancel',
      message,
      confirm: () => {          
        this.clearDroppedFiles()
        this.router.navigate(['/pricing'])
      },
      cancel: () => {
        this.clearDroppedFiles()
      }
    })
  }

  async computePageCount() {
    let numPages = 0
    for (const fileEntry of this.droppedFiles()) {
      const file = await this.getFileAsPromise(fileEntry)
      if (SUPPORTED_FILE_TYPES.includes(file.type)) {
        try {
          const result = await this.fileFlowService.examineFile(await file.arrayBuffer(), file.type);
          numPages += result.numPages
        }
        catch (err) {
          console.warn(`Error computing page count for file ${file.name}`, err)
        }
      }
    }
    return numPages
  }

  uploadFiles(files: FileSystemFileEntry[], userDocId: string) {
    // Upload all files
    const pipeline = this.toolsService.getPipeline(this.selectedPipeline)
    if (files.length > 0) {
      const pipelineDesc = pipeline?.description
      this.utilityService.presentToast(
        `Evaluating ${files.length == 1
          ? ('<b>' + files[0].name + '</b>')
          : (files.length + ' files for queuing ')} ${pipelineDesc ? 'using <b>' + pipelineDesc + '</b>' : ''}`,
        { duration: 4000 })
    }  
    console.log('filesToUpload', this.filesToUpload())
    console.log('files', files)
    const isPubSub = this.userService.isPublishSubscribe()
    if (!isPubSub)
      this.clearDroppedFiles()
    for (const fileEntry of files) {
      fileEntry.file((file: File) => {
        // Only allow file types for PDF, JPEG, PNG
        if(SUPPORTED_FILE_TYPES.includes(file.type))
          this.fileFlowService.uploadFile(userDocId, file, this.selectedPipeline)
      })
    }
  }

  async addFolder() {
    await this.utilityService.prompt({
      message: 'Enter the name of the new folder',
      inputType: 'text',
      confirm: async (name: any) => {
        try {
          if (this.fileFlowService.flowFiles().find(f => f.isFolder && f.fileName === name.value)) {
            throw new Error(`Folder ${name.value} already exists`)
          }
          else {
            const user = this.userService.user() as UserRecord
            await this.fileFlowService.addFile({
              isFolder: true,
              userDocId: user.docId,
              fileName: name.value,
              state: 'idle',
              createdAt: new Date()
            })
          }
        }
        catch (e: any) {
          this.utilityService.notify({ message: e.message })
        }
      }
    })
  }
}
