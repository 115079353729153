import { Injectable, inject, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { map, firstValueFrom, debounceTime } from 'rxjs';
import { QueryDocumentSnapshot } from '@angular/fire/firestore';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface DefaultRecord {
  docId?: string;
  key: string
  value: any
  updatedAt?: Date
  lastUpdateBy?: string;
}

export const DefaultsRecordConverter = {    
  toFirestore(sub: DefaultRecord) { 
    return { ...sub } 
  },
  fromFirestore(snapshot: QueryDocumentSnapshot): DefaultRecord {
    return this.fromFirestoreData(snapshot.data())
  },
  fromFirestoreData(data: any): DefaultRecord {
    if (data) {
      data.updatedAt = data.updatedAt?.toDate()
      return data as DefaultRecord
    }
    else
      return {} as DefaultRecord
  },
  fromArray(data: any[]): DefaultRecord[] {
    return data.map(d => this.fromFirestoreData(d))
  }
}

export const DEFAULTS_COLLECTION = 'defaults'

@Injectable({
  providedIn: 'root'
})
export class DefaultsService {

  auth = inject(AuthService)
  firebase = inject(FirebaseService)
  defaults = signal<Map<string, DefaultRecord>>(new Map())

  allDefaults$ = this.firebase.collectionWithConverterNoAuth$(DEFAULTS_COLLECTION, DefaultsRecordConverter)
    .pipe(
      debounceTime(100),
      map((result:DefaultRecord[]) => result.toSorted((a, b) => a.key < b.key ? -1 : 1))
    )

  constructor() {
    this.allDefaults$      
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(defaults => {
        // console.warn("defaults cached", defaults)
        this.defaults.set(new Map<string, DefaultRecord>(defaults.map(d => [d.key, d])))
      })
  }

  private getDefaultsPath(id:string|null = null) {
    return `${DEFAULTS_COLLECTION}/${id}`
  }

  async updateDefault(rec: DefaultRecord, data: any) {
    const path = this.getDefaultsPath(rec.key)
    const user = await firstValueFrom(this.auth.user$)
    await this.firebase.updateAt(path, { ...data, updatedAt: new Date(), lastUpdateBy: user?.email }) 
  }

  async deleteDefault(rec: DefaultRecord) {
    const path = this.getDefaultsPath(rec.key)
    await this.firebase.delete(path)
  }

  substituteArgs(value: string, args?: any[]) {
    let val = value
    if (args) {
      args.forEach((arg, index) => {
        val = val.replace(/\$\w+/, arg)
      })
    }
    return val
  }

  getDefault(key: string, args?: any[]) {
    const doc = this.defaults().get(key)
    return doc ? this.substituteArgs(doc.value, args) : `undefined key: ${key}`
  }
}
