import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FileflowService } from '../fileflow/fileflow.service';
import { FlowFile } from '@fidoc/shared';
import { UserService } from '@fidoc/util';

@Component({
  selector: 'lib-folder-menu',
  standalone: true,
  imports: [
    CommonModule, 
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './folder-menu.component.html',
  styleUrl: './folder-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FolderMenuComponent {
  fileFlowService = inject(FileflowService);
  userService = inject(UserService);
  folder = input.required<FlowFile>()
}
