/* eslint-disable @typescript-eslint/no-explicit-any */

export function pick(obj: any, keys: string[]): any {
  return keys.reduce((o, k) => { o[k] = obj[k]; return o }, {})
}

// Return a dictionary of counts of each unique selector in the array
export function frequencies(arr: any[], selector: (x:any) => any): any {
  return arr.reduce((cnts, elem) => {
    const val = selector(elem)
    cnts[val] = (cnts[val] || 0) + 1
    return cnts
  }, {})
}

export function splitTrim(s: string): string[] {
  return s?.split(',').map(s => s.trim()) || []
}

// Handle both comma and newline separated values
export function splitTrim2(s: string): string[] {
  return s?.split(/[,\n]/).map(s => s.trim()) || []
}

export function stringifyKeysInOrder(obj: any) {
  const replacer = (key:string, value:any) =>
    value instanceof Object && !(value instanceof Array) 
      ? 
        Object.keys(value)
        .sort()
        .reduce((sorted:any, key) => {
          sorted[key] = value[key]
          return sorted 
        }, {}) 
    : value
  return JSON.stringify(obj, replacer, 2)
}

export function upperFirst(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function articlize(s: string): string {
  return ('AEIOU'.includes(s?.charAt(0)) ? 'an ' : 'a ') + s
}

// Replacement for lodash.range
export const range = (start: number, end: number, step: number = 1) => {
  return Array.from({length: (end - start)/step + 1}, (_, i) => (i + start) * step);
}

export const ellipsify = (str: string, maxLength: number) => {
  return str.length > maxLength ? str.substring(0, maxLength) + " ..." : str
}

// For comparing objects of depth > 1

export function isObject(object: unknown): boolean {
  return object != null && typeof object === 'object';
}

export function areObjectsEqual(object1: any, object2: any): boolean {
  const keys1 = Object.keys(object1 || {})
  const keys2 = Object.keys(object2 || {})
  if (keys1.length !== keys2.length)
    return false

  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (areObjects) {
      if (!areObjectsEqual(val1, val2))
        return false
    }
    else if (val1 !== val2)
      return false
  }
  return true
}

export function evaljson(val: string) {
  try {
    return (typeof val === 'string' ? JSON.parse(val) : val)
  }
  catch (err) {
    console.error(err)
    return null
  }
}

export function canShareOnWeb() {
  try {
    if (!navigator.share) { // !navigator.canShare
      return false
    }
    else {
      // const result = navigator.canShare({})
      // console.log('testing canShare()', result)
      return true
    }
  }
  catch (err) {
    console.warn(err)
    return false
  }
}

export function convertToPipeSeparatedValues(data: any[], headers: string[]): string {
  return '|' + headers.map(h => `${h}`).join('|') + "\n" +
    data.map(row => '|' + headers.map(header => `${row[header] || ''}`).join('|')).join('\n')
}

export function convertToCsv(data: any[], headers: string[]): string {
  return headers.map(h => `"${h}"`).join(',') + "\n" +
    data.map(row => headers.map(header => `"${row[header] || ''}"`).join(',')).join('\n')
}

export async function downloadCSV(rows: any[], headers: string[], filename: string) {    
  const data = convertToCsv(rows, headers)
  downloadFile(data, filename, 'text/csv')
}

export function getBlobURL(data: string, type: string) {
  const blob = new Blob([data], { type });
  return window.URL.createObjectURL(blob)
}

export function downloadFile(data: string, filename: string, type: string) {
  const url = getBlobURL(data, type)
  const anchor = document.createElement("a")
  anchor.download = filename
  anchor.href = url
  anchor.click()
  return url
}

/**
 * Recursively Retries a function maxTry number of times before giving up.
 * If we need a more sophisticated retry related API later, we can install
 * the npm package `ts-retry`
 * This function was taken from https://tusharf5.com/posts/type-safe-retry-function-in-typescript/
 */
export async function retry<T extends (...arg0: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  maxTry: number,
  retryCount = 1): Promise<Awaited<ReturnType<T>>> {
  const currRetry = typeof retryCount === 'number' ? retryCount : 1;
  try {
    if (currRetry > 1)
      console.log(`Retrying ${currRetry} of ${maxTry}`)
    const result = await fn(...args);
    return result;
  }
  catch (e) {
      console.warn(`Retry ${currRetry} failed.`);
      if (currRetry > maxTry) {
        console.error(`All ${maxTry} retry attempts exhausted, throwing error`);
        throw e;
      }
      return retry(fn, args, maxTry, currRetry + 1);
  }
}
