import Stripe from 'stripe'
const STRIPE_TEST_PUBLISHABLE_KEY = 'pk_test_51Q2RJaJc8FXHjVD6QadT3QNfKgJclljxGYJgwoED1VwP9VLqe7ve2ibX4NPSVaSS3yUjvQ1K1u86ktg4BLYyW0MP00MCQqI1r3'
const STRIPE_PUBLISHABLE_KEY = 'pk_live_51Q2RJaJc8FXHjVD6ZTVGnDVIVlk7zCu0SmECvEfGkWeFWab5lkcvYdUXxLBLh7SXD14aj4lpk69JcnopoOkdjGHB00eUoipEcQ'
const STRIPE_SECRET_KEY = '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'
const STRIPE_TEST_SECRET_KEY = 'sk_test_51Q2RJaJc8FXHjVD6FgWnSP77nq1oiyKSObPXb7oITlswpEKD2vK5riYExjDiCI5dVNLt7ZSRkmf2FtK7f7lZRHPd00Q1YLdifq'
const STRIPE_TEST_ENDPOINT_SECRET = 'whsec_azR4hQbUwhKVnLWk4BsmaKLkoy04KN1a'
const STRIPE_ENDPOINT_SECRET = 'whsec_i9NZDas968FAENBlM1SmxG9lHo2Zm6i7'
export const TRIAL_PAGE_BALANCE = 25

export const STRIPE_generic_template_id = 'd-ed90adc8ce534fe39f881eec702f4f01'
export const STRIPE_magic_link_template_id = 'd-d46903a81add4e7bbca626c196f671d5'
export const STRIPE_welcome_template_id = 'd-96f729b3cbaa4788895d9d3a80121732'
export const STRIPE_enterprise_welcome_template_id = 'd-b84a19d9eb0b45cbaad9b9ae4c24666e'
export const STRIPE_unsubscribe_group_id = 25239

//These are set up in stripe - need to keep them in sync
export const MONTHLY_METER_EVENT_NAME = 'monthly_meter'
export const YEARLY_METER_EVENT_NAME = 'yearly_meter'

// global meter id's from Stripe
//TODO - need to retrieve based on env - dev or prod. These are in dev currently
export const MONTHLY_METER_ID = 'mtr_test_61RKHoRMPBLW26IzU41Jc8FXHjVD67Dk'
export const YEARLY_METER_ID = 'mtr_test_61RKHZz0cGOm4f7Lt41Jc8FXHjVD6I2S'
export const COUPON_CODE = 'FIDOCS50'
export const STRIPE_SUBSCRIPTION_INVALID_STATUSES = ['incomplete', 'incomplete_expired', 'past_due', 'unpaid', 'paused']
export interface SubscriptionInfo {
  subscriptionId: string
  productId: string
  customerId: string
  invoiceId?: string
  meterId?: string
  status: Stripe.Subscription.Status
  interval?: Stripe.Plan.Interval 
  trialStartDate?: Date
  trialEndDate?: Date
  pagesAllocated?: number
  subscriptionStartDate?: Date
  subscriptionEndDate?: Date
  hasPaid: boolean,
  failureData?: Stripe.Charge.Outcome
}
export function getStripeSecretKey(env: string) {
    // console.log('Env = ', env)
    return env === 'dev'
        ? STRIPE_TEST_SECRET_KEY
        : STRIPE_SECRET_KEY
  }

  export function getStripePublishableKey(env: string) {
    return env === 'dev' ?
        STRIPE_TEST_PUBLISHABLE_KEY
        : STRIPE_PUBLISHABLE_KEY
  }

  export function getStripePaymentLinkEndPointSecret(env: string) {
    return env === "dev" ?
        STRIPE_TEST_ENDPOINT_SECRET
        : STRIPE_ENDPOINT_SECRET
  }
