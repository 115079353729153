import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirebaseService } from '@fidoc/util';
import { CostRecordConverter, FirestoreCollectionTypes } from '@fidoc/shared';
import { limit, orderBy, startAfter, QueryConstraint } from '@angular/fire/firestore';
import { debounceTime, shareReplay, BehaviorSubject, firstValueFrom } from 'rxjs';
import { downloadCSV } from '@cheaseed/node-utils';
import { FileflowService } from '@fidoc/fileflow';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'lib-cost-table',
  standalone: true,
  imports: [
    CommonModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './cost-table.component.html',
  styleUrl: './cost-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostTableComponent {
  firebase = inject(FirebaseService);
  fileflowService = inject(FileflowService);

  // Pagination properties
  currentPage = signal(1); // Convert currentPage to a signal
  itemsPerPage: number = 25; // Number of items per page
  lastVisibleValue: any = null; // Tracks the last document's field value for pagination
  costs = signal<any[]>([])
  numCosts = computed(() => this.costs().length)

  // Computed properties for start and end record numbers
  startRecord = computed(() => (this.currentPage() - 1) * this.itemsPerPage + 1);
  endRecord = computed(() => Math.min(this.startRecord() + this.numCosts() - 1, this.startRecord() + this.itemsPerPage - 1));

  // Sorting properties
  sortBy: { column: string, direction: 'asc' | 'desc' } = { column: 'loggedAt', direction: 'desc' }; // Default sorting

  ngOnInit(): void {
    this.fetchCosts();
  }

  // Fetch costs with server-side pagination and sorting
  fetchCosts(): void {
    console.log('Fetching costs with query:', {
      collection: FirestoreCollectionTypes.COSTS_COLLECTION,
      orderBy: this.sortBy.column,
      direction: this.sortBy.direction,
      startAfter: this.lastVisibleValue,
      limit: this.itemsPerPage
    });

    const queryConstraints: QueryConstraint[] = [
      orderBy(this.sortBy.column, this.sortBy.direction), // Dynamic sorting
      limit(this.itemsPerPage) // Limit to 250 items per page
    ];

    // Add startAfter only if lastVisibleValue is set
    if (this.lastVisibleValue) {
      queryConstraints.push(startAfter(this.lastVisibleValue));
    }

    this.firebase.collectionWithConverter$(
      FirestoreCollectionTypes.COSTS_COLLECTION,
      CostRecordConverter,
      ...queryConstraints
    ).pipe(
      debounceTime(200),
      shareReplay(1)
    ).subscribe({
      next: (costs) => {
        //console.log('Fetched costs:', costs); // Debugging
        this.costs.set(costs); // Update the BehaviorSubject with the new data
        if (costs.length > 0) {
          // Store the value of the sortBy column from the last document
          this.lastVisibleValue = costs[costs.length - 1][this.sortBy.column];
        }
      },
      error: (err) => {
        console.error('Error fetching costs:', err); // Debugging
      }
    });
  }

  // Handle sorting change
  onSortChange(column: string): void {
    if (this.sortBy.column === column) {
      // Toggle direction if the same column is clicked
      this.sortBy.direction = this.sortBy.direction === 'asc' ? 'desc' : 'asc';
    } else {
      // Set new column but keep the current direction
      this.sortBy.column = column;
    }

    console.log('Sorting by:', this.sortBy); // Debugging

    this.resetPagination(); // Reset pagination when sorting changes
    this.fetchCosts(); // Fetch data with new sorting
  }

  // Reset pagination
  resetPagination(): void {
    this.currentPage.set(1); // Update signal
    this.lastVisibleValue = null;
  }

  // Navigate to the next page
  nextPage(): void {
    this.currentPage.update(value => value + 1); // Update signal
    this.fetchCosts();
  }

  // Navigate to the previous page
  previousPage(): void {
    if (this.currentPage() > 1) {
      this.currentPage.update(value => value - 1); // Update signal
      this.fetchCosts();
    }
  }

  async exportCosts() {
    // By default, firebase will return the results of the previous query
    // Since we us limit() for the previously run query, running the same query
    // again gives the same subset from the local cache unless the source is set to 
    // server
      const allCosts$ = this.firebase.fetchCollectionDataFromServer(
        FirestoreCollectionTypes.COSTS_COLLECTION,
        CostRecordConverter,
        orderBy(this.sortBy.column, this.sortBy.direction))
      const costs = await firstValueFrom(allCosts$);
      console.log('COSTS', costs.length);
      const headers = [
        'user',
        'groupDocId',
        'loggedAt',
        'fileDocId',
        'fileName',
        'fileSize',
        'numPages',
        'stepName',
        'promptDocId',
        'credits',
        'cost',
        'azureModelUsed',
        'azureTokensUsed',
        'azureCost',
        'elapsedMsec'
      ];
      downloadCSV(costs, headers, 'all-costs.csv');
    }
}