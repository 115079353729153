import Stripe from "stripe"
import { getPerUnitPrice, getStripeSecretKey, INVOICE_OVERAGE_ITEM_DESCRIPTION, INVOICE_OVERAGE_MEMO, INVOICE_OVERAGE_TYPE, SubscriptionInfo } from "./stripe-constants"
import { UserOrGroup, UserRecord } from "./user.models"
import { LoggerInterface  } from "@cheaseed/node-utils"
import { getTimestamp } from "./utils"
import { Group } from "./group"

export async function getMeterEventSummary(stripe: Stripe, user: UserOrGroup,  start_time: number, end_time: number, logger?: LoggerInterface) {
  const subscriptionInfo = user.subscriptionInfo
  if(!subscriptionInfo) {
    logger?.warn('getMeterEventSummary', `No subscription information found for user ${user}`)
    return null
  }
  //if(subscriptionInfo.status !== 'active') {
  //  logger?.warn('getMeterEventSummary', `Subscription not active for user ${user}`)
  //  return
  //}
  if(!subscriptionInfo.meterId) {
    logger?.warn('getMeterEventSummary', `MeterId not found for user ${user}`)
    return null
  }
  //const stripe = getStripe(env)
  //const event_name = getMeterEventName(stripe)
  const identifier = subscriptionInfo.meterId
  //const start_time = start || subscriptionInfo.subscriptionStartDate
  //const end_time = end || subscriptionInfo.subscriptionEndDate
  logger?.log(`Start time: ${start_time}`)
  logger?.log(`identifier: ${identifier}, customer: ${subscriptionInfo.customerId}`)
  logger?.log(`End time: ${end_time}`)

  const summary = await stripe.billing.meters.listEventSummaries(identifier, {
    customer: subscriptionInfo.customerId,
    start_time, //: getTimestamp(start_time), // has to be aligned to minute boundaries
    end_time //: getTimestamp(end_time)
  })
  return summary.data[0]
}

/*export async function getMeterIdForProduct(productId: string, stripe: Stripe) {
  const tieredPrices = await getTieredPrices(stripe)
  const price = tieredPrices.find(p => p.product === productId)
  return price.recurring.meter
}
*/

export async function sendMeterEvent(env: string, entityId: string, subscriptionInfo: SubscriptionInfo, pages: number, logger?: LoggerInterface) {
  if(!subscriptionInfo) {
    logger?.warn('sendMeterEvent', `No subscription information found for user ${entityId}`)
    return
  }
  if(subscriptionInfo.status !== 'active') {
    logger?.warn('sendMeterEvent', `Subscription not active for user ${entityId}`)
    return
  }
  if(!subscriptionInfo.meterId) {
    logger?.warn('sendMeterEvent', `meterId not defined for user ${entityId}`)
    return
  }
  const stripe = getStripe(env)
  //const meterId = await getMeterIdForProduct(stripe, subscriptionInfo.productId)
  const meter = await stripe.billing.meters.retrieve(subscriptionInfo.meterId)
  const event_name = meter.event_name //getMeterEventName(subscriptionInfo.interval as string)
  //const identifier = getMeterEventId(event_name)

  const eventResponse = await stripe.billing.meterEvents.create({
    //identifier,
    event_name,
    payload: {
      pages: pages.toString(),
      stripe_customer_id: subscriptionInfo.customerId
    },
    timestamp: Math.floor(Date.now()/1000)
  })
  logger?.log('Response to event sent to stripe meter ', JSON.stringify(eventResponse))
  //const summary = await getMeterEventSummaries(env, user, subscriptionInfo.subscriptionStartDate, subscriptionInfo.subscriptionEndDate, logger)
  //logger?.log('Event Summary: ', JSON.stringify(summary))
}

export function getStripe(env: string) {
  return new Stripe(getStripeSecretKey(env))
}

export async function billOverage(env: string, userOrGroup: UserOrGroup, logger?: LoggerInterface, startDate?: Date, endDate?: Date) {
  const stripe = getStripe(env)
  const start = getTimestamp(startDate || userOrGroup.subscriptionInfo.subscriptionStartDate)
  const end = getTimestamp(endDate || userOrGroup.subscriptionInfo.subscriptionEndDate)
  const meterSummary = await getMeterEventSummary(stripe, userOrGroup, start, end, logger)
  logger.log(`Meter Summary for user ${userOrGroup.docId}`, JSON.stringify(meterSummary))
  const sub = await stripe.subscriptions.retrieve(userOrGroup.subscriptionInfo.subscriptionId) as Stripe.Subscription

  const stripeInvoiceParams: Stripe.InvoiceCreateParams = {
      customer: userOrGroup.subscriptionInfo.customerId,
      description: INVOICE_OVERAGE_MEMO,
      metadata: { type: INVOICE_OVERAGE_TYPE },
      automatic_tax: {enabled: true}
  }
  if(sub.default_payment_method) {
    stripeInvoiceParams.default_payment_method = sub.default_payment_method as string
    stripeInvoiceParams.collection_method = 'charge_automatically'
  }
  else {
    stripeInvoiceParams.collection_method = 'send_invoice'
    //30 days from now; in seconds; matches the 30 day period we have for enterprise subscription invoices we send
    //stripeInvoiceParams.due_date = Math.floor(Date.now() / 1000) + (30 * 24 * 60 * 60);
    stripeInvoiceParams.days_until_due = 30
  }
  if(userOrGroup.isGroup) {
    stripeInvoiceParams.metadata["groupDocId"] = (userOrGroup as Group).docId
  }
 
  let inv = await stripe.invoices.create(stripeInvoiceParams)
  
  logger.log(`Created invoice ${JSON.stringify(inv)}`)
  //const prices = await stripe.prices.list({active: true, product: user.subscriptionInfo.productId})
  
  const price = await getPerUnitPrice(userOrGroup.subscriptionInfo.productId, stripe)
  logger.log(`billOverage price object`, JSON.stringify(price))
  //TODO - to apply discount; can look it up from the subscription object
  const amountToBill = price.unit_amount*meterSummary.aggregated_value
  const invoiceItemParams: Stripe.InvoiceItemCreateParams = {
    description: INVOICE_OVERAGE_ITEM_DESCRIPTION, 
    quantity: meterSummary.aggregated_value, 
    unit_amount: price.unit_amount, 
    invoice: inv.id, 
    customer: userOrGroup.subscriptionInfo.customerId, 
    subscription: userOrGroup.subscriptionInfo.subscriptionId,
    period: { start, end }
    //, amount: amountToBill
  }
  
  let res = await stripe.invoiceItems.create(invoiceItemParams)
  logger.log(`Created invoice item ${JSON.stringify(res)}`)
  //inv = await stripe.invoices.finalizeInvoice(inv.id)
  //logger.log(`Finalized invoice ${JSON.stringify(inv)}`)
  const paidInvoice = stripeInvoiceParams.collection_method === 'send_invoice' ? 
    await stripe.invoices.sendInvoice(inv.id) : 
    await stripe.invoices.pay(inv.id)
  if(stripeInvoiceParams.collection_method === 'send_invoice')
    logger.log(`Sent Invoice for ${userOrGroup.name} and id ${userOrGroup.docId}`, JSON.stringify(paidInvoice))
  else
    logger.log(`Paid Invoice for ${userOrGroup.name} and id ${userOrGroup.docId}`, JSON.stringify(paidInvoice))

  return paidInvoice
}
