import { FileflowServiceInterface, FlowFile, FlowStep, FlowTool } from '../fileflow.interface';
import { UserRecord } from '../user.models';
import { DocuPandaAsImageTool } from './DocuPandaAsImageTool';
import { DocuPandaRemoveWatermarkTool } from './DocuPandaRemoveWatermarkTool';
import { DocuPandaTool } from './DocuPandaTool';

export class DocuPandaStandardizationTool implements FlowTool {
  name = 'standardization'
  description = 'Standardization'
  precedents: FlowTool[] = []
  type = 'standardization'
  startTime = 0
  

  flowService: FileflowServiceInterface

  constructor(flowService?: FileflowServiceInterface) {
    if(flowService)
      this.initialize(flowService)
  }
  
  initialize(flowService: FileflowServiceInterface) {
    this.flowService = flowService
    this.precedents.push(
      new DocuPandaTool(this.flowService), 
      new DocuPandaAsImageTool(this.flowService),
      new DocuPandaRemoveWatermarkTool(this.flowService)
    )
    return this
  }

  shouldPublish(): boolean {
      return true
  }
  checkExecute(file: FlowFile, user: UserRecord) {
    this.flowService.defaultCheckExecute(file, user)
  }

  getContentDisposition(fileName: string) {
    return  'inline;'
  }

  async execute(
    file: FlowFile,
    last: FlowStep | null,
    params?: any) {
      this.startTime = Date.now()
      const schemas:any = {}
      const domain = file.domainName || this.flowService.defaultDomainName()
      let documentId = file.externalDocumentId

      // If unknown documentId, get it from last step
      if (!documentId) {
        const output = await this.flowService.getDocupandaOutput(file)
        if (output)
          documentId = output.data.documentId
      }

      // Classify and take first result
      let result = (await this.flowService.classify( [ documentId ], domain ))[0]
      // If multi-class and multi-page, split
      if (file.numPages > 1 && result.assignedClassIds.length > 1) {
        const instructions = this.flowService.getSplitInstructions(file, result.assignedClassIds)
        const subdocIds = await this.flowService.split( documentId, instructions )
        const classifyResult = await this.flowService.classify( subdocIds, domain )
        for (const cls of classifyResult) {
          // Get classId for each subdocId
          result = await this.flowService.standardize(cls.documentId, domain)
          for (const res of result)
            schemas[res.className] = res.standardization
        }
      }
      else {
        result = await this.flowService.standardize(documentId, domain)
        for (const res of result)
          schemas[res.className] = res.standardization
      }
      // Attach standardizations to file
      await this.flowService.updateFile(file, { externalDocumentId: documentId, schemas })
      await this.flowService.updateStep(file, {
        name: this.name,
        description: this.description,
        elapsedMsec: Date.now() - (this.startTime || 0),
        state: 'complete',
        error: undefined,
        data: schemas
    });
    return true
  }
}