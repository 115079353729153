import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OptionItem, UserService, FirebaseService, JsonFormComponent, RuntimeConfigService } from '@fidoc/util';
import { BehaviorSubject } from 'rxjs';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { UtilityService } from '@fidoc/util';
import { JsonFormData } from '@cheaseed/node-utils';
import { UserRecord } from '@fidoc/shared';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'lib-actions',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    IonModal,
    IonContent,
    JsonFormComponent
],
  templateUrl: './actions.component.html',
  styleUrl: './actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsComponent {
    userService = inject(UserService)
    utilityService = inject(UtilityService)
    firebaseService = inject(FirebaseService)
    runtimeConfigService = inject(RuntimeConfigService)
    actionOptions = signal<OptionItem[]>([])
    provisionUser$ = new BehaviorSubject(false)
    provisionPartner$ = new BehaviorSubject(false)
    provisionUserFormData: JsonFormData = {
          controls: [ 
            {
              name: 'email',
              type: 'email',
              label: 'Email',
              errorText: "Must be a valid email address",
              validators: {
                required: true,
                email: true
              },
            },
            {
              name: 'user',
              type: 'text',
              validators: {
                required: true,
                minLength: 3
              },
              label: 'User Name',
            },
            {
              name: 'evalEndDate',
              type: 'date',
              validators: {
                required: true,
              },
              label: 'Evaluation End Date'
            },
            {
              name: 'pages',
              type: 'number',
              validators: {
                required: true,
                pattern: '^[0-9]+$'
              },
              label: 'Pages'
            }
          ]
        };
    
    constructor() {           
        this.actionOptions.set([
            {
                label: '',
                labelFunc: (user: UserRecord) => `${this.runtimeConfigService.isMaintenanceMode() ? "Exit" : "Enter"} Maintenance Mode`,
                action: (user: UserRecord) => this.runtimeConfigService.toggleMaintenanceMode()
            },
            {   label: '',
                labelFunc: (user: UserRecord) => `Remove groupDocId from ${user.docId}`,                
                action: (user: UserRecord) => this.userService.setGroupDocId(user.docId, null)
            },
            {   label: '', 
                labelFunc: (user: UserRecord) => `Toggle Current Role to ${user.currentRole === 'user' ? 'Admin' : 'User'}`,
                action: (user: UserRecord) => this.toggleRole(user) 
            },
            {   label: 'Provision New Enterprise Trial User', 
                action: (user: UserRecord) => this.provisionUser$.next(true)
            },
            // {   label: '(API Testing) Convert PDF or JPG to Base64 URI', 
            //     action: (user: UserRecord) => this.showBase64ConverterPage()
            // },
            // {  label: 'Generate API Key', 
            //   action: (user: UserRecord) => this.generateAPIKey()
            // },
        ])
    }

    async toggleRole(user: UserRecord) {
        await this.userService.updateUser(user, { currentRole: user.currentRole === 'user' ? 'admin' : 'user' })
    }
    showBase64ConverterPage() {
        window.open('assets/convertToBase64.html', '_blank')
    }

    async generateAPIKey() {
      const res = await this.firebaseService.awaitCloudFunction('firebaseAuthFunctions', {function: 'createAPIKey', customer: 'Test'})
      console.log('API Key response', res)
  }
}
