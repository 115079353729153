import { Alignment, Cell, Row, ValueType, Worksheet } from 'exceljs';
import { format as ssfFormat } from 'ssf';
import { dateToSerial, isRowBlank, NON_PRINTABLE_REGEX, TableCoords } from './utils';
import { FileflowServiceInterface } from './fileflow.interface';
import { UNDERSCORE } from './fidoc-constants';

const CELL_PADDING = 4;
// column widths when using the default excel body font of Calibri-11 gets overesitmated by about 25%
const HEURISTIC_PERCENTAGE = 0.75
const MAX_COLUMN_WIDTH = 64
const TABLE_TOP_SPACING = 1;
const MIN_COLUMNS = 10
const DEFAULT_TEXT_COLUMNS = 14


interface OCRData {
  pages: any
  pageNumber: number
  sectionNumber: number
}

//currently detected errors in the input JSON
enum TABLE_ERRORS {
  INVALID_COLUMN_NAME,
  COLUMN_ROW_VALUES_MISMATCH
}
interface ExcelTableErrorObject {
  type: TABLE_ERRORS
  data: any[]
}
/**
   * If a cell value currency or number is preceded by a + (say +58), keep the value as the 
   * number or currency and tweak the cell format to add the + sign. Similarly for values
   * such as .82x, move the x into the format. 
   * NOTE - this is NOT locale aware and will not work for a lot of non en-US locales
   */
interface HackInterface {
  newValue?: string
  formatPrefix?: string
  formatPostFix?: string
}
interface ExcelTableErrors {
  columnNameErrors: ExcelTableErrorObject,
  rowMismatchErrors: ExcelTableErrorObject
}
// formats for various types of cells in Excel

//TODO - this will be different if formatting for non-US currencies. will need to do a lookup?
const CURRENCY_INDICATOR = '$' 

const TEXT_FORMAT = '@'
const CURRENCY_ACCOUNTING_ZERO = `${CURRENCY_INDICATOR}-`
const CURRENCY_ZERO = `${CURRENCY_INDICATOR}`
const ACCOUNTING_ZERO = '-'
const EM_DASH = '—' // non-ascii character with code 8212
const CURRENCY_EM_DASH = `${CURRENCY_INDICATOR}${EM_DASH}`
const EM_DASH_ACCOUNTING_FORMAT=`#;-#;${EM_DASH};@`
const CURRENCY_EM_DASH_ACCOUNTING_FORMAT = `${CURRENCY_INDICATOR}#;-${CURRENCY_INDICATOR}#;${CURRENCY_INDICATOR}${EM_DASH};@`
const CURRENCY_ACCOUNTING_FORMAT = `_(${CURRENCY_INDICATOR}* #,##0.00_);_(${CURRENCY_INDICATOR}* (#,##0.00);_(${CURRENCY_INDICATOR}* "-"??_);_(@_)`
const ACCOUNTING_FORMAT = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'

const DECIMAL_PLACES_PLACE_HOLDER = '{N}'
const PERIOD = '.'
const COMMA = ','

const CURRENCY_FORMAT = `$#,##0_);($#,##0)`
const CURRENCY_FORMAT_PERIOD = `$#,##0${PERIOD}_);($#,##0${PERIOD})`
const CURRENCY_FORMAT_DECIMAL = `$#,##0.${DECIMAL_PLACES_PLACE_HOLDER}_);($#,##0.${DECIMAL_PLACES_PLACE_HOLDER})`
const CURRENCY_PLUS_FORMAT = `$+#,##0_);($+#,##0)`
const CURRENCY_PLUS_FORMAT_PERIOD = `$+#,##0${PERIOD}_);($+#,##0${PERIOD})`

const CURRENCY_PLUS_FORMAT_DECIMAL = `$+#,##0.${DECIMAL_PLACES_PLACE_HOLDER}_);($+#,##0.${DECIMAL_PLACES_PLACE_HOLDER})`

const PERCENTAGE_FORMAT = `0%;(0)%`
const PERCENTAGE_FORMAT_PERIOD = `0${PERIOD}%;(0${PERIOD})%`

const PERCENTAGE_FORMAT_DECIMAL = `0.${DECIMAL_PLACES_PLACE_HOLDER}%;(0.${DECIMAL_PLACES_PLACE_HOLDER})%`

const NEGATIVE_NUMBER_FORMAT = `#,##0_);(#,##0)`
const NEGATIVE_NUMBER_FORMAT_PERIOD = `#,##0${PERIOD}_);(#,##0${PERIOD})`

const NEGATIVE_NUMBER_FORMAT_DECIMAL = `#,##0.${DECIMAL_PLACES_PLACE_HOLDER}_);(#,##0.${DECIMAL_PLACES_PLACE_HOLDER})`

const NUMBER_FORMAT = `#,##0`
const NUMBER_FORMAT_PERIOD = `#,##0${PERIOD}`

const NUMBER_FORMAT_DECIMAL = `#,##0.${DECIMAL_PLACES_PLACE_HOLDER}`
const GENERAL_NUMBER_FORMAT = `0`
const GENERAL_NUMBER_FORMAT_PERIOD = `0${PERIOD}`

const GENERAL_NUMBER_FORMAT_DECIMAL = `0.${DECIMAL_PLACES_PLACE_HOLDER}`
const NEGATIVE_GENERAL_NUMBER_FORMAT = `0_);(0)`
const NEGATIVE_GENERAL_NUMBER_FORMAT_PERIOD = `0${PERIOD}_);(0${PERIOD})`
const NEGATIVE_GENERAL_NUMBER_FORMAT_DECIMAL = `0.${DECIMAL_PLACES_PLACE_HOLDER}_);(0.${DECIMAL_PLACES_PLACE_HOLDER})`
//Match 1234, 123,456, $123, $123,456, $ 100, OR (125), (123,456), ($125) ( $20), ( $ 30 )....
const CELL_VALUE_REGEX = /^(?:\+)?(?:\$|\$\+)?(?:\s*)?(?:[\(|\-])?(?:\s*)?(?:\$)?(?:\s*)?(?:\d+(?:,\d+)*(?:\%)?|\d+)(?:\.)?(?:\d+)?(?:\s*)?(?:\%)?(?:\))?(?:\s*)?(?:\%)?(?:\s*)?$/g
///^(\$)*(\s)*(?:\d+(?:,\d+)*|\d+)(?:\.\d+)?|^(\$)*(\s)*(\()(\s)*(?:\d+(?:,\d+)*|\d+)(?:\.\d+)?(\s)*(\))$/

export class ExcelUtils {
  flowService: FileflowServiceInterface
  constructor(flowService: FileflowServiceInterface) {
    this.flowService = flowService
  }
  // logging 
  log(...args: any) {
    this.flowService.log(...args);
  };
  warn(...args: any) {
    this.flowService.warn(args)
  }
  error(...args: any) {
    this.flowService.error(args)
  }
  debug(...args: any) {
    this.flowService.debug(args)
  }
  /**
     *
     * @param ws Calculate a suitable width for each column which all tables
     * on a sheet span
     * @param tableCoords
     */
  fixTableColumnWidths(ws: Worksheet, tableCoords: TableCoords) {
    this.log(
      `Fixing table widths for table ${tableCoords.table.name} startRow: ${tableCoords.startRow} numRows: ${tableCoords.numRows}`,
    );
    const columnMap = new Map<string, Cell[]>();
    const tableRows = ws.getRows(tableCoords.startRow, tableCoords.numRows) as Row[]

    tableRows?.forEach((row: Row, index) => {
      row.eachCell((cell) => {
        let arr = columnMap.get(cell.col);
        if (!arr) {
          arr = [cell];
          columnMap.set(cell.col, arr);
        } else arr.push(cell);
      });
    });
    columnMap.forEach((value, key) => {
      let maxLength = 5;
      value.forEach((cell) => {
        //TODO - will fail for dates and formulae
        const length = cell.value?.toString()?.length;
        if (length && length > maxLength) {
          maxLength = length;
        }
      });
      //this.log(
      //  `Max Length for column ${key} in table ${tableCoords.table.name}calculated as ${maxLength}`,
      //);
      const column = ws.getColumn(key);
      // table widths override text row widths
      column.width = maxLength + CELL_PADDING;
    });
  }

  addFigureElement(ws: Worksheet, content: string, id: number) {
    const row = ws.addRow({ id, name: ' ' })
    const cell = row.getCell(1)
    cell.value = content
    const column = ws.getColumn(cell.col)
    //if (cell.value) {
    //  this.log('Column width before setting width of text row ', column.width)
    //}
    //row.alignment = { horizontal: 'left', vertical: 'middle', wrapText: true }
    return ws.rowCount
  }
  /**
   * Add a text element to the given worksheet using the content provided
   */
  addTextElement(ws: Worksheet, content: string, id: number) {
    const row = ws.addRow({ id, name: ' ' })
    const cell = row.getCell(1)
    cell.value = content.replaceAll(NON_PRINTABLE_REGEX, '')
    //ws.views = [{ state: 'frozen', ySplit: 1 }];
    const column = ws.getColumn(cell.col)
    //if (cell.value) {
    //  this.log('Column width before setting width of text row ', column.width)
    //}
    row.alignment = { horizontal: 'left', vertical: 'top', wrapText: true }
    return ws.rowCount
  }

  /**
   * Add a table to the given spreadsheet
   * @param ws 
   * @param rows 
   * @param columnHeaders 
   * @param id 
   * @param ocrObject
   */
  addTable(ws: Worksheet, rows: any[][], columnHeaders: any[], id: number, ocrObject?: OCRData) {
    let numRows = rows.length
    if (numRows === 0) 
      return
    const startRow = ws.rowCount + 1
    //this.debug('Start/Number of rows in table ', startRow, numRows)
    //this.debug('columnHeaders', columnHeaders)
    let areColumnHeadersBlank = isRowBlank(columnHeaders)
    //this.debug('Are column headers blank ?', areColumnHeadersBlank)
    let columns = areColumnHeadersBlank ? columnHeaders : this.cleanArray(columnHeaders)
    let wroteNormalRows = false
    // excel does not accept columns with empty names
    columns = columns.map(str => {
      return { name: str.length === 0 ? ' ' : str }
    })

    // this.debug('Number of Columns', columns, columns.length)
    if(!ocrObject) 
      console.log('About to clean array for direct docupanda output', rows)
    let cleanRows = rows.map(row => this.cleanArray(row))
    
    const ignoreIndenterErrors = this.flowService.ignoreIndenterErrors
    if (ignoreIndenterErrors) {
      this.warn('Ignoring indenter introduced errors in structure because the excelGeneration.ignoreIndenterErrors flag is set')
    }
    const hasErrors = ignoreIndenterErrors ? false: this.hasErroneousData(cleanRows, columns)
    //console.log('hasErrors', hasErrors)
    if (hasErrors && ocrObject) {
      //fallback to the the table from the ocrObject if present if there was a table structure mismatch
      //if (ocrObject) { //&& hasErrors.rowMismatchErrors.data.length > 0) {
      this.debug('Detected invalid table structure. Falling back to ocr output for this table')
      //this.log('OCR OBJECT', JSON.stringify(ocrObject))
      let ocrRows = ocrObject.pages[ocrObject.pageNumber].sections[ocrObject.sectionNumber].tableList
      if (!ocrRows) {
        throw new Error(`Did not find expected tableList in ${JSON.stringify(ocrObject.pages[ocrObject.pageNumber].section[ocrObject.sectionNumber])}`)
      }
      columnHeaders = ocrRows[0]
      //recursive call using ocr output
      return this.addTable(ws, ocrRows.slice(1), columnHeaders, id, undefined)

    }
    else {
      this.writeTableRowsAsNormalRows(ws, cleanRows, columns, hasErrors)
      wroteNormalRows = true

    }
    // apply various hacks to the excel rows to correct data issues
    this.fixFormats(ws, wroteNormalRows ? startRow : startRow + TABLE_TOP_SPACING, ws.rowCount + 1, hasErrors, !areColumnHeadersBlank && !wroteNormalRows)
    // Add a blank row after the table
    //if (!wroteNormalRows)
    //ws.addRow({ id, name: ' ' })
    //this.log('ws.rowCount AFTER adding blank row', ws.rowCount)

  }

  accountForTableTopSpacing(ws: Worksheet, id: number, blankRows: number) {
    for (let i = 0; i < blankRows; i++)
      ws.addRow(id, ' ')
  }
  /**
   * 
   * @param arr array of strings from which to remove non printable characters
   * @returns the cleaned array
   */
  cleanArray(arr: string[]) {
    const row = arr.map((str: string) => 
    {
      // if using direct Docupanda output, docupanda adds an underscore
      // for blank cells. Remove them 12/25/2024
      // Similar code exists in the indenter which is also a consumer
      // of Docupanda output. Ideally we should have fixed this in the
      // Docupanda tool itself before writing the docupanda response. However
      // it is important to maintain the exact docupanda output in order to 
      // report bugs
      if(str === UNDERSCORE) 
        return ''
      return str.replaceAll(NON_PRINTABLE_REGEX, '')
    })
    return this.applyDataHacks(row)
  }

  /**
   * Remove trailing $, any number occurring right after a %, trailing "\*", 
   */
  applyDataHacks(row: string[]) {
    const numberPercentnumber = /^\d+(\.\d+)?\%\d+$/
    const trailingProduct = /^[A-Za-z0-9\/-]+(\\)?\*$/
    const trailingDollarSign = /^(\$)?(\s+)?\d+(\,\d+)*?(\.\d+)?(\s+)?\$$/

    for (let i = 0; i < row.length; i++) {
      const str = row[i].trimEnd()
      if (str.match(numberPercentnumber)) {
        row[i] = str.substring(0, str.length - 1)
      }
      if (str.match(trailingProduct)) {
        row[i] = str.substring(0, str.length - 2)
      }
      if (str.match(trailingDollarSign)) {
        row[i] = str.substring(0, str.length - 1)
        //If there is a column after the current one and 
        // it does not have a leading $, then move the $
        //to that column
        if ( (i < row.length - 1) && !row[i+1].trimStart().startsWith('$', 0))
          row[i+1] = '$' + row[i+1].trimStart()

      }
    }
    return row
  }
  applyFormatHacks(cell: Cell, val: string) {
    const plusNumber = /^\+(\s+)?\d+(\.\d+)?$/
    const trailingX = /^\d+(\.\d+)?[x|X]$/
    const isDecimal = /^(\d+)?(\.\d+)?$/
    const result: HackInterface = {}
    /*if(val?.match(plusNumber)) {
      //cell.numFmt = '+' + val.match(isDecimal) ? NUMBER_FORMAT_DECIMAL: NUMBER_FORMAT
      //const trimmedMatch = val.replace(/ /g, '')
      //cell.value = parseFloat(trimmedMatch.replaceAll(/[^0-9\.\-]/g, ''))
       result.formatPrefix = '+'
       result.newValue = val.substring(1, val.length)
    }
    
    else */
    if (val?.match(trailingX)) {
      //this.log('MATCHED', val, Number.parseFloat(val.substring(0, val.length - 1)))
      //const trimmedMatch = val.replace(/ /g, '')
      //const newVal = parseFloat(trimmedMatch.replaceAll(/[^0-9\.\-]/g, ''))
      //cell.value = newVal
      //this.log('Isdecimal', newVal.toString().match(isDecimal))
      //cell.numFmt = newVal.toString().match(isDecimal) ? NUMBER_FORMAT_DECIMAL + val[val.length -1] : NUMBER_FORMAT + val[val.length -1]
      result.formatPostFix = val[val.length - 1]
      result.newValue = val.substring(0, val.length - 1)
    }
    return result
  }

  /**
   * 
   * @param rows 
   * @param columns 
   * @returns 
   */
  writeTableRowsAsNormalRows(ws: Worksheet, rows: any[][], columns: any[], hasErrors: boolean) {
    //this.log('Error Object', errorObject)
    //const columnErrorObject = errorObject?.columnNameErrors
    //const hasColumnErrors = errorObject?.columnNameErrors?.data.length > 0
    //const excelRow = ws.addRow(columns.map(c => c.name))
    const excelRow = ws.addRow(columns.map(c => c.name))
    excelRow.alignment = { wrapText: true }
    excelRow.eachCell({ includeEmpty: true }, (cell, col) => {
      //console.log('In each cell')
      const c = excelRow.getCell(col)
      c.font = { color: { argb: 'FFFFFFFF' } }
      c.fill = {
        type: 'pattern',
        pattern: 'solid', //'lightHorizontal', //'none', //'solid',
        bgColor: { argb: 'FF4F81BD' },
        fgColor: { argb: 'FF4F81BD' }

      }
      //console.log('Cell', c.fill)
    
  })
  //excelRow.font = { color: { argb: 'FFFFFFFF' } }
    //excelRow.alignment = { wrapText: true }
    const excelRows = ws.addRows(rows)
    //row.eachCell(cell => {
    //if(hasColumnErrors &&  
    // columnErrorObject?.data.find(c => c.name == cell.value?.toString())) {
    /*cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFFB6C1' }
    }*/
    //}
    //cell.alignment = { wrapText: true, horizontal: 'left', vertical: 'top' }

    //})

    //const rowErrorObject = errorObject?.rowMismatchErrors
    //const hasRowErrors = rowErrorObject?.data.length > 0
    //const excelRows = ws.addRows(rows)
    //for (let i = 0; i < excelRows.length; i++) {
    /*if(hasRowErrors && 
      rowErrorObject?.data.find(x => x === excelRows[i].number)) {
        excelRows[i].fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFFFB6C1' }
        } 
      }*/
    //excelRows[i].alignment = { wrapText: true, horizontal: 'left', vertical: 'top' }
    //}

  }

  /**
   * return true if 
   *  - a column name doesnt start with a letter or underscore; or has characters other than digits/letters/underscore
   * - the number of row values in a row dont match the number of columns
   */
  hasErroneousData(rows: string[][], columns: { name: string }[] | undefined) {
    const regex = /^[^\{\}\[\]\%\#\'\,\|\;\#\+\^]+$/
    if (!columns)
      return false
    //const columnNameErrors: ExcelTableErrorObject = { type: TABLE_ERRORS.INVALID_COLUMN_NAME, data: [] }
    /*for (let i = 0; i < columns.length; i++) {
      if (!regex.test(columns[i].name)) {
        this.warn('Invalid column name detected', columns[i].name)
        columnNameErrors.data.push(columns[i])

      }
      if (columns[i].name.length > 255) {
        this.warn('Invalid column name LENGTH detected ( > 255)', columns[i].name)
        columnNameErrors.data.push(columns[i])
      }
    } */

    //const rowMismatchErrors: ExcelTableErrorObject = { type: TABLE_ERRORS.COLUMN_ROW_VALUES_MISMATCH, data: [] }
    //console.log('rows[0]', rows[0])
    for (let i = 0; i < rows.length; i++) {
      //this.log('Row ', i + 1, 'Number of Cells', rows[i].length)
      //console.log('row.length', i, rows[i].length)
      //console.log('Column length', columns.length)
      if (rows[i].length !== columns.length) {
        console.warn(`Mismatch between row length and column length`, rows[i], columns)
        return true //rowMismatchErrors.data.push(i)
      }
    }
    return false //columnNameErrors.data.length === 0 && rowMismatchErrors.data.length === 0 ? undefined : { columnNameErrors, rowMismatchErrors }

  }
  /**
   * Make columns names unique since Excel does not like duplicate column names
   * It causes Excel to treat the file as corrupted and it fixes it by adding
   * unique numbers to the duplicate column. The workaround is to add extra
   * spaces before and after the duplicate column name
   * @param columns 
   */
  dedupColumns(columns: { name: string }[]) {
    // Store the frequency of strings
    var hash = new Map();

    // Iterate over the array
    for (let i = 0; i < columns.length; i++) {
      // For the first occurrence, update the frequency count
      if (!hash.has(columns[i].name))
        hash.set(columns[i].name, 1);
      else {
        var count = hash.get(columns[i].name);
        hash.set(columns[i].name, count + 1);
        let spaces = ' '
        for (let j = 1; j < count + 1; j++) {
          spaces += ' '
        }
        columns[i].name = spaces + columns[i].name + spaces

      }
    }
  }

  /**
   * Fix the cell formats according to the types of values. Only handles $ for currency cells
   * Other currencies will be treated as text
   */
  fixFormats(ws: Worksheet, startRow: number, numRows: number, hasErrors: boolean = true, formatHeader: boolean = true) {
    //this.debug("TABLE", JSON.stringify(table))
    //const rows = ws.getRows(startRow, numRows) as Row[]
    //this.debug("FIX FORMATS ROWS", rows.length)
    //this.debug('IN FIX FORMATS with startRow, numRows, hasErrors, formatHeader', startRow, numRows, hasErrors, formatHeader)
    for (let index = startRow; index < numRows; index++) {
      const row = ws.getRow(index)
      //this.debug('FIX FORMATS index, startRow, row.number, row values', index, startRow, row.number, row.values)
      //row.eachCell(c => this.log('ROW VALUES IN FIX FORMATS', c.value))
      //this.debug("LAST ROW", ws.lastRow)
      //this.debug("row and index", rows[index], index)
      let formatted = false
      row.alignment = { vertical: 'top', horizontal: 'left' }
      if (row.number === startRow && formatHeader) {
        this.debug('Formatting first row', row.hasValues, row.values)
        //this.log('shouldFormat cells', shouldFormatTextCells)
        // align column  headers 
        let style: Partial<Alignment> = { wrapText: true, vertical: 'top' }
        style.horizontal = 'center'
        style.shrinkToFit = true
        row.alignment.wrapText = true
        formatted = true

        /*else {
          style.horizontal = 'left'
  
        }*/
        //this.log('formatting header', row.eachCell(c => this.log(c.value)))

        //}
        //row.eachCell((cell) => {
        //  this.log("Cell value for row with index 1 (column header" ,cell.value)
        //row.eachCell((cell) => cell.numFmt = TEXT_FORMAT)
        //})
      }
      else {
        //if(!formatted) {
        //this.log("ROW VALUES", row.values)
        row.eachCell((cell, columnNumber) => {
          let needsRightAlignment = false
          let val = cell.value?.toString().trimStart().trimEnd() as string
          //if(columnNumber === 1)
          //  this.flowService.log("VAL", val)
          if (val) {
            let hackedVals = undefined
            const match = val.match(CELL_VALUE_REGEX)
            if (!match) {
              hackedVals = this.applyFormatHacks(cell, val)
            }
            if (hackedVals?.newValue)
              this.debug('HACKED VALS', hackedVals.newValue)
            if (!match && !hackedVals?.newValue) {
              //if (!hasErrors) {
              //cell.numFmt = TEXT_FORMAT //'"$"#,##;[Red]\-"$"#,##'
              const trimmedVal = val.replaceAll(/ /g, '')
              //this.flowService.log('trimmedVal isAccounting Zero ?', trimmedVal === CURRENCY_ACCOUNTING_ZERO || trimmedVal === ACCOUNTING_ZERO)
              if (trimmedVal === CURRENCY_ACCOUNTING_ZERO || trimmedVal === CURRENCY_ZERO) {
                needsRightAlignment = true
                cell.value = 0
                cell.numFmt = CURRENCY_ACCOUNTING_FORMAT
              }
              else if (trimmedVal === ACCOUNTING_ZERO) {
                needsRightAlignment = true
                cell.value = 0
                cell.numFmt = ACCOUNTING_FORMAT
              }
              else if (trimmedVal === EM_DASH) {
                needsRightAlignment = true
                cell.value = 0
                cell.numFmt = EM_DASH_ACCOUNTING_FORMAT
              }
              else if (trimmedVal === CURRENCY_EM_DASH) {
                needsRightAlignment = true
                cell.value = 0
                cell.numFmt = CURRENCY_EM_DASH_ACCOUNTING_FORMAT
              }
              else {
                const style: Partial<Alignment> = { vertical: 'top', horizontal: 'left' }
                if (cell.value?.toString().length > 50)
                  style.wrapText = true
                //console.log('Setting cell style')
                if(cell.style) {
                  cell.style.alignment = style
                  cell.style.numFmt = TEXT_FORMAT
                }
                else
                  cell.style = { alignment: style, numFmt: TEXT_FORMAT }
              }
              if(needsRightAlignment) {

              }
            }
            else {
              if (hackedVals?.newValue) {
                val = hackedVals.newValue
              }
              //this.log("Matched regex for numbers or currency or percentages", val)
              const trimmedMatch = val.replace(/ /g, '')
              let v = parseFloat(trimmedMatch.replaceAll(/[^0-9\.\-]/g, ''))
              const arr = v.toString().split('.') //TODO - this is not locale specific; works in en-US
              const decimalPlaces = arr.length > 1 ? arr[1].length : 0
              if (trimmedMatch.startsWith('$') || ((trimmedMatch.startsWith('(') || trimmedMatch.startsWith('+')) && trimmedMatch.length > 1 && trimmedMatch.at(1) === '$')) { // currency; either $123 or $(123)
                //this.log('Matched currency', val)
                //From https://stackoverflow.com/questions/41510572/currency-format-is-exceljs
                //cell.numFmt = '_("$"* #,##0_);_("$"* (#,##0);_("$"* "-"??_);_(@_)'  //"$"#,##;[Red]\-"$"#,##' //'#,##;(#,##)'
                cell.numFmt = decimalPlaces === 0 ? 
                  (trimmedMatch.endsWith(PERIOD) ? CURRENCY_FORMAT_PERIOD: CURRENCY_FORMAT) : CURRENCY_FORMAT_DECIMAL
                if (trimmedMatch.startsWith('+')) {
                  cell.numFmt = '+' + cell.numFmt
                }
                else if (trimmedMatch.startsWith('$') && trimmedMatch.at(1) === '+') {
                  cell.numFmt = decimalPlaces === 0 ? 
                  (trimmedMatch.endsWith(PERIOD) ? CURRENCY_PLUS_FORMAT_PERIOD: CURRENCY_PLUS_FORMAT) : CURRENCY_PLUS_FORMAT_DECIMAL
                }
                if (trimmedMatch.startsWith('(') || trimmedMatch.startsWith('-') || ((trimmedMatch.startsWith('$')) && (trimmedMatch.at(1) === '(')))
                  v = -v
                cell.value = v
              }
              else {
                const trimmedVal = val.replaceAll(' ', '')
                if (trimmedVal.endsWith("%") || trimmedVal.endsWith('%)')) {
                  //this.log('Matched percentage', val)
                  cell.numFmt = decimalPlaces === 0 ? 
                  ((trimmedVal.endsWith(".%") || trimmedVal.endsWith('.%)')) ? PERCENTAGE_FORMAT_PERIOD: PERCENTAGE_FORMAT) : PERCENTAGE_FORMAT_DECIMAL
                  if (trimmedMatch.startsWith('+')) {
                    cell.numFmt = '+' + cell.numFmt
                  }
                  v = v / 100
                  if (trimmedVal.startsWith('('))
                    v = -v
                  cell.value = v
                }
                else {
                  //this.log('Matched number ', val)
                  if (trimmedMatch.includes(COMMA)) {
                    cell.numFmt = trimmedVal.startsWith('(') ?
                      decimalPlaces === 0 ? 
                        (trimmedMatch.endsWith(PERIOD) ? NEGATIVE_NUMBER_FORMAT_PERIOD: NEGATIVE_NUMBER_FORMAT) : NEGATIVE_NUMBER_FORMAT_DECIMAL
                      : decimalPlaces === 0 ? 
                      (trimmedMatch.endsWith(PERIOD) ? NUMBER_FORMAT_PERIOD: NUMBER_FORMAT) : NUMBER_FORMAT_DECIMAL

                  }
                  else {

                    cell.numFmt = trimmedVal.startsWith('(') ?
                      decimalPlaces === 0 ? 
                        (trimmedVal.endsWith(PERIOD) ? NEGATIVE_GENERAL_NUMBER_FORMAT_PERIOD: NEGATIVE_GENERAL_NUMBER_FORMAT): NEGATIVE_GENERAL_NUMBER_FORMAT_DECIMAL
                      : decimalPlaces === 0 ? 
                          (trimmedVal.endsWith(PERIOD) ? GENERAL_NUMBER_FORMAT_PERIOD: GENERAL_NUMBER_FORMAT) : GENERAL_NUMBER_FORMAT_DECIMAL
                  }
                  if (trimmedMatch.startsWith('+')) {
                    cell.numFmt = '+' + cell.numFmt
                  }
                  if (trimmedVal.startsWith('('))
                    v = -v
                  cell.value = v
                }

              }
              //replace the decimal place holder with 0's as per the number of decimal places
              if (cell.numFmt && decimalPlaces > 0) {
                let str = ''
                for (let i = 0; i < decimalPlaces; i++)
                  str += '0'
                //this.log('NUMFMT BEFORE for decimal places', cell.value, cell.numFmt, decimalPlaces)
                cell.numFmt = cell.numFmt.replaceAll(DECIMAL_PLACES_PLACE_HOLDER, str)
                //this.log('NUMFMT AFTER for decimal places', cell.value, cell.numFmt, decimalPlaces)
              }
              const alignment = cell.style.alignment
              if (alignment) {
                alignment.horizontal = 'right'
              }
              else {
                cell.style.alignment = { horizontal: 'right', vertical: 'top' }
              }
            }
            if (cell.numFmt && hackedVals?.formatPrefix)
              cell.numFmt = hackedVals.formatPrefix + cell.numFmt
            if (cell.numFmt && hackedVals?.formatPostFix) {
              console.log('Adding postfix to format', cell.numFmt, hackedVals?.formatPostFid)
              cell.numFmt = cell.numFmt + hackedVals?.formatPostFix
            }

          }
          if(needsRightAlignment) {
              const alignment = cell.style.alignment
              if (alignment) {
                alignment.horizontal = 'right'
                alignment.vertical = 'top'
              }
              else {
                cell.style.alignment = { horizontal: 'right', vertical: 'top' }
              }
          }
        })
      }
    }

  }

  /**
   * Add a page number to a worksheet. Leave a couple of rows blank prior
   * @param ws 
   * @param content 
   * @param id 
   */
  addPageNumber(ws: Worksheet, content: string, id: number) {
    return this.addElement(ws, content, id, 2, { horizontal: 'right', vertical: 'bottom', wrapText: false })
  }

  /**
   * Add a footer element to a worksheet. Leave 3 rows blank prior
   * @param ws 
   * @param content 
   * @param id 
   */
  addFooter(ws: Worksheet, content: string, id: number) {
    return this.addElement(ws, content, id, 3, { horizontal: 'center', vertical: 'bottom', wrapText: true })
  }

  addElement(ws: Worksheet, content: string, id: number, blankRows: number, rowAlignment: Partial<Alignment>) {
    for (let i = 0; i < blankRows; i++) {
      ws.addRow([""])
    }
    const row: Row = ws.addRow({ id: id, name: ' ' })
    const cell: Cell = row.getCell(1)
    cell.value = content
    row.alignment = rowAlignment
    //const currentRowIdx = ws.rowCount; // Find out how many rows are there currently
    //const endColumnIdx = ws.columnCount; // Find out how many columns are in the worksheet
    // merge by start row, start column, end row, end column
    return ws.rowCount
    //ws.mergeCells(currentRowIdx, 1, currentRowIdx, endColumnIdx)
  }

  /**
   * 
   * @param ws 
   * @param rows 
   */
  mergeTextCells(ws: Worksheet, rows: number[]) {
    //merge text fields upto the max columns in the sheet. If the sheet
    // contains, only text fields or has tables with a small number of columns,
    // use a large enough column count - 12
    for (let i = 0; i < rows.length; i++) {
      ws.mergeCells(rows[i], 1, rows[i], ws.columnCount < MIN_COLUMNS ? DEFAULT_TEXT_COLUMNS : ws.columnCount)
      const row = ws.getRow(rows[i])
      row.alignment = { horizontal: 'left', vertical: 'top', wrapText: true }
      //row.height = 20 //TODO
    }
  }

  async initializeGlobalize() {
    /*load(await import("cldr-data/main/en/numbers.json"))
    load(await import("cldr-data/main/en/ca-gregorian.json"))
    load(await import("cldr-data/main/en/currencies.json"))
    load(await import("cldr-data/main/en/dateFields.json"))
    load(await import("cldr-data/main/en/numbers.json"))
    //await import( "cldr-data/main/en/timeZoneNames" ),
    load(await import("cldr-data/main/en/units.json"))
    load(await import("cldr-data/supplemental/currencyData.json"))
    //await import( "cldr-data/supplemental/likelySubtags" ),
    //await import( "cldr-data/supplemental/metaZones" ),
    //await import( "cldr-data/supplemental/plurals" ),
    load(await import("cldr-data/supplemental/timeData.json"))
    load(await import("cldr-data/supplemental/weekData.json"))
    locale('en')
    */
  }

  autoFitColumns(ws: Worksheet) {
    //const numFmt = column.numFmt;
    for (let i = 0; i < ws.columnCount; i++) {
      const column = ws.getColumn(i + 1)
      let maxLength = 6;
      column.eachCell(/*{ includeEmpty: true },*/(cell: Cell) => {
        let columnLength: number = 5;
        let numFmt = cell.numFmt
        //if(i === 0)
        //  this.flowService.log('AutoFit value and numFmt', cell.value, cell.numFmt)
        if (numFmt && cell.value != undefined) {
          switch (cell.type) {
            case ValueType.Date:
              const serialDate = dateToSerial(cell.value as Date);
              const formattedDate = ssfFormat(numFmt, serialDate);
              columnLength = formattedDate.length;
              break;
            case ValueType.Number:
              try {
                //hack - ssfFormat blows up if custom format (n)% is specified
                if (numFmt.endsWith(')%')) {
                  //this.log('HACK', numFmt)
                  numFmt = numFmt.substring(0, numFmt.indexOf(';'))
                  const formattedNumber = ssfFormat(numFmt, cell.value as Number);
                  columnLength = formattedNumber.length + 3; // 2 for the brackets and 1 for the % sign
                }
                else {
                  // see applyFormatHacks for this case
                  // ssfFormat blows up if custom format '0X' is specified
                  if(numFmt.endsWith('x') || numFmt.endsWith('X')) {
                    numFmt = numFmt.substring(0, numFmt.length - 1)
                    const formattedNumber = ssfFormat(numFmt, cell.value as Number);
                    columnLength = formattedNumber.length + 1;
                  }
                  else if(numFmt.includes(EM_DASH)) {
                    numFmt = numFmt.replaceAll(EM_DASH, '')
                    const formattedNumber = ssfFormat(numFmt, cell.value as Number);
                    columnLength = formattedNumber.length + 1;
                  }
                  else {
                    const formattedNumber = ssfFormat(numFmt, cell.value as Number);
                    columnLength = formattedNumber.length;
                  }
                }
                //this.log('Cell Width for Number type', columnLength, cell.value, numFmt)
              }
              catch (e) {
                console.error('Error formatting number', cell.value, numFmt)
                throw e
              }
              break;
            case ValueType.RichText:
              /*const richTextValue = (cell.value as CellRichTextValue).richText.map((r) => r.text).join('');
              columnLength = richTextValue.length;
              */
              break;
            case ValueType.Formula:
            case ValueType.String:
              const formattedString = ssfFormat(numFmt, cell.value);
              columnLength = formattedString.length;
              break
            case ValueType.SharedString:
              this.debug('Skipping ValueType', cell.type)
              break;
            default:
              this.warn('Unknown ValueType found', cell.type)
              const formatted = ssfFormat(numFmt, cell.value);
              columnLength = formatted.length;
              break;
          }
        } else {
          //columnLength = cell.text.length;
        }
        maxLength = Math.max(maxLength, columnLength);

      });

      column.width = Math.min(Math.floor(HEURISTIC_PERCENTAGE * maxLength + CELL_PADDING), MAX_COLUMN_WIDTH)
      // if some cell with strings go over the heuristic, word wrap them
      column.eachCell(cell => {
        if (cell.type === ValueType.String && cell.value?.toString().length > column.width) {
          const style = cell.style
          if (style.alignment)
            style.alignment.wrapText = true
          else
            style.alignment = { wrapText: true }
        }
      })
    }
  }

  maxFitColumns(ws: Worksheet) {
    for (let i = 0; i < ws.columnCount; i++) {
      const column = ws.getColumn(i + 1)
      const lengths = []
      column.eachCell((cell: Cell) => lengths.push(cell.value?.toString().length))
      const maxLength = Math.max(...lengths.filter(v => typeof v === 'number'))
      column.width = maxLength
      // console.log('Set column width', column.width)
    }
  }

  inferColumnHeaders(obj: any) {
    const set = new Set<string>()
    const colHeaders:string[] = []
    obj.forEach(row => Object.keys(row).forEach(key => set.add(key)))
    if (set.has('name')) {
      set.delete('name')
      colHeaders.push('name')
    }
    return colHeaders.concat(Array.from(set).sort())
  }

  streamObjectsToWorksheet(schemaName: string, files: any[], ws: Worksheet) {
    // Generate a worksheet for each obj in objs
    // Combine the generated worksheets into a single composite worksheet aligning row headers and adding a new column for each obj value
    const fileSchemas: any[] = []
    for (const file of files) {
      const schema = file.schemas[schemaName]
      const [ literalRows, tables ] = this.collectObjectComponents(schema.data)
      const literalMap = new Map(literalRows.map(row => [ row[0], row[1] ]))
      fileSchemas.push({ fileName: file.fileName, literalRows, literalMap, tables })
    }
    // Collect literal keys
    const literalKeys = new Set<string>()
    for (const schema of fileSchemas)
      schema.literalRows.forEach(row => literalKeys.add(row[0]))
    const keys = Array.from(literalKeys).toSorted()
    const tableRows = keys.map(key => [ key ])    
    // Construct literals table across all files
    for (const schema of fileSchemas) {
      for (const row of tableRows) {
        const value = schema.literalMap.get(row[0])
        row.push(value ? value : '')
      }
    }
    const colHeaders = [ '', ...fileSchemas.map(schema => schema.fileName) ]
    this.addTable(ws, tableRows, colHeaders, null)

    // Handle tables
    // Compute union of all table column headers
    // Add schema.fileName as a new column
    const tableColKeys = new Set<string>() 
    for (const schema of fileSchemas) {
      for (const table of schema.tables)
        table.colHeaders.forEach(key => tableColKeys.add(key))
    }
    tableColKeys.delete('name')
    const tableColHeaders = Array.from(tableColKeys).toSorted()
    if (tableColHeaders.length > 0) {
      tableColHeaders.unshift('fileName')
      tableColHeaders.unshift('name')
    }
    const data = []
    for (const schema of fileSchemas) {
      // console.log("schema name", schema.fileName)
      for (const table of schema.tables) {
        for (const row of table.rowMap) {
          const dataRow = []
          for (const col of tableColHeaders) {
            const val = (col === 'fileName' ? schema.fileName : (`${row[col] || ''}`))
            dataRow.push(val)
          }
          data.push(dataRow)
        }
      }
    }
    // console.log({ tableColHeaders, data })
    this.addTable(ws, data, tableColHeaders, null)
    this.maxFitColumns(ws)
  }

  collectObjectComponents(obj: any, keyPrefix = '') {
    // console.log('collectObjectComponents', obj, keyPrefix)
    const keys = Object.keys(obj)
    // Order literals first, then objects, then arrays
    const literals = keys.filter(key => typeof obj[key] !== 'object' && !Array.isArray(obj[key])).sort()
    const objects = keys.filter(key => typeof obj[key] === 'object' && !Array.isArray(obj[key])).sort()
    const arrays = keys.filter(key => Array.isArray(obj[key])).sort()
    // console.log({ literals, objects, arrays })
    const literalRows:any = []
    const tables:any = []

    // Collect all literals and objects into one set of rows and sort them
    for (const key of literals) {
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      literalRows.push([fullKey, `${obj[key]}`])
    }

    // Write object tables
    for (const key of objects) {
      // console.log('collectObjectComponents', key, obj[key])
      const [ subrows, subtables ] = this.collectObjectComponents(obj[key], keyPrefix ? `${keyPrefix}.${key}` : key)
      literalRows.push(...subrows)
    }

    // Write array tables
    // TODO: Test and handle multiple and nested tables in a schema
    for (const key of arrays) {
      const val = obj[key]
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      // console.log({ fullKey, val })
      if (Array.isArray(val)) {
        const colHeaders = this.inferColumnHeaders(val)
        const tableRows = val.map(row => colHeaders.map(key => `${row[key] || ''}`))
        tables.push({ tableRows, colHeaders, fullKey, rowMap: val })
      }
    }
    return [ literalRows, tables ]
  }

  streamObjectToWorksheet(obj: any, ws: Worksheet, keyPrefix = '') {
    const keys = Object.keys(obj)
    // Order literals first, then objects, then arrays
    const literals = keys.filter(key => typeof obj[key] !== 'object' && !Array.isArray(obj[key])).sort()
    const objects = keys.filter(key => typeof obj[key] === 'object' && !Array.isArray(obj[key])).sort()
    const arrays = keys.filter(key => Array.isArray(obj[key])).sort()

    // Collect all literals and objects into one set of rows and sort them
    // Write literals table
    const literalRows:any = []
    for (const key of literals) {
      const val = obj[key]
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      literalRows.push([fullKey, `${val}`])
    }
    this.addTable(ws, literalRows, ['', ''], null)

    // Write object tables
    for (const key of objects) {
      const val = obj[key]
      this.streamObjectToWorksheet(val, ws, keyPrefix ? `${keyPrefix}.${key}` : key)
    }

    // Write array tables
    for (const key of arrays) {
      const val = obj[key]
      const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
      // console.log({ fullKey, val })
      if (Array.isArray(val)) {
        const colHeaders = this.inferColumnHeaders(val)
        ws.addRow('') // empty row        
        if (true) {
          const tableRows = val.map(row => colHeaders.map(key => row[key] ? `${row[key]}` : ''))
          // console.log({ colHeaders, tableRows })
          this.addTable(ws, tableRows, colHeaders, null)
        }
        else {
          // Simple table conversion
          ws.addRow(colHeaders)
          for (const row of val) {
            const orderedRow = colHeaders.map(key => row[key])
            ws.addRow(orderedRow)
          }
        }
        ws.addRow('') // empty row
      }
    }
  }

  
  addStyledTextElement(ws: Worksheet, content: any, id: number) {
    const row = ws.addRow({ id, name: ' ' })
    const cell = row.getCell(1)
    cell.value = content.text.replaceAll(NON_PRINTABLE_REGEX, '')
    cell.style = content.style
    row.alignment = { horizontal: 'left', vertical: 'top', wrapText: true }
    return ws.rowCount
  }

  addRows(ws: Worksheet, rows: any[]) {
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      if (typeof row === 'string')
        this.addTextElement(ws, row, i)
      else
        this.addStyledTextElement(ws, row, i)
    }
    this.maxFitColumns(ws)
  }

}