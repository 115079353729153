@if (file$ | async; as file) {
    <div class="flex flex-col items-center">
        <div class="flex w-full justify-between items-center">
            <div class="text-xl font-bold pt-0 pb-4">
                {{ file.fileName }}
            </div>
            <div class="flex gap-2 text-sm md:text-base">
                <div 
                    class="border cursor-pointer bg-slate-200 hover:bg-slate-400" 
                    (click)="fileFlowService.downloadSourceFile(file)">
                    <div class="p-2 text-center align-middle">Download Input</div>
                </div>
                <div
                    class="border cursor-pointer bg-slate-200 hover:bg-slate-400" 
                    (click)="fileFlowService.downloadOutputFile(file)">
                    <div class="p-2 text-center align-middle">Download Output</div>
                </div>  
            </div>
        </div>
        <div class="w-full grid grid-cols-2 gap-2">
            <div class="w-full resize-x overflow-auto min-w-[50%]">
                @if (docUrl$ | async; as docUrl) {
                    @if (file.fileName.endsWith('.pdf')) {
                        <ngx-extended-pdf-viewer 
                            [src]="(docUrl$ | async)!"
                            zoom="100"
                            [showToolbar]="true"
                            [enablePrint]="true"
                            [showPrintButton]="true"
                            [showDownloadButton]="false"
                            [showOpenFileButton]="false"
                            [showSidebarButton]="false"
                            [showHandToolButton]="false"                    
                            [textLayer]="false"
                            [contextMenuAllowed]="false"
                            [showRotateButton]="false"
                            [showDrawEditor]="false"
                            [showHighlightEditor]="false"
                            [showTextEditor]="false"
                            [showStampEditor]="false"
                            [showSecondaryToolbarButton]="false"
                            [filenameForDownload]="file.fileName"
                            (pageChange)="onPDFPageChange($event)"
                            (updateFindMatchesCount)="updateFindMatchesCount($event)">
                        </ngx-extended-pdf-viewer>
                    }
                    @else {
                        <img [src]="docUrl"/>
                    }
                }
            </div>
            <div class="resize-x overflow-auto">
                <lib-file-schema-viewer 
                    [file]="file"
                    [page]="previewPage"
                    (pageSelected)="onPageSelected($event)"    
                    (selectedText)="onSelectedText($event)"/>
            </div>
        </div>
    </div>    
}
