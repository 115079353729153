import { CommonModule } from '@angular/common';
import { Component, Inject, computed, inject, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatBadgeModule } from '@angular/material/badge';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { AuthService, DefaultMessageComponent, Environment, OptionItem, OptionItemMenuComponent, StripeService, UserService } from '@fidoc/util';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { BehaviorSubject, filter, debounceTime, map, firstValueFrom, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileflowService, ToolsService } from '@fidoc/fileflow';
import { JsonFormComponent } from '@cheaseed/shared/ui';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AcceptTermsComponent, LoginComponent } from '@fidoc/profile';
import { INITIAL_PAGE_BALANCE, UserRecord } from '@fidoc/shared';
import { PricingComponent } from '@fidoc/admin';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatBadgeModule,
    LoginComponent,
    IonContent,
    IonModal,
    JsonFormComponent,
    AcceptTermsComponent,
    DefaultMessageComponent,
    OptionItemMenuComponent,
    PricingComponent
],
  selector: 'fidoc-root',
  templateUrl: './app.component.html',
  styles: ``,
})
export class AppComponent {
  utilityService = inject(PortalUtilityService)
  auth = inject(AuthService)
  userService = inject(UserService)
  fileFlowService = inject(FileflowService)
  toolsService = inject(ToolsService)
  stripeService = inject(StripeService)
  router = inject(Router)
  route = inject(ActivatedRoute)

  title = 'fidoc';
  appInfo = signal<Environment|undefined>(undefined)
  shouldSignOut$ = new BehaviorSubject(false)
  launchedStripe$ = new BehaviorSubject(false)
  selectedPlan$ = this.route.queryParamMap.pipe(map(params => params.get('selectedPlan')))
  launchSelectedPlan$ = this.selectedPlan$
    .pipe(
      tap(async (selectedPlan) => {
        // console.log('selectedPlan', selectedPlan)
        const stripeLaunched = await firstValueFrom(this.launchedStripe$)
        if (!stripeLaunched)
          setTimeout(() => this.launchStripePaymentLink(selectedPlan), 300)
        return selectedPlan
      })      
    )

  menuOptions: OptionItem[] = [
    { label: 'Home', routerLink: '/home' },
    { label: 'Pricing', routerLink: '/pricing', role: 'admin' },
    { label: 'Admin', routerLink: '/admin', role: 'admin' },
  ]

  adminOptions: OptionItem[] = [
    { 
      label: 'Toggle Role',
      role: 'admin', 
      labelFunc: (user: UserRecord) => `Toggle Current Role to ${user.currentRole === 'user' ? 'Admin' : 'User'}`,
      action: async(user: UserRecord) => await this.userService.updateUser(user, { currentRole: user.currentRole === 'user' ? 'admin' : 'user' }), 
    },
    { 
      label: 'Provision Enterprise Trial User',
      role: 'admin', 
      action: async() => await this.userService.provisionEnterpriseTrialUser()
    }
  ]

  profileOptions: OptionItem[] = []
  authorizedOptions = computed(() => {
    const user = this.userService.user()
    return this.menuOptions.filter(p => (p.role === 'admin' && user.currentRole === 'admin') || !p.role)
  })

  authorizedProfileOptions = computed(() => {
    const user = this.userService.user()
    return this.profileOptions.filter(p => (p.role === 'admin' && user.isAdmin) || !p.role)
  })

  constructor(
    @Inject('environment') public environment: any
  ) { 
      this.appInfo.set(this.environment)

      this.auth.user$
      .pipe(
        debounceTime(300),
        takeUntilDestroyed())
      .subscribe(user => {
        const url = window.location.href
        // console.log('app auth.user$', user, url)
        // If no user or anonymous user
        if (!user) {
          // Check if email link
          if (this.auth.isSignInWithEmailLink(url))
            try {
              this.auth.processEmailLinkSignIn(url)
            }
            catch(e) {
              console.log('SignIn with email failed', e)
              this.utilityService.presentToast(`Failed to sign in. Please try again`)
            }
        }        
      })
      this.shouldSignOut$
        .pipe(
          filter(signOut => !!signOut),
          takeUntilDestroyed())
        .subscribe(() => {
          this.userService.signOut()
          this.shouldSignOut$.next(false)
        })
      
      this.profileOptions = [
          { label: 'Profile', icon: 'person', routerLink: '/profile' },
          { label: 'FAQ', icon: 'faq', externalLink: `${this.environment.webHost}/docs/faq`, top: false },
          { label: 'Privacy Notice', icon: 'privacy', externalLink: '/privacy', top: false },
          { label: 'Terms of Service', icon: 'privacy', externalLink: '/tos', top: false },
          { label: 'Sign Out', icon: 'logout', action: () => this.signOut() },
          { 
            label: 'More (Admin)', 
            submenu: this.adminOptions,
            role: 'admin' 
          }
        ]
  }

  async signOut() {
    if (this.auth.isLoggedIn()) {
      this.shouldSignOut$.next(true)
      await this.router.navigate(['/'])
    }
  }

  clickMenuItem(item: OptionItem, user: UserRecord) {
    if (item.externalLink)
      window.open(item.externalLink, '_blank')
    else if (item.routerLink)
      this.router.navigateByUrl(item.routerLink)
    else if (item.action)
      item.action(user)
  }

  clickMenuOption(ev: any) {
    console.log('clickMenuOption', ev)
    const { item, user } = ev
    this.clickMenuItem(item, user)
  }

  /**
   * 
   *  deprecated - not used any more
   */
  clickCredits(user: UserRecord) {
    // console.log('clickCredits', user)
    if (user.currentRole === 'admin' && (user.pageBalance || 0) < 10) {
      this.userService.updatePageBalance(user, INITIAL_PAGE_BALANCE)
      this.utilityService.presentToast(`Added ${INITIAL_PAGE_BALANCE} credits to your (admin) account`)
    }
  }

  async acceptTerms(user: UserRecord) {
    console.log('acceptTerms', user)
    await this.userService.updateUser(user, { hasAcceptedTerms: true, acceptedTermsAt: new Date() })
    // an enterprise trial user is preapproved - so dont send email if so
    if(!!user.isEnterpriseTrialUser) {
      this.userService.notifyAdminForApproval(user)
      const selectedPlan = await firstValueFrom(this.selectedPlan$)
      this.launchStripePaymentLink(selectedPlan)
    }
  }

  launchStripePaymentLink(selectedPlan: string) {
    // console.log('launchStripePaymentLink', selectedPlan)
    if (selectedPlan) {
      const prod = this.stripeService.getProduct(selectedPlan)
      if (prod) {
        const url = this.stripeService.getPaymentLinkUrl(this.userService.userDocId(), prod.paymentLink)
        if (url) {
          window.open(url, '_blank')
          this.launchedStripe$.next(true)
        }
      }
      else
        console.warn('Could not find product', selectedPlan)
    }
  }
}
