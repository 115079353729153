import { Inject, Injectable, NgZone } from '@angular/core'
import { Location } from '@angular/common'
import { 
  ActionSheetController, 
  AlertController, 
  LoadingController, 
  ToastController,
  ModalController,
  NavController,
  ToastOptions,
  AlertInput
} from '@ionic/angular/standalone'
import { BehaviorSubject, fromEvent, take } from 'rxjs'
import { Router } from '@angular/router'
import { FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PortalUtilityService {

  backButtonClicked$ = new BehaviorSubject(false)
  backButtonAvailable$ = new BehaviorSubject(false)

  constructor(
    @Inject('environment') public environment: any,
    private alertController: AlertController,
    private toastController: ToastController,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private location: Location,
    private router: Router,
    private ngZone: NgZone,
    private navController: NavController,
  ) { }

  preventOverflow(alert: HTMLIonAlertElement) {
    fromEvent(alert, 'willPresent')
      .pipe(take(1))
      .subscribe((e) => {
        this.willPresent()
      })

    fromEvent(alert, 'willDismiss')
      .pipe(take(1))
      .subscribe((e) => {
        this.willDismiss()
      })
  }

  willPresent() {
    // console.log("in willPresent", modal)
    document.body.style.overflowY = 'hidden'
  }

  willDismiss() {
    // Bug: Not called if isOpen closes the modal!
    // console.log("in willDismiss", modal)
    document.body.style.overflowY = 'auto'    
  }

  async notify(props: { header?: string, message?: string, okText?: string, backdropDismiss?: boolean, cancel?: () => void }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      backdropDismiss: props.backdropDismiss || true,
      buttons: [
        {
          text: props.okText || 'OK',
          handler: (props.cancel ? props.cancel : undefined)
        }
      ]
    })
    this.preventOverflow(alert)
    await alert.present()
    return alert
  }

  async multiPrompt(props: { 
    header?: string, 
    message?: string, 
    inputs: AlertInput[],
    okText?: string, 
    cancelText?: string, 
    cancel?: () => void, 
    confirm?: (data:any) => void }) {
  const alert = await this.alertController.create({
    header: props.header,
    message: props.message,
    backdropDismiss: false,
    inputs: props.inputs,
    buttons: [
      {
        text: props.cancelText || 'Cancel',
        role: 'cancel',
        handler: props.cancel || undefined
      }, {
        text: props.okText || 'OK',
        handler: props.confirm || undefined
      }
    ]
  })
  await alert.present()
  const fld:HTMLElement = document.querySelector('#' + props.inputs[0].name) as HTMLElement
  fld?.focus()
}

  async prompt(props: { 
    header?: string, 
    message?: string, 
    inputType?: 'textarea' | 'text' | 'tel', 
    inputValue?: string,
    inputId?: string,
    inputCssClass?: string,
    placeholder?: string, 
    okText?: string, 
    cancelText?: string, 
    attributes?: any,
    cancel?: () => void, 
    confirm?: (data:any) => void }) {
  const alert = await this.alertController.create({

    header: props.header,
    message: props.message,
    backdropDismiss: false,
    inputs: [
      {
        name: 'value',
        attributes: props.attributes,
        id: props.inputId || 'value',
        type: props.inputType || 'text',
        cssClass: props.inputCssClass,
        value: props.inputValue || '',
        placeholder: props.placeholder
      }
    ],
    buttons: [
      {
        text: props.cancelText || 'Cancel',
        role: 'cancel',
        handler: props.cancel || undefined
      }, {
        text: props.okText || 'OK',
        handler: props.confirm || undefined
      }
    ]
  })
  await alert.present()
  const fld:HTMLElement = document.querySelector("#value") as HTMLElement
  fld.focus()
}

  async password(props: { 
      header?: string, 
      message?: string, 
      inputValue?: string,
      inputCssClass?: string,
      placeholder?: string, 
      okText?: string, 
      confirm?: (data:any) => void }) {
    const alert = await this.alertController.create({
      header: props.header || 'Enter password',
      message: props.message || 'Password required',
      inputs: [
        {
          name: 'value',
          type: 'password',
          cssClass: props.inputCssClass,
          value: props.inputValue || '',
          placeholder: props.placeholder
        }
      ],
      buttons: [
        {
          text: props.okText || 'OK',
          handler: props.confirm || undefined
        }
      ]
    })
    await alert.present()
  }

  async confirm(props: { header?: string, message?: string, noLabel?: string, yesLabel?: string, cancel?: () => void, confirm?: () => void }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      cssClass: 'alert-confirm',
      buttons: [
        {
          text: props.noLabel || 'No',
          role: 'cancel',
          handler: props.cancel || undefined
        }, {
          text: props.yesLabel || 'Yes',
          handler: props.confirm || undefined
        }
      ]
    })
    this.preventOverflow(alert)
    await alert.present()
  }

  async multiconfirm(props:{ header?: string, cssClass?: string, message?: string, buttons?: any[] }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      cssClass: props.cssClass || 'alert-confirm',
      buttons: props.buttons
    })
    this.preventOverflow(alert)
    await alert.present()
  }


  async presentToast(msg:string, properties?: ToastOptions) {
    const props:ToastOptions = {      
      message: msg,
      duration: 2000,
      position: "middle",
      cssClass: 'alert-toast',
      ...properties
    }
    // console.log(props)
    const toast = await this.toastController.create(props)
    await toast.present()
  }

  async presentToastWithURL(msg: string, url: string, properties?: ToastOptions) {
    const props:ToastOptions = {      
      message: '<a target="_blank" href=' + url + '/a>' + msg,
      duration: 2000,
      position: "middle",
      color: 'blue',
      cssClass: 'alert-toast-link',
      ...properties
    }
    const toast = await this.toastController.create(props)
    await toast.present();
}
  async showActionSheet(header:string, buttons: any[]) {
    buttons.push(
      { 
      text: 'Not now',
      icon: 'close',
      cssClass: 'cancel-button',
      }
    )
    const ac = await this.actionSheetController
      .create({
        header,
        cssClass: 'plus-action-sheet',
        translucent: true,
        buttons: buttons
        })
    return await ac.present()
  }

  async loading(message: string) {
    const loading = await this.loadingController.create({
      message: message,
      cssClass: 'alert-confirm'
    });
    await loading.present();
    return loading
  }

  // centralized back button behavior
  goBack(defaultRoute?: string) {
    const state:any = this.location.getState()
    if (state.navigationId === 1) {
      // If this is the first page rendered and we get a goBack, just go to home
      console.log("returning to home with navigationId", state.navgiationId)
      this.ngZone.run(() => this.router.navigate([defaultRoute || '/']))
    }
    else {
      this.navController.setDirection('back')
      this.location.back()
    }
  }

  async launchModalComponent(componentClass: any, componentProps: any, cssClass: string | undefined = undefined) {
    const modal = await this.modalController.create({
        component: componentClass,
        componentProps: componentProps,
        cssClass
    })
    return await modal.present();
  }

  validateEmail(email: string) {
    return Validators.email(new FormControl(email)) === null;
  }
  
}