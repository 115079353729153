import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { UserRecord } from '@fidoc/shared';

export interface OptionItem {
  label: string;
  icon?: string;
  action?: (user: UserRecord) => void;
  displayIf?: (user: UserRecord) => boolean;
  labelFunc?: (user: UserRecord) => string;
  enableIf?: (user: UserRecord) => boolean;
  externalLink?: string;
  top?: boolean;
  routerLink?: string;
  role?: string;
  submenu?: OptionItem[];
}

@Component({
  selector: 'lib-option-item-menu',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule
  ],
  templateUrl: './option-item-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionItemMenuComponent {
  user = input<UserRecord>()
  item = input<OptionItem>()
  action = output<{ item: OptionItem, user: UserRecord }>()
}