export interface AzureServerConfig  {
    env: string,
    endpoint: string,
    key: string,
    models: string[],
    defaultModel?: string

}

export const AZURE_OPENAI_SERVERS: AzureServerConfig[] = []

export const AZURE_DOCINTEL_SERVERS = []

export function getAzureOpenAIServer(env: "dev" | "prod"): AzureServerConfig {
    //console.log("Azure End Point lookup env", env)
    if (['dev', 'prod'].includes(env))
        return AZURE_OPENAI_SERVERS.find( server => server.env === env )
    else
        throw new Error('Internal Error: Invalid environment specified in Azure server lookup')    
}