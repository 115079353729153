import { SubscriptionInfo } from "./stripe-constants";

export enum PipelineEnvironment  {
    PIPELINE_EXEC_ENV_CLOUD = "cloud",
    PIPELINE_EXEC_ENV_LOCAL = "local"
 }
export interface UserRecord {
  docId: string;
  name: string;
  email: string;
  photoURL: string;
  provider: string;
  timezone: string;
  ipAddress?: string;
  claims?: { admin: boolean }
  createdAt: Date;
  updatedAt: Date;
  invitedBy?: string;
  lastIpAddress?: string;
  lastLogin?: Date;
  initialReferrer?: string;
  lastReferrer?: string;
  lastUserAgent?: string;
  initialBuildTag: string;
  initialReleaseTag: string;
  initialPatchLevel?: number;
  buildTag: string;
  releaseTag: string;
  patchLevel?: number;
  groupDocId: string;
  pageBalance: number;
  subscriptionInfo?: Partial<SubscriptionInfo>,
  isAdmin?: boolean;
  currentRole?: 'admin' | 'user';
  isApproved?: boolean;
  defaultPipeline?: string;
  hasAcceptedTerms?: boolean;
  acceptedTermsAt?: Date;
  pipelineEnv?: PipelineEnvironment,
  isEnterpriseTrialUser?: boolean
  enterpriseTrialUserCreatedBy?: string
}

export enum UserCollectionTypes {
  USERS = "users"
}

export enum ROLE_TYPE {
  user = 'user',
  admin = 'admin'
}