import { Inject, Injectable, inject } from '@angular/core'
import { BehaviorSubject, debounceTime, filter, firstValueFrom, map, switchMap, take, combineLatestWith, withLatestFrom, distinctUntilChanged, timeout, catchError, of, from, forkJoin, distinctUntilKeyChanged } from 'rxjs'
import { FirebaseService } from './firebase.service'
import { CleverTapService } from './clevertap.service'
import { deleteDoc, limit, orderBy, where, getDocs, doc, FirestoreDataConverter, Timestamp, DocumentData, QueryDocumentSnapshot, SnapshotOptions, WriteBatch } from '@angular/fire/firestore'
import { ADMIN_CERTIFIED, ANONYMOUS_USER_ID, AZURE_OPEN_AI, AppSource, Conversation, DEFAULT_SEED_BALANCE, DEFAULT_SEED_BALANCE_KEY, PROMPT_STATUS_KEY, USER_PERSONAS_KEY, UserKeyDoc, diff, guessTimezone, nowstr, nowstrISO } from '@cheaseed/node-utils'
import { AuthService } from './auth.service'
import { HttpClient } from '@angular/common/http'
import { ContentService } from './content.service'
import { ChatStateStatus } from './chat-state.service'

export enum UserCollectionTypes {
  USERS = "users",
  KEYS = "userkeys",
  ENTRIES = "entries",
  EVENTS = "events",
  MESSAGES = "messages",
  PROGRAMS = "programs",
  TASKS = "tasks",
  STREAKS = "streaks",
  CHALLENGES = "challenges",
  LINKFEEDBACK = "linkfeedback",
  LOGS = "logs",
  HOMEITEMS = "homeitems",
  SUBSCRIPTIONS = "subscriptions",
  CONSUMABLES = "consumables",
  OFFERS = "offers",
  PORTALOFFERS = "portalOffers",
  PROMPTS = "prompts",
  CHATSTATE= "chatState",
  CHATSTATS = "chatStats",
  LEDGER = "ledger",
  CLEVERTAPEVENTS = "clevertap-events"
}
const COMBO_SUFFIX = ".combo"
const OTHER_SUFFIX = ".other"
const LASTUSED_SUFFIX = ".last"
const LASTVISITDATE = 'user.last'
export const USER_CREATED_AT = 'user.createdAt'
const USER_CURRENT_LEVEL = 'user.currentLevel'
const USER_NAME = 'user.name'
const ACCESS_LOCKED_CONTENT_KEY = 'user.accessLockedContent'
const DISABLE_SOUND_KEY = 'Onboarding.disableSound'
export const MIGRATION_STATUS_COMPLETED = 'completed'
export const MIGRATION_STATUS_SEEDED = 'seeded'
export const CLOUD_OPEN_AI = "OpenAI.isCloud"
export const ANY_SEED_TYPE = "any"

export enum ROLE_TYPE {
  user = 'user',
  admin = 'admin'
}

export interface CheaseedUser {
  id?: string;
  docId?: string;
  email?: string;
  name: string;
  photoURL: string;
  provider: string;
  initialBuildTag: string;
  initialReleaseTag: string;
  initialPatchLevel?: number;
  timezone: string;
  ipAddress?: string;
  claims?: { admin: boolean }
  homeVersion?: number;
  isDeactivated?: boolean;
  createdAt: string;
  tokens?: Array<string>;
  invitedBy?: string;
  lastIpAddress?: string;
  lastLogin?: Date;
  buildTag: string;
  releaseTag: string;
  patchLevel?: number;
  isAnonymousUser?: boolean;
  hasAllowedNotifications?: boolean;
  lastLoadStatus?: UserLoadStatus;
  migrateStatus?: string;
  currentLevel?: number;
  seedBalance: number;  // deprecate
  seedTypeBalance: Record<string, number>;
  appSource?: string;
  stripeCustomerId?: string;
  stripePaymentInfoSaved?: boolean;
  initialReferrer?: string;
  lastReferrer?: string;
  lastUserAgent?: string;
  numCoachesPurchased?: number;  // set on first purchase of coach
  lastAnonymousUser?: string; // last anonymous user id used before auth
  isAdminCertified?: boolean;
  groupDocId: string; // docId of the group to which the user belongs
}

const UserKeyDocConverter = {
  toFirestore(doc: UserKeyDoc) {
    const obj: DocumentData = Object.assign({}, doc)
    if (doc.createdAt)
      obj.createdAt = Timestamp.fromDate(new Date(doc.createdAt))
    if (doc.updatedAt)
      obj.updatedAt = Timestamp.fromDate(new Date(doc.updatedAt))
    return obj
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): UserKeyDoc {
    const data = snapshot.data();

    if(data.createdAt) {
      if (typeof data.createdAt === 'string' )
        console.warn(`Encountered user key createdAt as a string`, data)
      else
        data.createdAt = data.createdAt.toDate().toISOString()
    }
    if(data.updatedAt) {
      if (typeof data.updatedAt === 'string' )
        console.warn(`Encountered user key updatedAt as a string`, data)
      else
        data.updatedAt = data.updatedAt.toDate().toISOString()
    }
    return data as UserKeyDoc
  }
}
type UserLoadStatus = 'created' | 'exists' | 'loggedout' | null;

@Injectable({
  providedIn: 'root'
})
export class SharedUserService {

  protected firebase = inject(FirebaseService)

  user$ = new BehaviorSubject<CheaseedUser|null>(null)
  private _user: CheaseedUser|null = null
  role$ = new BehaviorSubject<ROLE_TYPE>(ROLE_TYPE.user) // can be toggled to admin if hasAdminClaim
  private userKeyMap: Record<string, UserKeyDoc> = {}  // map of string to object (contains value key)
  private userKeyCache: Record<string, any> = {}

  userLoadStatus$ = new BehaviorSubject< UserLoadStatus>(null)
  private changelist$ = new BehaviorSubject<Set<string>>(new Set())
  lastUserKeyRefresh$ = new BehaviorSubject<string|null>(null)
  isAdminRole$ = this.role$.pipe(map(role => role === ROLE_TYPE.admin))
  toggleRole$ = new BehaviorSubject(false)
  requestLogin$ = new BehaviorSubject(false) // true when dialog should be up
  requireLogin$ = new BehaviorSubject(false) // true when authentication must be successful
  requestPayment$ = new BehaviorSubject<Conversation | any>(undefined) // any to support boolean or conversation
  paymentCompleted$ = new BehaviorSubject<any>(null) // set to description of payment if just completed
  devPasswordNeeded$ = new BehaviorSubject<any>(undefined)
  userLoggedIn$ = this.user$
    .pipe(
      filter(user => !!user),
      distinctUntilKeyChanged("docId"))
  userInChat$ = new BehaviorSubject(false) // user is currently in a chat
  abortedAnonymousConversion$ = new BehaviorSubject(false)
  userGroupDocId$ = new BehaviorSubject<string|null|undefined>(undefined)

  clonedUsers$ = this.firebase.collection$('users', where('clonedFrom', '!=', '')) //, orderBy('clonedAt', 'desc'))
    .pipe(
      debounceTime(400),
      map(users => users.toSorted((a, b) => a.clonedAt > b.clonedAt ? -1 : 1)))

  constructor(
    @Inject('environment') private environment: any,
    private auth: AuthService,
    protected clevertapService: CleverTapService,
    private contentService: ContentService,
    private http: HttpClient
  ) { 
      this.observeAuthChanges()

      this.toggleRole$
        .pipe(
          filter(toggle => !!toggle),
          withLatestFrom(this.role$))
        .subscribe(([ , role]) => {
            const newRole = role === ROLE_TYPE.admin ? ROLE_TYPE.user : ROLE_TYPE.admin
            this.role$.next(newRole)
            console.log("toggled to role ", newRole)
          }
        )
  }

  private getIpAddress() {
    return this.http.get("https://api.ipify.org/?format=json")
      .pipe(
        timeout(2000),
        catchError(err => { // if ipify fails after 1 sec, just continue
          console.error("ipify failed with error", err)
          return of(null)
        }))
  }

  observeAuthChanges() {

    // Subscribe to login
    // this establishes the initial read of userkeys
    this.auth.user$
      .pipe(
        filter(u => !!u),
        // tap(u => console.log('auth user$ login detected', u)),
        switchMap(u => this.firebase.doc$(this.getUserPath(u?.email))
          .pipe(
            take(1),
            combineLatestWith(this.getIpAddress()), 
            map(([ user, resp ]) => ({ user, address: resp, firebaseUser: u })),
          )),
        map((data: any) => {
          const { user, address, firebaseUser } = data
          const u = firebaseUser, 
                docId = u.email,
                path = this.getUserPath(docId),
                update: any = {
                  lastIpAddress: address?.ip,
                  lastLogin: new Date(),
                  buildTag: this.environment.buildTag,
                  releaseTag: this.environment.releaseTag,
                  name: user?.name || u.displayName,
                  photoURL: u.photoURL,
                  provider: u.providerId,
                  isAnonymousUser: u.isAnonymousUser,
                  lastUserAgent: navigator.userAgent
                }
          if (this.environment.patchLevel > 0)
            update.patchLevel = this.environment.patchLevel
          if (document.referrer)
            update.lastReferrer = document.referrer

          // console.log("user$ data", {data, u, docId, path, update})
          if (this.auth.getDisplayName()) {
            update.name = this.auth.getDisplayName()
            this.auth.clearDisplayName()
          }
          const appSource = this.environment.appSource
          let newUser:CheaseedUser
          if (!user) {
            const seedBalance = this.contentService.getGlobal(DEFAULT_SEED_BALANCE_KEY) || DEFAULT_SEED_BALANCE
            newUser = { 
              ...update,
              homeVersion: 2,
              currentLevel: 0, // will force initialization of home items
              initialBuildTag: this.environment.buildTag,
              initialReleaseTag: this.environment.releaseTag,
              timezone: guessTimezone(),
              createdAt: nowstr(),
              seedBalance,
              seedTypeBalance: {}
            }
            if (this.environment.patchLevel > 0)
              newUser.initialPatchLevel = this.environment.patchLevel
            if (document.referrer)
              newUser.initialReferrer = document.referrer

            // console.log("creating new user with", newUser)
            // Double check user exists in firestore
            firstValueFrom(this.firebase.doc$(path).pipe(take(1)))
              .then(userdoc => {
                if (!userdoc) {
                  this.firebase.updateAt(path, newUser)
                }
                else {
                  console.log(`${path} already exists in firestore`)
                  // We've aborted the initial write of the user
                  // It's unclear what will happen to app
                }
              })
          }
          else {
            newUser = { ...user, ...update }
            // Always update firebase user with latest session data and photoURL (if set)
            // do NOT update timezone - it is set only once when the user is created
            // console.log("updating existing user with", newUser)
            if (appSource !== AppSource.Admin)
              this.firebase.updateAt(path, update)
          }
          // Track role locally
          this.role$.next(newUser.claims?.admin ? ROLE_TYPE.admin : ROLE_TYPE.user)
          this.resetChangelist()          
          if (appSource === AppSource.App || appSource === AppSource.Portal) {
            this.updateClevertapProfile(docId, { ...update, photoURL: null })
            // if the user was deactivated, the login should recreate his/her cloud tasks
            if (newUser.isDeactivated) {
              this.firebase.callCloudFunction('reactivateUser', { email: docId })
                .subscribe(() => console.log("reactivated user", docId))
            }
          }
          const result = { 
            ...newUser, 
            docId,
            lastLoadStatus: user ? 'exists' : 'created' as UserLoadStatus,
            appSource
          } // touchup user object
          // console.log("user$ result", result)
          return result
        }),
        switchMap(data => from(this.checkAnonymousUserConversion(data))),
        filter(u => u.appSource !== AppSource.Admin), // do not continue with admin app users
        switchMap((user: CheaseedUser) => this.firebase.collectionWithConverter$(this.getUserKeysPath(user.docId), UserKeyDocConverter, orderBy('updatedAt', 'desc'))
          .pipe(
            take(1), // complete after first read
            map((keydocs:UserKeyDoc[]) => ({ user, keydocs })  )))
        )
      .subscribe((data: { user: CheaseedUser, keydocs: UserKeyDoc[]}) => {
        const { user, keydocs } = data
        console.log(`Initializing user ${user.docId} with ${keydocs.length} userkeys`)
        if (user) { // avoid null user here
          this.userKeyMap = Object.fromEntries(keydocs.map(doc => [doc.key, doc]))
          this.userKeyCache = Object.fromEntries(keydocs.map(doc => [doc.key, doc.value]))
          this.setTransientUserKey(USER_NAME, user.name)
          this.setTransientUserKey(USER_CREATED_AT, user.createdAt)
          this.setTransientUserKey(USER_CURRENT_LEVEL, user.currentLevel)
          this.lastUserKeyRefresh$.next(keydocs[0]?.updatedAt || nowstrISO())
          this.setUser(user)
          this.userLoadStatus$.next(user.lastLoadStatus as UserLoadStatus)
          this.observeUserChanges(user.docId as string)
          this.requireLogin$.next(false)
          this.requestLogin$.next(false)
        }
      })

    // Flush UserKey updates
    this.changelist$
      .pipe(
        debounceTime(500),
        filter(changes => changes.size > 0))        
      .subscribe(changes => this.updateUserKeys(changes))
  }

  observeUserChanges(id: string) {
    // Observe changes to user object
    this.firebase.doc$(this.getUserPath(id))
      .pipe(
        filter(u => !!u),
        this.auth.takeUntilAuth(),
        debounceTime(1000), 
      )
      .subscribe(user => {
        console.log("observeUserChanges for", id)
        this.setUser(user)
    })

    // Monitor changes to userkeys from the cloud, using initial userKeyRefresh timestamp from startup
    const startTime = Timestamp.fromDate(new Date(this.lastUserKeyRefresh$.value as string))
    this.firebase.collectionChanges$(this.getUserKeysPath(id), UserKeyDocConverter, where('updatedAt', '>', startTime))
      .pipe(
        // tap(changes => console.log(`UserKeyChanges has ${changes.length} change${changes.length > 1 ? 's' : ''}`)),
        switchMap(changes => from(changes)),
        map((change:any) => ({ type: change.type, doc: change.doc.data() })),
        // tap(change => console.log("userkey change observed.", change.type, change.doc?.key)),
        filter(change => change.type !== 'removed'),
        distinctUntilChanged((prev:any, curr:any) => {
          return [ 'added', 'modified' ].includes(curr.type)
            && prev?.doc.key === curr.doc.key
            && prev?.doc.updatedAt === curr.doc.updatedAt
        }),  
        withLatestFrom(this.changelist$)
      )
      .subscribe(([ change, changelist ]) => {
        // Compute conflicts with userkey changelist  
        const key = change.doc.key
        // console.log(`RECEIVED ${key} (${change.type}):`, change.doc)
        const conflict = changelist.has(key)
        if (conflict)
          console.warn(`Detected changelist conflict for ${key}, will ignore`)
        else {
          this.userKeyMap[key] = change.doc
          this.userKeyCache[key] = change.doc.value
        }
        this.lastUserKeyRefresh$.next(change.updatedAt)
      })
  }

  async ensureKeyWritten(key: string, cachedKeyDoc: UserKeyDoc) {
    const userId = this.getCurrentUser()?.docId as string
    const doc:UserKeyDoc = await firstValueFrom(this.watchUserKey(userId, key))
    if (!doc || doc.updatedAt === cachedKeyDoc.updatedAt)
      console.error(`ensureKeyWritten: ${key} not found or still unchanged`, doc)
    else {
      console.warn("ensureKeyWritten: updated userkey found", doc)
      this.userKeyMap[key] = doc
      this.userKeyCache[key] = doc.value
    }
    this.lastUserKeyRefresh$.next(nowstrISO())
  }

  setUser(user: CheaseedUser|null) {
    console.log("setUser", user?.docId)
    const isAdminCertified = (window.localStorage?.getItem(ADMIN_CERTIFIED) === 'true')
    const newUser = user ? { ...user, isAdminCertified } : null
    this._user = newUser
    this.user$.next(newUser)
    // console.log("setUser", newUser)
    return newUser as CheaseedUser
  }

  setUserAdminCertified(user: CheaseedUser) {
    this.devPasswordNeeded$.next(false)
    localStorage.setItem(ADMIN_CERTIFIED, 'true')
    return this.setUser(user) // this will read ADMIN_CERTIFIED
  }

  finalize() {
    const changes = this.changelist$.value
    if (changes.size > 0)
      this.updateUserKeys(changes)
  }

  deleteDuplicateInProgressChatStates(
    batch: WriteBatch, 
    inProgressDocs:any, 
    chatState: any) {
    // check if INPROGRESS states for the specified chatState exist
    // If so, delete them
    // it is possible there are multiple such chats because
    // of Issue #3641 prior to Beta 77
    const duplicates = inProgressDocs.docs.filter((doc:any) => {
      const data = doc.data()
      return data.chatName === chatState.chatName && 
        data.status === ChatStateStatus.INPROGRESS      
    })
    if (duplicates.length > 0) {
      console.log(`deleting ${duplicates.length} duplicate chats`)
      for (const d of duplicates) {
        batch.delete(d.ref)
      }
    }
  }

  async copyUserData(from: string, to: string) {
    console.log(`copyUserData from ${from} to ${to}`)

    // Abort if there is an INPROGRESS chat state for the target user
    const inProgressDocs = await firstValueFrom(
      this.firebase.collection$(
        this.getUserChatStatePath(to), 
        where('status', '==', ChatStateStatus.INPROGRESS)))
    if (inProgressDocs.length > 0) {
      console.log(`found ${inProgressDocs.length} INPROGRESS chat states for ${to}, aborting conversion`)
      this.abortedAnonymousConversion$.next(true)
      return
    }

    // copy anonymous userkeys etc to new document id
    let coll = this.getUserKeysCollectionRef(from)
    let newColl = this.getUserKeysCollectionRef(to)
    let docs = await getDocs(coll)
    const batch = this.firebase.writeBatch()
    docs.forEach(s => {
      if (s.id !== PROMPT_STATUS_KEY) {
        console.log(`copyUserKey ${s.id}`)
        const data = s.data()
        data.updatedAt = new Date() // Force updatedAt to now to ensure it is loaded into cache
        batch.set(doc(newColl, s.id), data)
      }
    })

    coll = this.getUserChatStateCollectionRef(from)
    newColl = this.getUserChatStateCollectionRef(to)
    docs = await getDocs(coll)
    for( const s of docs.docs ) {
      console.log(`copyChatState ${s.id}`)      
      batch.set(doc(newColl, s.id), s.data())
    }

    // copy prompts
    coll = this.getUserPromptsCollectionRef(from)
    newColl = this.getUserPromptsCollectionRef(to)
    docs = await getDocs(coll)
    docs.forEach(s => {
      console.log(`copyPrompt ${s.id}`)
      batch.set(doc(newColl, s.id), s.data())
    })

    // copy anonymous chatstats to new document id
    coll = this.getUserChatStatsCollectionRef(from)
    newColl = this.getUserChatStatsCollectionRef(to)
    docs = await getDocs(coll)
    docs.forEach(s => {
      console.log(`copyChatStats ${s.id}`)
      batch.set(doc(newColl, s.id), s.data())
    })

    // copy user attributes to new user doc
    const oldUserData = await this.getUserDoc(from)
    const newUserDocRef = this.getDocRef(to, '')
    batch.update(newUserDocRef, { lastAnonymousUser: from })
    if (oldUserData?.initialReferrer)
      batch.update(newUserDocRef, { initialReferrer: oldUserData.initialReferrer })    
    await batch.commit()
  }

  private resetChangelist() {
    this.changelist$.next(new Set())
  }

  private addChange(key: string) {
    const newset = new Set(this.changelist$.value)
    this.changelist$.next(newset.add(key))
  }

  clearKeys() {
    this.userKeyMap = {}
    this.userKeyCache = {}
  }

  allUserKeys(): string[] {
    return Object.keys(this.userKeyMap)
  }

  getCache(): any {
    return this.userKeyCache
  }

  hasAdminClaim() {
    return this.getCurrentUser()?.claims?.admin
  }
  
  updateClevertapProfile(id: string, update: any) {
    // CleverTap keys have the first letter capitalized - hence have
    // to duplicate the keys below. Use some sort of renameKey function
    const name = update.name
    const clevertapProfile:any = { 
      ...update,
      Identity: id,
      Email: id,
      Name: name,
      SupportsForcedAppUpdate: true,
      'First Name': name?.split(' ')[0]
    }
    // console.log("updateClevertapProfile", id, clevertapProfile)
    this.clevertapService.onUserLogin(id, clevertapProfile)
  }

  getUserPath(id:string|null = null) {
    const uid = id || this.getCurrentUser()?.docId
    if (!uid) 
      throw new Error("No user id specified")
    else
      return `${UserCollectionTypes.USERS}/${id || this.getCurrentUser()?.docId}`
  }

  getUserCollectionPath(collection:string, id:string|null = null) {
    return `${this.getUserPath(id)}/${collection}`
  }

  getUserEventsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.EVENTS, id)
  }

  getClevertapEventsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.CLEVERTAPEVENTS, id)
  }

  getUserEntriesPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.ENTRIES, id)
  }

  getUserEntryPath(docId: string, id:string|null = null) {
    const path = this.getUserCollectionPath(UserCollectionTypes.ENTRIES, id)
    return `${path}/${docId}`
  }

  getUserKeysPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.KEYS, id)
  }

  getUserKeyPath(userid:string|null = null, key: string) {
    return `${this.getUserKeysPath(userid)}/${key}`
  }

  getUserStreaksPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.STREAKS, id)
  }

  getUserProgramsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.PROGRAMS, id)
  }

  getUserChallengesPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.CHALLENGES, id)
  }

  getUserLinkFeedbackPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.LINKFEEDBACK, id)
  }

  getUserLogsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.LOGS, id)
  }

  getUserMessagesPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.MESSAGES, id)
  }

  getUserHomeItemsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.HOMEITEMS, id)
  }

  getUserSubscriptionsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.SUBSCRIPTIONS, id)
  }

  getUserConsumablesPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.CONSUMABLES, id)
  }

  getUserOffersPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.OFFERS, id)
  }

  getUserOfferPath(docId:string) {
    return `${this.getUserOffersPath()}/${docId}`
  }

  getUserPortalOffersPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.PORTALOFFERS, id)
  }

  getUserPortalOfferPath(docId:string) {
    return `${this.getUserOffersPath()}/${docId}`
  }

  getUserLedgerPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.LEDGER, id)
  }

  async addUserLedgerEntry(entry: unknown) {
    await this.firebase.updateAt(this.getUserLedgerPath(this.getCurrentUserId()), entry)
  }
  
  getUserPromptsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.PROMPTS, id)
  }

  getUserChatStatePath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.CHATSTATE, id)
  }

  getUserChatStatsPath(id:string|null = null) {
    return this.getUserCollectionPath(UserCollectionTypes.CHATSTATS, id)
  }

  getUserMessagePath(msgId:string) {
    return `${this.getUserMessagesPath()}/${msgId}`
  }

  getUserConsumablePath(consumableId:string) {
    return `${this.getUserConsumablesPath()}/${consumableId}`
  }

  private getDocRef(userId:string|null = null, uri: string, converter?: FirestoreDataConverter<any> ) {
    return this.firebase.getFirestoreDocRef(this.getUserPath(userId) + `/${uri}`, converter)
  }

  async getUserDoc(id:string) {
    return await this.firebase.getFirestoreDoc(this.getUserPath(id))
  }
  async getUserEntryDoc(id:string) {
    return await this.firebase.getFirestoreDoc(`${this.getUserEntriesPath()}/${id}`)
  }
  async updateUserEntryDoc(id:string, data:any) {
    return await this.firebase.updateAt(`${this.getUserEntriesPath()}/${id}`, data)
  }
  getUserEntryDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.ENTRIES}/${id}`)
  }
  getUserMessageDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.MESSAGES}/${id}`)
  }
  getUserKeyDocRef(id:string, userId:string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.KEYS}/${id}`, UserKeyDocConverter )
  }
  getUserStreaksDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.STREAKS}/${id}`)
  }
  getUserEventDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.EVENTS}/${id}`)
  }
  getUserProgramDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.PROGRAMS}/${id}`)
  }
  getUserChallengeDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.CHALLENGES}/${id}`)
  }
  getUserTaskDocRef(id:string, userId: string|null = null) {
    return this.getDocRef(userId, `${UserCollectionTypes.TASKS}/${id}`)
  }
  getUsersCollectionRef() {
    return this.firebase.getFirestoreCollectionRef(`${UserCollectionTypes.USERS}`)
  }
  getUserMessagesCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserMessagesPath(id))
  }
  getUserEntriesCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserEntriesPath(id))
  }
  getUserProgramsCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserProgramsPath(id))
  }
  getUserChallengesCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserChallengesPath(id))
  }
  getUserEventsCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserEventsPath(id))
  }
  getUserKeysCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserKeysPath(id))
  }
  getUserStreaksCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserStreaksPath(id))
  }
  getUserTasksCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(`${this.getUserPath(id)}/${UserCollectionTypes.TASKS}`)
  }
  getUserChatStateCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserChatStatePath(id))
  }
  getUserPromptsCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserPromptsPath(id))
  }
  getUserChatStatsCollectionRef(id: string|null = null) {
    return this.firebase.getFirestoreCollectionRef(this.getUserChatStatsPath(id))
  }

  deleteUserCollection(collection: string) {
    return this.firebase.deleteRecursively(this.getUserCollectionPath(collection))
  }

  getUserEvents(id:string|null = null) {
    return this.firebase.collection$(this.getUserEventsPath(id), orderBy('createdAt', 'desc'))
  }
  getUserMessages(id:string|null = null) {
    return this.firebase.collection$(this.getUserMessagesPath(id), orderBy('createdAt', 'desc'))
  }
  getUserKeys(id:string|null = null) {
    return this.firebase.collection$(this.getUserKeysPath(id), orderBy('updatedAt', 'desc'))
  }
  getUserPrograms(id:string|null = null) {
    return this.firebase.collection$(this.getUserProgramsPath(id), orderBy('createdAt', 'desc'))
  }
  getUserChallenges(id:string|null = null) {
    return this.firebase.collection$(this.getUserChallengesPath(id), orderBy('createdAt', 'desc'))
  }
  getUserStreaks(id:string|null = null) {
    return this.firebase.collection$(this.getUserStreaksPath(id), orderBy('createdAt', 'desc'))
  }
  getActiveUserStreak(id:string|null = null) {
    return this.firebase.collection$(this.getUserStreaksPath(id), where('endDate', '==', 'ACTIVE'), orderBy('createdAt', 'desc'), limit(1))
  }
  getClevertapEvents(id:string|null = null) {
    return this.firebase.collection$(this.getClevertapEventsPath(id), orderBy('createdAt', 'desc'))
  }


  setUserCreatedKey(user: CheaseedUser|null) {
    const u = user || this.getCurrentUser()
    this.setTransientUserKey(USER_CREATED_AT, u?.createdAt)
  }

  userDaysOld() {
    const createDay = this.getCurrentUser()?.createdAt?.slice(0, 10)
    return createDay ? diff(createDay as string, null, 'day') : 0
  }

  hasUserKey(key:string) {
    const entry = this.userKeyMap[key]
    return !!entry
  }

  getUserKey(key:string) : any {
    const entry = this.userKeyMap[key]
    return entry?.value
  }

  getUserKeyDoc(key:string) : UserKeyDoc|null {
    return this.userKeyMap[key]
  }

  getComboKeyName(key: string): string {
    return key + COMBO_SUFFIX
  }
  getUserKeyCombo(key: string): any {
    return this.getUserKey(this.getComboKeyName(key))
  }
  getOtherAttributeName(key: string) : string {
    return key + OTHER_SUFFIX
  }
  getOtherAttributeValue(key: string) : string {
    return this.getUserKey(this.getOtherAttributeName(key))
  }
  getUserKeyLastUsed(key:string) : any {
    return this.getUserKey(key + LASTUSED_SUFFIX)
  }

  getIdxUserKey(cid: string) { return `${cid}._current` }

  getUserKeysStartingWith(prefix:string) {
    const keys = []
    for (const key in this.userKeyMap) {
      if (key.startsWith(prefix))
        keys.push(key)
    }
    console.log("getUserKeysStartingWith", prefix, keys)
    return keys
  }

  setUserKeyLastUsed(key:string, val: unknown) {
    return this.setUserKey(key + LASTUSED_SUFFIX, val)
  }

  setTransientUserKey(key:string, val: unknown) {
    // console.log("setTransientUserKey", key, val)
    // Do not add to changelist
    this.setUserKeyFromValue(key, val)
  }

  setUserKeyFromValue(key:string, val: unknown) {
    const obj = this.userKeyMap[key]
    if (obj)
      this.userKeyMap[key].value = val
    else
      this.userKeyMap[key] = { value: val, key }
    this.userKeyCache[key] = val
  }

  areObjectsEqual(object1: any, object2: any): boolean {
    const keys1 = Object.keys(object1 || {})
    const keys2 = Object.keys(object2 || {})
    if (keys1.length !== keys2.length)
      return false

    for (const key of keys1) {
      const val1 = object1[key]
      const val2 = object2[key]
      const areObjects = this.isObject(val1) && this.isObject(val2)
      if (areObjects) {
        if (!this.areObjectsEqual(val1, val2))
          return false
      }
      else if (val1 !== val2)
        return false
    }
    return true
  }

  isObject(object: unknown): boolean {
    return object != null && typeof object === 'object';
  }

  setUserKey(key:string, val: unknown, force = false) {
    // console.log(`setUserKey: Entering with key ${key} val ${val}`)
    const entry = this.userKeyMap[key]
    if (!force && entry) {
      const changed = this.isObject(val) ? 
        !this.areObjectsEqual(val, entry.value) :
        (JSON.stringify(val) !== JSON.stringify(entry.value))
      if (changed) {
        // console.log("setUserKey: Detected change for", key, val, entry.value)
        this.setUserKeyFromValue(key, val)
        this.addChange(key)
      }
    }
    else {
      this.setUserKeyFromValue(key, val)    
      this.addChange(key)
    }
    // console.log("setUserKey exiting", key, val, this.userKeyMap)
    return val
  }

  appendUserKeyList(key: string, val: string) {
    // Append val to the end of the list at key
    const combokey = this.getComboKeyName(key)
    const arr = this.getUserKey(combokey) || []
    // console.log("appendUserKeyList", key, val, arr)
    if (val && !arr.includes(val)) {
      const newarr = [ ...arr, val ]
      this.setUserKey(combokey, newarr)
      return newarr
    }
    return arr
  }

  async deleteUserKey(key:string) {
    const docRef = this.firebase.getFirestoreDocRef(`${this.getUserKeysPath()}/${key}`)
    await deleteDoc(docRef)
    delete this.userKeyMap[key]
    delete this.userKeyCache[key]
    // console.log(`Deleted UserKey ${key}`)
  }

  async writeUserKey(key: string, doc: UserKeyDoc, merge = true) {
    // console.log("writeUserKey", key, doc)
    await this.firebase.updateAt(this.getUserKeyPath(null, key), doc, merge, UserKeyDocConverter)
  }

  private async updateUserKeys(set: Set<string>) {
    try {
      const changes = Array.from(set)
      // console.log("entering updateUserKeys with", changes)
      // Reset changelist right away in case new changes are incoming
      this.resetChangelist()
      for (const k of changes) {
        const obj = this.userKeyMap[k]
        if (obj) {
          // handles the create and update case
          const data = this.createUserKeyObject(k, { createdAt: obj.createdAt }, obj.value)
          if (!obj.createdAt) {
            // if saving the key for the first time, set the createdAt field
            obj.createdAt = data.updatedAt
            data.createdAt = data.updatedAt
          }
          await this.writeUserKey(k, data)
        }
      }
    }
    catch(err) {
      console.error("Error in updateUserKeys", err)
    }
  }
 
  createUserKeyObject(key: string, data: any, value: unknown) {
    const now = nowstrISO()
    return { key, createdAt: now, ...data, value, updatedAt: now } as UserKeyDoc
  }

  copyAttributesToUserKeys(prefix: string, attributes: any) {
    for (const key in attributes) {
      const value = attributes[key]
      this.setUserKey(`${prefix}.${key}`, value)
    }
  }

  incrementSequence(id: any) : string {
    const key = "seq." + id
    const val = this.getUserKey(key)
    const newVal = "" + (val ? parseInt(val) + 1 : 1)
    this.setUserKey(key, newVal)
    return newVal
  }

  putUserLinkFeedback(attributes:any) {
    this.firebase.updateAt(this.getUserLinkFeedbackPath(), attributes)
  }

  // Get all unread messages to display first and count
  getUnreadMessages() {   
    return this.firebase.collection$(this.getUserMessagesPath(), where('status', '==', 'UNREAD'), orderBy('createdAt', 'desc'))
      .pipe(
        map(msgs => msgs.map((msg:any) => ({ ...msg, createDate: new Date(msg.createdAt) })))
      )
  }
  
  getLastVisitDate() {
    // const { value } = await Storage.get({ key: TIMESTAMPKEY })
    // return value
    return this.getUserKey(LASTVISITDATE)
  }
  
  setLastVisitDate(date:string) {
    // await Storage.set({ key: TIMESTAMPKEY, value: date })
    return this.setUserKey(LASTVISITDATE, date)
  }

  removeLastVisitDate() {
    // await Storage.remove({ key: TIMESTAMPKEY })
    return this.setUserKey(LASTVISITDATE, null)
  }

  isFirstVisitToday(): boolean {
    const today = nowstr('yyyyMMdd')
    const value = this.getLastVisitDate()
    if ( value !== today) {
      console.log("Detected first visit today", today)
      this.setLastVisitDate(today)
      return true
    }
    return false
  }

  getPersonas(): string[] {
    return this.getUserKey(USER_PERSONAS_KEY) || []
  }

  setPersonas(personas: string[]) {
    console.log("setPersonas", personas)
    this.setUserKey(USER_PERSONAS_KEY, personas)
  }

  addPersona(p: string) {
    const personas = this.getPersonas()
    if (!personas.includes(p)) {
      this.setPersonas([ ...personas, p ])
      return true
    }
    return false
  }

  userHasPersona(p: string): boolean {
    // console.log("userHasPersona", p, this.getPersonas())
    return this.getPersonas().includes(p)
  }

  getUserEventWhere(actionType: string, eventAttr: string, val: any) {
    return this.firebase.collection$(this.getUserEventsPath(), 
      where('action', '==', actionType), 
      where(eventAttr, '==', val),
      orderBy('createdAt', 'desc'))
  }

  getUserEventsEnded() {
    return this.firebase.collection$(this.getUserEventsPath(), where('action', '==', 'ENDED'))
  }

  accessLockedContent(state:boolean) {
    this.setUserKey(ACCESS_LOCKED_CONTENT_KEY, state)
  }
  
  canAccessLockedContent() {
    return this.getUserKey(ACCESS_LOCKED_CONTENT_KEY)
  }

  isSoundEnabled() {
    return this.getUserKey(DISABLE_SOUND_KEY) !== 'yes'
  }
  
  async saveFeedback(entryType:string) {
    const entries = Object.entries(this.userKeyCache).filter(entry => entry[0].startsWith(`${entryType}.`))
    // Check that there are entries to save
    if (entries.length > 0) {
      const feedback = Object.fromEntries(entries)
      const data = { user: this.getCurrentUser()?.docId, feedback, createdAt: nowstr() }
      console.log("Saving feedback", data)
      this.firebase.callCloudFunction("sendFeedbackEmail", data)
        .subscribe(() => { console.log("sent feedback") })
    }
  }

  setInvitedBy(inviter:string) {
    this.firebase.updateAt(this.getUserPath(), { invitedBy: inviter } )
  }

  setMigrationStatus(migrateStatus: string) {
    console.log("setMigrationStatus", migrateStatus)
    this.firebase.updateAt(this.getUserPath(), { migrateStatus } )
  }

  setCurrentLevel(currentLevel: number) {
    console.log("userService.setCurrentLevel", currentLevel)
    this.firebase.updateAt(this.getUserPath(), { currentLevel } )
    this.setTransientUserKey(USER_CURRENT_LEVEL, currentLevel)
  }

  requestAccountDeletion() {
    const id = this.getCurrentUser()?.docId 
    this.firebase.callCloudFunction("requestAccountDeletion", {
      user: id,
      subject: `${this.environment.production ? "prod" : "dev"} user ${id} requested account deletion`,
      text: "Please delete the user manually in the admin portal."
    })
      .subscribe(() => { console.log("sent account deletion request") })
  }

  watchUserKey(userId: string|null|undefined, key: string) {
    return this.firebase.docWithConverter$(this.getUserKeyPath(userId, key), UserKeyDocConverter)
  }

  async readUserKey(userId: string, key: string) {
    const doc:UserKeyDoc = await firstValueFrom(this.watchUserKey(userId, key))
    console.log("readUserKey", doc)
    return doc.value
  }

  async setSeedBalance(balance: number) {
    await this.firebase.updateAt(this.getUserPath(), { seedBalance: balance })
  }
  
  async readSeedBalance(userId: string) {
    const userDoc = await this.getUserDoc(userId)
    console.log("seed balance", userDoc.seedBalance)
    return userDoc.seedBalance
  }

  hasSeedType(user: CheaseedUser, seedType: string) {
    const balance = user?.seedTypeBalance || {}
    return balance[ANY_SEED_TYPE] > 0 || balance[seedType] > 0
  }

  isGroupMember(user: CheaseedUser) {
    return !!user.groupDocId
  }

  async consumeSeedTypeFor(user: CheaseedUser, seedType: string) {
    const types = user.seedTypeBalance || {}    
    const typeToConsume = types[ANY_SEED_TYPE] > 0 ? ANY_SEED_TYPE : seedType
    const cnt = types[typeToConsume]
    if (cnt > 0) {
      if (cnt - 1 > 0)
        types[typeToConsume] = cnt - 1
      else {
        types[typeToConsume] = 0
        // delete types[typeToConsume]
      }
      await this.firebase.updateAt(this.getUserPath(), { seedTypeBalance: types })
    }
    else 
      throw new Error(`consumeSeedTypeFor: no seed type ${seedType} to consume`)
  }

  async incrementSeedTypeBalance(seedCredits: any) {
    const user = await this.getUserDoc(this.getCurrentUserId() as string) as CheaseedUser
    const types = user.seedTypeBalance || {}
    console.log("incrementSeedTypeBalance", { types, seedCredits })
    if (seedCredits) {
      Object.entries(seedCredits).forEach(([key, value]) => {
        const val = (typeof value === 'string' ? parseInt(value) : (value as number)) || 0
        types[key] = (types[key] || 0) + val
      })
    }
    console.log("incrementSeedTypeBalance updated to", types)
    await this.firebase.updateAt(this.getUserPath(), { seedTypeBalance: types })
  }

  async setStripeCustomerId(stripeCustomerId: string) {
    await this.firebase.updateAt(this.getUserPath(), { stripeCustomerId })
  }

  async incrementNumCoachesPurchased(user: CheaseedUser) {
    const numCoachesPurchased = (user.numCoachesPurchased || 0) + 1
    await this.firebase.updateAt(this.getUserPath(), { numCoachesPurchased })
  }

  async setStripePaymentInfoSaved(stripePaymentInfoSaved : boolean) {
    //await this.firebase.updateAt(this.getUserPath(), { stripePaymentInfoSaved })
  }

  async toggleUserKey(userId: string, key: string) {
    const val = await this.readUserKey(userId, key)
    const newval = val ? false : true
    await this.firebase.updateAt(this.getUserKeyPath(userId, key), { key: key, value: newval } )
  }

  isAnonymous() {
    return !!this.getCurrentUser()?.isAnonymousUser
  }

  getCurrentUser() {
    return this._user
  }

  getCurrentUserId() {
    return this.getCurrentUser()?.docId
  }

  getCurrentSeedBalance() {
    return this.getCurrentUser()?.seedBalance as number
    //return this.readSeedBalance(this.getCurrentUserId() as string)
  }

  async migrateUser(userId: string) {
    await firstValueFrom(this.firebase.callCloudFunction('migrateUserToHomeV2', { userId }))
  }

  filterUntilUserChanges() {
    return distinctUntilChanged((prev:CheaseedUser|null, curr) => prev?.docId === curr?.docId)
  }

  putUserEvent(attributes: any) {
    const ref = this.getUserEventsCollectionRef()
    const data = { ...attributes, createdAt: nowstr()}
    this.firebase.setData(ref, data)
    return data
  }

  isAzureOpenAI() {
    const val = this.getUserKey(AZURE_OPEN_AI)
    // return val !== undefined ? val as boolean: false 
    // flip to true for #3270
    return val === undefined ? true : val as boolean
  }

  initializeDefaultAttributeSpecs() {
    this.contentService.getValuesForDefaultAttributes({ userCreatedAt: this.getUserKey(USER_CREATED_AT) })
      .forEach((attr: any) => this.setUserKey(attr.key, attr.value))
  }

  deleteAllUserCollections() {
    return forkJoin([
      this.deleteUserCollection(UserCollectionTypes.KEYS),
      this.deleteUserCollection(UserCollectionTypes.ENTRIES),
      this.deleteUserCollection(UserCollectionTypes.EVENTS),
      this.deleteUserCollection(UserCollectionTypes.MESSAGES),
      this.deleteUserCollection(UserCollectionTypes.STREAKS),
      this.deleteUserCollection(UserCollectionTypes.PROGRAMS),
      this.deleteUserCollection(UserCollectionTypes.CHALLENGES),
      this.deleteUserCollection(UserCollectionTypes.LINKFEEDBACK),
      this.deleteUserCollection(UserCollectionTypes.LOGS),
      this.deleteUserCollection(UserCollectionTypes.HOMEITEMS),
      this.deleteUserCollection(UserCollectionTypes.PROMPTS),
    ])
  }

  async checkAnonymousUserConversion(userData: any) {
    if (!userData.isAnonymousUser) {
      const newUserDocId = userData.docId
      const anonUserId = window.sessionStorage?.getItem(ANONYMOUS_USER_ID)      
      if (anonUserId) {
        // Copy user data (keys, states) from anonymous user to new user
        console.log(`checkAnonymousUserConversion found ${anonUserId} in session storage`)
        await this.copyUserData(anonUserId, newUserDocId)
        sessionStorage.removeItem(ANONYMOUS_USER_ID)
      }
    }
    return userData
  }

  async requestLogin(user: CheaseedUser) {
    // console.log("requestLogin", user)
    if (!this.environment.production && !user.isAdminCertified) {
      this.devPasswordNeeded$.next(true)
      return
    }
    this.requestLogin$.next(true)
  }

  async setGroupDocId(userId: string, docId: string | undefined | null) {
    //console.log('setGroupDocId', user)
    await this.firebase.updateAt(this.getUserPath(userId), { groupDocId: docId })
    this.userGroupDocId$.next(docId)
  }

  async copyClonedUserKeys(user: CheaseedUser) {
    // Get user.docId userkeys `users/${user.docId}/keys`
    // Write all keys to this user in a batch
    const curruser = await firstValueFrom(this.user$)
    const userKeys = await firstValueFrom(this.firebase.collection$(this.getUserKeysPath(user.docId)))
    const newColl = this.getUserKeysCollectionRef(curruser?.docId)
    const batch = this.firebase.writeBatch()
    for (const k of userKeys) {
      const key = k.key
      console.log('copyClonedUserKey', key)
      try {
        if (key !== PROMPT_STATUS_KEY) {
          k.updatedAt = new Date() // Force updatedAt to now to ensure it is loaded into cache
          batch.set(doc(newColl, key), k)
        }
      }
      catch (err) {
        console.error(`Error copying userkey ${key}, continuing`, k, err)
      }
    }
    await batch.commit()
  }
}
