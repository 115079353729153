/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable, NgZone, SecurityContext } from '@angular/core'
import { BehaviorSubject, firstValueFrom, timer, combineLatestWith, debounceTime, filter, switchMap, timeout, withLatestFrom, distinctUntilChanged, combineLatest, catchError, TimeoutError, retry, throwError } from 'rxjs'
import { ActionSheetController, AnimationController, Platform } from '@ionic/angular'
import { Router } from '@angular/router'
import { Haptics, ImpactStyle } from '@capacitor/haptics'
import { DomSanitizer, SafeHtml, SafeResourceUrl } from '@angular/platform-browser';
import { AudioService } from './audio.service'
import { ChatQueueService } from './chat-queue.service'
import { ContentService } from './content.service'
import { EvaluatorService } from './evaluator.service'
import { FirebaseService } from './firebase.service'
import { FormService } from './form.service'
import { HandlebarsService } from './handlebars.service'
import { SharedEventService } from './shared-event.service'
import { SharedMessageService } from './shared-message.service'
import { CheaseedUser, SharedUserService } from './shared-user.service'
import { TaskSchedulerService } from './task-scheduler.service'
import { ProgramService } from './program.service'
import {
  AirtableBase,
  AppSource,
  BEHAVIOR_CLEARABLE,
  BEHAVIOR_CLEAR_CURRENT_VALUE,
  BEHAVIOR_DO_NOT_SHARE,
  BEHAVIOR_DO_NOT_TRACK,
  BEHAVIOR_DO_NOT_UPDATE_CHAT_QUEUES,
  BEHAVIOR_PREVENT_BACK_NAVIGATION,
  BEHAVIOR_ALLOW_AUTHENTICATION,
  BEHAVIOR_REQUIRE_AUTHENTICATION,
  BEHAVIOR_REQUIRE_PAYMENT,
  BEHAVIOR_RESPONSE_REQUIRED,
  BEHAVIOR_ROUTE_TO_HOME_ON_COMPLETION,
  BEHAVIOR_ROUTE_TO_TRACK_ON_COMPLETION,
  BEHAVIOR_SKIP_COMPLETION_CARD,
  BEHAVIOR_START_PROMPT_CHAIN,
  BEHAVIOR_USE_GENERATED_OPTIONS,
  BEHAVIOR_WAIT_FOR_PROMPT_RESPONSE,
  GUIDESHARE_MSG,
  PromptStatusValue,
  SCRIPTSHARE_MSG,
  UNPINNABLE,
  USER_LAST_CHAT_ID,
  UserKeyDoc,
  formatDate,
  nowstr,
  timestr,
  evaljson,
  ExtractService,
  STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER,
  BEHAVIOR_USER_NEXT_TO_ADVANCE,
  STRIPE_CANCELLED_TRANSACTION_IDENTIFIER,
  Conversation
} from '@cheaseed/node-utils';
import { MediaService } from './media.service'
import { EntryService } from './entry.service'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ChatState, ChatStateError, ChatStateService } from './chat-state.service'
import { PromptService } from './prompt.service'
import { OpenAIDriverService } from './openai-driver.service'
import { CheaseedStripeService } from './stripe.service'
import { GroupService } from './group.service'

export const LIVE_MODE_REFRESH_SECS = 60
const TIMEOUT_PROMPT_STATUS_MINS =  10  // 10 minute timeout for RUNNING prompt status

@Injectable({
  providedIn: 'root'
})
export class SharedChatService {

  nextConversation$ = new BehaviorSubject<string | null | undefined>(undefined)
  nextStatement$ = new BehaviorSubject<string | null>(null)
  isPinned$ = new BehaviorSubject(false)
  isStatementLoaded$ = new BehaviorSubject(false)

  currstmt: any = null
  currerror$ = new BehaviorSubject<any>(null)
  currentAttribute$ = new BehaviorSubject<any>(null)
  currentTextRendering$ = new BehaviorSubject<SafeHtml | null>(null)

  showProgress = false

  secsToLiveModeRefresh: number = LIVE_MODE_REFRESH_SECS
  liveMode = false
  disableClearCurrentValue = false
  videoURL: SafeResourceUrl | null = null
  conversation: any = null

  private lastValue:any = null
  private lastPromptChainStartIndex: number | undefined = undefined
  public launchSource: string | null = null
  private statementHistory: any[] | null = null
  private curIndex = -1
  private lastStatementStart = 0
  private chatFeedbackEntered = false
  disableNavigation$ = new BehaviorSubject(false)
  disableBackNavigation$ = new BehaviorSubject(false)
  chatPromptStatus$ = new BehaviorSubject<any>({ waiting: false })
  chatProgress$ = new BehaviorSubject<any>({})
  waitingForAuthentication$ = new BehaviorSubject(false)
  waitingForPurchase$ = new BehaviorSubject(false)
  waitingForCredit$ = new BehaviorSubject<string | null>(null)
  purchaseCompleted$ = new BehaviorSubject<any>(null)
  lastOtherValue$ = new BehaviorSubject('')
  private checkPromptStatusTimeout$ = new BehaviorSubject(false)
  private forcePrepareStatement$ = new BehaviorSubject(false)
  private promptStatusErrorNotify$ = new BehaviorSubject(false)
  private promptStatusErrorAlertActive$ = new BehaviorSubject(false)
  
  constructor(
    @Inject('environment') private environment: any,
    @Inject('AirtableService') private airtableService: AirtableBase,
    @Inject('UtilityService') private utilityService: any,
    @Inject('ExtractService') private extractService: ExtractService,
    //@Inject('GroupService') private groupService: GroupService,
    private platform: Platform,
    private audio: AudioService,
    private contentService: ContentService,
    private chatStateService: ChatStateService,
    private entryService: EntryService,
    private messageService: SharedMessageService,
    private userService: SharedUserService,
    private handlebars: HandlebarsService,
    private mediaService: MediaService,
    private evaluatorService: EvaluatorService,
    private eventService: SharedEventService,
    private formService: FormService,
    private actionSheetController: ActionSheetController,
    private chatQueueService: ChatQueueService,
    private programService: ProgramService,
    private promptService: PromptService,
    private taskSchedulerService: TaskSchedulerService,
    private firebase: FirebaseService,
    private sanitizer: DomSanitizer,
    private openai: OpenAIDriverService,
    private stripeService: CheaseedStripeService,
    private animationCtrl: AnimationController,
    private ngZone: NgZone,
    private router: Router,
    private groupService: GroupService
  ) {

    this.openai.promptStatus$
      .pipe(
        combineLatestWith(this.userService.lastUserKeyRefresh$), // this allows us to recheck if userkey updates after prompt status
        debounceTime(200),  // debounce to avoid repeated reprepares due to rapid changes to lastUserKeyRefresh$
        // filter(([ status, ]) => [ 'COMPLETED', 'ERROR' ].includes(status?.status as string)),
        timeout(TIMEOUT_PROMPT_STATUS_MINS * 60 * 1000),
        catchError(error => {
          // console.warn('promptStatus$ error', error)
          if (error instanceof TimeoutError) {
            this.checkPromptStatusTimeout$.next(true)
          }
          return throwError(() => error)
        }),
        retry(),
        takeUntilDestroyed()
      )
      .subscribe(data => {
        if (data) {
          const [ status, ] = data
          if (status?.status === 'RUNNING') {
            this.chatPromptStatus$.next(
              this.isWaitingForResponse(status) 
                ? { waiting: true, spinnerTitle: status.spinnerTitle } 
                : { waiting: false }
            )
          }
          else if (status?.status === 'INITIALIZING') {
            this.chatPromptStatus$.next({ waiting: true, spinnerTitle: status.spinnerTitle })
          }
          else if (status?.status === 'COMPLETED') {
            // Check that promptUserKey was updated in userkey cache recently and force status to NOTIFIED
            const promptUserKey = status?.promptUserKey as string
            if (!promptUserKey || this.isPromptStatusUserKeyReady(status)) {
              if (this.currstmt && this.getPromptsToWaitFor().length > 0) {
                this.reprepareCurrentStatement()
              } 
              this.openai.updatePromptStatusKeyNotified(status)
                .then(() => this.chatPromptStatus$.next({ waiting: false }))
            }
            else {
              const keyDoc = this.userService.getUserKeyDoc(promptUserKey as string) as UserKeyDoc
              console.log(`lastUserKeyRefresh$ triggered, but ${promptUserKey} was not updated in this prompt chain, last update was ${keyDoc.updatedAt}`)
              timer(10000).subscribe(() => this.userService.ensureKeyWritten(promptUserKey, keyDoc))
            }
          }
          else if (status?.status === 'NOTIFIED') {
            // Ensure we're not waiting anymore
            // console.log("Prompt status received as NOTIFIED, ensuring chatPromptStatus is not waiting")
            this.chatPromptStatus$.next({ waiting: false })
          }
          else if (status?.status === 'ERROR') {
            const usermsg = this.contentService.getGlobal('prompt.error.unrecoverable.message') || 'We encountered an unrecoverable error and are unable to continue. Please try again later.'
            if (!this.promptStatusErrorAlertActive$.value) { // guard against multiple alerts
              this.utilityService.notify({ 
                message: usermsg,
                cancel: () => this.promptStatusErrorNotify$.next(true)
              })
              this.promptStatusErrorAlertActive$.next(true)
            }            
          }
        }
      })

    this.promptStatusErrorNotify$
      .pipe(
        filter(notify => !!notify),
        withLatestFrom(this.openai.promptStatus$)
      ).subscribe(([, status]) => {
        this.openai.updatePromptStatusKeyNotified(status)
        this.chatPromptStatus$.next({ waiting: false })
        this.chatStateService.resetCurrentToLastPromptChainStart(status?.chatName as string, this.conversation.statements)
        this.setNextConversation(null)
        this.promptStatusErrorNotify$.next(false)        
        this.promptStatusErrorAlertActive$.next(false)
        throw new Error(`Prompt status error: ${JSON.stringify(status)}`)
      })
    
    this.userService.isAdminRole$
      .pipe(takeUntilDestroyed())
      .subscribe(isAdmin => this.showProgress = isAdmin)

    this.checkPromptStatusTimeout$
      .pipe(
        filter(check => !!check), // we have a timeout to check
        withLatestFrom(this.openai.promptStatus$),
        debounceTime(1000),
        takeUntilDestroyed())
      .subscribe(([ , promptStatus]) => {
        this.checkPromptStatusTimeout$.next(false)
        if (promptStatus?.status === 'RUNNING') {
          console.log("checkPromptStatusTimeout", promptStatus)
          // Write an error prompt status
          this.openai.updatePromptStatusKey({
            ...promptStatus,
            status: 'ERROR',
            error: { 
              message: `Prompt status ${promptStatus?.status} timeout`,
              type: 'TimeoutError',
              code: 'PROMPT_STATUS_TIMEOUT'
            }
            },
            promptStatus.createdAt)
        }
      })

    // Handle aborted anonymous conversions when in chat
    this.userService.abortedAnonymousConversion$
      .pipe(
        filter(aborted => !!aborted),
        withLatestFrom(this.userService.userInChat$, this.nextConversation$),
        takeUntilDestroyed())
      .subscribe(([ , inChat, chatName ]) => {
        if (inChat) {  // only if user was in a chat
          this.setNextConversation(null)
          if (chatName) { 
            this.utilityService.notify({ 
              message: 'We detected a previous unfinished coaching session. Tap Continue to resume where you left off.',
              okText: 'Continue',
              cancel: () => this.ngZone.run(() => { this.router.navigate([ '/conversation', chatName ]) })
            })
          }
        }
        this.userService.abortedAnonymousConversion$.next(false)
      })

    this.forcePrepareStatement$
      .pipe(
        filter(force => !!force),
        takeUntilDestroyed())
      .subscribe(() => {
        if (this.currstmt) {
          console.log("repreparing attribute")
          this.prepareAttribute()
          this.formatTextBlock()
        }
        this.forcePrepareStatement$.next(false)
      })

    // Whenever completionMap changes, reprepare the current statement
    this.chatStateService.completionMap$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.forcePrepareStatement$.next(true)
      })

    this.waitingForAuthentication$
      .pipe(
        filter(waiting => !!waiting),
        switchMap(() => this.userService.requireLogin$),
        filter(requireLogin => !requireLogin),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        this.waitingForAuthentication$.next(false)
        this.reprepareCurrentStatement()
      })

    this.waitingForPurchase$
      .pipe(
        filter(waiting => !!waiting),
        switchMap(() => this.stripeService.stripePaymentConsumable$),
        // Only advance if the transactionIdentifier changes
        distinctUntilChanged((prev, curr) => {
          return prev?.purchaseSource.transactionIdentifier === curr?.purchaseSource.transactionIdentifier
        }),
        // Only advance if the transactionIdentifier is not 'WEB' or actual txn id
        filter(consumable => ![ STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER, STRIPE_CANCELLED_TRANSACTION_IDENTIFIER ].includes(consumable?.purchaseSource.transactionIdentifier)),
        withLatestFrom(this.nextConversation$),
        takeUntilDestroyed()
      )
      .subscribe(async ([ consumable, convname ]) => {
        console.log("tear down purchase", consumable, convname)
        // convname is undefined if purchase made outside the context of a chat (admin menu for example)
        if (convname) {
          this.waitingForCredit$.next(convname)          
          this.reprepareCurrentStatement()
        }
        this.waitingForPurchase$.next(false)
        this.userService.requestPayment$.next(undefined)
      })

    combineLatest([ this.waitingForCredit$, this.userService.user$ ])
      .pipe(
        filter(([ convname, user ]) => !!convname && !!user),
        takeUntilDestroyed())
      .subscribe(async ([ convname, user ]) => {
        console.log("waitingForCredit", convname, user?.seedTypeBalance)
        if (user) {
          const conv = this.contentService.getConversationNamed(convname as string)
          const seedType = conv?.seedType as string
          if (this.userService.hasSeedType(user, seedType)) {
            this.waitingForCredit$.next(null)
            await this.consumeSeedType(convname as string, user as CheaseedUser, seedType, "Your purchase was successful.\n")
            this.reprepareCurrentStatement()
          }
        }
      })
  }

  async consumeSeedType(convname: string, user: CheaseedUser, seedType: string, purchaseMessage = '') {
    console.log("consumeSeedType", seedType, convname, purchaseMessage)
    await this.userService.consumeSeedTypeFor(user, seedType)
    await this.chatStateService.setChatPurchased(convname)
    this.userService.incrementNumCoachesPurchased(user)
    this.eventService.recordSeedsConsumedByChatEvent(
      {
        seedType: seedType,
        seedsUsed: 1,
        chatId: convname
      })
    this.purchaseCompleted$.next({ seedType, purchaseMessage })
  }

  private isPromptStatusUserKeyReady(status: PromptStatusValue) {
    // Called with both RUNNING (chain subset) and COMPLETED status
    const promptUserKey = status.promptUserKey as string
    const lastPromptChainStart = new Date(status.createdAt as string)
    return this.isUserKeyUpdatedSince(promptUserKey, lastPromptChainStart)
  }

  private isUserKeyUpdatedSince(key: string, sinceTime: Date) {
    const keyDoc = this.userService.getUserKeyDoc(key)
    if (!keyDoc) return false
    const lastKeyUpdate = new Date(keyDoc.updatedAt as string)
    const result = lastKeyUpdate > sinceTime
    console.log(`isUserKeyUpdated ${key} ${result}`)
    return result
  }

  reprepareCurrentStatement() {
    this.forcePrepareStatement$.next(true)
  }

  // Called on enter and leave a chat
  setNextConversation(id: string | null) {
    this.setStatementLoadedSubject(false)
    this.conversation = null
    this.nextConversation$.next(id)
    this.userService.userInChat$.next(!!id)
  }

  currentChatHasBehavior(b: string): boolean {
    return this.conversationHasBehavior(this.conversation, b)
  }

  conversationHasBehavior(c: any, b: string): boolean {
    return c?.behaviors?.includes(b)
  }

  async checkQueueAsNewsflash(id: string) {
    const conv = this.contentService.getConversationNamed(id)
    if (this.conversationHasBehavior(conv, 'QueueAsNewsflash'))
      await this.messageService.generateChatMessage(this.userService.getCurrentUserId() as string, conv)
  }

  async checkPushAsNewsflash(id: string) {
    const conv = this.contentService.getConversationNamed(id)
    if (this.conversationHasBehavior(conv, 'PushAsNewsflash'))
      await this.messageService.generateChatMessage(this.userService.getCurrentUserId() as string, conv)
  }

  getSeriesForChat(c: any) {
    const first = c.series.size ? Array.from(c.series)[0] : null
    const series = this.contentService.pathMap.get(first as string)
    const seriesIndex = series?.conversations.findIndex((conv: { name: string }) => c.name === conv.name)
    return { seriesIndex, series }
  }

  async shareTakeawayLink(id: string, conv: string) {
    const conversation = this.contentService.getConversationNamed(conv)
    this.router.navigate([
      'tileshare', id,
      { isLink: true, conversationType: conversation?.type }
    ])
  }

  async clickTakeawayTile(id: string, backLabel: string | null, onlyShareFlag = false) {
    const conv = id.split('.')[0]
    // Read conversation from replay if unchanged
    const conversation = await this.contentService.getFullChat(conv)
    // console.log("clickTakeawayTile", id, conversation)
    const title = "Quick Action",
      buttons: any[] = [
        {
          text: "Share Image",
          handler: () => {
            this.router.navigate(['tileshare', id])
          }
        },
        {
          text: "Share Link",
          handler: () => {
            this.shareTakeawayLink(id, conv)
          }
        }
      ]
    const doNotShare = conversation?.behaviors?.includes('DoNotShare')
    if (!doNotShare) {
      buttons.push({
        text: "Share Chat",
        handler: async () => {
          this.shareChat(conversation)
        }
      })
    }
    if (!onlyShareFlag) {
      buttons.push({
        text: "Take me to this Chat",
        handler: () => {
          this.ngZone.run(() => {
            this.router.navigate(['conversation', conversation?.name, { backLabel }])
          })
        }
      })
    }
    buttons.push({
      icon: 'close',
      text: 'Not Now',
      cssClass: 'cancel-button'
    })
    // TODO: Check that this code runs and that we want it to
    if (!onlyShareFlag && conversation.completionAction) {
      buttons.unshift({
        text: conversation.completionAction.message,
        handler: () => {
          // this.ngZone.run(() => {
          this.clickCompletionAction(conversation.completionAction, backLabel)
          // })
        }
      })
    }
    const ac = await this.actionSheetController.create({
      header: title,
      cssClass: "plus-action-sheet",
      translucent: false,
      buttons: buttons
    })
    return await ac.present()
  }

  clickCompletionAction(action: any, backLabel?: string | null, launchSource?: string) {
    switch (action.actionType) {
      case "entry":
        this.router.navigate(['sectionentryform', { entrySpecId: action.entryActionId?.name }])
        break
      case "chat":
        this.router.navigate(['conversation', action.chatActionId?.name, { backLabel, launchSource }])
        break
      case "route":
        this.router.navigateByUrl(action.routeActionId)
        break
    }
  }

  prepareSources() {
    const { hasSources, areas } = this.prepareSourceAreas(this.currstmt)
    this.currstmt.hasSources = hasSources
    this.currstmt.areas = areas
  }

  hasSources() {
    return this.currstmt?.hasSources
  }

  prepareSourceAreas(statement: any) {
    // handle sources
    const hasSources = (
      statement.primaryEvidenceSources?.length
      || statement.secondaryEvidenceSources?.length
      || statement.opinionSources?.length
      || statement.exampleSources?.length
      || statement.productSources?.length
      || statement.classSources?.length
      || statement.tipSources?.length)
    const areas = []
    areas.push({ name: "Backup", items: statement.primaryEvidenceSources?.concat(statement.secondaryEvidenceSources) })
    areas.push({ name: "Opinion", items: statement.opinionSources })
    areas.push({ name: "Examples", items: statement.exampleSources })
    areas.push({ name: "Products", items: statement.productSources })
    areas.push({ name: "Classes", items: statement.classSources })
    areas.push({ name: "Tips", items: statement.tipSources })
    for (const area of areas) {
      for (const item of area.items) {
        if (item.title) {
          item.formattedTitle = this.formatText(item.title)
        }
      }
    }
    return { hasSources, areas }
  }

  setLastValue(val: any) {
    this.lastValue = val
    // store for use in evaluator and handlebars
    this.userService.setTransientUserKey('lastResponse', val)
  }

  getOptionDict(opts: any) {
    return Object.fromEntries(opts.map((opt: any) => [opt.name, !!opt.isChecked]))
  }

  attributeHasBehavior(attribute: any, b: string) {
    return attribute?.behaviors?.includes(b)
  }

  async processAttribute() {
    if (this.currstmt?.attribute) {
      await this.saveAttribute(this.currstmt)
      this.evaluatorService.assignPersonas()
    }
  }

  async saveAttribute(statement: any) {
    const attribute = statement.attribute
    this.setLastValue(attribute.value)
    // console.log("Attempting to save attribute", attribute, this.lastValue)
    if (attribute.changed) {
      let value = attribute.value
      if (attribute.inputType === 'MULTIOPTIONS') {
        value = this.getOptionDict(attribute.optionLinks)
        // console.log("Computed MULTIOPTIONS value as", value)
        this.setLastValue(value)
        // Special case for MULTIOPTIONS -- clear the userkey so results are not merged
        await this.userService.deleteUserKey(attribute.name)
      }
      else if (attribute.inputType === 'DATE') {
        console.log(`saveAttribute attempting to format date ${value}`)
        const str = formatDate(value)
        console.log(`saveAttribute converting date ${value} to ${str}`)
        value = str
      }
      else if (attribute.inputType === 'HOUROFDAY') {
        const militaryTime = formatDate(value, "HH00")
        console.log(`saveAttribute converting HOUROFDAY ${value} to ${militaryTime}`)
        value = militaryTime
      }
      // If Other is selected
      if (attribute.inputSubtype === 'IncludeOther' || attribute.inputType === 'COMBOBOX') {
        const k = this.userService.getOtherAttributeName(attribute.name)
        if (attribute.otherChecked && attribute.otherValue) {
          // rewrite attribute value with otherValue
          value = attribute.otherValue
          this.userService.setUserKey(k, attribute.otherValue)
          this.userService.setUserKeyLastUsed(attribute.attributeName, attribute.otherValue)
          this.userService.appendUserKeyList(attribute.attributeName, attribute.otherValue)
        }
        else if (this.userService.getUserKey(k))
          this.userService.deleteUserKey(k)
      }
      // Write the attribute key
      this.userService.setUserKey(attribute.name, value)
      if (this.attributeHasBehavior(attribute, "saveAsComboValue")) {
        this.userService.setUserKeyLastUsed(attribute.attributeName, value)
        this.userService.appendUserKeyList(attribute.attributeName, value)
      }
      if (this.attributeHasBehavior(attribute, "syncUserProperty")) {
        // Convert attribute name to Clevertap friendly user property name replace('.', '_')
        const propName = attribute.name.replace('.', '_')
        // Add user property to user profile
        this.eventService.setProfile(Object.fromEntries([[propName, value]]))
      }
    }
  }

  prepareOptionLinks(attribute: any) {
    const result = []
    let currerror = null
    for (const opt of attribute.optionLinks) {
      const curr = { ...opt }
      curr.disabled = false
      const description = (attribute.inputSubtype === 'Chat'
        ? this.contentService.getConversationNamed(opt.description)?.title 
        : curr.description) || 'MISSING DESCRIPTION: ' + curr.name
      curr.formattedDescription = this.formatText(description)
      if (curr.enableIf) {
        currerror = null
        try {
          curr.disabled = !this.evaluatorService.evaluate(curr.enableIf, this.userService.getCache())
          result.push(curr)
        }
        catch (err) {
          console.error(err)
          currerror = { error: err, source: curr.enableIf }
        }
      }
      else
        result.push(curr)
    }
    attribute.currerror = currerror
    return result.filter((opt: any) => !opt.disabled)
  }

  prepareAttribute() {
    const spec = this.currstmt.attributeSpec
    let attribute = null
    if (spec) {
      const inputTypeParams = spec.inputTypeParams || {}
      attribute = { ...spec }
      let links = attribute.optionLinks
      if (links?.length === 0 && attribute.behaviors?.includes(BEHAVIOR_USE_GENERATED_OPTIONS)) {
        let nameKey:string, descKey:string, optionsKey:string
        if (spec.inputTypeParams) {
          attribute.multiParams = inputTypeParams
          optionsKey = attribute.multiParams.optionsKey
          nameKey = inputTypeParams.optionNameKey || 'name'
          descKey = inputTypeParams.optionDescriptionKey || 'description' 
        }
        else {
          optionsKey = `generated.${attribute.name}`
          nameKey = 'name'
          descKey = 'description' 
        }
        // Handle list extraction from a global that has a markdown heading
        const fromGlobalHeading = attribute.multiParams?.fromGlobalHeading
        if (fromGlobalHeading) {
          const content = this.handlebars.formatWithUserKeys(this.contentService.getGlobal(optionsKey), true) || ''
          console.log(`prepareAttribute evaluated ${optionsKey} content`, content)
          links = this.extractService.extractListFrom(null, content as string, fromGlobalHeading)
          console.log(`prepareAttribute extracted ${fromGlobalHeading}`, links)
          const targetKey = attribute.multiParams?.targetOptionsKey
          if (targetKey)
            this.userService.setUserKey(targetKey, links)
        }
        else {
          links = this.userService.getUserKey(optionsKey) || []
        }
        links = Array.isArray(links) ? links : []
        // Verify links structure...
        links = links.map((item: any) => {
          if (typeof item === 'string')
            return ({
              name: item,
              description: item,
              formattedDescription: item
            })
          else
            return ({
              name: item[nameKey],
              description: item[descKey],
              formattedDescription: item[descKey]
            })
        })
        attribute.optionLinks = links
      }
      // Ensure formatting of optionLinks
      links = this.prepareOptionLinks(attribute)
      attribute.optionLinks = links
      attribute.value = this.userService.getUserKey(attribute.name)
      if (attribute.behaviors?.includes(BEHAVIOR_CLEAR_CURRENT_VALUE) && !this.disableClearCurrentValue) {
        attribute.value = undefined // should prevent writing to Firestore if not already there
        console.log(`Found ${BEHAVIOR_CLEAR_CURRENT_VALUE} for attribute ${attribute.name}`)
      }
      attribute.clearable = attribute.behaviors?.includes(BEHAVIOR_CLEARABLE)
      // console.log("set attribute.value to ", attribute.value)
      // Reset value if it doesn't exist among optionLinks
      if (attribute.inputType === 'OPTIONS' && links?.length > 0 && !links.map((o: { name: string }) => o.name).includes(attribute.value))
        attribute.value = null
      if (links?.length > 0 && attribute.inputType === 'MULTIOPTIONS' && attribute.value) {
        const obj = attribute.value
        // console.log("prepareAttribute", links, obj)
        for (const opt of links)
          opt.isChecked = obj[opt.name]
        // Ensure that attribute value only contains options in current links
        // attribute.value = Object.fromEntries(links.map((opt:any) => [ opt.name, opt.isChecked ]))
        // console.log("prepareAttribute", attribute.value, links)
      }
      if (attribute.inputSubtype === 'IncludeOther' || attribute.inputType === 'COMBOBOX') {
        const k = this.userService.getOtherAttributeName(attribute.name)
        this.lastOtherValue$.next('')
        attribute.otherValue = this.userService.getUserKey(k)
        attribute.otherChecked = attribute.otherValue ? true : false
        // console.log("Set otherValue to ", attribute.otherValue)
        if (attribute.inputType === 'COMBOBOX')
          this.formService.assignComboBoxChoiceList(attribute, attribute.otherValue || attribute.value)
      }
      if (attribute.inputType === 'HOUROFDAY') {
        if (attribute.value) {
          const time = timestr(attribute.value / 100, 0)
          // console.log(`prepareAttribute converting ${attribute.value} to ${time}`)
          attribute.value = time
        }
        else {
          const time = nowstr("yyyy-MM-dd'T'HH:mm:ss")
          // console.log(`prepareAttribute converting ${attribute.value} to ${time}`)
          attribute.value = time
          attribute.changed = true
        }
      }
      if (attribute.inputType === 'DATE') {
        // DATE widget works best with a local Date object
        attribute.value = attribute.value ? new Date(attribute.value) : undefined
        if (this.attributeHasBehavior(attribute, 'minOneWeekFromNow')) {
          const d = new Date()
          d.setDate(d.getDate() + 7)
          attribute.minDate = d
          // console.log("getMinDatePickerDate", d)
        }
      }
      if (attribute.inputType === 'SLIDER') {
        attribute.sliderParams = inputTypeParams
      }
      if (attribute.inputType === 'TEXTAREA') {
        attribute.buttonParams = inputTypeParams
      }
      if (attribute.inputType === 'RANKER') {
        const selectionKey = attribute.multiParams?.selectionKey
        const selectionVal = this.userService.getUserKey(selectionKey) || {}
        // console.log("prepareAttribute RANKER", { attribute, selectionKey, selectionVal })
        // Prune optionLinks to only include those selected
        const items = attribute.multiParams
          ? attribute.optionLinks.filter((opt: any) => !!selectionVal[opt.name])
          : attribute.optionLinks
        const map = new Map(items.map((item:any) => [ item.name, item ]))
        const ordering = attribute.value && Array.isArray(attribute.value) && attribute.value.length === items.length
          ? attribute.value.map((name:string) => map.get(name))
          : null
        // console.log("RANKER", items, ordering, attribute)
        if (ordering && !ordering.some((item:any) => !item)) {
          attribute.optionLinks = ordering
        }
        else {
          attribute.optionLinks = items
          attribute.value = items.map((item:any) => item.name)
          attribute.changed = true
        }
      }
      if (!attribute.value) {
        this.formService.populateDefault(attribute, {})
      }
      if (attribute.question)
        attribute.formattedQuestion = this.formatText(attribute.question)
    }
    this.currstmt.isSingleSelectAttribute = ['OPTIONS', 'RADIOCHIPS'].includes(attribute?.inputType)
    this.currstmt.attribute = attribute
    this.currstmt.showNextButton = this.shouldShowNextButton()
    this.currentAttribute$.next(attribute)
    // console.log("prepareAttribute", attribute)
    return this.currentAttribute$
  }

  multiOptionSelected(changedOption: any, attr: any) {
    attr.changed = true
    // Handle none and all options
    const noneOption = attr.optionLinks.find((opt: any) => opt.behaviors?.includes('isMultiOptionNone'))
    const allOption = attr.optionLinks.find((opt: any) => opt.behaviors?.includes('isMultiOptionAll'))
    if (noneOption) {
      // console.log("multiOptionSelected", changedOption, noneOption, changedOption.isChecked, noneOption.isChecked)
      if (changedOption.name !== noneOption.name) {
        if (changedOption.isChecked) {
          console.log("disabling noneOption")
          noneOption.disabled = true
        }
        else if (!attr.optionLinks.find((opt: any) => opt.isChecked)) {
          console.log("enabling noneOption")
          noneOption.disabled = false
        }
      }
      else if (changedOption.name === noneOption.name) {
        if (changedOption.isChecked) {
          console.log("disabling all others")
          attr.optionLinks.filter((opt: any) => opt !== noneOption).forEach((opt: any) => { opt.disabled = true })
        }
        else {
          console.log("enabling all others")
          attr.optionLinks.forEach((opt: any) => { opt.disabled = false })
        }
      }
    }
    if (allOption) {
      if (changedOption.name === allOption.name) {
        if (changedOption.isChecked) {
          console.log("enabling and checking all others")
          attr.optionLinks.forEach((opt: any) => { opt.disabled = false; opt.isChecked = !opt.behaviors?.includes('isMultiOptionNone') })
        }
        else {
          console.log("enabling and unchecking all others")
          attr.optionLinks.forEach((opt: any) => { opt.disabled = false; opt.isChecked = false })
        }
      }
      else if (!changedOption.isChecked) {
        console.log("unchecking the all option")
        allOption.disabled = false
        allOption.isChecked = false
      }
    }
    // Must reset to trigger change detection
    this.currstmt.attribute = attr
    this.currentAttribute$.next(attr)
    // console.log("multiOptionSelected", changedOption, noneOption, allOption, this.currstmt.attribute.optionLinks)
  }

  setPinnedSubject(isPinned: boolean) {
    this.isPinned$.next(isPinned)
  }

  async setCurrentChat(name: string) {
    const conv = this.contentService.getConversationNamed(name)
    if (!conv)
      throw new Error(`Conversation ${name} not found`)
    const chat = await this.contentService.getFullChat(name)
    const lastChatId = await this.chatStateService.getChatId(name)  // creates chatId if needed
    this.userService.setUserKey(USER_LAST_CHAT_ID, lastChatId)
    return { ...conv, ...chat }
  }

  async getConversation(conversationId: string, params: any) {
    console.log("getConversation", this.conversation, conversationId, params)
    this.launchSource = params.launchSource
    this.conversation = await this.setCurrentChat(conversationId)

    const cname = this.getCurrentConversationName()
    if (this.launchedInApp()) {
      this.setPinnedSubject(this.chatQueueService.isPinned(cname))
      // Remove any unread messages about this conversation
      this.messageService.markChatAsViewed(cname)
      // Always remove from playlist and upnext
      this.chatQueueService.removeUpnext(cname)
      // Remove from recommended chats
      this.chatQueueService.removeRecommendedChat(cname)
    }
    if(this.launchedInAppOrPortal()) {
      this.eventService.recordConversationStart({
        id: cname,
        backLabel: params.backLabel,
        launchSource: this.launchSource,
        series: this.conversation.series,
        topics: this.conversation.topics
      })
    }

    // Process any clearOnLaunch attributes
    if (!params.arrivedViaBackButton) {
      // console.log("About to clearOnLaunch", this.conversation)
      this.conversation.statements
        .filter((s: any) => s.attributeSpec)
        .map((s: any) => this.contentService.getAttributeSpec(s.attributeSpec.name))
        .filter((attr: any) => attr?.behaviors?.includes('clearOnLaunch'))
        .forEach((attr: any) => {
          // console.log("clearOnLaunch", attr.id)
          this.userService.setUserKey(attr.id, null)
        })
    }
    this.extractService.reset()
    // Reset Evaluator service context (important for enableIfs and onCompletion evaluation!)
    this.evaluatorService.reset()
    this.evaluatorService.resetTrackedEntries()
    // Check for PreventBackNavigation behavior
    this.disableBackNavigation$.next(this.conversation.behaviors?.includes(BEHAVIOR_PREVENT_BACK_NAVIGATION))

    this.statementHistory = []
    this.lastPromptChainStartIndex = undefined
    const chatState = await this.chatStateService.getChatState(cname)
    
    this.curIndex = params.restartFlag
      ? -1
      : this.shouldTrackConversation() // rewind because getNextIndex will increment
        ? ((chatState?.currentIndex || 0) - 1)
        : -1

    if (this.curIndex > this.conversation.statements.length) {
      // handle errant index beyond the length of the conversation, reset to beginning
      this.curIndex = -1
    }
    //TODO - remove this
    this.setLastValue(chatState?.lastValue)
    // Handle jumping to a pinned statement
    if (params.statementId) {
      const idx = this.conversation.statements.findIndex((item: any) => item.id === params.statementId)
      if (idx >= 0) {
        this.curIndex = idx - 1
        this.setLastValue(null)
      }
      params.statementId = null // reset after use
    }
    this.currstmt = null
    this.chatFeedbackEntered = false
    this.getNextPage()
    this.userService.setTransientUserKey('chat.title', this.conversation.title)
    this.disableNavigation$.next(false)
  }

  async presentSeriesIntro(series: any, chat: any, seriesIntroClass: any) {
    throw new Error("Method not implemented.")
    // const modal = await this.modalController.create({
    //   component: seriesIntroClass,
    //   cssClass: 'series-intro-modal',
    //   componentProps: { series, chat }
    // });
    // return await modal.present();
  }

  shouldShowCompletionCard(): boolean {
    return ['Program', 'Newsflash', AppSource.Web].includes(this.launchSource as string)
      ? false
      : !this.conversation.behaviors?.includes(BEHAVIOR_SKIP_COMPLETION_CARD)
  }

  shouldTrackConversation(): boolean {
    return !this.conversation.behaviors?.includes(BEHAVIOR_DO_NOT_TRACK)
  }

  shouldUpdateChatQueues(): boolean {
    return !this.conversation.behaviors?.includes(BEHAVIOR_DO_NOT_UPDATE_CHAT_QUEUES)
  }

  statementHasBehavior(b: string): boolean {
    return this.currstmt?.behaviors?.includes(b)
  }

  isChatShareable(conversation: any) {
    const doNotShare = conversation?.behaviors?.includes(BEHAVIOR_DO_NOT_SHARE)
    return doNotShare ?
      false :
      ["Script", "Guide"].includes(conversation.type) ?
        true :
        conversation.statements.find((s: any) => s.statementName === 'takeaway')
  }

  isShareable() {
    const doNotShare = this.conversation?.behaviors?.includes(BEHAVIOR_DO_NOT_SHARE)
    return !doNotShare && this.statementHasBehavior('Shareable') || ['Script', 'Guide'].includes(this.conversation?.type)
  }

  async markCurrentStatement() {
    if (this.shouldTrackConversation()) {
      // console.log('Marking current statement')
      const cname = this.getCurrentConversationName()
      await this.chatStateService.setState(cname, { 
        current: this.curIndex,
        currentStatementName: this.currstmt?.name, 
        contentTimestamp: this.contentService.contentConfigTimestamp$.value, // to ensure presence in old chat states
        lastValue: this.lastValue,
        lastPromptChainStartIndex: this.lastPromptChainStartIndex
      })
    }
  }

  getNextIndex(idx: number) {
    const stmts = this.conversation.statements,
      clen = stmts.length
    let stmt
    // console.log("getNextIndex", this.conversation, idx)
    this.resetCurrError()
    idx = idx + 1
    try {
      while (idx < clen) {
        stmt = stmts[idx]
        if (!stmt.enableIf) return idx
        else {
          const result = this.evaluatorService.evaluate(stmt.enableIf, this.userService.getCache())
          console.log(`${stmt.name} (${idx + 1}) enableIf evaluated to ${result}`)
          if (result)
            return idx
          else
            idx = idx + 1
        }
      }
    }
    catch (err) {
      console.error(err)
      this.setCurrError({ statement: stmt, error: err, source: stmt.enableIf })
      return idx
    }
    return -1
  }

  // TODO: Replace all audio plays with this call
  playSound(name: string) {
    if (this.userService.isSoundEnabled() && this.environment.appSource !== AppSource.Portal)
      this.audio.play(name)
  }

  getPromptsToWaitFor() {
    // If statement also has a StartPromptChain behavior, use promptSpecsToWaitFor
    if (this.currstmt) {
      const waitForSpecs = this.currstmt.promptSpecsToWaitFor.map((spec: { name: string }) => spec.name)
      const oldWaitForSpecs = this.currstmt.promptSpecs.map((spec: { name: string }) => spec.name).concat()
      const promptsToWaitFor = this.statementHasBehavior(BEHAVIOR_START_PROMPT_CHAIN)
        ? waitForSpecs
        : [ ...waitForSpecs, ...oldWaitForSpecs]
      return promptsToWaitFor
    }
    else
      return []
  }

  isWaitingForResponse(s: PromptStatusValue) {
    // Return true if any of the current statement's wait prompts aren't completed in the pending prompts list
    const status = s.status
    if (status === 'NOTIFIED' || status === 'ERROR')
      return false
    // If still RUNNING or COMPLETED, only check the prompts in the tail of the list (since only the head is running)
    const completeds = new Set(s.pendingPrompts?.slice(1) || [])
    const waitFors = this.getPromptsToWaitFor()
    // Determine whether all the waitfors are in completed set
    // console.log("isWaitingForResponse waitFors", waitFors)
    const done = waitFors.length > 0 && waitFors.every((name: string) => completeds.has(name))
    if (done) {
      if (status === 'RUNNING') {
        const lastPromptChainStart = new Date(s.createdAt as string)
        // check if subset of prompts are all completed with updated userkeys
        const subsetComplete = waitFors.every((name: string) => {
          const spec = this.promptService.getPromptSpecNamed(name)
          // check that each userkey was written after the start of this chain
          return this.isUserKeyUpdatedSince(spec?.key as string, lastPromptChainStart) 
        })
        console.log("isWaitingForResponse checked subset completion", subsetComplete)
        if (subsetComplete)
          this.reprepareCurrentStatement() // TODO: prevent repeated reprepares after subset completes
        return subsetComplete ? false : true
      }
      else
        return false
    }
    else
      return true
  }

  async checkForWaitingForPromptResponse() {
    // console.log("checkForWaitingForPromptResponse", this.currstmt)
    if (this.statementHasBehavior(BEHAVIOR_WAIT_FOR_PROMPT_RESPONSE)) {
      const promptStatus = await firstValueFrom(this.openai.promptStatus$)
      const pendings = promptStatus?.pendingPrompts
      if (pendings && promptStatus) {
        // If promptStatus is not yet NOTIFIED or ERROR, then check if any promptsToWaitFor are pending
        if (this.isWaitingForResponse(promptStatus)) {
            throw new Error(`Waiting for response to prompt ${pendings[0]}`)
        }
        else { 
          const promptsToWaitFor = this.getPromptsToWaitFor()
          const lookup = promptStatus.status === 'NOTIFIED'
            ? !!promptsToWaitFor.find((name: string) => !pendings.includes(name))
            : true
          if (lookup) {
            console.log(`Found ${promptStatus.status} prompt status that requires looking up past prompt outcome, including ERROR`, { pendings, promptsToWaitFor })
            // TODO: Check if an error occurred for the prompt chain, based on promptsToWaitFor (from which the startPromptName can be inferred)
            // Will need to look up error in prompt history, since it might be in the past
            // This may not be necessary at all
          }
        }
      }
    }
  }

  //  Return true if current content timestamp is different from the chatState content timestamp
  async checkContentTimestamp(chatState: ChatState) {
    const contentConfigTimestamp = this.contentService.contentConfigTimestamp$.value
    const chatContentTimestamp = chatState.contentTimestamp
    // if there's no contentTimestamp in chatState, or it's different from the current contentConfigTimestamp, restart the chat
    // this should happen the first time after this release on the first refresh (getNextPage)
    if (!chatContentTimestamp || chatContentTimestamp !== contentConfigTimestamp) {
      console.log("Content update detected, will exit chat", contentConfigTimestamp, chatState)
      await this.utilityService.notify({
        header: "Content Update",
        backdropDismiss: false,
        message: this.contentService.getGlobal('content.error.chat.restart') 
          || "The content for this chat was updated while your chat was in progress. You will need to restart the chat. Don't worry, all of your responses have been saved. We apologize for the inconvenience.",
        cancel: () => {
          this.chatStateService.resetCurrentIndex(chatState.chatName as string)
          this.restartConversation()
        }
      })
      return true
    }
    return false
  }

  async handleNavigationError(err: any) {
    console.error(err.message)
    if (this.platform.is('capacitor'))
      await Haptics.impact({ style: ImpactStyle.Heavy })
    this.playSound('boing')
    this.utilityService.presentToast(
      this.contentService.getGlobal('response.required.message') || 'A response is required.',
      { duration: 1000, position: "bottom" })
    this.setStatementLoadedSubject(true)
  }

  // Return -1 if no next statement found (end), 
  // 0 if error condition (bad chat or response required), 
  // 1 if statement found
  async getNextPage() {
    // console.log("getNextPage", this.conversation, this.curIndex)
    this.setStatementLoadedSubject(false)
    if (!this.conversation || this.conversation.statements.length === 0)
      return 0

    // end statement
    const convName = this.getCurrentConversationName()
    const chatState = await this.chatStateService.getChatState(convName)
    if (this.currstmt) {
      await this.processAttribute()
      try {
        // Prevent advance if attribute value required and not set
        const attr = this.currstmt.attribute
        if (this.statementHasBehavior(BEHAVIOR_RESPONSE_REQUIRED) && attr) {
          if (attr.inputType === 'MULTIOPTIONS') {
            const selectedCount = attr.optionLinks.filter((opt: any) => opt.isChecked).length
            if (selectedCount === 0)
              throw new Error(`ResponseRequired and MULTIOPTION attribute ${attr.id} was not set`)
            else if (attr.multiParams?.min && selectedCount < attr.multiParams.min)
              throw new Error(`ResponseRequired and attribute ${attr.id} requires at least ${attr.multiParams.min} selection(s), selected ${selectedCount}`)
            else if (attr.multiParams?.max && selectedCount > attr.multiParams.max) {
              this.utilityService.notify({ message: `Please select at most ${attr.multiParams.max} option(s)` })
              throw new Error(`ResponseRequired and attribute ${attr.id} requires at most ${attr.multiParams.max} selection(s), selected ${selectedCount}`)
            }
          }
          else if ((!attr.value && attr.value !== 0))
            throw new Error(`ResponseRequired and attribute ${attr.id} was not set (value ${attr.value})`)
        }
        // Prevent advance if oncompletion validation fails
        if (!this.processCompletion()) {
          throw new Error(`OnCompletion validation failed for ${attr.id}`)
        }
        // Prevent advance if addableEntrySpec and ResponseRequired
        if (this.statementHasBehavior(BEHAVIOR_RESPONSE_REQUIRED) && this.currstmt.addableEntrySpec) {
          if (!this.entryService.hasAddableEntry(this.currstmt.name) && this.launchedInApp())
            throw new Error(`AddableEntrySpec required and entry was not saved`)
        }
        // Check for waiting for prompt response
        await this.checkForWaitingForPromptResponse()

        // If all checks pass and this is the last page,
        if (this.curIndex === this.conversation.statements.length - 1)
          return 0

        // Handle prompt chain start
        if (this.statementHasBehavior(BEHAVIOR_START_PROMPT_CHAIN)) {
          this.openai.resetThread()
          // Only process first promptSpec
          const spec = this.currstmt.promptSpecs[0]
          if (spec) {
            // Check for currently running prompt and reject if not finalized
            const current = await firstValueFrom(this.openai.promptStatus$) as PromptStatusValue
            if (current && this.openai.isNotFinalized(current)) {
              throw new Error(`Prompt chain ${current.startPromptName} is currently running, aborting request to start ${spec.name}`)
            }
            else {
              // delay to allow attribute to write to Firestore, so that cloud prompting uses latest userkey value (#3278)
              const delay = this.currstmt.attribute ? 500 : 0
              this.openai.sendPrompt(chatState?.chatId as string, convName, spec.name, 0, delay)
              // Record this statement as ChatState.lastPromptChainStartIndex
              // For use in exiting the chat when there is an unrecoverable error
              // currentIndex will be set to lastPromptChainStartIndex on exit
              this.lastPromptChainStartIndex = this.curIndex
            }
          }
        }
      }
      catch (err: any) {
        await this.handleNavigationError(err)
        return 0
      }

      // Proceed if no errors
      this.recordStatementEndForward()
      this.statementHistory?.unshift(this.curIndex)
    }

    // Check for content updates; exit if found
    if (chatState) { // only if chatState remains in progress
      if (await this.checkContentTimestamp(chatState as ChatState)) {
        // if content timestamp changed, prevent page advance
        return 0
      }
    }

    const newIndex = this.getNextIndex(this.curIndex)
    if (newIndex < 0) {
      return -1
    }
    // start statement
    this.setCurrentStatement(newIndex)
    this.lastStatementStart = Date.now()
    if (this.liveMode)
      await this.refreshCurrentStatement()
    else
      this.formatTextBlock()
    this.checkAuthentication()
    await this.checkPayment(chatState as ChatState)
    this.prepareAttribute()
    this.prepareVideo()
    this.prepareSources()
    await this.markCurrentStatement()
    this.checkTakeaway()
    this.handleQueueActions()
    this.resetDisplay()
    this.setStatementLoadedSubject(true)
    this.nextStatement$.next(this.currstmt)
    if (newIndex > 0) // Only play sound if not first statement
      this.playSound('next')
    return 1
  }

  checkAuthentication() {
    if (this.statementHasBehavior(BEHAVIOR_REQUIRE_AUTHENTICATION)) {
      const user = this.userService.getCurrentUser()
      if (user?.isAnonymousUser) {
        this.waitingForAuthentication$.next(true)
        this.userService.requestLogin$.next(true)
        this.userService.requireLogin$.next(true)
      }
    }
    else if (this.statementHasBehavior(BEHAVIOR_ALLOW_AUTHENTICATION)) {
      const user = this.userService.getCurrentUser()
      if (user?.isAnonymousUser) {
        this.waitingForAuthentication$.next(true)
        this.userService.requestLogin$.next(true)
      }
    }
  }

  async checkPayment(chatState: ChatState) {
    if (this.launchSource === AppSource.Portal && this.statementHasBehavior(BEHAVIOR_REQUIRE_PAYMENT) && !chatState.purchased) {

      const user = this.userService.getCurrentUser() as CheaseedUser
      console.log("checkPayment", this.conversation as Conversation, user.seedTypeBalance)
      console.log("checkPayment", "isGroupMember", this.userService.isGroupMember(user))
      const conv = this.conversation as Conversation
      if (typeof this.conversation === 'object') {
        const seedType = conv.seedType
        if (seedType) {
          if (this.userService.isGroupMember(user)) {
          // if approved prepaid group member - check if sufficient balance, deduct the cost from the balance
            if (!await this.groupService.handleGroupPayment(user, seedType)) {
              this.reprepareCurrentStatement()
              return
            }
          }
          // if non-group member or group member with insufficient balance -- check user's wallet
          if (this.userService.hasSeedType(user, seedType)) {
            // if user has the seed type in wallet, then consume it and proceed without payment
            await this.consumeSeedType(this.conversation.name, user, seedType)
            this.reprepareCurrentStatement() // to force textBlock refresh
            return
          }
        }
      }
      // prompt user to purchase
      this.userService.requestPayment$.next(this.conversation)
      this.waitingForPurchase$.next(true)
    }
  }

  setCurrentStatement(newIndex: number) {
    if (newIndex < 0) {
      console.log("setCurrentStatement: newIndex < 0", newIndex)
      return
    }
    this.curIndex = newIndex
    // Generate a clean copy of the statement
    const stmt = { ...this.conversation.statements[this.curIndex] }
    if (stmt.attributeSpec?.name) {
      const attrSpec = this.contentService.getAttributeSpec(stmt.attributeSpec.name)
      // console.log("attrSpec", attrSpec)
      stmt.attributeSpec = attrSpec
    }
    stmt.imageLoaded = false
    this.currstmt = stmt
    this.currstmt.isVideoFirst = this.statementHasBehavior("VideoFirst")
    this.currstmt.submitRequired = (this.environment.appSource === AppSource.Portal && !stmt.attributeSpec)
      || this.statementHasBehavior("SubmitRequired") 
    this.setChatProgress()
  }

  setChatProgress() {
    this.chatProgress$.next({
      showProgress: this.showProgress,
      value: (this.curIndex + 1) / this.conversation.statements.length,
      description: `${this.curIndex + 1}/${this.conversation.statements.length}`
    })
  }

  setStatementLoadedSubject(value: boolean) {
    this.isStatementLoaded$.next(value)
  }

  async getPrevPage() {
    this.setStatementLoadedSubject(false)
    if (this.conversation.statements.length === 0) return
    else if (this.curIndex === 0) return

    // Process attribute
    await this.processAttribute()

    // Handle going back to previous index, back to start if nothing in history
    let prevIndex = this.statementHistory?.shift()
    if (!prevIndex) {
      // Check that enableIf on first statement succeeds, reject prev page if not
      prevIndex = this.getNextIndex(-1)
      if (prevIndex < 0)
        return
    }
    // Handle prompt chaining in progress
    try {
      await this.checkForWaitingForPromptResponse()
    }
    catch (err: any) {
      await this.handleNavigationError(err)
      return
    }
    // Record statement end backward
    this.eventService.recordStatementEnd(
      {
        id: this.currstmt.name,
        duration: this.elapsedSinceStart(),
        direction: "back"
      })
    // format text block
    this.setCurrentStatement(prevIndex)
    this.resetCurrError()
    this.formatTextBlock()
    this.prepareAttribute()
    this.prepareVideo()
    this.prepareSources()
    this.resetDisplay()
    this.checkTakeaway()
    await this.markCurrentStatement()
    // Reset Evaluator service context
    this.evaluatorService.reset()
    this.playSound('prev')
    this.setStatementLoadedSubject(true)
    this.nextStatement$.next(this.currstmt)
    // console.log("getPrevPage prepared", this.currstmt)

  }

  processCompletion(): boolean {
    // if statement has an onCompletion expression, process it
    if (this.currstmt.onCompletion) {
      this.resetCurrError()
      try {
        const result = this.evaluatorService.evaluate(this.currstmt.onCompletion, this.userService.getCache(), true)
        if (result?.goto) {
          const idx = this.conversation.statements.findIndex((item: any) => item.statementName === result.goto)
          if (idx > -1) {
            // Set to one before the desired index
            this.curIndex = idx - 1
            console.log("processCompletion performing goto to", result.goto)
          }
        }
        else if (result !== undefined) {
          console.log("processCompletion detected validation result", result)
          return result
        }
      }
      catch (err) {
        console.error(err)
        this.setCurrError({ statement: this.currstmt, error: err, source: this.currstmt.onCompletion })
      }
    }
    return true
  }

  resetDisplay() {
    this.animateContentDisplay()
    for (const c of ['swipeLeftMessage', 'swipeLeftMessageBottom']) {
      const elem = document.querySelector("." + c)
      if (elem) {
        elem.className = c
        window.requestAnimationFrame(function () {
          window.requestAnimationFrame(function () {
            elem.className = c + " fading"
          })
        })
      }
    }
  }

  animateContentDisplay() {
    const elem = document.querySelector('.statement-div')
    // console.log("animateContentDisplay", elem)
    /*
      transition('* => *', [
      style({ opacity: 0 }),
      animate('900ms 300ms ease-out', style({ opacity: 1 }))
    ]
    */
    if (elem) {
      this.animationCtrl.create()
        .addElement(elem)
        .delay(100)
        .duration(800)
        .easing('ease-out')
        .fromTo('opacity', '0', '1')
        .play()
    }
  }

  async refreshCurrentStatement() {
    this.setStatementLoadedSubject(false)
    this.resetCurrError()
    const record = await this.airtableService.selectStatement(this.currstmt.name)
    this.currstmt.textBlock = record.TextBlock
    this.currstmt.enableIf = record.EnableIf
    this.currstmt.onCompletion = record.OnCompletion
    this.currstmt.behaviors = record.Behaviors
    if (record.PromptSpecs?.length > 0) {
      this.currstmt.promptSpecs = record.PromptSpecs.map(async (id: string) => {
        const spec = await this.airtableService.find('PromptSpec', id)
        return { name: spec.Name }
      })
    }
    if (record.AttributeSpec) {
      const id = record.AttributeSpec[0]
      const attr = await this.airtableService.find('AttributeSpec', id)      
      const spec = {
        id: attr.Name,
        name: attr.Name,
        order: attr.Order,
        attributeName: attr.AttributeName,
        inputType: attr.InputType,
        inputTypeParams: evaljson(attr.InputTypeParams),
        question: attr.Question
      }
      this.currstmt.attributeSpec = Object.assign(this.currstmt.attributeSpec || {}, spec)
      if (attr.OptionLinks) {
        // console.log("Existing optionLinks", this.currstmt.attributeSpec?.optionLinks)
        const links = []
        for (const id of attr.OptionLinks) {
          const option = await this.airtableService.find('Option', id)
          links.push({
            name: option.Name,
            description: option.Description,
            enableIf: option.EnableIf,
            messageIfSelected: option.MessageIfSelected,
            behaviors: option.Behaviors
          })
        }
        // 20220805 Not sure why this guard is necessary given above check, but this is where we see an exception sometimes
        if (this.currstmt.attributeSpec)
          this.currstmt.attributeSpec.optionLinks = links
      }
    }
    this.formatTextBlock()
    this.secsToLiveModeRefresh = LIVE_MODE_REFRESH_SECS
    this.prepareAttribute()
    this.setStatementLoadedSubject(true)
  }

  formatText(str: string) {
    try {
      return this.handlebars.formatWithUserKeys(str)
    }
    catch (err) {
      console.error(err)
      this.setCurrError({ statement: str, error: err, source: 'handlebars' })
      return this.sanitizer.bypassSecurityTrustHtml(str)
    }
  }

  checkFormatText(str: string) {
    return this.formatText(str)
  }

  formatTextBlock() {
    const bgcolors: any = {
      'BackgroundVellum': 'var(--chea-vellum)',
      'BackgroundDarkBlue': 'var(--chea-dark-blue)',
      'BackgroundTangerine': 'var(--chea-tangerine)',
      'BackgroundRaspberry': 'var(--chea-raspberry)'
    }
    this.currstmt.questionFirst = this.statementHasBehavior("QuestionFirst")
    this.currstmt.customTextBackgroundColor = null
    Object.keys(bgcolors).forEach(b => {
      if (this.statementHasBehavior(b)) {
        const col = bgcolors[b]
        console.log("Found", b, col)
        this.currstmt.customTextBackgroundColor = col
      }
    })
    const text = this.currstmt.textBlock
    this.currstmt.renderBlock = this.formatText(text)
    this.currentTextRendering$.next(this.currstmt.renderBlock)
  }

  setCurrError(err: any) {
    this.currerror$.next(err)
  }

  resetCurrError() {
    this.setCurrError(null)
  }

  async prepareVideo() {
    let url = this.currstmt.videoURL
    this.videoURL = null
    if (url) {
      if (url.startsWith("media")) {
        const furl = await this.firebase.getDownloadURL(url)
        this.videoURL = this.sanitizer.bypassSecurityTrustUrl(furl)
        console.log("Url from Firebase", this.videoURL)
      }
      else {
        url = url + "?autoplay=0&title=0&byline=0&portrait=0"
        this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(url)
        console.log("VideoURL is ", url)
      }
    }
  }

  checkTakeaway() {
    // If statement id ends with 'takeaway'
    const id = this.currstmt.name
    if (/.*\.takeaway/.test(id)) {
      this.currstmt.isTakeawayStatement = true
      this.currstmt.takeawayTileUrl = this.contentService.getTakeawayUrl(id)
      this.chatQueueService.addTakeaway(id)
    }
    else
      this.currstmt.isTakeawayStatement = false
  }

  handleQueueActions() {
    let idx = 0
    for (const act of (this.currstmt.queueActions || [])) {
      // Check that persona is matched
      const c = this.contentService.getConversationNamed(act.name)
      // check persona
      if (!c?.persona || this.userService.userHasPersona(c.persona.name)) {
        // Delay each event by 1.2 seconds to avoid debounce in HomeItemsService
        timer(1200 * idx)
          .subscribe(() =>
            this.chatQueueService.addRecommendedChat(act.name, this.getCurrentConversationName()))
      }
      idx = idx + 1
    }
  }

  elapsedSinceStart() {
    return Date.now() - this.lastStatementStart
  }

  recordStatementEndForward() {
    const attribute = this.currstmt.attribute
    let val = attribute?.value
    if (typeof val === 'string')
      val = val.slice(0, 1024)

    const props: any = {
      id: this.currstmt.name,
      duration: this.elapsedSinceStart(),
      attributeName: attribute?.id,
      attributeValue: attribute?.value,
      direction: "forward"
    }
    if (attribute?.otherValue)
      props['attributeOtherValue'] = attribute.otherValue
    this.eventService.recordStatementEnd(props)
  }

  toggleLiveMode() {
    this.liveMode = !this.liveMode
    return this.liveMode
  }

  toggleClearCurrentValue() {
    this.disableClearCurrentValue = !this.disableClearCurrentValue
    return this.disableClearCurrentValue
  }

  async refreshCurrentChat() {
    this.setStatementLoadedSubject(false)
    this.resetCurrError()
    const record: any = await this.airtableService.selectChat(this.getCurrentConversationName())
    const statements = []
    for (const id of record.Statements) {
      const stmt: any = await this.airtableService.find("Statement", id)
      statements.push(stmt)
    }
    statements.sort((a, b) => a.Order > b.Order ? 1 : -1)
    // console.log("conversation", JSON.stringify(this.conversation, null, 2))
    this.conversation.name = record.Name
    this.conversation.behaviors = record.Behaviors
    this.conversation.statements = statements.map(s => {
      return {
        id: s.Name,
        name: s.Name,
        order: s.Order,
        behaviors: s.Behaviors,
        statementName: s.StatementName,
        textBlock: s.TextBlock,
        primaryEvidenceSources: [],
        secondaryEvidenceSources: [],
        opinionSources: [],
        exampleSources: [],
        productSources: [],
        classSources: [],
        tipSources: []
      }
    })
    // console.log("conversation", this.conversation)
    await this.restartConversation()
    this.secsToLiveModeRefresh = LIVE_MODE_REFRESH_SECS
  }

  /** 
   * Reset instance variables related to the currently running chat
   */
  resetChatState() {
    this.curIndex = -1
    this.lastPromptChainStartIndex = 0
    this.setLastValue(null)
    this.currstmt = null
  }
  async restartConversation() {
    this.resetChatState()
    this.extractService.reset()
    this.disableNavigation$.next(false)
    await this.getNextPage()
  }

  isSettingsConversation() {
    return this.conversation.type === 'Settings'
  }

  async completeConversation() {

    // Disable navigation until next conversation starts
    this.disableNavigation$.next(true)

    this.playSound('next')
    // save attribute
    await this.processAttribute()
    // remove currentidx user key
    if (this.shouldTrackConversation()) {
      //TODO - should we do something to the currentIndex in the chatState instead?
      // We already mark the chatState as completed but retain the last set currentIndex
      // and lastValue fields
      //this.removeCurrentIdxUserKey()
    }
    // end statement
    this.recordStatementEndForward()
    this.recordChatFeedback()
    // reset addable entry map
    // note: could try to only flush entries for this chat id
    this.entryService.setAddableEntryMap(new Map())

    // process OnComplete
    const cname = this.getCurrentConversationName()
    this.processCompletion()
    await this.chatStateService.markChatComplete(cname)
    if (this.launchedInAppOrPortal()) {
      // end conversation
      await this.eventService.recordConversationEnd({
        id: cname,
        launchSource: this.launchSource,
        series: this.conversation.series,
        topics: this.conversation.topics
      })
    }
    if(this.launchedInApp()) {
      // update playlist
      this.chatQueueService.removeFromPlaylist(cname)
      // Add chat to RecentChats
      if (!this.isSettingsConversation() && this.launchSource !== 'Learn')
        this.chatQueueService.addRecentChat(cname)
      // Complete if current program step
      this.programService.checkCompletedChat(cname)
      // Check for Followup Reminders
      this.contentService.getFollowupTaskSets(cname)
        .forEach(ts => {
          this.taskSchedulerService.scheduleTaskSet(ts, false)
        })
    }

    // Used for web chats
    this.conversation.completed = true

    // get recommendations for next chat
    if (this.shouldShowCompletionCard()) {
      this.router.navigate(['end-conversation', { conversationId: cname, launchSource: this.launchSource }])
      return false
    }
    else {
      this.setStatementLoadedSubject(false)
      // return to launch point
      return true
    }
  }

  getCurrentCompletionRoute() {
    return this.conversationHasBehavior(this.conversation, BEHAVIOR_ROUTE_TO_HOME_ON_COMPLETION)
      ? ['/tabs/home']
      : this.conversationHasBehavior(this.conversation, BEHAVIOR_ROUTE_TO_TRACK_ON_COMPLETION)
        ? ['/tabs/career']
        : null
  }

  shouldShowNextButton() {
    const attr = this.currstmt.attribute
    return attr &&
      (['DATE', 'HOUROFDAY', 'TEXT', 'COMBOBOX', 'TEXTAREA', 'MULTIOPTIONS', 'SCALE', 'SPINNER', 'OPTIONS'].includes(attr.inputType) 
      || this.attributeHasBehavior(attr, BEHAVIOR_USER_NEXT_TO_ADVANCE))
  }

  shouldForward(): boolean {
    return (this.currstmt
      && (!this.currstmt.attribute || !this.currstmt.showNextButton)
      && !this.videoURL)
  }

  hasNextStatement(): boolean {
    return this.curIndex >= 0 && this.curIndex < this.conversation.statements.length - 1
  }

  hasPreviousStatement(): boolean {
    return this.curIndex > 0
  }

  isLastStatement(): boolean {
    return this.curIndex === this.conversation.statements.length - 1
  }

  canPinConversation(): boolean {
    return !this.conversation?.behaviors?.includes(UNPINNABLE)
  }

  isConversationPinned(): boolean {
    return this.chatQueueService.isPinned(this.getCurrentConversationName())
  }

  pinConversation() {
    this.chatQueueService.pinConversation(this.conversation)
    this.setPinnedSubject(true)
  }

  unpinConversation() {
    this.chatQueueService.unpinConversation(this.conversation)
    this.setPinnedSubject(false)
  }

  isGuideConversation(): boolean {
    return this.conversation.type === 'Guide'
  }

  suspendConversation() {
    if (this.currstmt)
      this.eventService.recordConversationSuspend(this.conversation, { statement: this.currstmt.name })
  }

  sanitizeStatementText() {
    return this.sanitizer.sanitize(SecurityContext.HTML, this.currstmt.renderBlock)
  }

  getCurrentStatementId(): string {
    return this.currstmt.name
  }

  getCurrentConversationName(): string {
    return this.conversation.name
  }

  async handleForward() {
    // Return true if advanced to next page or end page; false if not advanced
    try {
      if (this.hasNextStatement()) {
        const result = await this.getNextPage()
        // console.log("handleForward result", result)
        if (result > 0)
          return true
        else if (result < 0) {
          return !(await this.completeConversation())
        }
        else
          return true
      }
      else {
        // Check for waiting for prompt response
        await this.checkForWaitingForPromptResponse()
        return !(await this.completeConversation())
      }
    }
    catch (err) {
      await this.handleNavigationError(err)
      return err instanceof ChatStateError
        ? false
        : true
    }
  }

  handleBack() {
    if (this.hasPreviousStatement())
      this.getPrevPage()
  }

  launchedInApp(): boolean {
    return this.launchedInAppOrPortal() && this.launchSource !== AppSource.Portal
  }

  launchedInAppOrPortal(): boolean {
    return this.launchSource !== AppSource.Web
  }

  setChatFeedback(feedback: any) {
    const userkey = `chat.feedback.${this.getCurrentConversationName()}`
    this.userService.setUserKey(userkey, { ...feedback })
    this.chatFeedbackEntered = true
  }

  getChatFeedback(): any {
    const userkey = `chat.feedback.${this.getCurrentConversationName()}`
    return this.userService.getUserKey(userkey)
  }

  recordChatFeedback() {
    const feedback = this.getChatFeedback()
    if (feedback && this.chatFeedbackEntered) {
      console.log("recordChatFeedback", feedback)
      this.eventService.recordChatFeedback({ ...feedback, chatId: this.getCurrentConversationName() })
    }
  }

  async shareChat(conversation: any) {
    let msg, result
    const user = this.userService.getCurrentUser()
    const cname = conversation.name
    // console.log("shareChat", conversation)
    if (conversation.type === "Script") {
      msg = this.contentService.getGlobal(SCRIPTSHARE_MSG)
      result = await this.mediaService.generateBranchLinkStatement(msg, `${cname}.script`, user)
    }
    else if (conversation.type === "Guide") {
      msg = this.contentService.getGlobal(GUIDESHARE_MSG)
      result = await this.mediaService.generateBranchLinkStatement(msg, `${cname}.guide`, user)
    }
    else
      result = await this.mediaService.generateBranchLinkChat(conversation, user)
    if (result?.completed)
      this.eventService.recordShareContent({ id: cname, app: result.app })
  }

  toggleShowProgress() {
    this.showProgress = !this.showProgress
    this.setChatProgress()
  }

  async chatOptionClicked(option:any) {
    // complete this chat
    await this.completeConversation()
    // force spinner to show
    this.setStatementLoadedSubject(false)
    // wait for chat completion
    this.chatStateService.waitForChatCompletion(this.getCurrentConversationName(), 30)
      .then(() => {
        // branch to the next chat in the chat player
        this.setNextConversation(option.description)
      })
  }
}