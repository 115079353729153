import Stripe from "stripe"
import { getPerUnitPrice, getStripeSecretKey, INVOICE_OVERAGE_ITEM_DESCRIPTION, INVOICE_OVERAGE_MEMO, INVOICE_OVERAGE_TYPE } from "./stripe-constants"
import { UserRecord } from "./user.models"
import { LoggerInterface  } from "@cheaseed/node-utils"
import { getTimestamp } from "./utils"

export async function getMeterEventSummary(stripe: Stripe, user: UserRecord,  start_time: number, end_time: number, logger?: LoggerInterface) {
  const subscriptionInfo = user.subscriptionInfo
  if(!subscriptionInfo) {
    logger?.warn('getMeterEventSummary', `No subscription information found for user ${user}`)
    return null
  }
  //if(subscriptionInfo.status !== 'active') {
  //  logger?.warn('getMeterEventSummary', `Subscription not active for user ${user}`)
  //  return
  //}
  if(!subscriptionInfo.meterId) {
    logger?.warn('getMeterEventSummary', `MeterId not found for user ${user}`)
    return null
  }
  //const stripe = getStripe(env)
  //const event_name = getMeterEventName(stripe)
  const identifier = subscriptionInfo.meterId
  //const start_time = start || subscriptionInfo.subscriptionStartDate
  //const end_time = end || subscriptionInfo.subscriptionEndDate
  logger?.log(`Start time: ${start_time}`)
  logger?.log(`identifier: ${identifier}, customer: ${subscriptionInfo.customerId}`)
  logger?.log(`End time: ${end_time}`)

  const summary = await stripe.billing.meters.listEventSummaries(identifier, {
    customer: subscriptionInfo.customerId,
    start_time, //: getTimestamp(start_time), // has to be aligned to minute boundaries
    end_time //: getTimestamp(end_time)
  })
  return summary.data[0]
}

/*export async function getMeterIdForProduct(productId: string, stripe: Stripe) {
  const tieredPrices = await getTieredPrices(stripe)
  const price = tieredPrices.find(p => p.product === productId)
  return price.recurring.meter
}
*/

export async function sendMeterEvent(env: string, user: UserRecord, pages: number, logger?: LoggerInterface) {
  const subscriptionInfo = user.subscriptionInfo
  if(!subscriptionInfo) {
    logger?.warn('sendMeterEvent', `No subscription information found for user ${user.docId}`)
    return
  }
  if(subscriptionInfo.status !== 'active') {
    logger?.warn('sendMeterEvent', `Subscription not active for user ${user.docId}`)
    return
  }
  if(!subscriptionInfo.meterId) {
    logger?.warn('sendMeterEvent', `meterId not defined for user ${user.docId}`)
    return
  }
  const stripe = getStripe(env)
  //const meterId = await getMeterIdForProduct(stripe, subscriptionInfo.productId)
  const meter = await stripe.billing.meters.retrieve(subscriptionInfo.meterId)
  const event_name = meter.event_name //getMeterEventName(subscriptionInfo.interval as string)
  //const identifier = getMeterEventId(event_name)

  const eventResponse = await stripe.billing.meterEvents.create({
    //identifier,
    event_name,
    payload: {
      pages: pages.toString(),
      stripe_customer_id: subscriptionInfo.customerId
    },
    timestamp: Math.floor(Date.now()/1000)
  })
  logger?.log('Response to event sent to stripe meter ', JSON.stringify(eventResponse))
  //const summary = await getMeterEventSummaries(env, user, subscriptionInfo.subscriptionStartDate, subscriptionInfo.subscriptionEndDate, logger)
  //logger?.log('Event Summary: ', JSON.stringify(summary))
}

export function getStripe(env: string) {
  return new Stripe(getStripeSecretKey(env))
}

export async function billOverage(env: string, user: UserRecord, logger?: LoggerInterface, startDate?: Date, endDate?: Date) {
  const stripe = getStripe(env)
  const start = getTimestamp(startDate || user.subscriptionInfo.subscriptionStartDate)
  const end = getTimestamp(endDate || user.subscriptionInfo.subscriptionEndDate)
  const meterSummary = await getMeterEventSummary(stripe, user, start, end, logger)
  logger.log(`Meter Summary for user ${user.docId}`, JSON.stringify(meterSummary))
  const sub = await stripe.subscriptions.retrieve(user.subscriptionInfo.subscriptionId) as Stripe.Subscription

  let inv = await stripe.invoices.create({
      customer: user.subscriptionInfo.customerId,
      description: INVOICE_OVERAGE_MEMO,
      collection_method: 'charge_automatically',
      metadata: {type: INVOICE_OVERAGE_TYPE},
      automatic_tax: {enabled: true},
      default_payment_method: sub.default_payment_method as string,
      //amount: meterSummary.aggregated_value
  })
  logger.log(`Created invoice ${JSON.stringify(inv)}`)
  //const prices = await stripe.prices.list({active: true, product: user.subscriptionInfo.productId})
  
  const price = await getPerUnitPrice(user.subscriptionInfo.productId, stripe)
  logger.log(`billOverage price object`, JSON.stringify(price))
  //TODO - to apply discount; can look it up from the subscription object
  const amountToBill = price.unit_amount*meterSummary.aggregated_value
  const invoiceItemParams: Stripe.InvoiceItemCreateParams = {
    description: INVOICE_OVERAGE_ITEM_DESCRIPTION, 
    quantity: meterSummary.aggregated_value, 
    unit_amount: price.unit_amount, 
    invoice: inv.id, 
    customer: user.subscriptionInfo.customerId, 
    subscription: user.subscriptionInfo.subscriptionId,
    period: { start, end }
    //, amount: amountToBill
  }
  
  let res = await stripe.invoiceItems.create(invoiceItemParams)
  logger.log(`Created invoice item ${JSON.stringify(res)}`)
  //inv = await stripe.invoices.finalizeInvoice(inv.id)
  //logger.log(`Finalized invoice ${JSON.stringify(inv)}`)
  const paidInvoice = await stripe.invoices.pay(inv.id)
  logger.log(`Paid Invoice for user ${user.docId}`, JSON.stringify(paidInvoice))
  return paidInvoice
}
