import {
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'lib-file-status',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-status.component.html',
  styleUrl: './file-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileStatusComponent {
  fileEntry = input.required<FileSystemFileEntry>();
  file = signal<File | null>(null);

  constructor() {
    effect(
      () => {
        this.fileEntry().file((f: File) => this.file.set(f));
      }
    );
  }
}
