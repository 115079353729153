@if (file$ | async; as file) {
    <div class="flex flex-col items-center p-4">
        <div class="flex w-full justify-between items-center">
            <div class="text-xl font-bold">
                {{ file.fileName }}
            </div>
        </div>
        <div class="flex w-full justify-between items-start gap-2">
            <div class="w-full resize-x overflow-auto">
                <ngx-extended-pdf-viewer 
                    [src]="(docUrl$ | async)!"
                    zoom="60"
                    [enablePrint]="false"
                    [showDownloadButton]="true"
                    [showOpenFileButton]="false"
                    [showSidebarButton]="false"
                    [textLayer]="true"
                    [showHandToolButton]="true"
                    [filenameForDownload]="file.fileName">
                </ngx-extended-pdf-viewer>        
            </div>
            <div class="resize-x overflow-auto">
                <lib-file-schema-viewer [file]="file"/>
            </div>
        </div>
    </div>    
}
