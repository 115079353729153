import { FindOptions, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';

export function findPdfText(
    pdfViewerService: NgxExtendedPdfViewerService, 
    searchText: string, 
    pageNum: number,
    regexp = true) {

    // console.log('findPdfText', searchText, pageNum);
    const findOptions: FindOptions = {    
        highlightAll: true,
        matchCase: true,
        wholeWords: false,
        matchDiacritics: false,
        findMultiple: false,
        regexp,
        dontScrollIntoView: false,
        useSecondaryFindcontroller: true,
    };
    

    const pattern = regexp
        ? searchText.split('').join('.?')
        : searchText;
    console.log('findPdfText', pattern, pageNum);
    pdfViewerService.find(pattern, findOptions);
}
