import { CHATGPT_COSTS, JsonFormData } from '@cheaseed/node-utils'
import {
    AgentType,
    BaseFlowTool,
    FileflowServiceInterface,
    FlowFile,
    FlowStep,
    FlowTool,
} from '../fileflow.interface'
import { defaultTransformerParameters, executeAzureTool } from '../utils'
import { LlamaParseTool } from './LlamaParseTool'

export class GPTStandardizationTool extends BaseFlowTool {

    name = 'lpa-standardization'
    description = 'Extract schema from LPA document'
    precedents: FlowTool[] = []
    type = 'transformer'
    apiType = AgentType.completions
    outputType = 'json'
    instructions = undefined
    parameters: JsonFormData = defaultTransformerParameters
    startTime = 0

    protected override initialize(flowService: FileflowServiceInterface) {
        super.initialize(flowService)
        this.precedents.push(new LlamaParseTool(this.flowService))
        return this
    }

    async execute(
        file: FlowFile,
        last: FlowStep | null,
        params?: any)
    {
        // console.log('GPTStandardizationTool.execute', file, last, params)
        this.startTime = Date.now()
        
        // Get the output from the last step
        let output = last ? await this.flowService.getFileContents(last.storageName) : null
        if (!output)
            throw new Error(`No output found in last step ${last?.name} ${last?.outputURL}`)

        const instructions = params?.instructions || await this.flowService.getToolInstructions(this)
        const model = params?.model || 'gpt-4o'

        // Extract the markdown from the output of the previous step
        // console.log('Last Output:', output)
        const content = output.markdown

        // Submit the request to the Azure tool
        const result = await this.submitRequest(file, content, instructions, model, params.production)
        const schemas:any = { "LPA": result }

        // Update the FlowStep
        await this.flowService.uploadAnalysis(this, file, schemas);
        // Attach standardizations to file
        await this.flowService.updateFile(file, { schemas })
    }

    private async submitRequest(file: FlowFile, content: string, instructions: string, model: string, isProduction: boolean) {
        const messages = [
            {
                role: 'user',
                content
            }
        ]
        const { usage, result } = await executeAzureTool(
            this,
            messages,
            this.flowService,
            { instructions, model, isProduction }
        )
        
        const actual_output = JSON.parse(result)
        this.flowService.log('Output: ', { instructions, result, actual_output })

        const userId = this.flowService.getUserId()
        const user = await this.flowService.getUser(userId)
        const prompt = await this.flowService.updateStepPrompt(file, this.name, content, JSON.stringify(actual_output))
        const costs = CHATGPT_COSTS[model]
        const azureCost = costs 
            ? (usage.prompt_tokens * costs.input / 1000) + (usage.completion_tokens * costs.output / 1000)
            : 0
        await this.flowService.logPipelineStep({
            user: userId,
            groupDocId: user.groupDocId, 
            fileDocId: file.docId,
            fileName: file.fileName,
            loggedAt: new Date(),
            fileSize: file.size,
            stepName: this.name,
            promptDocId: prompt.id,
            azureModelUsed: model,
            input_tokens: usage.prompt_tokens,
            output_tokens: usage.completion_tokens,
            // azureTokensUsed: usage.total_tokens, // deprecated
            azureCost,
            elapsedMsec: Date.now() - this.startTime
        })        
        return actual_output
    }
}
