import { ChangeDetectionStrategy, Component, computed, effect, inject, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { delay, ExcelUtils, FlowFile, FlowTool } from '@fidoc/shared';
import { MatTab, MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';
import { DomainService } from '@fidoc/util';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FileflowService } from '../fileflow/fileflow.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToolsService } from '../fileflow/tools.service';

@Component({
  selector: 'lib-file-schema-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatTab,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './file-schema-viewer.component.html',
  styleUrl: './file-schema-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSchemaViewerComponent {

    domainService = inject(DomainService)
    flowService = inject(FileflowService)
    toolsService = inject(ToolsService)
    excelUtils = new ExcelUtils(this.flowService)

    selectedText = output<any>()
    pageSelected = output<any>()
    file = input.required<FlowFile>()
    page = input<number>()
    domain = computed(() => {
        const domainName = this.file().domainName as string
        const domain = this.domainService.getDomainNamed(domainName)
        return domain
    })
    schemas = computed<Record <string, any>>(() => this.file().schemas || {})
    schemaNames = computed(() => Object.keys(this.schemas()).toSorted())
    schemaModes = signal<Map<string, boolean>>(new Map())
  
    classes = computed(() => {
        const types = this.domain()?.classes || []
        return new Map<string, any>(types.map(t => [t.className, t]))
    })

    schemaStreams = computed(() => {
        const streams = new Map<string, any>()
        for (const schemaName of this.schemaNames()) {
            const schema = this.schemas()[schemaName]
            const stream: any[] = []
            this.streamSchemaToTable(schema.data, stream)
            streams.set(schemaName, stream)
        }
        return streams
    })

    pages = signal<any[]>([])
    tab$ = new BehaviorSubject('')
    tabIndex$ = new BehaviorSubject(0)

    constructor() {
        effect(() => {
            // Reset tab index 
            const nextPage = this.page() || 0
            if (nextPage) {
                this.tabIndex$.next(nextPage - 1)
            }
        })

        effect(async () => {
            const file = this.file()
            console.log('file changed', file)

            // Get last step output file
            let tool: FlowTool | undefined
            const steps = await this.flowService.getFileSteps(file)
            const lastStep = steps.toReversed().find(s => {
                if (s.state === 'complete') {
                    tool = this.toolsService.getTool(s.name)
                    if (tool?.generateOutputPreview)
                        return true
                }
                return false
            })
    
            // Read output from last previewable step
            let pages = []
            if (lastStep) {
                console.log(`Getting output from ${tool?.name}`, lastStep.storageName)
                const output = await this.flowService.getFileContents(lastStep.storageName, true)
                pages = tool?.generateOutputPreview!(output) || []
            }
            // await delay(10000) // testing spinner
            this.pages.set(pages)
        }, { allowSignalWrites: true })
    }

    tabChanged(ev: any) {
        // console.log('tab changed', ev)
        this.tabIndex$.next(ev.index)
        this.tab$.next(ev.tab.textLabel.toLowerCase())
        if (this.schemaNames().length === 0) {
            this.pageSelected.emit(ev)
        }
    }

    displayModeChanged(ev: any, schemaName: string) {
        this.schemaModes.update(modes => modes.set(schemaName, ev.checked))
    }

    toggleModeChanged(displayMode: boolean | undefined, schemaName: string) {
        this.schemaModes.update(modes => modes.set(schemaName, !displayMode))
    }

    async downloadFile() {
        const f = this.file()
        await this.flowService.downloadOutputFile(f)
    }

    appendTable(id: string, ws: any[], rows: any[], headers: string[] | null = null) {
        ws.push({ id, headers, rows})
    }

    streamSchemaToTable(obj: any, ws: any[], keyPrefix = '') {
        const keys = Object.keys(obj)
        // Order literals first, then objects, then arrays
        const literals = keys.filter(key => typeof obj[key] !== 'object' && !Array.isArray(obj[key])).sort()
        const objects = keys.filter(key => typeof obj[key] === 'object' && !Array.isArray(obj[key])).sort()
        const arrays = keys.filter(key => Array.isArray(obj[key])).sort()
        let cnt = 0
        // Collect all literals and objects into one set of rows and sort them
        // Write literals table
        const literalRows:any = []
        for (const key of literals) {
            const val = obj[key]
            const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
            literalRows.push([fullKey, `${val}`])
        }
        this.appendTable(`${keyPrefix}.${cnt}`, ws, literalRows)
        cnt++

        // Write object tables
        for (const key of objects) {
            const val = obj[key]
            this.streamSchemaToTable(val, ws, keyPrefix ? `${keyPrefix}.${key}` : key)
        }

        // Write array tables
        for (const key of arrays) {
            const val = obj[key]
            const fullKey = keyPrefix ? `${keyPrefix}.${key}` : key
            if (Array.isArray(val)) {
                const colHeaders = this.excelUtils.inferColumnHeaders(val)
                const tableRows = val.map(row => colHeaders.map(key => row[key] ? `${row[key]}` : ''))
                // console.log({ colHeaders, tableRows })
                this.appendTable(`${keyPrefix}.${cnt}`, ws, tableRows, colHeaders)
            }
        }
    }

    onTextMouseUp() {
        const selection = window.getSelection();
        if (selection) {
            const text = selection.toString().trim()
            if (text.length > 0) {
                // console.log("Selected text:", selection.toString());
                this.selectedText.emit({ 
                    mode: this.pages().length > 0 ? 'pages' : 'schema', 
                    page: this.tabIndex$.getValue(), 
                    text })
            }
        }
    }
}
