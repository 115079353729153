<table class="table-auto text-xs border w-full"> 
    @for (step of steps(); track step.name) {
      <tr>
        <td class="px-2 border text-left font-bold w-64 bg-fidocs-green">{{ step.description || step.name }}</td>
        <td class="px-2 border text-center" *ngIf="userService.user()?.currentRole === 'admin'">
          @if (step.parameters) {
            <span 
              class="hover:bg-slate-200 line-clamp-2"
              (click)="showParameters(step)">
              {{ step.parameters | json }}
            </span>
          }         
        </td>
        <td class="px-2 border text-left w-32">
          @if (step.state === 'error') {
            <span 
              class="text-red-500 underline cursor-pointer"
              (click)="step.opened = !step.opened">
              {{ step.state + (step.opened ? ': ' + step.error : '') }}
            </span>
          }
          @else {
            {{ step.state }}
          }
          {{ step.elapsedMsec ? (step.elapsedMsec / 1000).toFixed(2) + ' secs' : '' }}
        </td>
        <td class="px-1 border text-center w-28">
          @if (withinLastNDays(step.lastUpdatedAt, 5)) {
            {{ step.lastUpdatedAt | dfnsFormatDistanceToNow: { addSuffix: true } }}
          }
          @else {
            {{ step.lastUpdatedAt | date: 'MMM dd, YYYY' }}
          }
        </td>
        <td class="px-2 border text-center w-28" *ngIf="userService.user()?.currentRole === 'admin'">
          @if (step.state === 'pending') {
            <mat-spinner
              strokeWidth="2"
              diameter="20"
              class="fidoc-mat-spinner"/>
          }
          @else {
            <span *ngIf="step.outputURL" 
              class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-xl sm:text-2xl px-2"
              matTooltip="View output document"
              (click)="viewOutput(step)">
              {{ step.type === 'xls-transformer' ? 'table_view' : 'visibility' }}
            </span>
            <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-xl sm:text-2xl" [matMenuTriggerFor]="moreMenu">
              more_horiz
            </span>
            <mat-menu #moreMenu="matMenu" xPosition="after">
              <ng-template matMenuContent>
                <button mat-menu-item (click)="viewTranscript(step)">
                  View Transcript
                </button>
                <button mat-menu-item (click)="rerun(step)" [disabled]="readOnly()">
                  Rerun
                </button>
                <button mat-menu-item (click)="inspect(step)">
                  Inspect
                </button>
                <mat-divider></mat-divider>
                <button mat-menu-item (click)="delete(step)" [disabled]="readOnly()">
                  Delete
                </button>
              </ng-template>
            </mat-menu>    
          }
        </td>
      </tr>
    }
</table>
