<div class="p-4 py-0">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" [selectedIndex]="tabIndex$ | async" (selectedTabChange)="tabChanged($event)">
        <mat-tab label="Users" value="users">
            <ng-template matTabContent>
                <lib-user-table/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Files" value="files">
            <ng-template matTabContent>
                <ng-container *ngIf="recentFiles$ | async as files; else loading">
                    <div class="p-4">
                        <ion-searchbar
                          [debounce]="500"
                          show-clear-button="always"
                          [value]="searchFilter$ | async"
                          (ionInput)="searchFilter$.next($event.target.value)">
                        </ion-searchbar>
                    </div>
                    @for (file of files; track file.docId) {
                        <div class="p-2 w-full even:bg-slate-100 odd:white">
                            <lib-file-summary 
                                [file]="file" 
                                [adminView]="true"
                                class="w-full"/>
                          </div>
                
                    }
                </ng-container>
            </ng-template>
        </mat-tab>
        <mat-tab label="Defaults" value="defaults">
            <lib-defaults></lib-defaults>
        </mat-tab>
        <mat-tab label="Domains" value="domains">
            <lib-domain></lib-domain>
        </mat-tab>
        <mat-tab label="Groups" value="groups">
            <lib-groups></lib-groups>
        </mat-tab>
        <mat-tab label="Costs" value="costs">
            <ng-template matTabContent>
                <lib-cost-table/>
            </ng-template>
        </mat-tab>
        <mat-tab label="Partners" value="partners">
            <lib-partners></lib-partners>
        </mat-tab>
        <mat-tab label="Actions" value="actions">
            <ng-template matTabContent>
                <lib-actions></lib-actions>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</div>

<ng-template #loading>
    <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
</ng-template>