<div class="h-[85vh]">
    @if (schemaNames().length == 0 && pages().length == 0 && file().state != 'idle') {
        <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
    }
    @else {
        <mat-tab-group 
            class="h-full"
            mat-stretch-tabs="false" 
            mat-align-tabs="start" 
            animationDuration="0ms" 
            [selectedIndex]="tabIndex$ | async" 
            (selectedTabChange)="tabChanged($event)">
            @if (schemaNames().length > 0) {        
                @for (schemaName of schemaNames(); track schemaName) {
                    @let schema = schemas()[schemaName];
                    @let classDescription = classes().get(schemaName)?.description;
                    @let displayMode = schemaModes().get(schemaName);
                    <mat-tab [label]="schemaName">
                        <ng-template matTabContent>
                            <div class="flex justify-between items-center p-2 py-3">
                                <div class="font-bold">{{ classDescription }}</div>
                                <span class="material-symbols-outlined hover:bg-slate-200 cursor-pointer text-xl sm:text-2xl" [matMenuTriggerFor]="moreMenu">
                                    more_horiz
                                </span>
                                <mat-menu #moreMenu="matMenu" xPosition="after" class="dialog-menu">
                                    <button mat-menu-item (click)="toggleModeChanged(displayMode, schemaName)">
                                        {{ displayMode ? 'View as Table' : 'View as JSON' }}
                                    </button>
                                    <button mat-menu-item (click)="downloadFile()">
                                        Download as XLSX
                                    </button>
                                </mat-menu>                  
                                <!-- <mat-checkbox [checked]="displayJson" (change)="displayModeChanged($event, schemaName)">JSON</mat-checkbox> -->
                            </div>
                            <div class="overflow-y-scroll">
                                @if (displayMode) {
                                    <pre class="text-xs">{{ schema.data | json }}</pre>
                                }
                                @else {
                                    @let stream = schemaStreams().get(schemaName);
                                    @for (t of stream; track t.id) {
                                        <table [id]="t.id" class="table-fixed text-xs border w-full" (mouseup)="onTextMouseUp()">
                                            @if (t.headers) {
                                                <thead>
                                                    <tr>
                                                        @for (h of t.headers; let index = $index; track index) {
                                                            <th class="text-left px-2">{{ h }}</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    @for (r of t.rows; let index = $index; track index) {
                                                        <tr>
                                                            @for (c of r; let cindex = $index; track cindex) {
                                                                <td class="px-2">{{ c }}</td>
                                                            }
                                                        </tr>
                                                    }
                                                </tbody>
                                            }
                                            @else {
                                                <tbody>
                                                    @for (r of t.rows; let index = $index; track index) {
                                                        <tr>
                                                            @let numCols = r.length;                                                            
                                                            @for (c of r; let cindex = $index; track cindex) {
                                                                <td class="p-2 last:text-right break-words" [ngClass]="{'w-1/2': numCols === 2}">{{ c }}</td>
                                                            }
                                                        </tr>
                                                    }
                                                </tbody>
                                            }
                                        </table>
                                    }
                                    <!-- <pre class="text-xs">{{ stream | json }}</pre> -->
                                }
                            </div>    
                        </ng-template>
                    </mat-tab>
                }
            }
            @else if (pages().length > 0) {
                @for (page of pages(); track page.pageNum; let count = $count) {
                    <mat-tab [label]="page.tabLabel">
                        <ng-template matTabContent>
                            <div class="overflow-y-scroll" (mouseup)="onTextMouseUp()">
                                @for (section of page.sections; track $index) {
                                    @if (section.type === 'text') {
                                        @if (page.textOnly) {
                                            <pre class="p-2">{{ section.text }}</pre>
                                        }
                                        @else {
                                            <div class="p-2">{{ section.text }}</div>
                                        }
                                    }
                                    @else if (section.type === 'table') {
                                        <table class="table-auto text-xs border w-full">
                                        @for (row of section.tableList; let rindex = $index; track rindex) {
                                            <tr>
                                                @for (cell of row; let cindex = $index; track cindex) {
                                                    <td class="px-2">{{ cell }}</td>
                                                }
                                            </tr>
                                        }
                                        </table>
                                    }
                                }
                                <!-- <pre class="text-xs">{{ page.sections | json }}</pre> -->
                            </div>
                        </ng-template>
                    </mat-tab>
                }
            }
        </mat-tab-group>
    }
</div>
    