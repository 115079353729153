import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, HostListener, inject, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { RuntimeConfigService } from '../runtime-config.service';
import { UtilityService } from '../utility.service';
import { DefaultsService } from '../defaults.service';

@Component({
    selector: 'lib-file-drop',
    standalone: true,
    imports: [
        CommonModule,
        NgxFileDropModule,
        MatButtonModule
    ],
    templateUrl: './file-drop.component.html',
    styleUrl: './file-drop.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileDropComponent {

    // Alternative: https://github.com/hackingharold/ngx-dropzone
    // Alternative: https://www.youtube.com/watch?v=w7vKcUJld-U, https://gitlab.com/ryanlogsdon/angular-drag-and-drop-file-upload

    dropLabel = "Drop PDF, JPEG, or PNG files, or paste image clips here"
    @Output() droppedFiles = new EventEmitter<FileSystemFileEntry[]>();
    runtimeConfigService = inject(RuntimeConfigService)
    utilityService = inject(UtilityService)
    defaultsService = inject(DefaultsService)

    public fileOver(event: any) {
        // console.log(event);
    }

    public fileLeave(event: any) {
        // console.log(event);
    }

    public dropped(droppedFiles: NgxFileDropEntry[]) {
        if(this.runtimeConfigService.isMaintenanceMode()) {
            const msg = this.defaultsService.getDefault('maintenanceModeMessage')
            this.utilityService.presentToast(msg, { duration: 8000 })
            return
        }
        const files: FileSystemFileEntry[] = []
        for (const droppedFile of droppedFiles) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                files.push(fileEntry)
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log("nonfile", droppedFile.relativePath, fileEntry);
            }
        }
        // console.log('emitting', this.files.length, this.files);
        this.droppedFiles.emit(files)
    }

    @HostListener('window:paste', ['$event'])
    public handlePaste(event: ClipboardEvent) {
        const items = event.clipboardData?.items;
        const files: FileSystemFileEntry[] = []
        if (items) {
            const itemsArray = Array.from(items);
            for (const item of itemsArray) {
                if (item.type.startsWith('image/')) {
                    const file = item.getAsFile();
                    if (file) {
                        const fileEntry = {
                            name: file.name,
                            isFile: true,
                            isDirectory: false,
                            file: (callback: (file: File) => void) => callback(file)
                        } as FileSystemFileEntry;
                        files.push(fileEntry);
                    }
                }
            }
            if (files.length > 0)
                this.droppedFiles.emit(files);
        }
    }
}
