import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowFile } from '@fidoc/shared';
import { MatTab, MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'lib-file-schema-viewer',
  standalone: true,
  imports: [
    CommonModule,
    MatTabsModule,
    MatTab,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './file-schema-viewer.component.html',
  styleUrl: './file-schema-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSchemaViewerComponent {

  file = input.required<FlowFile>()
  schemas = computed<Record <string, any>>(() => this.file().schemas || {})
  schemaNames = computed(() => Object.keys(this.schemas()))
  tab$ = new BehaviorSubject('')
  tabIndex$ = new BehaviorSubject(0)

  tabChanged(ev: any) {
    console.log('tab changed', ev)
    this.tabIndex$.next(ev.index)
    this.tab$.next(ev.tab.textLabel.toLowerCase())
  }

}
