import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, combineLatestWith, debounceTime, distinctUntilKeyChanged, filter, firstValueFrom, map, shareReplay, switchMap, tap } from 'rxjs';
import { findPdfText, FirebaseService, UserService } from '@fidoc/util';
import { FlowFile, flowFileConverter, getUserFilePath } from '@fidoc/shared';
import { FindResultMatchesCount, NgxExtendedPdfViewerModule, NgxExtendedPdfViewerService } from 'ngx-extended-pdf-viewer';
import { FileSchemaViewerComponent } from '../file-schema-viewer/file-schema-viewer.component';
import { FileflowService } from '../fileflow/fileflow.service';

@Component({
  selector: 'lib-file-reviewer',
  standalone: true,
  imports: [
    CommonModule,
    NgxExtendedPdfViewerModule,
    FileSchemaViewerComponent
  ],
  templateUrl: './file-reviewer.component.html',
  styleUrl: './file-reviewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileReviewerComponent {
  
  route = inject(ActivatedRoute)
  firebase = inject(FirebaseService)
  userService = inject(UserService)
  fileFlowService = inject(FileflowService)
  private ngxExtendedPdfViewerService = inject(NgxExtendedPdfViewerService);
  docUrl$ = new BehaviorSubject('')
  previewPage: number | undefined

  fileDocId$ = this.route.paramMap.pipe(map(params => params.get('docId')))

  file$ = this.userService.user$
    .pipe(
        filter(user => !!user),
        distinctUntilKeyChanged('docId'),
        combineLatestWith(this.fileDocId$),
        filter(([ user, fileDocId ]) => !!user && !!fileDocId),
        switchMap(([ user, fileDocId ]) => 
            this.firebase.doc$(getUserFilePath(user!.docId, fileDocId as string), flowFileConverter)
                .pipe(
                    debounceTime(100),
                    shareReplay(1)
                )
        ),
        map(file => {
            if (!file.domainName)
                file.domainName = this.fileFlowService.defaultDomainName()
            return file as FlowFile
        }),
        tap(async (file) => {
            if (!this.docUrl$.getValue()) {
                const url = await this.fileFlowService.getFileContentsAsLocalURL(file!.storageName)
                this.docUrl$.next(url)
            }
        })
    )

    async onSelectedText(selection: any) {
        console.log('selection', selection)
        if (selection.mode === 'pages') {
            await findPdfText(this.ngxExtendedPdfViewerService, selection.text, selection.page + 1)
        }
    }

    async onPageSelected(ev: any) {
        const page = ev.index + 1
        const label = ev.tab.textLabel
        // console.log('page ', page, label)
        this.ngxExtendedPdfViewerService.scrollPageIntoView(page)
        // this.namedDest = `${page}`
    }

    async onPDFPageChange(page: number) {
        // console.log('PDF page change', page)
        const file = await firstValueFrom(this.file$)
        if (!file.schemas)
            this.previewPage = page
    }

    updateFindMatchesCount(result: FindResultMatchesCount) {
        // console.log('find result', result.total, result.current, result)
    }
}
