import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import {
  RouteReuseStrategy,
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router'
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app'
import { getFunctions, provideFunctions } from '@angular/fire/functions'
import { initializeFirestore, provideFirestore } from '@angular/fire/firestore'
import { getStorage, provideStorage } from '@angular/fire/storage'
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAuth, getAuth } from '@angular/fire/auth'
import { appRoutes } from './app.routes';
import { environment } from '../environments/environment'
import { PatchLevel, ReleaseTag } from 'releasetag'
import { BuildTag } from 'buildtag'
import { provideIonicAngular, IonicRouteStrategy } from '@ionic/angular/standalone';
import { CurrencyPipe } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UserService } from '@fidoc/util';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideHttpClient(),
    // Ionic standalone https://ionicframework.com/docs/angular/build-options#standalone-based-applications
    provideIonicAngular({ innerHTMLTemplatesEnabled: true }),
    { provide: CurrencyPipe, useClass: CurrencyPipe},
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true }) //, 'fidocs-db');
      // const firestore = initializeFirestore(getApp(), { ignoreUndefinedProperties: true });
      // console.log('Firestore initialized', firestore)
      return firestore;
    }),
    provideStorage(() => {
        const storage = getStorage();
        return storage;
    }),
    provideFunctions(() => {
        const functions = getFunctions();
        return functions;
    }),
    importProvidersFrom(NgxFileDropModule),
    { provide: 'environment', useValue: { ...environment, buildTag: BuildTag, releaseTag: ReleaseTag, patchLevel: PatchLevel } },
    { provide: 'UserService', useClass: UserService },
    // { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ]

}
