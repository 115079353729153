import { UserRecord } from '../user.models';
import {
  FlowFile,
  FlowStep,
  FlowTool,
} from '../fileflow.interface';
import { FileflowServiceInterface } from '../fileflow.interface';
import { JsonFormData } from '@cheaseed/node-utils';
import { DOCUPANDA_CREDIT_COST, DOCUPANDA_CREDIT_OVERAGE_COST } from '../fileflow';
export class DocuPandaTool implements FlowTool {
    name = 'standard-ocr'
    description = 'Data Extraction (standard)'
    type = 'ocr'
    processingMethod = '' // override in subclasses
    startTime = 0
    
    parameters: JsonFormData = {
      submitAlwaysEnabled: true,
      controls: [ 
        { 
          name: 'pages', 
          label: 'Page range',
          type: 'text', 
          placeholder: '(e.g. 1-3,6,10)' 
        },        
        {          
          name: 'processingMethod',
          label: 'Processing method',
          type: 'radio',
          choices: [
            { value: 'asImage' },
            { value: 'removeWatermark' }
          ]
        }
      ]
    }
    //firebase = inject(FirebaseService)
    flowService: FileflowServiceInterface

    constructor(flowService?: FileflowServiceInterface) {
      if(flowService)
        this.initialize(flowService)
    }
    initialize(flowService: FileflowServiceInterface) {
      this.flowService = flowService
      return this
    }
    checkExecute(file: FlowFile, user: UserRecord) {
      //TODO this needs to change with overages
      if (file.numPages > (user.pageBalance || 0) && (user.subscriptionInfo?.status === 'trialing' || user.subscriptionInfo?.status === 'canceled'))
        throw new Error(`Insufficient pageBalance credits ${user.pageBalance || 0}`)
    }

    async execute(
        file: FlowFile,
        last: FlowStep | null,
        params?: any) 
    {
        this.startTime = Date.now()
        this.flowService.log(`ocr with ${this.name}`, file, params)

        const result = await this.flowService.postDocumentToDocupanda(file, this.processingMethod, params)
        // account for the fact that we could receive the data as res.data (if this instance
        // is running locally or just res if this instance is running in the cloud)
        const response = result.data ? result : { data: result }

        const externalDocumentId = (response?.data as any)?.documentId
        const numPages = (response?.data as any)?.result.numPages
        const userId = this.flowService.getUserId()
        const user = await this.flowService.getUser(userId)
        // Broadcast the number of pages consumed
        this.flowService.consumedPages$.next(numPages)
        this.flowService.log(
          'scan complete',
          file.fileName,
          `numPages (${numPages})`,
          'response length', JSON.stringify(response?.data).length,
        )
        await this.flowService.uploadAnalysis(this, file, response)
        await this.flowService.updateFile(file, { numPages, externalDocumentId })
        await this.flowService.logPipelineStep({
          user: this.flowService.getUserId(), 
          fileDocId: file.docId,
          fileName: file.fileName,
          loggedAt: new Date(),
          fileSize: file.size,
          numPages: numPages,
          stepName: this.name,
          docupandaCredits: numPages,
          docupandaCost: numPages * (user.pageBalance < 0 ? DOCUPANDA_CREDIT_OVERAGE_COST : DOCUPANDA_CREDIT_COST),
          elapsedMsec: Date.now() - this.startTime
        })
        //this.flowService.log('Docupanda Execute', 'sending meter event to Stripe with payload', numPages)
        //await this.flowService.sendMeterEvent(numPages)
        return true
    }

    getContentDisposition(fileName: string) {
      return  'inline;'
    }

}
