import { JsonFormData } from '@cheaseed/node-utils';
import { DocuPandaTool } from './DocuPandaTool';

export class DocuPandaAsImageTool extends DocuPandaTool {
  override name = 'standard-ocr-asImage';
  override description = 'Data Extraction from Image (standard)';
  override parameters: JsonFormData = {
    submitAlwaysEnabled: true,
    controls: [
      {
        name: 'pages',
        label: 'Page range',
        type: 'text',
        placeholder: '(e.g. 1-3,6,10)',
      },
    ],
  };
  override processingMethod = 'asImage';
}
