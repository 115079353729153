import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JsonFormComponent, UtilityService } from '@fidoc/util';
import { FirebaseService, UserRecordConverter, UserService } from '@fidoc/util';
import { FirestoreCollectionTypes, UserRecord } from '@fidoc/shared';
import { where } from '@angular/fire/firestore';
import { debounceTime, tap, shareReplay, BehaviorSubject } from 'rxjs';
import { IonContent, IonModal } from '@ionic/angular/standalone';
import { JsonFormData } from '@cheaseed/node-utils';
import { ActivatedRoute } from '@angular/router';
import { PartnerViewComponent } from "../../partner-view/partner-view.component";
import { BreadcrumbStripComponent } from '../../nav/breadcrumb-strip.component';

@Component({
  selector: 'lib-partners',
  standalone: true,
  imports: [
    CommonModule,
    IonModal,
    IonContent,
    JsonFormComponent,
    PartnerViewComponent,
    BreadcrumbStripComponent
],
  templateUrl: './partners.component.html',
  styleUrl: './partners.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnersComponent {
    userService = inject(UserService)
    utilityService = inject(UtilityService)
    firebase = inject(FirebaseService)
    route = inject(ActivatedRoute)

    selectedPartner = signal<any>(null)
    provisionPartner$ = new BehaviorSubject(false)
    provisionPartnerFormData: JsonFormData = {
        controls: [ 
            {
            name: 'email',
            type: 'email',
            label: 'Email',
            errorText: "Must be a valid email address",
            validators: {
                required: true,
                email: true
            },
            },
            {
            name: 'user',
            type: 'text',
            validators: {
                required: true,
                minLength: 3
            },
            label: 'Partner Name',
            },
        ]
        };

    partners$ = this.firebase.collectionWithConverter$(
        FirestoreCollectionTypes.USERS,
        UserRecordConverter,
        where('currentRole', '==', 'partner'))
            .pipe(
                debounceTime(200),
                tap(res => console.log(`partners retrieved ${res.length} partners`)),
                shareReplay(1)
            )

    deletePartner(partner: UserRecord) {
        this.utilityService.confirm({
            header: 'Delete Partner',
            message: `Are you sure you want to delete the partner ${partner.name}?`,
            yesLabel: 'Delete',
            confirm: () => {
                // Handle pre-delete logic here
                // this.userService.deleteUser(partner)
                console.log('Not yet implemented')
            }
        })
    }

    createPartner(user: UserRecord) {
        this.provisionPartner$.next(true)
    }
}
