import { Inject, Injectable, computed, inject, signal } from '@angular/core';
import {
  StorageReference,
  UploadTask,
  deleteObject,
  getBlob,
  getDownloadURL,
  getMetadata,
  getStorage,
  percentage,
  ref,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { PortalUtilityService } from '@cheaseed/portal/util';
import { BehaviorSubject, Observable, Subject, debounceTime, firstValueFrom, map, shareReplay, switchMap, tap, withLatestFrom } from 'rxjs';
import { DefaultsService, FirebaseService, UserService } from '@fidoc/util';
import {
  UserRecord,
  FlowFile,
  FlowPrompt,
  FlowStep,
  FlowTool,
  flowFileConverter,
  flowStepConverter,
  flowStepPromptConverter,
  FileflowServiceInterface,
  expandPageRange,
  PIPELINE_ENV_KEY,
  isExecEnvCloud,
  PipelineEnvironment,
  getBlobFromJson,
  getToolPath,
  getUserFilePath,
  getUserFileStepPath,
  getUserFileStepPromptPath,
  getUserFilesPath,
  isObjectEmpty,
  getStorageFilePath,
  getStorageFilePathForSuffix,
  PipelineLoggerInterface,
  PipelineContext,
  NO_PIPELINE,
  blobToBase64,
  DOCUPANDA_DOCUMENT_ENDPOINT,
  post,
  poll,
  FlowDomain,
  get,
  FlowSchemaType,
  DOMAINS_COLLECTION,
  base64ToBlob,
  sendMeterEvent,
  ExcelUtils,
  getBlobFromBuffer,
  COSTS_COLLECTION,
  PipelineStepLog
} from '@fidoc/shared';
import { connect } from 'ngxtension/connect';
import { JsonFormData } from '@cheaseed/node-utils';
import { marked } from 'marked'
import { DomSanitizer } from '@angular/platform-browser';
import { Workbook } from 'exceljs';
// import { IPDFViewerApplication, PDFNotificationService } from 'ngx-extended-pdf-viewer';

export interface FlowState {
  domains: FlowDomain[];
  flowFiles: FlowFile[];
  currentFolder?: FlowFile;
}

@Injectable({
  providedIn: 'root',
})
export class FileflowService implements FileflowServiceInterface, PipelineLoggerInterface {
  firebase = inject(FirebaseService)
  utilityService = inject(PortalUtilityService)
  userService = inject(UserService)
  defaults = inject(DefaultsService)
  private sanitizer = inject(DomSanitizer)
  showToolParameters$ = new BehaviorSubject<{ tool: FlowTool, file: FlowFile | null, params: JsonFormData } | undefined>(undefined)
  showInspector$ = new BehaviorSubject<{ title: string, message: string} | undefined>(undefined)
  uploadedFileReadyQueue = signal<FlowFile[]>([])
  consumedPages$ = new Subject<number>()
  context: PipelineContext | null = null //transient;
  // private pdfViewerApplication!: IPDFViewerApplication | undefined;
  // pdfNotificationService = inject(PDFNotificationService)

  // Signal state
  private state = signal<FlowState>({
    flowFiles: [],
    domains: [],
    currentFolder: undefined,
  })

  // Signal selectors
  flowFiles = computed(() => this.state().flowFiles);
  domains = computed(() => this.state().domains);
  defaultDomain = computed(() => this.domains().find(d => d.isDefault));
  currentFolder = computed(() => this.state().currentFolder);
  availableFolders = computed(() => this.flowFiles().filter(f => f.isFolder).map(f => f.fileName).sort())
  currentFolderFiles = computed(() => {
    const fldr = this.currentFolder()?.fileName 
    return this.flowFiles().filter(f => fldr ? f.containedInFolder === fldr : !f.containedInFolder)
  })
  selectedFolder = signal<FlowFile | null>(null)

  domainClassMaps = computed(() => {
    const map = new Map<string, Map<string, FlowSchemaType>>(this.domains().map(d => [ d.domainName, new Map<string, FlowSchemaType>(d.classes.map(c => [ c.className, c ]))]))
    // console.log('domainClassMaps', map)
    return map
  })

  flowFiles$ = this.userService.userDocId$
    .pipe(
      switchMap(userId =>
        this.firebase.collectionWithConverter$(
          getUserFilesPath(userId as string),
          flowFileConverter,
        ),
      ),
      debounceTime(300),
      map(files => files.toSorted((a, b) => a.createdAt > b.createdAt ? -1 : 1) as FlowFile[]),
      map(files => files.map(f => {
        if (!f.domainName)
          f.domainName = this.defaultDomainName()
        return f
      })),
      shareReplay(1)
    )

  domains$ = this.userService.userDocId$
    .pipe(
      switchMap(() => this.firebase.collection$(DOMAINS_COLLECTION)),
      debounceTime(200),
      shareReplay(1))

  loading = signal(false)
  openedFiles = signal<Set<string>>(new Set<string>())

  constructor(@Inject('environment') private environment: any) {
    // Connect all observables to the state
    // See https://www.youtube.com/watch?v=R7-KdADEq0A
    connect(this.state)
      .with(this.flowFiles$, (prev, flowFiles) => ({ ...prev, flowFiles }))
      .with(this.domains$, (prev, domains) => ({ ...prev, domains }))

    this.consumedPages$
      .pipe(withLatestFrom(this.userService.user$))
      .subscribe(([ numPages, user]) => {
        this.userService.updatePageBalance(user as UserRecord, 0 - numPages)
      })

    // See https://github.com/stephanrauh/ngx-extended-pdf-viewer/issues/2621#issuecomment-2442540589
    // effect(() => {
    //   this.pdfViewerApplication = this.pdfNotificationService.onPDFJSInitSignal();
    // });
  }

  constructPrefix(context: PipelineContext | null) {
    /*if(context)
      return `User: ${context.userId}:Pipeline:${context.pipeline || NO_PIPELINE}:Tool:${context.tool || NO_TOOL}`
    */
    return null
  }

  getEnv() {
    return this.environment.production === true ? "prod": "dev"
  }
  async getUser(userId: string) {
    return this.userService.user() as UserRecord
  }

  async sendMeterEvent(numPages: number) {
    this.log('Sending meter event to Stripe with payload', numPages)
    await sendMeterEvent(this.getEnv(), this.userService.user() as UserRecord, numPages, this)
  }
  log(...args: any[]): void {
    const prefix = this.constructPrefix(this.context)
    if(prefix)
      console.log(prefix, ...args)
    else
      console.log(...args)
  }
  debug(...args: any[]): void {
    const prefix = this.constructPrefix(this.context)
    if(prefix)
      console.debug(prefix, ...args)  
    else
      console.debug(...args)
  }
  warn(...args: any[]): void {
    const prefix = this.constructPrefix(this.context)
    if(prefix)
      console.warn(prefix, ...args)  
    else
      console.warn(...args)  
  }
  error(...args: any[]): void {
    const prefix = this.constructPrefix(this.context)
    if(prefix)
      console.error(prefix, ...args)
    else
      console.error(...args)
  }

  async getFlowFilesForUser(userDocId: string) {
    return await firstValueFrom(this.firebase.collectionWithConverter$(getUserFilesPath(userDocId),flowFileConverter))
  }

  async checkExecuteStep(tool: FlowTool, file: FlowFile) {
    this.log('checking execute step', tool, file);
    if (tool.parameters) {
      const params = this.getToolParameters(tool)
      this.showToolParameters$.next({ tool, file, params })
    }
    else {
      await this.executeStep(null,tool, file)
    }
  }

  getToolParameters(tool: FlowTool) {
    const params = { ...tool.parameters } as JsonFormData
    this.log('Tool params', params)
    const instructionsParam = params.controls.find(c => c.name === 'instructions')
    if (instructionsParam) {
      instructionsParam.value = tool.instructions
    }
    // this.log('getToolParameters', params)
    return params
  }

  markFileOpened(file: FlowFile) {
    this.openedFiles.update(set => new Set(set.add(file.docId)))
  }

  toggleFileOpened(file: FlowFile) {
    if (file.isFolder) {
      // re-render view with folder contents, provide breadcrumbs to return
      this.state.update(s => ({ ...s, currentFolder: file }))
    }
    else {
      this.openedFiles.update(set => {
        // this.log('toggling file opened', file.docId, set.has(file.docId))
        if (set.has(file.docId))
          set.delete(file.docId)
        else
          set.add(file.docId)
        return new Set(set) // must create new object to trigger change detection
      })
    }
  }

  resetCurrentFolder() {
    this.state.update(s => ({ ...s, currentFolder: undefined }))
  }

  async executeStep(context: PipelineContext | null, tool: FlowTool, file: FlowFile, params?: any) {
    const env = this.defaults.getDefault(PIPELINE_ENV_KEY)
    if(isExecEnvCloud(this.userService.user() as UserRecord, env as PipelineEnvironment)) {
      const data: any = {
        userId: this.getUserId(),
        toolName: tool.name,
        file: JSON.stringify(file)
      }
      if(params) {
        data.params = JSON.stringify(params)
      }
      await this.firebase.awaitCloudFunction('executePipelineStep', data)
    }
    else {
      try {
        this.context = context
        await this.executeStepLocal(tool, file, params)
      }
      finally { this.context = null }
    }
    
  }

  private async executeStepLocal(tool: FlowTool, file: FlowFile, params?: any) {
    this.log(`executing tool`, tool.name, file, params)
    this.setContextTool(tool.name)
    this.markFileOpened(file)
    const lastStep = await firstValueFrom(this.getLastCompletedStep(file, tool.name)) as FlowStep
    const filteredParams = Object.fromEntries(Object.entries(params || {}).filter(([k, v]) => !!v)) as any
    const instructions = params?.instructions
    this.log('lastStep detected', lastStep)
    await this.updateStep(file, { 
      name: tool.name,
      description: tool.description, 
      state: 'pending', 
      type: tool.type, 
      instructions, 
      parameters: filteredParams });
    await this.updateFile(file, { 
      state: 'running', 
      stateDescription: tool.type === 'ocr' ? 'scanning' : 'transforming'
    })
    await this.deleteStepPrompts(file, tool.name) // clear out any previous prompts
    try {
      if (tool.checkExecute)
        tool.checkExecute(file, this.userService.user() as UserRecord)
      let env = params
      if(env) {
        env.production = this.environment.production
      }
      else
        env = { production: this.environment.production }
      await tool.execute(file, lastStep, env)
    }
    catch (e:any) {
      this.error('error executing tool', e)
      await this.updateStep(file, { name: tool.name, state: 'error', error: e.message });
      await this.updateFile(file, { state: 'error', stateDescription: 'error' })
      throw e
    }
    await this.updateFile(file, { state: 'idle' })
  }

  setContextTool(toolName: string) {
    if(this.context) 
      this.context.tool = toolName
  }
  getFileSteps(file: FlowFile) {
    const path = `${getUserFilePath(file.userDocId as string, file.docId)}/steps`
    return this.firebase
      .collectionWithConverter$(path, flowStepConverter)
      .pipe(
        debounceTime(300),
        map((steps: FlowStep[]) => steps.toSorted((a, b) => a.lastUpdatedAt < b.lastUpdatedAt ? -1 : 1) as FlowStep[]),
        map(steps => steps.map(s => ({ ...s, parameters: isObjectEmpty(s.parameters) ? undefined : s.parameters }))),
        // tap(steps => this.log('steps', steps)), // JSON.stringify(steps))),
        shareReplay(1)
      )
  }

  getFileStepPrompts(file: FlowFile, stepName: string) {
    const path = `${getUserFileStepPath(file.userDocId as string, file.docId, stepName)}/prompts`
    return this.firebase
      .collectionWithConverter$(path, flowStepPromptConverter)
      .pipe(
        debounceTime(300),
        map(prompts => prompts.toSorted((a, b) => a.createdAt < b.createdAt ? -1 : 1) as FlowPrompt[]),
        tap(prompts => this.log('prompts', prompts)),
        shareReplay(1)
      )
  }

  getLastCompletedStep(file: FlowFile, priorToToolName = '') {
    return this.getFileSteps(file)
      .pipe(
        map(steps => steps.toReversed().find(s => s.name !== priorToToolName && s.state === 'complete')),
        tap(step => this.log('last completed step', step?.name)),
      )
  }

  getCompletedStepForTool(file: FlowFile, toolName = '') {
    return this.getFileSteps(file)
      .pipe(
        map(steps => steps.toReversed().find(s => s.name === toolName && s.state === 'complete')),
      )
  }

  private getFile(userId: string, docId: string) {
    return firstValueFrom(
      this.firebase.doc$(getUserFilePath(userId, docId), flowFileConverter),
    );
  }

  async addFile(f: Partial<FlowFile>) {
    this.loading.set(true);
    const path = getUserFilesPath(f.userDocId as string);
    const result = await this.firebase.updateAt(path, { ...f });
    this.loading.set(false);
    return result;
  }

  async updateStep(file: FlowFile, s: Partial<FlowStep>) {
    const path = `${getUserFilePath(file.userDocId as string, file.docId)}/steps/${s.name}`;
    const result = await this.firebase.updateAt(path, {
      ...s,
      lastUpdatedAt: new Date(),
    });
    return result;
  }

  async updateStepPrompt(file: FlowFile, step: string, input: any, output: string) {
    const path = `${getUserFileStepPath(file.userDocId as string, file.docId, step)}/prompts`;
    const result = await this.firebase.updateAt(path, {
      prompt: input,
      response: output,
      createdAt: new Date()
    });
    return result
  }

  async updateFile(file: FlowFile, data: Partial<FlowFile>) {
    const path = getUserFilePath(file.userDocId as string, file.docId);
    await this.firebase.updateAt(path, data);
  }

  async confirmMoveFile(doc: FlowFile, fldr: string) {
    await this.utilityService.confirm({
      header: `Move File`,
      message: `Are you sure you want to move ${doc.fileName} to ${fldr || 'My Files'}?`,
      confirm: () => {
        this.updateFile(doc, { containedInFolder: fldr })
      },
    });
  }

  async renameFolder(folder: FlowFile) {
    await this.utilityService.prompt({
      message: `Enter the new name of the folder ${folder.fileName}`,
      inputType: 'text',
      confirm: async (name: any) => {
        try {
          const newName = name.value
          if (this.flowFiles().find(f => f.isFolder && f.fileName === newName)) {
            throw new Error(`Folder ${newName} already exists`)
          }
          else {
            const user = this.userService.user() as UserRecord
            await this.updateFile(folder, ({ fileName: newName }))
            // Move files to new folder by setting containedInFolder: newName
            this.flowFiles().forEach(async f => {
              if (f.containedInFolder === folder.fileName)
                this.updateFile(f, { containedInFolder: newName })
            })
            this.resetCurrentFolder()
          }
        }
        catch (e: any) {
          this.utilityService.notify({ message: e.message })
        }
      }
    })
  }

  async zipFolder(folder: FlowFile | null) {
    const fileNames:string[] = []
    this.flowFiles().forEach(f => {
      if (!folder || f.containedInFolder === folder.fileName) {
        // for each file, add to zip the original and output files
        if (f.storageName)
          fileNames.push(f.storageName)
        if (f.outputStorageName)
          fileNames.push(f.outputStorageName as string)
      }
    })
    const zipfileName = folder ? `${folder.fileName}.zip` : 'MyFiles.zip'
    await this.zipFiles(zipfileName, fileNames, this.userService.userDocId() as string)
  }

  async zipFiles(zipfileName: string, fileNames: string[], userId: string) {
    console.log(`Will zip files`, fileNames)
    const loading = await this.utilityService.loading(`Creating ${zipfileName}`)
    const response = await this.firebase.awaitCloudFunction('createZipFromFiles', { 
      userId,
      fileNames 
    })
    loading.dismiss()
    this.log(response)
    // Download base64 file returned
    const buf = base64ToBlob(response.data as string, 'application/zip')
    const blob = new Blob([buf], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);
    // window.open(url);
    const a = document.createElement('a');
    a.href = url;
    a.download = zipfileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  async summarizeFolder(folder: FlowFile) {
    this.selectedFolder.set(folder)
    const schemaMap = this.buildSchemaMap(folder)
    if (schemaMap.size === 0) {
      await this.utilityService.presentToast(`No schemas in ${folder.fileName}`)
      return
    }
    console.log(schemaMap)
    const excelUtils = new ExcelUtils(this)

    // Create a workbook with one schema per tab
    const wb = new Workbook()
    const schemaNames = Array.from(schemaMap.keys())

    // Create a summary page
    const ws = wb.addWorksheet('Summary')
    const domain = folder.domainName || this.defaultDomainName()
    const classNameMap = await this.domainClassMaps().get(domain)
    const rows:any[] = []
    rows.push({ text: `Summarization of Folder: ${folder.fileName}`, style: { font: { size: 14 } } }) 
    rows.push({ text: `Domain: ${domain}`, style: { font: { size: 14 } } })
    rows.push('')
    for (const schemaName of schemaNames) {
      const cls = classNameMap?.get(schemaName)
      const files = schemaMap.get(schemaName) || []
      rows.push({ 
          text: `${schemaName}: ${cls?.description} : ${files.length} files`,
          style: { font: { bold: true } }
        })
      for (const file of files) {
        rows.push({ text: `     ${file.fileName}`, style: { font: { color: {argb: 'FF0000FF'  } } } })
      }
      rows.push('')
    }
    excelUtils.addRows(ws, rows)

    // Create a worksheet for each schema
    for (const schemaName of schemaNames) {
      const ws = wb.addWorksheet(schemaName)
      const files = schemaMap.get(schemaName) || []
      excelUtils.streamObjectsToWorksheet(schemaName, files, ws)
    }
    const blob = getBlobFromBuffer(await wb.xlsx.writeBuffer());
    const url = URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
  
  buildSchemaMap(folder: FlowFile) {
    const schemaMap = new Map<string, FlowFile[]>()
    const files = this.flowFiles().filter(f => f.containedInFolder === folder.fileName)
    files.forEach(f => {
      if (f.schemas) {
        const keys = Object.keys(f.schemas)
        keys.forEach(k => {
          const files = schemaMap.get(k) || []
          schemaMap.set(k, [...files, f])
        })
      }      
    })
    return schemaMap
  }
  
  getPDFNumPages(buf: ArrayBuffer) {
    const binaryString = new TextDecoder().decode(new Uint8Array(buf));
    const numPages = (binaryString.match(/\/Type[\s]*\/Page[^s]/g) || []).length;
    return numPages
  }

async examineFile(buf: ArrayBuffer, type: string) {
  try {
    const numPages = (type === 'application/pdf') ? this.getPDFNumPages(buf) : 1
    const fileSize = buf.byteLength // extract before detachment by pdfjs
    const result = { numPages, fileSize }
    console.log(result)
    return result
  } 
  catch (error) {
    this.error('Error examining file:', error);
    throw new Error('Failed to examine file');
  }
}
  
  async confirmDeleteFile(doc: FlowFile, stepsOnly = false) {
    await this.utilityService.confirm({
      header: `Delete ${doc.isFolder ? 'Folder' : 'File'} ${stepsOnly ? ' Steps' : ''}`,
      message: `Are you sure you want to delete ${stepsOnly ? 'pipeline steps of ' : ''} ${doc.fileName}?`,
      confirm: () => {
        if (doc.isFolder)
          this.deleteFolder(doc)
        else
          this.deleteFile(doc, stepsOnly);
      },
    });
  }

  async deleteStepPrompts(file: FlowFile, stepName: string) {
    const { docId, userDocId } = file
    const prompts = await firstValueFrom(this.getFileStepPrompts(file, stepName))
    for (const prompt of prompts) {
      const path = getUserFileStepPromptPath(userDocId, docId, stepName, prompt.docId)
      await this.firebase.delete(path)
    }
  }

  async deleteStep(file: FlowFile, step: FlowStep) {
    const { docId, userDocId } = file
    if (step.storageName) {
      const storageRef = ref(getStorage(), step.storageName)
      try {
        await deleteObject(storageRef)
      }
      catch(e) {
        // Swallow missing file error
        this.warn(`Error deleting file ${step.storageName}`, e)
      }
      this.log('file deleted', step.storageName)
    }
    await this.deleteStepPrompts(file, step.name)
    await this.firebase.delete(getUserFileStepPath(userDocId, docId, step.name))
  }

  async deleteFolder(folder: FlowFile) {
    this.flowFiles().forEach(async f => {
        if (f.containedInFolder === folder.fileName)
          await this.deleteFile(f)
    })
    await this.deleteFile(folder)
    this.resetCurrentFolder()
  }

  async deleteFile(file: FlowFile, stepsOnly = false) {
    this.loading.set(true)
    const { docId, userDocId } = file
    const filepath = getUserFilePath(userDocId, docId)
    try {
      await this.updateFile(file, { state: 'deleting', stateDescription: 'deleting' })
      // Delete all steps
      const steps = await firstValueFrom(this.getFileSteps(file))
      for (const step of steps) {
        await this.deleteStep(file, step)
      }
      // Delete file
      if (!stepsOnly) {
        if (file.storageName) {
          const storageRef = ref(getStorage(), file.storageName)
          try {
            await deleteObject(storageRef)
          }
          catch(e) {
            this.warn(`Error deleting file ${file.storageName}`, e)
          }
          this.log('file deletion process complete', file.storageName)
        }
        await this.firebase.delete(filepath)
      }
      else {
        await this.updateFile(file, { state: 'idle', stateDescription: 'deleted steps' })
      }
    } 
    catch (error) {
      this.error(`error deleting file ${filepath}`, error)
    }
    this.loading.set(false)
  }

  async getFileContentsBase64(storagePath: string) {
    const storageRef = ref(getStorage(), storagePath)
    const blob = await getBlob(storageRef)
    const result = await blobToBase64(blob)
    return result as string
}

  // Get file contents for internal use without using fetch
  async getFileContents(storagePath: string, asJson = true) {
    const storageRef = ref(getStorage(), storagePath)
    const blob = await getBlob(storageRef)
    const result = await blob.text()
    this.log(result)
    return asJson ? JSON.parse(result) : result
  }

  async getFileContentsAsLocalURL(path: string) {
    const storageRef = ref(getStorage(), path)
    const blob = await getBlob(storageRef)
    const buffer = await blob.arrayBuffer()
    const metadata = await getMetadata(storageRef)
    // this.log(`Metadata for file ${path}`, metadata)
    const url = URL.createObjectURL(new Blob([buffer], { type: metadata.contentType }));
    return url
  }

  async uploadFile(userDocId: string, file: File, pipelineName: string) {
    this.log('uploading file', file);
    //const name = getSafeName(file.name);
    const path = getStorageFilePath(userDocId, file.name);
    const storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file, { contentDisposition: 'attachment; filename*=utf-8\'\'' + file.name });
    percentage(uploadTask).subscribe((change) => {
      this.log('task progress', change);
    });

    uploadTask.then(async (task) => {
      const cnt = this.flowFiles().length
      const data = {
        userDocId,
        createdAt: new Date(),
        state: 'uploaded',
        stateDescription: 'waiting',
        containedInFolder: this.currentFolder()?.fileName,
        fileName: file.name,
        fileType: file.type,
        storageName: path,
        size: file.size,
        downloadURL: await getDownloadURL(storageRef),
        pipelineName
      } as FlowFile
      const result = await this.addFile(data)
      this.uploadedFileReadyQueue.update(queue => [...queue, { ...data, docId: result.id }]);
      if (cnt === 0) {
        // Send email to admin that uploads by user have begun
        const msg = `${this.environment.production ? 'prod' : 'dev'}: User uploads by ${userDocId} have begun`
        this.firebase.awaitCloudFunction("sendEmailAttachment",
          {
            to: this.environment.adminEmail,
            provider: 'mailgun',
            subject: msg,
            text: msg
        })    
      }
    })
  }

  private async finalizeUpload(
    uploadTask: UploadTask,
    file: FlowFile,
    tool: FlowTool,
    storageRef: StorageReference,
  ) {
    // percentage(uploadTask).subscribe((change) =>
    //   this.log('upload progress', change),
    // );
    uploadTask.then(async (task) => {
      // this.log('task complete', task);
      // Update the step
      const outputURL = await getDownloadURL(storageRef);
      const storageName = storageRef.fullPath   
      await this.updateStep(file, {
        name: tool.name,
        description: tool.description,
        elapsedMsec: Date.now() - (tool.startTime || 0),
        state: 'complete',
        error: undefined,
        storageName,
        outputURL        
      });
      // Store the last output URL on the file
      await this.updateFile(file, { outputURL, outputStorageName: storageName, outputType: tool.type });
    });
  }

  async uploadBlob(tool: FlowTool, file: FlowFile, blob: Blob, suffix: string) {
    const path = getStorageFilePathForSuffix(file.storageName, file.fileName, tool.name, suffix);
    const storageRef = ref(getStorage(), path);
    const task = uploadBytesResumable(storageRef, blob);
    await this.finalizeUpload(task, file, tool, storageRef);
  }

  async uploadAnalysis(tool: FlowTool, file: FlowFile, analysis: any) {
    const blob = getBlobFromJson(analysis);
    await this.uploadBlob(tool, file, blob, '.json');
  }

  submitParameters(event: any, tool: FlowTool, file: FlowFile | null) {
    this.log('submitting parameters', event, tool, file)
    this.showToolParameters$.next(undefined)
    if (file) {
      // Handle step execution
      let env = event
      if(env) {
        env.production = this.environment.production
      }
      else
        env = { production: this.environment.production }
      const context: PipelineContext = {
        pipeline: NO_PIPELINE,
        userId: this.getUserId(),
        tool: tool.name
      }
      this.executeStep(context, tool, file, env)
    }
    else {
      // Handle tool instructions update
      const instructions = event.instructions
      this.updateTool(tool, { instructions })
    }
  }

  async updateTool(tool: FlowTool, data: Partial<FlowTool>) {
    await this.firebase.updateAt(
      getToolPath(tool.name), 
      { 
        ...data, 
        name: tool.name, 
        userId: this.userService.userDocId(), 
        updatedAt: new Date() 
      }
    )
  }

  renderMarkdown(text: string) {
    // this.log('rendering markdown', text)
    const block = (marked(text) as string).trim() 
    return block ? this.sanitizer.bypassSecurityTrustHtml(block) : null
  }

  async postDocumentToDocupanda(file: FlowFile, processingMethod: string, params?: any): Promise<any> {
    const base64 = await this.getFileContentsBase64(file.storageName)
    const body:any = {
      dataset: this.environment.production ? 'prod' : 'dev',
      document: {
        file: {
          filename: file.fileName,
          contents: base64
        }
      },
    }
    if (params?.pages) {
      const nums = expandPageRange(params.pages)
      body.pages = nums.map(page => page > 0 ? page - 1 : 0) // Convert to 0-based index
      this.log('updated body', body)
    }
    if (processingMethod)
      body.processingMethod = processingMethod
    else if (params?.processingMethod) {
      body.processingMethod = params.processingMethod
    }

    const response = await this.firebase.awaitCloudFunction(
      'fetchJSONFromURL',
      {
        method: 'POST',
        url: DOCUPANDA_DOCUMENT_ENDPOINT,
        body: JSON.stringify(body)
      },
      540000 // 9 minutes
    )
    this.log('Docupanda response', response)
    return response
  }

  getUserId() {
    return (this.userService.user() as UserRecord).docId
  }

  async getDocupandaOutput(file: FlowFile) {
    const steps = await firstValueFrom(this.getFileSteps(file) as Observable<any>)
    const ocr = steps[0] ? await this.getFileContents(steps[0].storageName) : null
    return ocr
  }

  inspect(title: string, obj: any) {
    this.showInspector$.next({ title, message: JSON.stringify(obj, null, 2) })
  }

  async classify(documentIds: string[], domain: string) {
    const classNameMap = await this.domainClassMaps().get(domain)
    console.log('classify', domain, classNameMap)
    const classIdMap = new Map<string, any>(Array.from(classNameMap!.values()).map(c => [c.classId, c]))
    const result = await post(
      `https://app.docupanda.io/classify/batch`, { 
        multiClass: true,
        includeUnknown: true,
        documentIds })
    const pollResults:any[] = await Promise.all(result.classificationJobIds.map((jobId: string) => poll(`https://app.docupanda.io/classify/${jobId}`)))
    const final = pollResults.map((job: any) => {
      const classes = job.assignedClassIds.map((classId: string) => classIdMap.get(classId))
      return { ...job, classes }
    })
    return final
  }

  getSplitInstructions(file: FlowFile, classIds: string[]) {
      const classMap = this.domainClassMaps().get(file.domainName as string)
      const classes:any[] = Array.from(classMap!.values()).filter(c => classIds.includes(c.classId))
      const instructions = classes.map(c => `- ${c.description}`).join('\n')
      return 'Generate subdocuments for each of the following classes of documents if present:\n\n' + instructions
  }

  async split(documentId: string, instructions: string) {
      const result = await post(`https://app.docupanda.io/split`, { documentId, instructions })
      const pollResults = await poll(`https://app.docupanda.io/split/${result.jobId}`)
      return pollResults.newDocumentIds
  }

  async standardize(documentId: string, domain: string) {
      // Get classes for domain
      const classNameMap = await this.domainClassMaps().get(domain)
      const classIdMap = new Map<string, any>(Array.from(classNameMap!.values()).map(c => [c.classId, c]))
      // Get classes for document
      const doc = await get(`https://app.docupanda.io/document/${documentId}`)
      this.log(`Standardizing ${documentId} ${doc.result.numPages} pages ${doc.classified ? 'classified to ' + doc.classIds.length + ' classes' : 'not classified'}`)
      // For each class, look up schemaId for class and create standardization
      const results:any[] = []
      for (const classId of doc.classIds) {
          const cls = classIdMap.get(classId as string)
          const schemaId = cls.schemaId || classNameMap?.get(cls.useSchemaOfClass)?.schemaId
          this.debug('standardizing on class', schemaId, cls.className)
          // Skip if no schemaId
          if (!schemaId)
            continue
          const body = {
              schemaId,
              standardizationMode: "default",
              forceRecompute: true,
              documentIds: [ documentId ]
          }
          const result = await post(`https://app.docupanda.io/standardize/batch`, body)
          const pollResults:any[] = await Promise.all(result.standardizationJobIds.map((jobId: string) => poll(`https://app.docupanda.io/job/${jobId}`, 1000)))
          const stds = await Promise.all(result.standardizationIds.map((stId: string) => get(`https://app.docupanda.io/standardization/${stId}`)))
          results.push({ className: cls.className, standardization: stds[0] })
      }
      return results
  }

  defaultDomainName() {
    return this.defaultDomain()?.domainName as string
  }

  defaultCheckExecute(file: FlowFile, user: UserRecord) {
    if ((user.pageBalance || 0) <= 0)
        throw new Error(`Insufficient pageBalance credits ${user.pageBalance || 0}`)
  }

  async logPipelineStep(data: PipelineStepLog) {
    await this.firebase.updateAt(COSTS_COLLECTION, data)
  }
}
