import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, firstValueFrom, timer, switchMap, of, filter, shareReplay, distinctUntilChanged } from 'rxjs';
import { HandlebarsService } from './handlebars.service';
import { CLOUD_OPEN_AI, SharedUserService } from './shared-user.service';
import {
  AZURE_OPENAI_35_MODEL_NAME,
  AZURE_OPEN_AI,
  ErrorPrompt,
  ExtractService,
  GPT35,
  MAX_CONTINUATIONS,
  OPENAI_35_MODEL_NAME,
  OPENAI_40_MODEL_NAME,
  OPENAI_CLOUD_FUNCTION_CLIENT_TIME_OUT,
  PROMPT_STATUS_KEY,
  Prompt,
  PromptSpec,
  PromptStatusValue,
  StatusKeyWriter,
  getFallbackModel,
  nowstr,
  splitTrim,
  writeCompleteStatus,
  writeErrorPrompt,
  writePromptStatus,
  UserKeyDoc,
  nowstrISO,
  OPENAI_40_TURBO_MODEL_NAME,
  OpenAIResult,
  OpenAIConfig,
  DEFAULT_OPENAI_MODEL_KEY,
  retry
} from '@cheaseed/node-utils';

import { PromptService } from './prompt.service';
import { ContentService } from './content.service';
import { FirebaseService } from './firebase.service';
import { getDoc } from '@angular/fire/firestore';
import { Events, SharedEventService } from './shared-event.service';
import { ChatStateService } from './chat-state.service';
import { OpenAIFactoryService } from './openai-factory-service';

export interface SpinnerTitling {
  title: string;
  subtitle: string;
}

@Injectable({
  providedIn: 'root',
})
export class OpenAIDriverService implements StatusKeyWriter {

  userService = inject(SharedUserService)
  contentService = inject(ContentService)
  currentModel = OPENAI_40_TURBO_MODEL_NAME
  defaultModel = OPENAI_40_TURBO_MODEL_NAME
  nextModelNameToToggle = OPENAI_40_MODEL_NAME
  
  readonly isLocal = true  // indicates local API
  isAzure = false
  isAzure$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(user => this.userService.watchUserKey(user?.docId, AZURE_OPEN_AI)),
      distinctUntilChanged((prev, curr) => {
        return prev?.value === curr?.value
      }),
      switchMap(() => {
        this.isAzure = this.userService.isAzureOpenAI()
        //Support both AzureOpenAI and OpenAI
        return of(this.isAzure)
      })
    )
 
  // Track chat history and thread name
  prompts: Prompt[] = []
  thread: string | undefined
  lastResponse: string | null = null
  currentTemperature = 0.5
  currentTopP = 1.0
  protected userId = ''
  protected promptChatName = ''

  promptStatus$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(user => this.userService.watchUserKey(user?.docId, PROMPT_STATUS_KEY)),
      distinctUntilChanged((prev, curr) => {
        return prev?.value.status === curr?.value.status && prev?.value.promptName === curr?.value.promptName
        }
      ),
      map((doc:UserKeyDoc) => {
        const status = doc?.value as PromptStatusValue
        if (status) {
          console.log(`Detected promptStatus$ ${status.status} chain ${status.startPromptName}, prompt ${status.promptName}`)
          return { ...status, createdAt: doc?.createdAt, updatedAt: doc?.updatedAt }
        }
        else
          return null
      }),
      shareReplay(1)
    )

  constructor(
    @Inject('environment') protected environment: any,
    @Inject('ExtractService') private extractService: ExtractService,
    @Inject('UtilityService') private utilityService: any,
    public handlebars: HandlebarsService,
    public promptService: PromptService,
    public firebase: FirebaseService,
    public eventService: SharedEventService,
    public chatStateService: ChatStateService,
    private openaiFactory: OpenAIFactoryService) { 
      this.openaiFactory.setLogger(this)
      this.isAzure$
        .subscribe(v => this.isAzure = v)

      this.contentService.loader$
        .pipe(filter(loader => !!loader))
        .subscribe(() => {
          const m = this.getGlobal(DEFAULT_OPENAI_MODEL_KEY) || OPENAI_40_MODEL_NAME
          this.defaultModel = m
        })
  }

  async toggleModel() {
    this.nextModelNameToToggle = this.defaultModel
    this.defaultModel = (this.defaultModel === OPENAI_40_MODEL_NAME)
    ? OPENAI_40_TURBO_MODEL_NAME
    : OPENAI_40_MODEL_NAME    
  }


  async setCurrentModel(model: string | undefined) {
    // Default to gpt-4 if undefined
    // AzureOpenAI uses "35" instead of "3.5" for model version 
    // (e.g. "gpt-3.5-turbo-16k" in OpenAI is "gpt-35-turbo-16k")
    const default4 = this.defaultModel
    this.currentModel = (model === GPT35)
        ? (this.isAzure 
            ? AZURE_OPENAI_35_MODEL_NAME
            : OPENAI_35_MODEL_NAME) 
        : (model || default4)
  }

  // Helpers
  getUserKey(key: string) { return this.userService.getUserKey(key) }
  setUserKey(key: string, value: unknown) { return this.userService.setUserKey(key, value, true) }
  getGlobal(key: string) { return this.contentService.getGlobal(key) }
  debug(...args: any[]) { console.debug(...args) }
  log(...args: any[]) { console.log(...args) }
  warn(...args: any[]) { console.warn(...args) }
  error(...args: any[]) { console.error(...args) }

  resetThread() {
    this.thread = nowstrISO()
    this.prompts = []
  }

  setThread(threadId: string, prompts: Prompt[]) {
    this.thread = threadId
    this.prompts = prompts
    this.log("setThread", { threadId, prompts })
  }

  async appendPrompt(prompt: Prompt) {
    this.prompts = [...this.prompts, prompt]
  }

  // Remove prompt from prompts$ subject
  protected async filterPrompts(prompt: Prompt) {
    const filtered = this.prompts.filter(p => p.promptKey !== prompt.promptKey)
    this.prompts = filtered
  }

  protected async removePrompts(name: string) {
    const filtered = this.prompts.filter(p => p.promptKey !== name)
    this.prompts = filtered
  }

  async sendPrompt(chatId: string, chatName: string, promptKey: string, promptCount = 0, delay = 0) {
    this.userId = this.userService.getCurrentUserId() as string
    const isCloud = this.getUserKey(CLOUD_OPEN_AI)
    this.promptChatName = chatName
    this.log("sendPrompt", { chatId, promptKey, promptCount, isCloud })
    if (isCloud === false) {
      await this.sendPromptUsingLocalOpenAI(chatId, promptKey, promptCount)
    }
    else { // default to cloud if undefined
      const now = nowstrISO()
      const newStatus:PromptStatusValue = {
        status: 'INITIALIZING',
        chatId,
        promptName: promptKey,
        startPromptName: promptKey,
        chatName,
        pendingPrompts: [ promptKey ], // need something pending to show spinner
        spinnerTitle: { 
          title: this.getGlobal('prompt.spinner.title.message') || '',
          subtitle: this.getGlobal('prompt.spinner.subtitle.message') || ''
        },
        isLocal: false
      }
      // Set status to default running so that spinner appears without delay
      await this.updatePromptStatusKey(newStatus, now)

      // Delay the call to the cloud function to allow userkeys to write
      if (delay) {
        this.log("sendPrompt", `delaying launch of cloud function by ${delay} msecs`)
        await firstValueFrom(timer(delay))
      }

      const start = Date.now()
      const args = {
        chatId: chatId,
        promptKey: promptKey,
        promptChatName: chatName,
        timezone: this.userService.getCurrentUser()?.timezone,
        promptCount: promptCount,
        threadId: this.thread,
        userId: this.userId,
        defaultModel: this.defaultModel
      }
      // Retry up to 5 times before giving up
      // if retries fail, manage INITIALIZING status in trigger by setting to ERROR
      try {
        await retry(
          (funcname: string, args: any, timeout: number) => this.firebase.awaitCloudFunction(funcname, args, timeout),
          [ 'sendPromptPubSub', args, OPENAI_CLOUD_FUNCTION_CLIENT_TIME_OUT ],
          5)
        this.log(`sendPrompt cloud function initiated for ${promptKey} in ${Date.now() - start} msecs`)
      }
      catch(error:any) {
        // Force an error in the promptStatus, so that the spinner stops and user is notified
        this.updatePromptStatusKey(
          { 
            ...newStatus,
            status: 'ERROR', 
            error: { 
              message: error.message, 
              type: error.name, 
              code: error.code }
          }, 
          now)
      }
    }
  }

  protected async sendPromptUsingLocalOpenAI(chatId: string, promptKey: string, promptCount: number, overrideModel?: string) {
    const spec = this.promptService.getPromptSpecNamed(promptKey)
    this.log("sendPromptUsingLocalOpenAI", { promptKey, promptCount, spec })
    if (!spec) {
      const errmsg = `PromptSpec ${promptKey} not found`
      const dummySpec = new PromptSpec({name: 'No Spec Found'})
      await this.writeCompleteStatus(dummySpec, chatId, promptKey, new Error(errmsg))
    }
    else {
      const temps = splitTrim(spec.testTemperatures)
      if (temps.length > 0) {
        // loop over test temperatures and write results to separate keys
        this.log("Found testTemperatures", temps)
        const basekey = spec.key
        // Remove previous keys
        for (const key of this.userService.getUserKeysStartingWith(basekey as string)) {
          this.log("Deleting user key", key)
          await this.userService.deleteUserKey(key)
        }
        for (const temperature of temps) {
          const newSpec = new PromptSpec(spec)
          newSpec.temperature = temperature
          newSpec.key = `${basekey}-${temperature}`
          await this.sendPromptLocal(newSpec, chatId, promptKey, promptCount)
          this.prompts = []
          await firstValueFrom(timer(1000))
        }
      }
      else
        await this.sendPromptLocal(spec, chatId, promptKey, promptCount, overrideModel)
    }
  }

  protected async sendPromptLocal(spec: PromptSpec, chatId: string, promptKey: string, promptCount: number, fallbackModel?: string) {
    // If an override model has been specified, it means it is an already running
    // prompt status with the pending prompt set - so skip writing the prompt status
    if(!fallbackModel)
      await this.writePromptStatus(spec, chatId, promptCount)
    this.currentTemperature = this.getPromptTemperature(spec)
    this.currentTopP = this.getPromptTopP(spec)
    await this.setCurrentModel(fallbackModel ? fallbackModel : spec.modelVersion)
    // No error handling for this
    const prompt = this.handlebars.formatWithUserKeys(spec.promptTemplate, true) || ''
    this.log("sendPrompt", promptKey, prompt)
    if (spec.hasSystemRole()) {
      const sys = this.promptService.addSystemPrompt(this.userId, chatId, promptKey, this.thread as string, prompt as string)
      this.appendPrompt(sys)
    }
    else {
      let data, done = false
      const extractionRecords = []
      // If prompt template evaluated to a non-empty string, send it to OpenAI
      if ((prompt as string).trim().length > 0) {
        while (!done) {
          try {
            data = await this.logChatQuery(spec.getOpenAPILookupKey(), this.userId, chatId, promptKey, spec.promptGroup, prompt as string)
            await this.appendPrompt(data)
            // Handle truncated responses (GPT3.5)
            // this code is relevant only to completions
            const maxContinuations = spec.maxContinuations || MAX_CONTINUATIONS
            let continuations = 0
            while (data.finish_reason === 'length' && continuations < maxContinuations) {
              const continue_prompt = this.promptService.getContinuePrompt(spec)
              const next = await this.logChatQuery(spec.getOpenAPILookupKey(), this.userId, chatId, promptKey, spec.promptGroup, continue_prompt)
              if (next.response === data.response)
                throw new Error("Identical response text detected in continuation loop, aborting")
              if (spec.isFormatJSON())
                data.response = this.promptService.appendJSON(data.response, next.response)
              else
                data.response += next.response
              data.finish_reason = next.finish_reason
              await this.appendPrompt(next)
              continuations++
            }
            if (continuations === maxContinuations) {
              const errmsg = `truncated response loop exceeded ${maxContinuations} continuations`              
              this.error(errmsg)
              await this.writePromptStatus(spec, chatId, promptCount, new Error(errmsg))
            }

            // Store in user key
            if (spec.key) {
              const parsed = spec.parseResponse(data.response)
              this.log("Storing user key", spec.key,
                typeof parsed === 'string'
                  ? `string length ${parsed.length}`
                  : parsed)
              this.setUserKey(spec.key, parsed)
              // Handle secondary extractions
              this.extractService.reset()
              if (spec.extractions) {
                for (const extract of spec.extractions) {
                  let value
                  if (extract.type === 'text')
                    value = this.extractService.extractTextFrom(spec.key, parsed, extract.heading)
                  else if (extract.type === 'list')
                    value = this.extractService.extractListFrom(spec.key, parsed, extract.heading)
                  if (value) {
                    this.log("Storing extracted user key", extract.key)
                    this.setUserKey(extract.key, value)
                    extractionRecords.push({ key: extract.key, value })
                  }
                }
                // Update prompt with extractions array
                if (extractionRecords.length > 0)
                  this.promptService.updatePrompt(data.createdAt, this.userId, { extractions: extractionRecords })
              }              
            }
            done = true
          }
          catch (err: any) {
            // Remove prompts for this key 
            await this.removePrompts(promptKey)
            const newFallback = getFallbackModel(err, spec.modelVersion as string, this.isAzure)
            if (newFallback && !fallbackModel) {
              this.warn(`Detected error for model ${spec.modelVersion} isAzure = ${this.isAzure}, ${JSON.stringify(err)}`)
              this.log(`Retrying ${promptKey} with model version ${newFallback}`)
              await this.sendPromptUsingLocalOpenAI(chatId, promptKey, promptCount, newFallback)
            }
            else {
              this.error('Aborting  after error', JSON.stringify(err))
              await this.writeErrorPrompt(spec, chatId, prompt as string, err)
              await this.writeCompleteStatus(spec, chatId, promptKey, err)
              throw err
            }
          }
        }
      }
      else {
        // prompt template evaluated to empty string, set key to default if specified
        if (spec.defaultKeyValue) {
          this.setUserKey(spec.key as string, spec.defaultKeyValue)
        }
      }

      // By default, we forget prompt
      if (!spec?.shouldRememberPrompt())
        await this.filterPrompts(data as Prompt)

      // Check for sending a Clevertap event on completion
      if (spec.shouldSendCompletionEvent()) {
          const extracts = Object.fromEntries(extractionRecords.map(e => [ e.key, e.value ]))
          this.eventService.record(Events.PromptCompleted, { promptKey, chatId, ...extracts })
      }

      // set lastResponse subject (for use in report )
      this.lastResponse = data?.response
    }
    // Chain to the next prompt if there is one
    // Note that we do not chain if testTemperatures is specified (testing mode only)
    if (spec.nextPromptSpec && !spec.testTemperatures) {
      const nextPrompt = spec.nextPromptSpec.name
      // Add delay for next prompts to avoid HTTP 429 rate-limiting errors
      timer(500 * (promptCount + 1)).subscribe(async () => {
        await this.sendPromptUsingLocalOpenAI(chatId, nextPrompt, promptCount + 1)
      })
    }
    else {
      await this.writeCompleteStatus(spec, chatId, promptKey)
    }
  }

  protected generateAsString(val: any) {
    return typeof val === 'string' ? val : JSON.stringify(val)
  }

  getPromptTemperature(spec: PromptSpec) {
    const val = parseFloat(spec.temperature || this.getGlobal('prompt.default.temperature'))
    return val || 0.5
  }

  getPromptTopP(spec: PromptSpec) {
    const val = parseFloat(spec.top_p || this.getGlobal('prompt.default.top_p'))
    return val || 1.0
  }

  getNowStr(): string {
    return nowstr()
  }

  async setErrorPrompt(data: ErrorPrompt) {
    await this.firebase.updateAt(`${this.userService.getUserPromptsPath(this.userId)}/${this.getNowStr()}`, data, false)
  }
  async logChatQuery(
    openAPIIndicator: string,
    userId: string,
    chatId: string,
    promptKey: string,
    promptGroup: string | undefined,
    prompt: string,
    expectsJson: boolean = false) {

    const config: OpenAIConfig = {
        isAzure: this.isAzure,
        env: this.environment.production === true ? "prod": "dev", 
        temperature: this.currentTemperature,
        topP: this.currentTopP,
        model: this.currentModel,
        logger: this
    }
    const openAPI = this.openaiFactory.getOpenAPI(openAPIIndicator, config)
    try {
      const obj: OpenAIResult = await openAPI.logChatQuery(config, prompt, this.prompts, expectsJson)
      const result = this.promptService.addPrompt(
          userId,
          chatId,
          promptKey,
          promptGroup,
          prompt,
          this.thread as string,
          obj)
        return result
    }
    catch(e: any) {
        this.error(e)
        const errmsg = e.error?.error?.message || JSON.stringify(e.error)
        const usermsg = this.getGlobal('prompt.error.message')
        // Check for context_length_exceeded error and if env dev, alert the user
        if (!this.environment.production && e instanceof HttpErrorResponse)
          this.utilityService.notify({ message: `${usermsg || errmsg}` })
        throw e
    }
    
  }

  async writeCompleteStatus(spec: PromptSpec, chatId: string, promptKey: string, error?: any) {
    await writeCompleteStatus(
      this,
      spec,
      chatId,
      promptKey,
      this.promptChatName,
      this.thread as string,
      error
    )
  }

  async writePromptStatus(spec: PromptSpec, chatId: string, promptCount: number, error?: any) {
    await writePromptStatus(
      this,
      spec,
      chatId,
      promptCount,
      this.thread as string,
      this.promptChatName,
      error)
  }

  async writeErrorPrompt(spec: PromptSpec, chatId: string, prompt: string, error: Error) {
    writeErrorPrompt(
      chatId,
      spec.name,
      prompt,
      this.userId,
      this.thread as string,
      this.currentModel,
      this.currentTemperature,
      this.currentTopP,
      this,
      error
    )
  }

  async updatePromptStatusKey(value: PromptStatusValue, createdAt?: string) {
    const nowstring = nowstrISO()
    await this.userService.writeUserKey(
      PROMPT_STATUS_KEY,
      {
        createdAt: createdAt || nowstring,
        updatedAt: nowstring,
        key: PROMPT_STATUS_KEY,
        value
      },
      false)
  }

  async getUserKeyDoc(key: string) {
    const doc = await getDoc(this.userService.getUserKeyDocRef(key, this.userId))
    return doc.data() as UserKeyDoc
  }

  async updatePromptStatusKeyNotified(status: any) {
    // Critical code -- must make sure incoming status is not stale relative to current userkey
    const newStatus = { ...status, status: 'NOTIFIED' }
    const createdAt = status.createdAt
    //console.log('updatePromptStatusKeyNotified', status.createdAt)
    // hacks to cleanup status for persisting
    delete newStatus.createdAt
    delete newStatus.updatedAt
    await this.updatePromptStatusKey(newStatus, createdAt)
  }

  isChatActive(status: PromptStatusValue) {
    return this.chatStateService.chatsInProgress$
      .pipe(
        map(m => {
          if ([ 'RUNNING', 'INITIALIZING', 'COMPLETED' ].includes(status.status))
            return true
          else
            return m.has(status.chatName as string)
        }))
  }

  isNotFinalized(s: PromptStatusValue) {
    // Only return false if NOTIFIED or ERROR
    return ![ 'NOTIFIED', 'ERROR' ].includes(s.status)
  }

}