<div class="flex flex-col justify-center px-6 py-4 pb-8">
  <!-- <div class="text-center">
    <lib-default-message [key]="auth.signInKey$ | async"/>
  </div> -->
  <div *ngIf="!loading(); else spinner" class="mt-6 flex flex-col gap-4 w-full">
    <button mat-raised-button (click)="checkDevPassword(googleLogin)">
      <div class="flex gap-3 w-64 pl-8">
        <img width="20" src="assets/shared/icon/google-signin.svg"/>
        <span class="text-sm font-semibold leading-6">Sign in with Google</span>
      </div>
    </button>
    <button *ngIf="!environment.production" mat-raised-button (click)="checkDevPassword(appleLogin)">
      <div class="flex gap-3 w-64 pl-8">
        <img width="20" src="assets/shared/icon/apple-signin.png"/>
        <span class="text-sm font-semibold leading-6">Sign in with Apple</span>
      </div>
    </button>
    <button mat-raised-button (click)="checkDevPassword(microsoftLogin)">
      <div class="flex gap-3 w-64 pl-8">
        <img width="20" src="assets/shared/icon/ms-symbollockup_mssymbol_19.png"/>
        <span class="text-sm font-semibold leading-6">Sign in with Microsoft</span>
      </div>
    </button>
    <button mat-raised-button (click)="checkDevPassword(promptForEmail)">
      <div class="flex gap-3 w-64 pl-8">
        <span class="material-symbols-outlined">mail</span>
        <span class="text-sm font-semibold leading-6">Sign in with email</span>
      </div>
    </button>
    <!-- <button mat-raised-button (click)="promptForPhone()">
      <div class="flex justify-center gap-3">
        <span class="material-symbols-outlined">phone</span>
        <span class="text-sm font-semibold leading-6">Sign in with phone</span>
      </div>
    </button>
    <button hidden id="phoneButton" style="display: none"></button> -->
  </div>
  <ng-template #spinner>
    <lib-spinner title="Signing In"/>
  </ng-template>
</div>
