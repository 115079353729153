import { FlowTool } from '../fileflow.interface';
import { JsonFormData } from '@cheaseed/node-utils';
import { DocuPandaTool } from './DocuPandaTool';

export class DocuPandaRemoveWatermarkTool extends DocuPandaTool {

    override name = 'standard-ocr-removeWatermark'
    override description = 'Data Extraction of Watermarked Document'
    override parameters: JsonFormData = {
      submitAlwaysEnabled: true,
      controls: [ 
        { 
          name: 'pages', 
          label: 'Page range',
          type: 'text', 
          placeholder: '(e.g. 1-3,6,10)' 
        }
      ]
    }
    override processingMethod = 'removeWatermark'
}