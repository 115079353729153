import { ChangeDetectionStrategy, Component, OnDestroy, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupService } from './group.service';
import { UtilityService } from '@fidoc/util';
import { RouterModule } from '@angular/router';
import { DateFnsModule } from 'ngx-date-fns';
import { Group, MAX_GROUP_NAME_LENGTH, MIN_GROUP_NAME_LENGTH, UserRecord } from '@fidoc/shared';
import { UserService, DefaultMessageComponent } from '@fidoc/util';

@Component({
  selector: 'lib-groups',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DateFnsModule,
    DefaultMessageComponent],
  templateUrl: './groups.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsComponent implements OnDestroy {
  
  userService = inject(UserService)
  utilityService = inject(UtilityService)
  groupService = inject(GroupService)

  constructor() { 
    // console.log('GroupsComponent created')
  }

  ngOnDestroy() {
    // console.log('GroupsComponent destroyed')
  }

  async createGroup(user: UserRecord | null) {
    await this.utilityService.prompt({ 
      header: 'Create Group', 
      message: 'Enter the name of the group you would like to create.',
      inputType: 'text', 
      placeholder: 'Group Name', 
      attributes: {
        minlength: MIN_GROUP_NAME_LENGTH,
        maxlength: MAX_GROUP_NAME_LENGTH
      },
      okText: 'Create', 
      confirm: (data:any) => this.groupService.createGroup(data.value, user!)
    }) 
  }
  
  deleteGroup(group: Group) {
    this.utilityService.confirm({
      header: 'Delete Group',
      message: `Are you sure you want to delete the group ${group.name}?`,
      yesLabel: 'Delete',
      confirm: () => this.groupService.deleteGroup(group)
    })
  }
}
