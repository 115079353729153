import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultMessageComponent, StripeService, UserService } from '@fidoc/util';
import { COUPON_CODE } from '@fidoc/shared';

@Component({
  selector: 'lib-pricing',
  standalone: true,
  imports: [
    CommonModule,
    DefaultMessageComponent
  ],
  templateUrl: './pricing.component.html',
  styleUrl: './pricing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricingComponent {

    stripeService = inject(StripeService)
    userService = inject(UserService)
    userDocId = this.userService.userDocId() as string

    getProductPriceWithDiscount(price: number) {
      const discount = this.stripeService.globalDiscount()
      return (discount > 0.0 ? price - (price * discount) : price).toFixed(2).replace(/\.?00+$/, "");
    }
}
