import { Injectable, inject, signal } from '@angular/core';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { debounceTime, map, shareReplay } from 'rxjs';
import { FirestoreCollectionTypes, FlowDomain } from '@fidoc/shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  auth = inject(AuthService)
  firebase = inject(FirebaseService)
  domains = signal<FlowDomain[]>([])

  allDomains$ = this.firebase.collectionNoAuth$(FirestoreCollectionTypes.DOMAINS_COLLECTION)
    .pipe(
      debounceTime(100),
      map((result: FlowDomain[]) => result.toSorted((a, b) => a.domainName < b.domainName ? -1 : 1)),
      shareReplay(1)
    )

  constructor() {
    this.allDomains$
      .pipe(takeUntilDestroyed())
      .subscribe(domains => {
        this.domains.set(domains)
      })
  }
  
  getDomainNamed(name: string) {
    return this.domains().find(domain => domain.domainName === name)
  }
}
