@let fldr = folder();
@if (userService.adminRole()) {
    <button mat-menu-item (click)="inspectorService.inspect(fldr.fileName, fldr)">
        <mat-icon class="material-symbols-outlined">visibility</mat-icon>
        <span class="text-blue-500">Inspect</span>
    </button>
    <button mat-menu-item (click)="fileFlowService.summarizeFolder(fldr)">
        <mat-icon class="material-symbols-outlined">visibility</mat-icon>
        <span class="text-blue-500">Summarize</span>
    </button>              
}
<button mat-menu-item (click)="fileFlowService.zipFolder(fldr)">
    <mat-icon class="material-symbols-outlined">folder_zip</mat-icon>
    <span>Download Folder</span>
</button>
<button mat-menu-item (click)="fileFlowService.renameFolderOrFile(fldr)">
    <mat-icon class="material-symbols-outlined">edit</mat-icon>
    <span>Rename</span>
</button>
<button mat-menu-item (click)="fileFlowService.confirmDeleteFile(fldr)">
    <mat-icon class="material-symbols-outlined">delete</mat-icon>
    <span>Delete Folder</span>
</button>
<button mat-menu-item (click)="fileFlowService.confirmDeleteFile(fldr, true)">
    <mat-icon class="material-symbols-outlined">delete</mat-icon>
    <span>Delete Folder Contents</span>
</button>
