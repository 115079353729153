<div class="min-h-full">
  <nav class="border-b border-gray-200 bg-white">
    <div class="mx-auto px-6" [ngClass]="{ 'max-w-7xl': !isReview }">
      <div class="flex justify-between">
        <div class="flex gap-1">
          @if (utilityService.backButtonAvailable$ | async) {
            <div class="w-9 h-9 self-center mb-2 cursor-pointer">
              <div (click)="utilityService.backButtonClicked$.next(true)">
                <span class="material-symbols-outlined text-4xl">chevron_left</span>
              </div>  
            </div>
          }

          <!-- Logo visible always -->
          <div class="pl-4 h-16 flex flex-shrink-0 items-center cursor-pointer" [routerLink]="[ '/home' ]">
            <img src="assets/images/fidocs-logo-medium.png" alt="fidocs logo" class="h-9"/>
          </div>

          @if (userService.user(); as user) {
            <!-- Top menu items only visible on large screens -->
            <div class="hidden sm:flex sm:pl-5">
              <ng-container *ngTemplateOutlet="optionsMenuTop; context: { user }"></ng-container>
            </div>
          }
        </div>

        @if (userService.user(); as user) {            
          <!-- Profile dropdown only visible on large screens -->
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <ng-container *ngTemplateOutlet="showPageBalance; context: { user }"/>
            <!-- Profile dropdown -->
            <div class="relative ml-3">            
              <div>
                <button [matMenuTriggerFor]="userMenu" 
                  class="max-w-xs items-center rounded-full bg-white"
                  aria-expanded="false" 
                  aria-haspopup="true">
                  <ng-container *ngTemplateOutlet="profileImage; context: { user }"></ng-container>
                </button>
                <mat-menu #userMenu="matMenu" xPosition="before" class="w-64">
                  <ng-template matMenuContent>
                    <div class="block px-4 py-2 text-sm text-gray-700 font-bold">
                      <ng-container *ngTemplateOutlet="userInfo; context: { user }"></ng-container>
                    </div>
                    <mat-divider/>
                    <ng-container *ngTemplateOutlet="profileMenu; context: { user }"></ng-container>
                  </ng-template>
                </mat-menu>
              </div>
            </div>
          </div>

          <!-- Hamburger menu only visible on small screens -->
          <div class="flex items-center sm:hidden">
            <ng-container *ngTemplateOutlet="showPageBalance; context: { user }"/>
            <!-- Mobile menu button -->
            <button 
              [matMenuTriggerFor]="hamburgerMenu"
              class="rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500" 
              aria-controls="mobile-menu" aria-expanded="false">
                <span class="material-symbols-outlined text-chea-purple">menu</span>              
            </button>
            <mat-menu #hamburgerMenu="matMenu" xPosition="before" class="w-64 font-sans">
              <ng-template matMenuContent>
                  <div class="flex items-center px-4 py-2 gap-4 text-base">
                    <ng-container *ngTemplateOutlet="profileImage; context: { user }"></ng-container>
                    <ng-container *ngTemplateOutlet="userInfo; context: { user }"></ng-container>
                  </div>
                  <ng-container *ngTemplateOutlet="optionsMenu; context: { position: 'left', user }"></ng-container>
                  <mat-divider/>
                  <ng-container *ngTemplateOutlet="profileMenu; context: { user }"></ng-container>
              </ng-template>
            </mat-menu>
          </div>
        }
        </div>
      </div>
  </nav>
  
  <div>
    <main>
      @if (userService.user(); as user) {
        @if (!user.hasAcceptedTerms) {
          <div class="w-full p-8 flex items-center justify-center">
            <lib-accept-terms (accepted)="acceptTerms(user)"></lib-accept-terms>            
          </div>
        }
        @else if (isUserActive(user)) {
          @if (hasFreeTrialExpired(user)) {
            @if (launchSelectedPlan$ | async; as selectedPlan) {
                <ng-container *ngTemplateOutlet="showInterstitialPage; context: { selectedPlan }"></ng-container>
            }
            @else {
                <div class="p-4 text-red-500 flex items-center justify-center bg-slate-300">
                    <lib-default-message 
                        key="trialExpired.choosePlan"
                        class="text-xl" 
                        default="Your free trial has expired. Please choose a plan"/>
                </div>
                <div class="w-full p-8 flex items-center justify-center">
                    <lib-pricing></lib-pricing>
                </div>
            }
          }
          @else {
            <div class="mx-auto p-6" [ngClass]="{ 'max-w-7xl': !isReview }">
              @if (user.currentRole == 'partner') {
                <lib-breadcrumb-strip [title]="'Partner: <b>' + user.name + '</b>'" [message]="user.docId" [hideBackArrow]="true"/>
                <lib-partner-view [partner]="user"/>
              }
              @else {
                <router-outlet></router-outlet>
              }
            </div>
          }
      }
      @else if (!user.subscriptionInfo && (selectedPlan$ | async)) {
          @if (launchSelectedPlan$ | async; as selectedPlan) {
            <ng-container *ngTemplateOutlet="showInterstitialPage; context: { selectedPlan }"></ng-container>
          }
        }
        @else if (!user.subscriptionInfo) {
          <div class="w-full p-8 flex items-center justify-center">
            <lib-pricing></lib-pricing>
          </div>
        }
        @else if (user.subscriptionInfo && ![ 'active', 'trialing', 'incomplete' ].includes(user.subscriptionInfo.status)) {
          <div class="w-full p-8 flex flex-col gap-4 items-center justify-center">
            An error occurred with your subscription (status = {{ user.subscriptionInfo.status }})
            <button mat-raised-button class="w-48 justify-self-center" (click)="userService.manageStripeSubscription(user)">
              Manage Subscription
            </button>
          </div>
        }
        @else {
          <div class="w-full p-8 flex items-center justify-center">
            <div class="text-center">
              <lib-default-message key="account.not.approved.markdown" [markdown]="true"/>
            </div>
          </div>
        }
      }
      @else if (userService.user() === null) {
        <div class="w-full h-full flex flex-col items-center justify-center">
          <lib-default-message key="login.message.markdown" [markdown]="true" class="text-center w-full py-8"/>
          <lib-login></lib-login>
        </div>
      }
      @else {
        <mat-spinner class="fidoc-mat-spinner"></mat-spinner>
      }
    </main>
  </div>
</div>

<ng-template #showInterstitialPage let-selectedPlan="selectedPlan">
    <div class="w-full p-8 text-center">
        <lib-default-message 
            key="awaiting.purchase.message"
            class="text-xl" 
            default="Awaiting purchase of $0 plan."
            [args]="[ stripeService.getProduct(selectedPlan)?.name ]"/>
        <br/>
        <lib-default-message 
            [key]="environment.production ? 'when.purchase.completed.prod' : 'when.purchase.completed.dev'"
            class="text-base" 
        />
        <br/>
        <div>
        <lib-default-message 
            key="select.different.plan.message"
            class="text-base" 
            default="Click below if you would like to select a different plan."/>
        <button mat-raised-button [routerLink]="[ '/home' ]" class="mt-10 w-48 justify-self-center">Select Plan</button>
        </div>
    </div>
</ng-template>

  <ng-template #showPageBalance let-user="user">
    <span 
      class="inline-flex items-center rounded-md bg-blue-100 px-1.5 pt-1.5 pb-1 text-xs text-blue-700 mb-1"
      matTooltip="Page credits remaining">
      @if (groupService.currentUserGroup(); as group) {
        @if (group.pageBalance >= 0) {
          Group: {{ group.pageBalance }}
        }
        @else {
          Overage: {{ 0 - group.pageBalance }}
        }
      }
      @else if (!user.groupDocId) {
        @if (user.pageBalance < 0) {
          Overage: {{ 0 - user.pageBalance }}
        }
        @else if (user.subscriptionInfo) {
          {{ (user.subscriptionInfo.status === 'trialing' 
              ? 'Trial: ' 
              : (user.subscriptionInfo.status === 'canceled'
                ? 'Cancelled: '
                : 'Plan: ')
              ) 
              + (user.pageBalance || '0') }}
        }
        @else if (user.pageBalance) {
          {{ (user.enterpriseTrial ? 'Trial: ' : 'Beta: ') + (user.pageBalance || '0')}}
        }
      } 
    </span>  
  </ng-template>

<ng-template #profileMenu let-user="user">
  @for (item of authorizedProfileOptions(); track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {
      <lib-option-item-menu [item]="item" [user]="user" (action)="clickMenuOption($event)"/>
    }
  }
  <ng-container *ngTemplateOutlet="buildInfo"></ng-container>
</ng-template>

<ng-template #userInfo let-user="user">
  <div class="font-sans">
    <div class="text-base font-medium">
      {{ user.isAnonymousUser ? 'Anonymous' : ( user.name || user.docId ) }}
      <span class="text-blue-500">{{ user.isAdmin ? ' (Admin)' : '' }}</span>
    </div>
    <div class="text-sm font-medium text-gray-500" style="overflow-wrap: anywhere">
      {{ user.name ? user.docId : '' }}
    </div>
  </div>
</ng-template>

<ng-template #optionsMenu let-pos="position" let-user="user">
  @for (item of authorizedOptions(); track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {      
      <button mat-menu-item
        *ngIf="item[pos] === undefined || item[pos]" 
        (click)="clickMenuItem(item, user)"
        class="font-bold">
          {{ item.label }}
      </button>
    }
  }
</ng-template>

<ng-template #optionsMenuTop let-user="user">
  @for (item of authorizedOptions(); track item.label) {
    @if (!item.displayIf || item.displayIf(user)) {      
      <div
        (click)="clickMenuItem(item, user)"
        class="flex justify-center items-center cursor-pointer p-4 px-6 hover:text-chea-purple hover:bg-gray-200">
          <div [style.color]="router.url === item.routerLink ? '#c94b97' : 'black'">
            {{ item.label }}
          </div>
      </div>
    }
  }
</ng-template>

<ng-template #buildInfo> 
  @if (appInfo(); as info) {
    <mat-divider/>
    <div class="w-full text-xs text-gray-400 p-4">
      <div>Release: {{ info.releaseTag }}</div>
      <div *ngIf="info.patchLevel > 0">PatchLevel: {{ info.patchLevel }}</div>
      <div>Build: {{ info.buildTag }}</div>
    </div>
  }
</ng-template>

<ng-template #profileImage let-user="user">
  @if (user.isAnonymousUser) {
    <div class="text-chea-purple">Sign In</div>
  }
  @else if (!user.photoURL) {
    <div class="material-symbols-outlined">person</div>
  }
  @else {
    <img class="h-8 w-8 rounded-full" [src]="user.photoURL" alt="">
  }
  <!-- <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt=""> -->
</ng-template>

<ion-modal #toolParameterModal
  *ngIf="fileFlowService.showToolParameters$ | async as data"
  [isOpen]="!!data" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="fileFlowService.showToolParameters$.next(undefined)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="toolParameterModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <h1 class="pb-8 text-xl text-center font-bold w-full">{{ data.tool.name }} parameters</h1>
        <cheaseed-json-form
          [formData]="data.params" 
          (formSubmit)="fileFlowService.submitParameters($event, data.tool, data.file)"/>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #inspectModal
  *ngIf="inspectorService.showInspector$ | async as data"
  [isOpen]="!!data" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="inspectorService.showInspector$.next(undefined)"
  style="--height: 80vh; --width: 85%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 80vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="inspectModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">        
        <h1 class="pb-8 text-xl text-center font-bold w-full">{{ data.title }} </h1>
        <div class="content h-full overflow-scroll text-xs" [innerHTML]="inspectorService.renderMarkdown(data.message)"></div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

<ion-modal #editGroupModal
  [isOpen]="!!(groupService.selectedGroupDocId$ | async)" 
  canDismiss="true"
  (willDismiss)="utilityService.willDismiss()"
  (willPresent)="utilityService.willPresent()"
  (didDismiss)="groupService.selectGroup(null)"
  style="--height: 90vh; --width: 90%; --min-width: 300px; --max-width: 700px">
  <ng-template>
    <ion-content style="height: 90vh">
      <div class="absolute top-2 right-2 text-right cursor-pointer hover:bg-slate-200" (click)="editGroupModal.dismiss()">
        <span class="material-symbols-outlined text-xl">close</span>
      </div>
      <div class="p-8">
        <lib-edit-group></lib-edit-group>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

